.profil {
    color: $blue;
    font-family: Oswald, Arial, Helvetica, sans-serif;

    position: relative;

    .nav__arrow {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 2;
        &:hover {
            i {
                color: $rose;
                text-shadow: 0 0 3px $violet;
            }
        }
        i {
            font-size: calc(2vw + 20px);
            color: $violet;
            pointer-events: none;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }
    .arrowDisabled {
        opacity: 0;
        transform: translateY(-20px);
    }

    .loader {
        position: relative;
        top: 40%;
        left: 150px;
    }
    .section {
        margin: 10px 10px;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        min-height: calc(100vh - 150px);
        min-height: 450px;
        max-width: 1700px;
        scroll-snap-align: center;
        height: auto;
        @media screen and (min-width: 1700px) {
            min-height: 750px;
            margin: 0 auto;
        }
        &:nth-child(1) {
            &::before {
                display: none;
            }
        }
        &::before {
            content: "";
            background-color: $blue;
            position: absolute;
            top: 0px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 50%;
            height: 2px;
        }

        &.profil__title {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            padding-top: 90px;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            min-height: calc(100vh - 170px);
            position: relative;
            .loader {
                position: relative;
                top: 150px;
            }
            i {
                position: absolute;
                font-size: calc(2vw + 20px);
                color: $violet;
                pointer-events: none;
                -webkit-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                bottom: 5vh;
                animation: movingArrow 0.6s ease-in-out infinite;
            }

            h2 {
                font-family: Offside;
                font-size: clamp(15px, calc(1.5vw + 1rem), 90px);
                color: $violet;
                @media screen and (max-width: 475px) {
                    font-size: clamp(15px, calc(1vw + 0.8rem), 90px);
                }
            }
            h3 {
                font-size: calc(0.5vw + 0.5rem);
            }
        }
        &.profil__school {
            @media screen and (max-width: 800px) {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-bottom: 50px;
            }
        }
        &.profil__work {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
            @media screen and (max-width: 800px) {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-bottom: 50px;
            }
        }
        &.profil__cv {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
            min-height: 700px;
            margin-bottom: 50px;
            .container__left {
                text-align: center;
            }

            img {
                width: 70%;
                max-width: 425px;
            }
            @media screen and (max-width: 800px) {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                min-height: 750px;
                margin-bottom: 50px;
                img {
                    width: 240px;
                }
            }
        }

        &.profil__img {
            margin: 0px;
            max-width: none;
            .img {
                width: 100%;
                height: 40vh;
                font-size: calc(1vw + 0.4rem);
                background-image: url("../../../public/assets/img/profil__img__blur.jpg");
                background-position: center;
                background-attachment: fixed;

                -webkit-animation: hueRotate 20s infinite;

                animation: hueRotate 20s infinite;
                &:hover {
                    h2 {
                        -webkit-filter: blur(0px);
                        filter: blur(0px);
                    }
                }
                h2 {
                    line-height: 40vh;
                    text-align: center;
                    color: $rose;
                    font-family: OffSide, Arial, Helvetica, sans-serif;
                    -webkit-filter: blur(10px);
                    filter: blur(10px);
                    -webkit-transition: -webkit-filter 0.2s ease-in-out;
                    transition: -webkit-filter 0.2s ease-in-out;
                    -o-transition: filter 0.2s ease-in-out;
                    transition: filter 0.2s ease-in-out;
                    transition: filter 0.2s ease-in-out,
                        -webkit-filter 0.2s ease-in-out;
                    @media screen and (max-width: 800px) {
                        -webkit-filter: blur(0px);
                        filter: blur(0px);
                    }
                }
            }
        }
        &.profil__school {
            @media screen and (max-width: 800px) {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-bottom: 50px;
            }
        }
        &.profil__dev {
            @media screen and (max-width: 800px) {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-bottom: 50px;
            }
        }
    }
    .container__left,
    .container__right {
        width: 45%;
        border-radius: 5px;
        overflow: hidden;
        padding: 10px;
        margin-top: 10px;
        p {
            font-size: clamp(0.8rem, calc(0.8vw + 0.5rem), 1.5rem);
        }
        @media screen and (max-width: 800px) {
            width: 90%;
        }
        img {
            width: 100%;
            border-radius: 5px;
        }
    }
    .container__left {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        transform: translateX(-100px);
        -webkit-transition: all 0.6s 0.1s ease-in-out;
        -o-transition: all 0.6s 0.1s ease-in-out;
        transition: all 0.6s 0.1s ease-in-out;
        &.visible {
            opacity: 1;
            -webkit-transform: translateX(0px);
            -ms-transform: translateX(0px);
            transform: translateX(0px);
        }
    }
}

.hidden {
    opacity: 0;
}
.dynamic-text__underscore {
    display: inline-block;
    position: relative;
    bottom: 0;
    left: 10px;
}

@-webkit-keyframes hueRotate {
    0% {
        -webkit-filter: hue-rotate(0deg) sepia(0.2);
        filter: hue-rotate(0deg) sepia(0.2);
    }
    25% {
        -webkit-filter: hue-rotate(90deg) sepia(0.2);
        filter: hue-rotate(90deg) sepia(0.2);
    }
    50% {
        -webkit-filter: hue-rotate(180deg) sepia(0.2);
        filter: hue-rotate(180deg) sepia(0.2);
    }
    75% {
        -webkit-filter: hue-rotate(270deg) sepia(0.2);
        filter: hue-rotate(270deg) sepia(0.2);
    }
    100% {
        -webkit-filter: hue-rotate(360deg) sepia(0.2);
        filter: hue-rotate(360deg) sepia(0.2);
    }
}

@keyframes hueRotate {
    0% {
        -webkit-filter: hue-rotate(0deg) sepia(0.2);
        filter: hue-rotate(0deg) sepia(0.2);
    }
    25% {
        -webkit-filter: hue-rotate(90deg) sepia(0.2);
        filter: hue-rotate(90deg) sepia(0.2);
    }
    50% {
        -webkit-filter: hue-rotate(180deg) sepia(0.2);
        filter: hue-rotate(180deg) sepia(0.2);
    }
    75% {
        -webkit-filter: hue-rotate(270deg) sepia(0.2);
        filter: hue-rotate(270deg) sepia(0.2);
    }
    100% {
        -webkit-filter: hue-rotate(360deg) sepia(0.2);
        filter: hue-rotate(360deg) sepia(0.2);
    }
}

@keyframes movingArrow {
    0% {
        transform: translateY(-4px);
    }
    50% {
        transform: translateY(4px);
    }
    100% {
        transform: translateY(-4px);
    }
}
