.projets {
    position: relative;
    min-height: 100vh;
    max-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    &__container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
        padding-top: 50px;
        margin-bottom: 30px;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;

        .project__left,
        .project__main,
        .project__right {
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            -webkit-transform: translateX(-100vw);
            -ms-transform: translateX(-100vw);
            transform: translateX(-100vw);

            min-width: 100vw;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-transition: rotate 0.4s ease-in-out;
            -o-transition: rotate 0.4s ease-in-out;
            transition: rotate 0.4s ease-in-out;
            &.move__left {
                -webkit-animation: moveLeft 0.4s ease-in-out backwards;
                animation: moveLeft 0.4s ease-in-out backwards;
            }
            &.move__right {
                -webkit-animation: moveRight 0.4s ease-in-out backwards;
                animation: moveRight 0.4s ease-in-out backwards;
            }
        }
    }

    button {
        background: transparent;
        color: $violet;
        &:hover {
            color: $rose;
            text-shadow: 0 0 2px $rose;
            i {
                -webkit-transition: all 0.05s ease-in-out;
                -o-transition: all 0.05s ease-in-out;
                transition: all 0.05s ease-in-out;
                -webkit-transform: scale(1.1, 1.1);
                -ms-transform: scale(1.1, 1.1);
                transform: scale(1.1, 1.1);
            }
        }
        &:active {
            i {
                -webkit-transform: scale(0.8, 0.8);
                -ms-transform: scale(0.8, 0.8);
                transform: scale(0.8, 0.8);
            }
        }
        i {
            font-size: clamp(10px, calc(2vw + 10px), 50px);
        }
        &.chevron__left {
            top: 50%;
            left: 20px;
            @media screen and (max-width: 450px) {
                left: 5px;
            }
        }
        &.chevron__right {
            top: 50%;
            right: 20px;
            @media screen and (max-width: 450px) {
                right: 5px;
            }
        }
        position: absolute;
        z-index: 20;
    }
}

@-webkit-keyframes moveRight {
    to {
        -webkit-transform: translateX(-200vw);
        transform: translateX(-200vw);
    }
}

@keyframes moveRight {
    to {
        -webkit-transform: translateX(-200vw);
        transform: translateX(-200vw);
    }
}
@-webkit-keyframes moveLeft {
    to {
        -webkit-transform: translateX(0vw);
        transform: translateX(0vw);
    }
}
@keyframes moveLeft {
    to {
        -webkit-transform: translateX(0vw);
        transform: translateX(0vw);
    }
}
