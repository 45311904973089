.project {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 50%;
    max-width: 900px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 300px;

    &__container {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        aspect-ratio: 16/9;
        overflow: hidden;

        border-radius: 10px;
        -webkit-box-shadow: 15px 20px 15px $blue__shadow;
        box-shadow: 15px 20px 15px $blue__shadow;

        border: 1px solid $violet;
        margin-bottom: 10px;
        cursor: pointer;
        position: relative;
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        transition: -webkit-transform 0.3s ease-in-out;
        -o-transition: transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out,
            -webkit-transform 0.3s ease-in-out;
        &:hover {
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);
            & + .project__side {
                .project__techno {
                    span {
                        -webkit-transform: translateX(0);
                        -ms-transform: translateX(0);
                        transform: translateX(0);
                    }
                }
                .project__date {
                    span {
                        -webkit-transform: translateY(0);
                        -ms-transform: translateY(0);
                        transform: translateY(0);
                    }
                }
            }
            .project__info {
                -webkit-transform: scaleY(0.85);
                -ms-transform: scaleY(0.85);
                transform: scaleY(0.85);
                h2 {
                    opacity: 1;
                    -webkit-transform: translateY(0);
                    -ms-transform: translateY(0);
                    transform: translateY(0);
                }
            }
        }
        .project__info {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            box-shadow: inset 0px 0px 10px $violet;

            border: 1px solid $violet;
            box-shadow: inset 0px 0px 20px 2px darken($violet, 15%);

            z-index: 3;
            position: absolute;
            background-color: $violet__shadow;
            -webkit-transform: scaleY(0);
            -ms-transform: scaleY(0);
            transform: scaleY(0);
            -webkit-transform-origin: bottom;
            -ms-transform-origin: bottom;
            transform-origin: bottom;
            -webkit-transition: -webkit-transform 0.3s ease-in-out;
            transition: -webkit-transform 0.3s ease-in-out;
            -o-transition: transform 0.3s ease-in-out;
            transition: transform 0.3s ease-in-out;
            transition: transform 0.3s ease-in-out,
                -webkit-transform 0.3s ease-in-out;
            padding: 15px 10px 10px;
            color: $rose;
            h2 {
                -webkit-transform: translateY(100%);
                -ms-transform: translateY(100%);
                transform: translateY(100%);
                opacity: 0;
                -webkit-transition: all 0.2s 0.1s ease-in-out;
                -o-transition: all 0.2s 0.1s ease-in-out;
                transition: all 0.2s 0.1s ease-in-out;
                font-family: Offside;
                margin-bottom: 15px;
                font-size: clamp(0.6rem, calc(2vw + 0.5rem), 2.2rem);
                @media screen and (max-width: 600px) {
                    font-size: calc(1.5vw + 0.3rem);
                }
            }
            p {
                width: 70%;
                font-size: clamp(0.6rem, calc(1vw + 0.3rem), 1.5rem);
                font-family: oswald;
                font-weight: 200;
                line-height: clamp(0.5rem, calc(2vw + 5px), 2.5rem);
                @media screen and (max-width: 600px) {
                    width: 90%;
                    font-size: calc(1.2vw + 0.2rem);
                }
            }
        }
        img {
            background-size: contain;
            width: 100%;
        }
    }
    &__side {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        font-size: clamp(0.4rem, calc(0.5vw + 0.3rem), 1.3rem);
        margin-top: 20px;
        color: $violet;
        font-weight: 600;
        font-family: Offside, Arial, Helvetica, sans-serif;
        margin-bottom: 30px;

        .project__techno {
            position: relative;

            border-left: 2px solid $violet;
            margin: 0 20px 0 0;
            overflow: hidden;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding: 10px;
            @media screen and (max-width: 800px) {
                padding: 0 0 0 5px;
            }
            span {
                -webkit-transform: translateX(-150%);
                -ms-transform: translateX(-150%);
                transform: translateX(-150%);
                -webkit-transition: -webkit-transform 0.2s ease-in-out;
                transition: -webkit-transform 0.2s ease-in-out;
                -o-transition: transform 0.2s ease-in-out;
                transition: transform 0.2s ease-in-out;
                transition: transform 0.2s ease-in-out,
                    -webkit-transform 0.2s ease-in-out;
                line-height: 20px;
                @media screen and (max-width: 600px) {
                    line-height: 10px;
                }
            }
        }
        .project__date {
            padding: 10px;
            border-top: 2px solid $violet;
            overflow: hidden;
            @media screen and (max-width: 650px) {
                padding: 10px 0 0 0;
            }
            span {
                display: block;
                -webkit-transition: -webkit-transform 0.2s ease-in-out;
                transition: -webkit-transform 0.2s ease-in-out;
                -o-transition: transform 0.2s ease-in-out;
                transition: transform 0.2s ease-in-out;
                transition: transform 0.2s ease-in-out,
                    -webkit-transform 0.2s ease-in-out;
                -webkit-transform: translateY(-200%);
                -ms-transform: translateY(-200%);
                transform: translateY(-200%);
                @media screen and (max-width: 600px) {
                    -webkit-transform: translateY(-250%);
                    -ms-transform: translateY(-250%);
                    transform: translateY(-250%);
                }
            }
        }
    }

    .navbtn {
        font-size: clamp(0.4rem, calc(1vw + 0.3rem), 1.3rem);
        height: 50px;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        opacity: 1;
        max-width: 550px;
        min-width: 100px;
        margin: 0 0 0 20px;
        padding: 0px 30px;

        line-height: 30px;
        position: relative;
        overflow: hidden;

        -webkit-animation: none;

        animation: none;
    }
}
