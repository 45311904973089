.social__network {
    ul {
        &#social__network--content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            position: relative;
            left: 0;
        }
        a {
            margin: 5px;
            padding: 1.5rem;
            -webkit-transition: 0.8s ease-out;
            -o-transition: 0.8s ease-out;
            transition: 0.8s ease-out;
            @media screen and (max-width: 800px) {
                margin: 5px 0;
            }
            &:hover {
                i {
                    color: $rose;
                    text-shadow: 0 0 4px $violet;
                }
            }
            i {
                -webkit-transition: 0.1s ease-out;
                -o-transition: 0.1s ease-out;
                transition: 0.1s ease-out;
                font-size: calc(1vw + 1rem);
                color: $violet;
                text-shadow: 0 0 2px $rose;
                pointer-events: none;
            }
        }
    }
}
@media screen and (max-width: 700px) {
    .social__network {
        ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            a {
                margin: 0px;
                padding: 1rem;
                -webkit-transition: 0.8s ease-out;
                -o-transition: 0.8s ease-out;
                transition: 0.8s ease-out;
                &:hover {
                    i {
                        color: $rose;
                        text-shadow: 0 0 4px $violet;
                    }
                }
                i {
                    -webkit-transition: 0.1s ease-out;
                    -o-transition: 0.1s ease-out;
                    transition: 0.1s ease-out;
                    font-size: calc(1vw + 1rem);
                    color: $violet;
                    text-shadow: 0 0 2px $rose;
                    pointer-events: none;
                }
            }
        }
    }
}
