.header {
    position: relative;
    height: 150px;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(lighten($violet, 30%)),
        to(lighten($light__blue, 47%))
    );
    background: -o-linear-gradient(
        top,
        lighten($violet, 30%),
        lighten($light__blue, 47%)
    );
    background: linear-gradient(
        to bottom,
        lighten($violet, 30%),
        lighten($light__blue, 47%)
    );
    -webkit-box-shadow: 0 0 105px 185px lighten($light__blue, 47%);
    box-shadow: 0 0 105px 185px lighten($light__blue, 47%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .backToMainPage {
        display: none;
        position: fixed;
        top: 20px;
        left: 20px;
        background: transparent;
        z-index: 4;
        @media screen and (max-width: 700px) {
            display: block;
        }
        &:hover {
            i {
                color: $rose;
                text-shadow: 0 0 15px $rose;
                -webkit-transform: translateX(-10px) scale(1.2, 1.2);
                -ms-transform: translateX(-10px) scale(1.2, 1.2);
                transform: translateX(-10px) scale(1.2, 1.2);
            }
        }
        i {
            font-size: calc(2vw + 20px);
            color: $violet;
            pointer-events: none;
            -webkit-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }
    }
    h2 {
        opacity: 0;
        position: relative;
        top: 10px;
        font-family: Offside, Arial, Helvetica, sans-serif;
        font-size: clamp(1rem, calc(2vw + 1rem), 3rem);
        color: darken($violet, 5%);
        text-align: center;
        border: 2px solid darken($violet, 5%);
        border-radius: 10px;
        padding: 10px 10px;
        -webkit-box-shadow: 0 0 15px darken($violet, 5%),
            inset 0 0 15px darken($violet, 5%);
        box-shadow: 0 0 15px darken($violet, 5%),
            inset 0 0 15px darken($violet, 5%);
        text-shadow: 0 0 15px darken($violet, 5%);
        -webkit-animation: pulse 2s infinite,
            apparition 0.4s forwards ease-in-out;
        animation: pulse 2s infinite, apparition 0.4s forwards ease-in-out;
    }
}

@-webkit-keyframes apparition {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes apparition {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes pulse {
    0% {
        transform: scale(0.95);
        text-shadow: 0 0 50px $violet__shadow;
        box-shadow: 0 0 0 0 $violet__shadow;
    }

    70% {
        transform: scale(1);
        text-shadow: 0 0 100px $violet;
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        text-shadow: 0 0 50px $violet;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
@keyframes pulse {
    0% {
        transform: scale(0.95);
        text-shadow: 0 0 50px $violet__shadow;
        box-shadow: 0 0 0 0 $violet__shadow;
    }

    70% {
        transform: scale(1);
        text-shadow: 0 0 100px $violet;
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        text-shadow: 0 0 50px $violet;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
