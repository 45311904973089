.nav {
    text-align: center;
    margin-top: calc(8vh + 50px);
    position: relative;
    i {
        font-size: 50px;
        color: $blue;
    }
}
.navbtn {
    height: 50px;
    border-radius: 10px;
    opacity: 0;
    background: none;
    padding: 0px 30px;
    font-size: clamp(1rem, calc(0.8rem + 1vw), 2rem);
    border: 2px solid $violet;
    color: $violet;
    line-height: 40px;
    position: relative;
    overflow: hidden;
    transform-origin: left;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-animation: button 750ms 4s cubic-bezier(0.175, 0.885, 0.32, 1.275)
        forwards;
    animation: button 750ms 4s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $violet;
        z-index: -1;
        opacity: 0;
        -webkit-transform: scale(0, 0.2) rotate(80deg);
        -ms-transform: scale(0, 0.2) rotate(80deg);
        transform: scale(0, 0.2) rotate(80deg);
        -webkit-transition: -webkit-transform 0.2s ease-in-out;
        transition: -webkit-transform 0.2s ease-in-out;
        -o-transition: transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out,
            -webkit-transform 0.2s ease-in-out;
        -webkit-box-shadow: 0px 0px 5px $rose, inset 0px 0px 5px $rose;
        box-shadow: 0px 0px 5px $rose, inset 0px 0px 5px $rose;
    }

    &:hover {
        -webkit-box-shadow: 0px 0px 5px $rose, inset 0px 0px 5px $rose;
        box-shadow: 0px 0px 5px $rose, inset 0px 0px 5px $rose;
        color: $rose;
        border: 2px solid $rose;
        -webkit-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
        @media screen and (max-width: 800px) {
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        &::before {
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            transform: scale(1, 1);
            opacity: 1;
        }
    }
    &:active {
        @media screen and (max-width: 800px) {
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
    @-webkit-keyframes button {
        from {
            opacity: 0;
            -webkit-transform: translateX(50vw) scale(0, 0);
            transform: translateX(50vw) scale(0, 0);
        }
        to {
            opacity: 1;
            -webkit-transform: translateX(0vw) scale(1, 1);
            transform: translateX(0vw) scale(1, 1);
        }
    }
    @keyframes button {
        from {
            opacity: 0;
            -webkit-transform: translateX(50vw) scale(0, 0);
            transform: translateX(50vw) scale(0, 0);
        }
        to {
            opacity: 1;
            -webkit-transform: translateX(0vw) scale(1, 1);
            transform: translateX(0vw) scale(1, 1);
        }
    }
    @-webkit-keyframes button2 {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes button2 {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

.nav {
    .closed {
        display: none;
    }
    button.animationDone {
        -webkit-animation: button2 750ms ease-in-out forwards;
        animation: button2 750ms ease-in-out forwards;
    }
}
