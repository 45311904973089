$runner__black: rgb(22, 22, 22);
$runner__hair: rgb(139, 102, 68);
$runner__suit: rgb(134, 44, 44);
$runner__shadow: rgb(57, 62, 80);
$runner__eyes: rgb(124, 199, 180);
$runner__skin: rgb(236, 227, 197);
$runner__white: rgb(255, 255, 255);
$pixel: 5px;

.loader {
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    z-index: 1;
    &.complete {
        &.regular {
            opacity: 1;
        }
        opacity: 0;
        -webkit-transition: opacity 0.6s ease-out;
        -o-transition: opacity 0.6s ease-out;
        transition: opacity 0.6s ease-out;
    }

    .runner__frame1,
    .runner__frame2,
    .runner__frame3,
    .runner__frame4,
    .runner__frame5,
    .runner__frame6,
    .runner__frame7,
    .runner__frame8,
    .runner__frame1--shadow,
    .runner__frame2--shadow,
    .runner__frame3--shadow,
    .runner__frame4--shadow,
    .runner__frame5--shadow,
    .runner__frame6--shadow,
    .runner__frame7--shadow,
    .runner__frame8--shadow {
        width: $pixel;
        height: $pixel;
        position: relative;
        z-index: 2;
        right: 70px;
    }

    .frame {
        position: absolute;

        width: 100%;
        height: 100%;
        opacity: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        &.frame__1 {
            -webkit-animation-name: running;
            animation-name: running;
            -webkit-animation-duration: 800ms;
            animation-duration: 800ms;
            -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
        &.frame__2 {
            -webkit-animation-name: running;
            animation-name: running;
            -webkit-animation-duration: 800ms;
            animation-duration: 800ms;
            -webkit-animation-delay: 100ms;
            animation-delay: 100ms;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
        &.frame__3 {
            -webkit-animation-name: running;
            animation-name: running;
            -webkit-animation-duration: 800ms;
            animation-duration: 800ms;
            -webkit-animation-delay: 200ms;
            animation-delay: 200ms;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
        &.frame__4 {
            -webkit-animation-name: running;
            animation-name: running;
            -webkit-animation-duration: 800ms;
            animation-duration: 800ms;
            -webkit-animation-delay: 300ms;
            animation-delay: 300ms;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
        &.frame__5 {
            -webkit-animation-name: running;
            animation-name: running;
            -webkit-animation-duration: 800ms;
            animation-duration: 800ms;
            -webkit-animation-delay: 400ms;
            animation-delay: 400ms;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
        &.frame__6 {
            -webkit-animation-name: running;
            animation-name: running;
            -webkit-animation-duration: 800ms;
            animation-duration: 800ms;
            -webkit-animation-delay: 500ms;
            animation-delay: 500ms;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
        &.frame__7 {
            -webkit-animation-name: running;
            animation-name: running;
            -webkit-animation-duration: 800ms;
            animation-duration: 800ms;
            -webkit-animation-delay: 600ms;
            animation-delay: 600ms;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
        &.frame__8 {
            -webkit-animation-name: running;
            animation-name: running;
            -webkit-animation-duration: 800ms;
            animation-duration: 800ms;
            -webkit-animation-delay: 700ms;
            animation-delay: 700ms;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
    }

    @-webkit-keyframes running {
        0% {
            opacity: 1;
        }
        12.5% {
            opacity: 1;
        }
        12.6% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes running {
        0% {
            opacity: 1;
        }
        12.5% {
            opacity: 1;
        }
        12.6% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    .runner__frame1 {
        -webkit-box-shadow: calc($pixel * 1) calc($pixel * 0) $runner__black,
            calc($pixel * 2) calc($pixel * 0) $runner__skin,
            calc($pixel * 3) calc($pixel * 0) $runner__skin,
            calc($pixel * 1) calc($pixel * -1) $runner__black,
            calc($pixel * 2) calc($pixel * -1) $runner__suit,
            calc($pixel * 3) calc($pixel * -1) $runner__black,
            calc($pixel * 1) calc($pixel * -2) $runner__black,
            calc($pixel * 2) calc($pixel * -2) $runner__suit,
            calc($pixel * 3) calc($pixel * -2) $runner__suit,
            calc($pixel * 4) calc($pixel * -2) $runner__black,
            calc($pixel * 5) calc($pixel * -2) $runner__black,
            calc($pixel * 7) calc($pixel * -2) $runner__skin,
            calc($pixel * 2) calc($pixel * -3) $runner__black,
            calc($pixel * 3) calc($pixel * -3) $runner__suit,
            calc($pixel * 4) calc($pixel * -3) $runner__suit,
            calc($pixel * 5) calc($pixel * -3) $runner__black,
            calc($pixel * 6) calc($pixel * -3) $runner__black,
            calc($pixel * 7) calc($pixel * -3) $runner__black,
            calc($pixel * 8) calc($pixel * -3) $runner__black,
            calc($pixel * 9) calc($pixel * -3) $runner__black,
            calc($pixel * 10) calc($pixel * -3) $runner__black,
            calc($pixel * 11) calc($pixel * -3) $runner__black,
            calc($pixel * 3) calc($pixel * -4) $runner__black,
            calc($pixel * 4) calc($pixel * -4) $runner__suit,
            calc($pixel * 5) calc($pixel * -4) $runner__black,
            calc($pixel * 6) calc($pixel * -4) $runner__skin,
            calc($pixel * 7) calc($pixel * -4) $runner__skin,
            calc($pixel * 8) calc($pixel * -4) $runner__skin,
            calc($pixel * 9) calc($pixel * -4) $runner__skin,
            calc($pixel * 10) calc($pixel * -4) $runner__skin,
            calc($pixel * 11) calc($pixel * -4) $runner__black,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 13) calc($pixel * -4) $runner__black,
            calc($pixel * 4) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -5) $runner__skin,
            calc($pixel * 6) calc($pixel * -5) $runner__skin,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__black,
            calc($pixel * 9) calc($pixel * -5) $runner__black,
            calc($pixel * 10) calc($pixel * -5) $runner__black,
            calc($pixel * 11) calc($pixel * -5) $runner__black,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 14) calc($pixel * -5) $runner__black,
            calc($pixel * 15) calc($pixel * -5) $runner__black,
            calc($pixel * 16) calc($pixel * -5) $runner__black,
            calc($pixel * 17) calc($pixel * -5) $runner__black,
            calc($pixel * 18) calc($pixel * -5) $runner__black,
            calc($pixel * 19) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -6) $runner__black,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__black,
            calc($pixel * 8) calc($pixel * -6) $runner__black,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__black,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__suit,
            calc($pixel * 13) calc($pixel * -6) $runner__suit,
            calc($pixel * 14) calc($pixel * -6) $runner__suit,
            calc($pixel * 15) calc($pixel * -6) $runner__suit,
            calc($pixel * 16) calc($pixel * -6) $runner__black,
            calc($pixel * 17) calc($pixel * -6) $runner__skin,
            calc($pixel * 18) calc($pixel * -6) $runner__black,
            calc($pixel * 19) calc($pixel * -6) $runner__suit,
            calc($pixel * 6) calc($pixel * -7) $runner__black,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__black,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__suit,
            calc($pixel * 12) calc($pixel * -7) $runner__suit,
            calc($pixel * 13) calc($pixel * -7) $runner__suit,
            calc($pixel * 14) calc($pixel * -7) $runner__suit,
            calc($pixel * 15) calc($pixel * -7) $runner__suit,
            calc($pixel * 16) calc($pixel * -7) $runner__black,
            calc($pixel * 17) calc($pixel * -7) $runner__black,
            calc($pixel * 18) calc($pixel * -7) $runner__black,
            calc($pixel * 19) calc($pixel * -7) $runner__suit,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__black,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__suit,
            calc($pixel * 12) calc($pixel * -8) $runner__suit,
            calc($pixel * 13) calc($pixel * -8) $runner__black,
            calc($pixel * 14) calc($pixel * -8) $runner__suit,
            calc($pixel * 15) calc($pixel * -8) $runner__suit,
            calc($pixel * 16) calc($pixel * -8) $runner__suit,
            calc($pixel * 17) calc($pixel * -8) $runner__black,
            calc($pixel * 18) calc($pixel * -8) $runner__skin,
            calc($pixel * 19) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__suit,
            calc($pixel * 12) calc($pixel * -9) $runner__suit,
            calc($pixel * 13) calc($pixel * -9) $runner__suit,
            calc($pixel * 14) calc($pixel * -9) $runner__black,
            calc($pixel * 15) calc($pixel * -9) $runner__black,
            calc($pixel * 16) calc($pixel * -9) $runner__black,
            calc($pixel * 17) calc($pixel * -9) $runner__black,
            calc($pixel * 18) calc($pixel * -9) $runner__suit,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__suit,
            calc($pixel * 13) calc($pixel * -10) $runner__suit,
            calc($pixel * 14) calc($pixel * -10) $runner__suit,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__suit,
            calc($pixel * 17) calc($pixel * -10) $runner__suit,
            calc($pixel * 18) calc($pixel * -10) $runner__suit,
            calc($pixel * 19) calc($pixel * -10) $runner__skin,
            calc($pixel * 20) calc($pixel * -10) $runner__skin,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__hair,
            calc($pixel * 13) calc($pixel * -11) $runner__hair,
            calc($pixel * 14) calc($pixel * -11) $runner__hair,
            calc($pixel * 15) calc($pixel * -11) $runner__hair,
            calc($pixel * 16) calc($pixel * -11) $runner__hair,
            calc($pixel * 17) calc($pixel * -11) $runner__hair,
            calc($pixel * 18) calc($pixel * -11) $runner__skin,
            calc($pixel * 19) calc($pixel * -11) $runner__skin,
            calc($pixel * 20) calc($pixel * -11) $runner__skin,
            calc($pixel * 21) calc($pixel * -11) $runner__skin,
            calc($pixel * 9) calc($pixel * -12) $runner__black,
            calc($pixel * 10) calc($pixel * -12) $runner__hair,
            calc($pixel * 11) calc($pixel * -12) $runner__hair,
            calc($pixel * 12) calc($pixel * -12) $runner__hair,
            calc($pixel * 13) calc($pixel * -12) $runner__hair,
            calc($pixel * 14) calc($pixel * -12) $runner__hair,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__black,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__hair,
            calc($pixel * 19) calc($pixel * -12) $runner__skin,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__eyes,
            calc($pixel * 8) calc($pixel * -13) $runner__black,
            calc($pixel * 9) calc($pixel * -13) $runner__hair,
            calc($pixel * 10) calc($pixel * -13) $runner__hair,
            calc($pixel * 11) calc($pixel * -13) $runner__hair,
            calc($pixel * 12) calc($pixel * -13) $runner__hair,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__black,
            calc($pixel * 15) calc($pixel * -13) $runner__black,
            calc($pixel * 16) calc($pixel * -13) $runner__black,
            calc($pixel * 17) calc($pixel * -13) $runner__black,
            calc($pixel * 18) calc($pixel * -13) $runner__black,
            calc($pixel * 19) calc($pixel * -13) $runner__skin,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__eyes,
            calc($pixel * 8) calc($pixel * -14) $runner__hair,
            calc($pixel * 9) calc($pixel * -14) $runner__hair,
            calc($pixel * 10) calc($pixel * -14) $runner__hair,
            calc($pixel * 11) calc($pixel * -14) $runner__hair,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__black,
            calc($pixel * 15) calc($pixel * -14) $runner__suit,
            calc($pixel * 16) calc($pixel * -14) $runner__suit,
            calc($pixel * 17) calc($pixel * -14) $runner__black,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__hair,
            calc($pixel * 21) calc($pixel * -14) $runner__hair,
            calc($pixel * 22) calc($pixel * -14) $runner__hair,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__black,
            calc($pixel * 15) calc($pixel * -15) $runner__white,
            calc($pixel * 16) calc($pixel * -15) $runner__suit,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__hair,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__black,
            calc($pixel * 22) calc($pixel * -15) $runner__hair,
            calc($pixel * 23) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__black,
            calc($pixel * 15) calc($pixel * -16) $runner__black,
            calc($pixel * 16) calc($pixel * -16) $runner__suit,
            calc($pixel * 17) calc($pixel * -16) $runner__hair,
            calc($pixel * 18) calc($pixel * -16) $runner__hair,
            calc($pixel * 19) calc($pixel * -16) $runner__hair,
            calc($pixel * 20) calc($pixel * -16) $runner__hair,
            calc($pixel * 21) calc($pixel * -16) $runner__hair,
            calc($pixel * 22) calc($pixel * -16) $runner__black,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__hair,
            calc($pixel * 16) calc($pixel * -17) $runner__suit,
            calc($pixel * 17) calc($pixel * -17) $runner__hair,
            calc($pixel * 18) calc($pixel * -17) $runner__hair,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__hair,
            calc($pixel * 21) calc($pixel * -17) $runner__hair,
            calc($pixel * 22) calc($pixel * -17) $runner__hair,
            calc($pixel * 23) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__white,
            calc($pixel * 15) calc($pixel * -18) $runner__white,
            calc($pixel * 16) calc($pixel * -18) $runner__suit,
            calc($pixel * 17) calc($pixel * -18) $runner__white,
            calc($pixel * 18) calc($pixel * -18) $runner__white,
            calc($pixel * 19) calc($pixel * -18) $runner__white,
            calc($pixel * 20) calc($pixel * -18) $runner__white,
            calc($pixel * 21) calc($pixel * -18) $runner__white,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__white,
            calc($pixel * 13) calc($pixel * -19) $runner__white,
            calc($pixel * 14) calc($pixel * -19) $runner__white,
            calc($pixel * 15) calc($pixel * -19) $runner__hair,
            calc($pixel * 16) calc($pixel * -19) $runner__suit,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__hair,
            calc($pixel * 19) calc($pixel * -19) $runner__hair,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__white,
            calc($pixel * 12) calc($pixel * -20) $runner__white,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__hair,
            calc($pixel * 15) calc($pixel * -20) $runner__hair,
            calc($pixel * 16) calc($pixel * -20) $runner__suit,
            calc($pixel * 17) calc($pixel * -20) $runner__hair,
            calc($pixel * 18) calc($pixel * -20) $runner__hair,
            calc($pixel * 19) calc($pixel * -20) $runner__hair,
            calc($pixel * 20) calc($pixel * -20) $runner__hair,
            calc($pixel * 21) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__hair,
            calc($pixel * 14) calc($pixel * -21) $runner__hair,
            calc($pixel * 15) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -21) $runner__suit,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__suit,
            calc($pixel * 17) calc($pixel * -22) $runner__black;
        box-shadow: calc($pixel * 1) calc($pixel * 0) $runner__black,
            calc($pixel * 2) calc($pixel * 0) $runner__skin,
            calc($pixel * 3) calc($pixel * 0) $runner__skin,
            calc($pixel * 1) calc($pixel * -1) $runner__black,
            calc($pixel * 2) calc($pixel * -1) $runner__suit,
            calc($pixel * 3) calc($pixel * -1) $runner__black,
            calc($pixel * 1) calc($pixel * -2) $runner__black,
            calc($pixel * 2) calc($pixel * -2) $runner__suit,
            calc($pixel * 3) calc($pixel * -2) $runner__suit,
            calc($pixel * 4) calc($pixel * -2) $runner__black,
            calc($pixel * 5) calc($pixel * -2) $runner__black,
            calc($pixel * 7) calc($pixel * -2) $runner__skin,
            calc($pixel * 2) calc($pixel * -3) $runner__black,
            calc($pixel * 3) calc($pixel * -3) $runner__suit,
            calc($pixel * 4) calc($pixel * -3) $runner__suit,
            calc($pixel * 5) calc($pixel * -3) $runner__black,
            calc($pixel * 6) calc($pixel * -3) $runner__black,
            calc($pixel * 7) calc($pixel * -3) $runner__black,
            calc($pixel * 8) calc($pixel * -3) $runner__black,
            calc($pixel * 9) calc($pixel * -3) $runner__black,
            calc($pixel * 10) calc($pixel * -3) $runner__black,
            calc($pixel * 11) calc($pixel * -3) $runner__black,
            calc($pixel * 3) calc($pixel * -4) $runner__black,
            calc($pixel * 4) calc($pixel * -4) $runner__suit,
            calc($pixel * 5) calc($pixel * -4) $runner__black,
            calc($pixel * 6) calc($pixel * -4) $runner__skin,
            calc($pixel * 7) calc($pixel * -4) $runner__skin,
            calc($pixel * 8) calc($pixel * -4) $runner__skin,
            calc($pixel * 9) calc($pixel * -4) $runner__skin,
            calc($pixel * 10) calc($pixel * -4) $runner__skin,
            calc($pixel * 11) calc($pixel * -4) $runner__black,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 13) calc($pixel * -4) $runner__black,
            calc($pixel * 4) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -5) $runner__skin,
            calc($pixel * 6) calc($pixel * -5) $runner__skin,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__black,
            calc($pixel * 9) calc($pixel * -5) $runner__black,
            calc($pixel * 10) calc($pixel * -5) $runner__black,
            calc($pixel * 11) calc($pixel * -5) $runner__black,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 14) calc($pixel * -5) $runner__black,
            calc($pixel * 15) calc($pixel * -5) $runner__black,
            calc($pixel * 16) calc($pixel * -5) $runner__black,
            calc($pixel * 17) calc($pixel * -5) $runner__black,
            calc($pixel * 18) calc($pixel * -5) $runner__black,
            calc($pixel * 19) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -6) $runner__black,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__black,
            calc($pixel * 8) calc($pixel * -6) $runner__black,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__black,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__suit,
            calc($pixel * 13) calc($pixel * -6) $runner__suit,
            calc($pixel * 14) calc($pixel * -6) $runner__suit,
            calc($pixel * 15) calc($pixel * -6) $runner__suit,
            calc($pixel * 16) calc($pixel * -6) $runner__black,
            calc($pixel * 17) calc($pixel * -6) $runner__skin,
            calc($pixel * 18) calc($pixel * -6) $runner__black,
            calc($pixel * 19) calc($pixel * -6) $runner__suit,
            calc($pixel * 6) calc($pixel * -7) $runner__black,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__black,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__suit,
            calc($pixel * 12) calc($pixel * -7) $runner__suit,
            calc($pixel * 13) calc($pixel * -7) $runner__suit,
            calc($pixel * 14) calc($pixel * -7) $runner__suit,
            calc($pixel * 15) calc($pixel * -7) $runner__suit,
            calc($pixel * 16) calc($pixel * -7) $runner__black,
            calc($pixel * 17) calc($pixel * -7) $runner__black,
            calc($pixel * 18) calc($pixel * -7) $runner__black,
            calc($pixel * 19) calc($pixel * -7) $runner__suit,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__black,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__suit,
            calc($pixel * 12) calc($pixel * -8) $runner__suit,
            calc($pixel * 13) calc($pixel * -8) $runner__black,
            calc($pixel * 14) calc($pixel * -8) $runner__suit,
            calc($pixel * 15) calc($pixel * -8) $runner__suit,
            calc($pixel * 16) calc($pixel * -8) $runner__suit,
            calc($pixel * 17) calc($pixel * -8) $runner__black,
            calc($pixel * 18) calc($pixel * -8) $runner__skin,
            calc($pixel * 19) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__suit,
            calc($pixel * 12) calc($pixel * -9) $runner__suit,
            calc($pixel * 13) calc($pixel * -9) $runner__suit,
            calc($pixel * 14) calc($pixel * -9) $runner__black,
            calc($pixel * 15) calc($pixel * -9) $runner__black,
            calc($pixel * 16) calc($pixel * -9) $runner__black,
            calc($pixel * 17) calc($pixel * -9) $runner__black,
            calc($pixel * 18) calc($pixel * -9) $runner__suit,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__suit,
            calc($pixel * 13) calc($pixel * -10) $runner__suit,
            calc($pixel * 14) calc($pixel * -10) $runner__suit,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__suit,
            calc($pixel * 17) calc($pixel * -10) $runner__suit,
            calc($pixel * 18) calc($pixel * -10) $runner__suit,
            calc($pixel * 19) calc($pixel * -10) $runner__skin,
            calc($pixel * 20) calc($pixel * -10) $runner__skin,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__hair,
            calc($pixel * 13) calc($pixel * -11) $runner__hair,
            calc($pixel * 14) calc($pixel * -11) $runner__hair,
            calc($pixel * 15) calc($pixel * -11) $runner__hair,
            calc($pixel * 16) calc($pixel * -11) $runner__hair,
            calc($pixel * 17) calc($pixel * -11) $runner__hair,
            calc($pixel * 18) calc($pixel * -11) $runner__skin,
            calc($pixel * 19) calc($pixel * -11) $runner__skin,
            calc($pixel * 20) calc($pixel * -11) $runner__skin,
            calc($pixel * 21) calc($pixel * -11) $runner__skin,
            calc($pixel * 9) calc($pixel * -12) $runner__black,
            calc($pixel * 10) calc($pixel * -12) $runner__hair,
            calc($pixel * 11) calc($pixel * -12) $runner__hair,
            calc($pixel * 12) calc($pixel * -12) $runner__hair,
            calc($pixel * 13) calc($pixel * -12) $runner__hair,
            calc($pixel * 14) calc($pixel * -12) $runner__hair,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__black,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__hair,
            calc($pixel * 19) calc($pixel * -12) $runner__skin,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__eyes,
            calc($pixel * 8) calc($pixel * -13) $runner__black,
            calc($pixel * 9) calc($pixel * -13) $runner__hair,
            calc($pixel * 10) calc($pixel * -13) $runner__hair,
            calc($pixel * 11) calc($pixel * -13) $runner__hair,
            calc($pixel * 12) calc($pixel * -13) $runner__hair,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__black,
            calc($pixel * 15) calc($pixel * -13) $runner__black,
            calc($pixel * 16) calc($pixel * -13) $runner__black,
            calc($pixel * 17) calc($pixel * -13) $runner__black,
            calc($pixel * 18) calc($pixel * -13) $runner__black,
            calc($pixel * 19) calc($pixel * -13) $runner__skin,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__eyes,
            calc($pixel * 8) calc($pixel * -14) $runner__hair,
            calc($pixel * 9) calc($pixel * -14) $runner__hair,
            calc($pixel * 10) calc($pixel * -14) $runner__hair,
            calc($pixel * 11) calc($pixel * -14) $runner__hair,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__black,
            calc($pixel * 15) calc($pixel * -14) $runner__suit,
            calc($pixel * 16) calc($pixel * -14) $runner__suit,
            calc($pixel * 17) calc($pixel * -14) $runner__black,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__hair,
            calc($pixel * 21) calc($pixel * -14) $runner__hair,
            calc($pixel * 22) calc($pixel * -14) $runner__hair,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__black,
            calc($pixel * 15) calc($pixel * -15) $runner__white,
            calc($pixel * 16) calc($pixel * -15) $runner__suit,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__hair,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__black,
            calc($pixel * 22) calc($pixel * -15) $runner__hair,
            calc($pixel * 23) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__black,
            calc($pixel * 15) calc($pixel * -16) $runner__black,
            calc($pixel * 16) calc($pixel * -16) $runner__suit,
            calc($pixel * 17) calc($pixel * -16) $runner__hair,
            calc($pixel * 18) calc($pixel * -16) $runner__hair,
            calc($pixel * 19) calc($pixel * -16) $runner__hair,
            calc($pixel * 20) calc($pixel * -16) $runner__hair,
            calc($pixel * 21) calc($pixel * -16) $runner__hair,
            calc($pixel * 22) calc($pixel * -16) $runner__black,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__hair,
            calc($pixel * 16) calc($pixel * -17) $runner__suit,
            calc($pixel * 17) calc($pixel * -17) $runner__hair,
            calc($pixel * 18) calc($pixel * -17) $runner__hair,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__hair,
            calc($pixel * 21) calc($pixel * -17) $runner__hair,
            calc($pixel * 22) calc($pixel * -17) $runner__hair,
            calc($pixel * 23) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__white,
            calc($pixel * 15) calc($pixel * -18) $runner__white,
            calc($pixel * 16) calc($pixel * -18) $runner__suit,
            calc($pixel * 17) calc($pixel * -18) $runner__white,
            calc($pixel * 18) calc($pixel * -18) $runner__white,
            calc($pixel * 19) calc($pixel * -18) $runner__white,
            calc($pixel * 20) calc($pixel * -18) $runner__white,
            calc($pixel * 21) calc($pixel * -18) $runner__white,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__white,
            calc($pixel * 13) calc($pixel * -19) $runner__white,
            calc($pixel * 14) calc($pixel * -19) $runner__white,
            calc($pixel * 15) calc($pixel * -19) $runner__hair,
            calc($pixel * 16) calc($pixel * -19) $runner__suit,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__hair,
            calc($pixel * 19) calc($pixel * -19) $runner__hair,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__white,
            calc($pixel * 12) calc($pixel * -20) $runner__white,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__hair,
            calc($pixel * 15) calc($pixel * -20) $runner__hair,
            calc($pixel * 16) calc($pixel * -20) $runner__suit,
            calc($pixel * 17) calc($pixel * -20) $runner__hair,
            calc($pixel * 18) calc($pixel * -20) $runner__hair,
            calc($pixel * 19) calc($pixel * -20) $runner__hair,
            calc($pixel * 20) calc($pixel * -20) $runner__hair,
            calc($pixel * 21) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__hair,
            calc($pixel * 14) calc($pixel * -21) $runner__hair,
            calc($pixel * 15) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -21) $runner__suit,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__suit,
            calc($pixel * 17) calc($pixel * -22) $runner__black;
    }
    .runner__frame1--shadow {
        -webkit-box-shadow: calc($pixel * 1) calc($pixel * 0) $runner__shadow,
            calc($pixel * 2) calc($pixel * 0) $runner__shadow,
            calc($pixel * 3) calc($pixel * 0) $runner__shadow,
            calc($pixel * 1) calc($pixel * 1) $runner__shadow,
            calc($pixel * 2) calc($pixel * 1) $runner__shadow,
            calc($pixel * 3) calc($pixel * 1) $runner__shadow,
            calc($pixel * 1) calc($pixel * 2) $runner__shadow,
            calc($pixel * 2) calc($pixel * 2) $runner__shadow,
            calc($pixel * 3) calc($pixel * 2) $runner__shadow,
            calc($pixel * 4) calc($pixel * 2) $runner__shadow,
            calc($pixel * 5) calc($pixel * 2) $runner__shadow,
            calc($pixel * 7) calc($pixel * 2) $runner__shadow,
            calc($pixel * 2) calc($pixel * 3) $runner__shadow,
            calc($pixel * 3) calc($pixel * 3) $runner__shadow,
            calc($pixel * 4) calc($pixel * 3) $runner__shadow,
            calc($pixel * 5) calc($pixel * 3) $runner__shadow,
            calc($pixel * 6) calc($pixel * 3) $runner__shadow,
            calc($pixel * 7) calc($pixel * 3) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 11) calc($pixel * 3) $runner__shadow,
            calc($pixel * 3) calc($pixel * 4) $runner__shadow,
            calc($pixel * 4) calc($pixel * 4) $runner__shadow,
            calc($pixel * 5) calc($pixel * 4) $runner__shadow,
            calc($pixel * 6) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 4) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 13) calc($pixel * 4) $runner__shadow,
            calc($pixel * 4) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 5) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 14) calc($pixel * 5) $runner__shadow,
            calc($pixel * 15) calc($pixel * 5) $runner__shadow,
            calc($pixel * 16) calc($pixel * 5) $runner__shadow,
            calc($pixel * 17) calc($pixel * 5) $runner__shadow,
            calc($pixel * 18) calc($pixel * 5) $runner__shadow,
            calc($pixel * 19) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 15) calc($pixel * 6) $runner__shadow,
            calc($pixel * 16) calc($pixel * 6) $runner__shadow,
            calc($pixel * 17) calc($pixel * 6) $runner__shadow,
            calc($pixel * 18) calc($pixel * 6) $runner__shadow,
            calc($pixel * 19) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 16) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 19) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 8) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 8) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 23) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow;
        box-shadow: calc($pixel * 1) calc($pixel * 0) $runner__shadow,
            calc($pixel * 2) calc($pixel * 0) $runner__shadow,
            calc($pixel * 3) calc($pixel * 0) $runner__shadow,
            calc($pixel * 1) calc($pixel * 1) $runner__shadow,
            calc($pixel * 2) calc($pixel * 1) $runner__shadow,
            calc($pixel * 3) calc($pixel * 1) $runner__shadow,
            calc($pixel * 1) calc($pixel * 2) $runner__shadow,
            calc($pixel * 2) calc($pixel * 2) $runner__shadow,
            calc($pixel * 3) calc($pixel * 2) $runner__shadow,
            calc($pixel * 4) calc($pixel * 2) $runner__shadow,
            calc($pixel * 5) calc($pixel * 2) $runner__shadow,
            calc($pixel * 7) calc($pixel * 2) $runner__shadow,
            calc($pixel * 2) calc($pixel * 3) $runner__shadow,
            calc($pixel * 3) calc($pixel * 3) $runner__shadow,
            calc($pixel * 4) calc($pixel * 3) $runner__shadow,
            calc($pixel * 5) calc($pixel * 3) $runner__shadow,
            calc($pixel * 6) calc($pixel * 3) $runner__shadow,
            calc($pixel * 7) calc($pixel * 3) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 11) calc($pixel * 3) $runner__shadow,
            calc($pixel * 3) calc($pixel * 4) $runner__shadow,
            calc($pixel * 4) calc($pixel * 4) $runner__shadow,
            calc($pixel * 5) calc($pixel * 4) $runner__shadow,
            calc($pixel * 6) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 4) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 13) calc($pixel * 4) $runner__shadow,
            calc($pixel * 4) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 5) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 14) calc($pixel * 5) $runner__shadow,
            calc($pixel * 15) calc($pixel * 5) $runner__shadow,
            calc($pixel * 16) calc($pixel * 5) $runner__shadow,
            calc($pixel * 17) calc($pixel * 5) $runner__shadow,
            calc($pixel * 18) calc($pixel * 5) $runner__shadow,
            calc($pixel * 19) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 15) calc($pixel * 6) $runner__shadow,
            calc($pixel * 16) calc($pixel * 6) $runner__shadow,
            calc($pixel * 17) calc($pixel * 6) $runner__shadow,
            calc($pixel * 18) calc($pixel * 6) $runner__shadow,
            calc($pixel * 19) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 16) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 19) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 8) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 8) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 23) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow;
    }
    .runner__frame2 {
        -webkit-box-shadow: calc($pixel * 8) calc($pixel * -2) $runner__skin,
            calc($pixel * 8) calc($pixel * -3) $runner__skin,
            calc($pixel * 9) calc($pixel * -3) $runner__black,
            calc($pixel * 10) calc($pixel * -3) $runner__black,
            calc($pixel * 8) calc($pixel * -4) $runner__black,
            calc($pixel * 9) calc($pixel * -4) $runner__black,
            calc($pixel * 10) calc($pixel * -4) $runner__black,
            calc($pixel * 11) calc($pixel * -4) $runner__black,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 13) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__black,
            calc($pixel * 9) calc($pixel * -5) $runner__black,
            calc($pixel * 10) calc($pixel * -5) $runner__skin,
            calc($pixel * 11) calc($pixel * -5) $runner__skin,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 14) calc($pixel * -5) $runner__black,
            calc($pixel * -1) calc($pixel * -6) $runner__skin,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__black,
            calc($pixel * 8) calc($pixel * -6) $runner__skin,
            calc($pixel * 9) calc($pixel * -6) $runner__skin,
            calc($pixel * 10) calc($pixel * -6) $runner__black,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__skin,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__black,
            calc($pixel * 19) calc($pixel * -6) $runner__black,
            calc($pixel * 20) calc($pixel * -6) $runner__black,
            calc($pixel * 21) calc($pixel * -6) $runner__black,
            calc($pixel * 22) calc($pixel * -6) $runner__black,
            calc($pixel * -1) calc($pixel * -7) $runner__skin,
            calc($pixel * 0) calc($pixel * -7) $runner__black,
            calc($pixel * 1) calc($pixel * -7) $runner__black,
            calc($pixel * 2) calc($pixel * -7) $runner__black,
            calc($pixel * 3) calc($pixel * -7) $runner__black,
            calc($pixel * 4) calc($pixel * -7) $runner__black,
            calc($pixel * 5) calc($pixel * -7) $runner__black,
            calc($pixel * 6) calc($pixel * -7) $runner__black,
            calc($pixel * 7) calc($pixel * -7) $runner__skin,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__black,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__black,
            calc($pixel * 14) calc($pixel * -7) $runner__black,
            calc($pixel * 17) calc($pixel * -7) $runner__black,
            calc($pixel * 18) calc($pixel * -7) $runner__black,
            calc($pixel * 19) calc($pixel * -7) $runner__suit,
            calc($pixel * 20) calc($pixel * -7) $runner__suit,
            calc($pixel * 21) calc($pixel * -7) $runner__suit,
            calc($pixel * 22) calc($pixel * -7) $runner__suit,
            calc($pixel * 0) calc($pixel * -8) $runner__skin,
            calc($pixel * 1) calc($pixel * -8) $runner__suit,
            calc($pixel * 2) calc($pixel * -8) $runner__suit,
            calc($pixel * 3) calc($pixel * -8) $runner__suit,
            calc($pixel * 4) calc($pixel * -8) $runner__black,
            calc($pixel * 5) calc($pixel * -8) $runner__black,
            calc($pixel * 6) calc($pixel * -8) $runner__skin,
            calc($pixel * 7) calc($pixel * -8) $runner__suit,
            calc($pixel * 8) calc($pixel * -8) $runner__suit,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__black,
            calc($pixel * 13) calc($pixel * -8) $runner__black,
            calc($pixel * 14) calc($pixel * -8) $runner__black,
            calc($pixel * 15) calc($pixel * -8) $runner__black,
            calc($pixel * 16) calc($pixel * -8) $runner__black,
            calc($pixel * 17) calc($pixel * -8) $runner__suit,
            calc($pixel * 18) calc($pixel * -8) $runner__suit,
            calc($pixel * 19) calc($pixel * -8) $runner__suit,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__suit,
            calc($pixel * 3) calc($pixel * -9) $runner__suit,
            calc($pixel * 4) calc($pixel * -9) $runner__suit,
            calc($pixel * 5) calc($pixel * -9) $runner__skin,
            calc($pixel * 6) calc($pixel * -9) $runner__suit,
            calc($pixel * 7) calc($pixel * -9) $runner__suit,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__black,
            calc($pixel * 12) calc($pixel * -9) $runner__black,
            calc($pixel * 13) calc($pixel * -9) $runner__black,
            calc($pixel * 14) calc($pixel * -9) $runner__black,
            calc($pixel * 15) calc($pixel * -9) $runner__black,
            calc($pixel * 16) calc($pixel * -9) $runner__suit,
            calc($pixel * 17) calc($pixel * -9) $runner__suit,
            calc($pixel * 18) calc($pixel * -9) $runner__suit,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 21) calc($pixel * -9) $runner__suit,
            calc($pixel * 22) calc($pixel * -9) $runner__suit,
            calc($pixel * 23) calc($pixel * -9) $runner__skin,
            calc($pixel * 5) calc($pixel * -10) $runner__skin,
            calc($pixel * 6) calc($pixel * -10) $runner__suit,
            calc($pixel * 7) calc($pixel * -10) $runner__black,
            calc($pixel * 8) calc($pixel * -10) $runner__black,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__black,
            calc($pixel * 13) calc($pixel * -10) $runner__black,
            calc($pixel * 14) calc($pixel * -10) $runner__black,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__suit,
            calc($pixel * 17) calc($pixel * -10) $runner__suit,
            calc($pixel * 18) calc($pixel * -10) $runner__suit,
            calc($pixel * 19) calc($pixel * -10) $runner__suit,
            calc($pixel * 20) calc($pixel * -10) $runner__black,
            calc($pixel * 8) calc($pixel * -11) $runner__black,
            calc($pixel * 9) calc($pixel * -11) $runner__black,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__black,
            calc($pixel * 13) calc($pixel * -11) $runner__black,
            calc($pixel * 14) calc($pixel * -11) $runner__black,
            calc($pixel * 15) calc($pixel * -11) $runner__suit,
            calc($pixel * 16) calc($pixel * -11) $runner__suit,
            calc($pixel * 17) calc($pixel * -11) $runner__suit,
            calc($pixel * 18) calc($pixel * -11) $runner__suit,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__suit,
            calc($pixel * 21) calc($pixel * -11) $runner__suit,
            calc($pixel * 10) calc($pixel * -12) $runner__black,
            calc($pixel * 11) calc($pixel * -12) $runner__black,
            calc($pixel * 12) calc($pixel * -12) $runner__hair,
            calc($pixel * 13) calc($pixel * -12) $runner__hair,
            calc($pixel * 14) calc($pixel * -12) $runner__hair,
            calc($pixel * 15) calc($pixel * -12) $runner__hair,
            calc($pixel * 16) calc($pixel * -12) $runner__suit,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__suit,
            calc($pixel * 19) calc($pixel * -12) $runner__suit,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 10) calc($pixel * -13) $runner__hair,
            calc($pixel * 11) calc($pixel * -13) $runner__hair,
            calc($pixel * 12) calc($pixel * -13) $runner__hair,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__hair,
            calc($pixel * 15) calc($pixel * -13) $runner__hair,
            calc($pixel * 16) calc($pixel * -13) $runner__hair,
            calc($pixel * 17) calc($pixel * -13) $runner__hair,
            calc($pixel * 18) calc($pixel * -13) $runner__hair,
            calc($pixel * 19) calc($pixel * -13) $runner__hair,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__skin,
            calc($pixel * 22) calc($pixel * -13) $runner__skin,
            calc($pixel * 9) calc($pixel * -14) $runner__hair,
            calc($pixel * 10) calc($pixel * -14) $runner__hair,
            calc($pixel * 11) calc($pixel * -14) $runner__hair,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__hair,
            calc($pixel * 15) calc($pixel * -14) $runner__hair,
            calc($pixel * 16) calc($pixel * -14) $runner__hair,
            calc($pixel * 17) calc($pixel * -14) $runner__black,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__hair,
            calc($pixel * 21) calc($pixel * -14) $runner__skin,
            calc($pixel * 22) calc($pixel * -14) $runner__eyes,
            calc($pixel * 9) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__hair,
            calc($pixel * 15) calc($pixel * -15) $runner__hair,
            calc($pixel * 16) calc($pixel * -15) $runner__black,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__black,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__hair,
            calc($pixel * 22) calc($pixel * -15) $runner__eyes,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__hair,
            calc($pixel * 15) calc($pixel * -16) $runner__hair,
            calc($pixel * 16) calc($pixel * -16) $runner__suit,
            calc($pixel * 17) calc($pixel * -16) $runner__suit,
            calc($pixel * 18) calc($pixel * -16) $runner__black,
            calc($pixel * 19) calc($pixel * -16) $runner__black,
            calc($pixel * 20) calc($pixel * -16) $runner__black,
            calc($pixel * 21) calc($pixel * -16) $runner__black,
            calc($pixel * 22) calc($pixel * -16) $runner__hair,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__hair,
            calc($pixel * 16) calc($pixel * -17) $runner__white,
            calc($pixel * 17) calc($pixel * -17) $runner__suit,
            calc($pixel * 18) calc($pixel * -17) $runner__suit,
            calc($pixel * 19) calc($pixel * -17) $runner__black,
            calc($pixel * 20) calc($pixel * -17) $runner__black,
            calc($pixel * 21) calc($pixel * -17) $runner__black,
            calc($pixel * 22) calc($pixel * -17) $runner__black,
            calc($pixel * 23) calc($pixel * -17) $runner__hair,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__hair,
            calc($pixel * 15) calc($pixel * -18) $runner__hair,
            calc($pixel * 16) calc($pixel * -18) $runner__hair,
            calc($pixel * 17) calc($pixel * -18) $runner__suit,
            calc($pixel * 18) calc($pixel * -18) $runner__suit,
            calc($pixel * 19) calc($pixel * -18) $runner__hair,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__black,
            calc($pixel * 24) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__hair,
            calc($pixel * 16) calc($pixel * -19) $runner__hair,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__suit,
            calc($pixel * 19) calc($pixel * -19) $runner__hair,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__hair,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 24) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__hair,
            calc($pixel * 15) calc($pixel * -20) $runner__white,
            calc($pixel * 16) calc($pixel * -20) $runner__white,
            calc($pixel * 17) calc($pixel * -20) $runner__white,
            calc($pixel * 18) calc($pixel * -20) $runner__suit,
            calc($pixel * 19) calc($pixel * -20) $runner__white,
            calc($pixel * 20) calc($pixel * -20) $runner__white,
            calc($pixel * 21) calc($pixel * -20) $runner__white,
            calc($pixel * 22) calc($pixel * -20) $runner__white,
            calc($pixel * 23) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -21) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__white,
            calc($pixel * 15) calc($pixel * -21) $runner__white,
            calc($pixel * 16) calc($pixel * -21) $runner__hair,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__suit,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 23) calc($pixel * -21) $runner__white,
            calc($pixel * 13) calc($pixel * -22) $runner__hair,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__hair,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__suit,
            calc($pixel * 19) calc($pixel * -22) $runner__hair,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 21) calc($pixel * -22) $runner__hair,
            calc($pixel * 22) calc($pixel * -22) $runner__hair,
            calc($pixel * 17) calc($pixel * -23) $runner__suit,
            calc($pixel * 18) calc($pixel * -23) $runner__suit;
        box-shadow: calc($pixel * 8) calc($pixel * -2) $runner__skin,
            calc($pixel * 8) calc($pixel * -3) $runner__skin,
            calc($pixel * 9) calc($pixel * -3) $runner__black,
            calc($pixel * 10) calc($pixel * -3) $runner__black,
            calc($pixel * 8) calc($pixel * -4) $runner__black,
            calc($pixel * 9) calc($pixel * -4) $runner__black,
            calc($pixel * 10) calc($pixel * -4) $runner__black,
            calc($pixel * 11) calc($pixel * -4) $runner__black,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 13) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__black,
            calc($pixel * 9) calc($pixel * -5) $runner__black,
            calc($pixel * 10) calc($pixel * -5) $runner__skin,
            calc($pixel * 11) calc($pixel * -5) $runner__skin,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 14) calc($pixel * -5) $runner__black,
            calc($pixel * -1) calc($pixel * -6) $runner__skin,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__black,
            calc($pixel * 8) calc($pixel * -6) $runner__skin,
            calc($pixel * 9) calc($pixel * -6) $runner__skin,
            calc($pixel * 10) calc($pixel * -6) $runner__black,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__skin,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__black,
            calc($pixel * 19) calc($pixel * -6) $runner__black,
            calc($pixel * 20) calc($pixel * -6) $runner__black,
            calc($pixel * 21) calc($pixel * -6) $runner__black,
            calc($pixel * 22) calc($pixel * -6) $runner__black,
            calc($pixel * -1) calc($pixel * -7) $runner__skin,
            calc($pixel * 0) calc($pixel * -7) $runner__black,
            calc($pixel * 1) calc($pixel * -7) $runner__black,
            calc($pixel * 2) calc($pixel * -7) $runner__black,
            calc($pixel * 3) calc($pixel * -7) $runner__black,
            calc($pixel * 4) calc($pixel * -7) $runner__black,
            calc($pixel * 5) calc($pixel * -7) $runner__black,
            calc($pixel * 6) calc($pixel * -7) $runner__black,
            calc($pixel * 7) calc($pixel * -7) $runner__skin,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__black,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__black,
            calc($pixel * 14) calc($pixel * -7) $runner__black,
            calc($pixel * 17) calc($pixel * -7) $runner__black,
            calc($pixel * 18) calc($pixel * -7) $runner__black,
            calc($pixel * 19) calc($pixel * -7) $runner__suit,
            calc($pixel * 20) calc($pixel * -7) $runner__suit,
            calc($pixel * 21) calc($pixel * -7) $runner__suit,
            calc($pixel * 22) calc($pixel * -7) $runner__suit,
            calc($pixel * 0) calc($pixel * -8) $runner__skin,
            calc($pixel * 1) calc($pixel * -8) $runner__suit,
            calc($pixel * 2) calc($pixel * -8) $runner__suit,
            calc($pixel * 3) calc($pixel * -8) $runner__suit,
            calc($pixel * 4) calc($pixel * -8) $runner__black,
            calc($pixel * 5) calc($pixel * -8) $runner__black,
            calc($pixel * 6) calc($pixel * -8) $runner__skin,
            calc($pixel * 7) calc($pixel * -8) $runner__suit,
            calc($pixel * 8) calc($pixel * -8) $runner__suit,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__black,
            calc($pixel * 13) calc($pixel * -8) $runner__black,
            calc($pixel * 14) calc($pixel * -8) $runner__black,
            calc($pixel * 15) calc($pixel * -8) $runner__black,
            calc($pixel * 16) calc($pixel * -8) $runner__black,
            calc($pixel * 17) calc($pixel * -8) $runner__suit,
            calc($pixel * 18) calc($pixel * -8) $runner__suit,
            calc($pixel * 19) calc($pixel * -8) $runner__suit,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__suit,
            calc($pixel * 3) calc($pixel * -9) $runner__suit,
            calc($pixel * 4) calc($pixel * -9) $runner__suit,
            calc($pixel * 5) calc($pixel * -9) $runner__skin,
            calc($pixel * 6) calc($pixel * -9) $runner__suit,
            calc($pixel * 7) calc($pixel * -9) $runner__suit,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__black,
            calc($pixel * 12) calc($pixel * -9) $runner__black,
            calc($pixel * 13) calc($pixel * -9) $runner__black,
            calc($pixel * 14) calc($pixel * -9) $runner__black,
            calc($pixel * 15) calc($pixel * -9) $runner__black,
            calc($pixel * 16) calc($pixel * -9) $runner__suit,
            calc($pixel * 17) calc($pixel * -9) $runner__suit,
            calc($pixel * 18) calc($pixel * -9) $runner__suit,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 21) calc($pixel * -9) $runner__suit,
            calc($pixel * 22) calc($pixel * -9) $runner__suit,
            calc($pixel * 23) calc($pixel * -9) $runner__skin,
            calc($pixel * 5) calc($pixel * -10) $runner__skin,
            calc($pixel * 6) calc($pixel * -10) $runner__suit,
            calc($pixel * 7) calc($pixel * -10) $runner__black,
            calc($pixel * 8) calc($pixel * -10) $runner__black,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__black,
            calc($pixel * 13) calc($pixel * -10) $runner__black,
            calc($pixel * 14) calc($pixel * -10) $runner__black,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__suit,
            calc($pixel * 17) calc($pixel * -10) $runner__suit,
            calc($pixel * 18) calc($pixel * -10) $runner__suit,
            calc($pixel * 19) calc($pixel * -10) $runner__suit,
            calc($pixel * 20) calc($pixel * -10) $runner__black,
            calc($pixel * 8) calc($pixel * -11) $runner__black,
            calc($pixel * 9) calc($pixel * -11) $runner__black,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__black,
            calc($pixel * 13) calc($pixel * -11) $runner__black,
            calc($pixel * 14) calc($pixel * -11) $runner__black,
            calc($pixel * 15) calc($pixel * -11) $runner__suit,
            calc($pixel * 16) calc($pixel * -11) $runner__suit,
            calc($pixel * 17) calc($pixel * -11) $runner__suit,
            calc($pixel * 18) calc($pixel * -11) $runner__suit,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__suit,
            calc($pixel * 21) calc($pixel * -11) $runner__suit,
            calc($pixel * 10) calc($pixel * -12) $runner__black,
            calc($pixel * 11) calc($pixel * -12) $runner__black,
            calc($pixel * 12) calc($pixel * -12) $runner__hair,
            calc($pixel * 13) calc($pixel * -12) $runner__hair,
            calc($pixel * 14) calc($pixel * -12) $runner__hair,
            calc($pixel * 15) calc($pixel * -12) $runner__hair,
            calc($pixel * 16) calc($pixel * -12) $runner__suit,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__suit,
            calc($pixel * 19) calc($pixel * -12) $runner__suit,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 10) calc($pixel * -13) $runner__hair,
            calc($pixel * 11) calc($pixel * -13) $runner__hair,
            calc($pixel * 12) calc($pixel * -13) $runner__hair,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__hair,
            calc($pixel * 15) calc($pixel * -13) $runner__hair,
            calc($pixel * 16) calc($pixel * -13) $runner__hair,
            calc($pixel * 17) calc($pixel * -13) $runner__hair,
            calc($pixel * 18) calc($pixel * -13) $runner__hair,
            calc($pixel * 19) calc($pixel * -13) $runner__hair,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__skin,
            calc($pixel * 22) calc($pixel * -13) $runner__skin,
            calc($pixel * 9) calc($pixel * -14) $runner__hair,
            calc($pixel * 10) calc($pixel * -14) $runner__hair,
            calc($pixel * 11) calc($pixel * -14) $runner__hair,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__hair,
            calc($pixel * 15) calc($pixel * -14) $runner__hair,
            calc($pixel * 16) calc($pixel * -14) $runner__hair,
            calc($pixel * 17) calc($pixel * -14) $runner__black,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__hair,
            calc($pixel * 21) calc($pixel * -14) $runner__skin,
            calc($pixel * 22) calc($pixel * -14) $runner__eyes,
            calc($pixel * 9) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__hair,
            calc($pixel * 15) calc($pixel * -15) $runner__hair,
            calc($pixel * 16) calc($pixel * -15) $runner__black,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__black,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__hair,
            calc($pixel * 22) calc($pixel * -15) $runner__eyes,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__hair,
            calc($pixel * 15) calc($pixel * -16) $runner__hair,
            calc($pixel * 16) calc($pixel * -16) $runner__suit,
            calc($pixel * 17) calc($pixel * -16) $runner__suit,
            calc($pixel * 18) calc($pixel * -16) $runner__black,
            calc($pixel * 19) calc($pixel * -16) $runner__black,
            calc($pixel * 20) calc($pixel * -16) $runner__black,
            calc($pixel * 21) calc($pixel * -16) $runner__black,
            calc($pixel * 22) calc($pixel * -16) $runner__hair,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__hair,
            calc($pixel * 16) calc($pixel * -17) $runner__white,
            calc($pixel * 17) calc($pixel * -17) $runner__suit,
            calc($pixel * 18) calc($pixel * -17) $runner__suit,
            calc($pixel * 19) calc($pixel * -17) $runner__black,
            calc($pixel * 20) calc($pixel * -17) $runner__black,
            calc($pixel * 21) calc($pixel * -17) $runner__black,
            calc($pixel * 22) calc($pixel * -17) $runner__black,
            calc($pixel * 23) calc($pixel * -17) $runner__hair,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__hair,
            calc($pixel * 15) calc($pixel * -18) $runner__hair,
            calc($pixel * 16) calc($pixel * -18) $runner__hair,
            calc($pixel * 17) calc($pixel * -18) $runner__suit,
            calc($pixel * 18) calc($pixel * -18) $runner__suit,
            calc($pixel * 19) calc($pixel * -18) $runner__hair,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__black,
            calc($pixel * 24) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__hair,
            calc($pixel * 16) calc($pixel * -19) $runner__hair,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__suit,
            calc($pixel * 19) calc($pixel * -19) $runner__hair,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__hair,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 24) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__hair,
            calc($pixel * 15) calc($pixel * -20) $runner__white,
            calc($pixel * 16) calc($pixel * -20) $runner__white,
            calc($pixel * 17) calc($pixel * -20) $runner__white,
            calc($pixel * 18) calc($pixel * -20) $runner__suit,
            calc($pixel * 19) calc($pixel * -20) $runner__white,
            calc($pixel * 20) calc($pixel * -20) $runner__white,
            calc($pixel * 21) calc($pixel * -20) $runner__white,
            calc($pixel * 22) calc($pixel * -20) $runner__white,
            calc($pixel * 23) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -21) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__white,
            calc($pixel * 15) calc($pixel * -21) $runner__white,
            calc($pixel * 16) calc($pixel * -21) $runner__hair,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__suit,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 23) calc($pixel * -21) $runner__white,
            calc($pixel * 13) calc($pixel * -22) $runner__hair,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__hair,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__suit,
            calc($pixel * 19) calc($pixel * -22) $runner__hair,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 21) calc($pixel * -22) $runner__hair,
            calc($pixel * 22) calc($pixel * -22) $runner__hair,
            calc($pixel * 17) calc($pixel * -23) $runner__suit,
            calc($pixel * 18) calc($pixel * -23) $runner__suit;
    }
    .runner__frame2--shadow {
        -webkit-box-shadow: calc($pixel * 8) calc($pixel * 2) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 13) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 14) calc($pixel * 5) $runner__shadow,
            calc($pixel * 1) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 19) calc($pixel * 6) $runner__shadow,
            calc($pixel * 20) calc($pixel * 6) $runner__shadow,
            calc($pixel * 21) calc($pixel * 6) $runner__shadow,
            calc($pixel * 22) calc($pixel * 6) $runner__shadow,
            calc($pixel * 1) calc($pixel * 7) $runner__shadow,
            calc($pixel * 0) calc($pixel * 7) $runner__shadow,
            calc($pixel * 1) calc($pixel * 7) $runner__shadow,
            calc($pixel * 2) calc($pixel * 7) $runner__shadow,
            calc($pixel * 3) calc($pixel * 7) $runner__shadow,
            calc($pixel * 4) calc($pixel * 7) $runner__shadow,
            calc($pixel * 5) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 19) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 0) calc($pixel * 8) $runner__shadow,
            calc($pixel * 1) calc($pixel * 8) $runner__shadow,
            calc($pixel * 2) calc($pixel * 8) $runner__shadow,
            calc($pixel * 3) calc($pixel * 8) $runner__shadow,
            calc($pixel * 4) calc($pixel * 8) $runner__shadow,
            calc($pixel * 5) calc($pixel * 8) $runner__shadow,
            calc($pixel * 6) calc($pixel * 8) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 3) calc($pixel * 9) $runner__shadow,
            calc($pixel * 4) calc($pixel * 9) $runner__shadow,
            calc($pixel * 5) calc($pixel * 9) $runner__shadow,
            calc($pixel * 6) calc($pixel * 9) $runner__shadow,
            calc($pixel * 7) calc($pixel * 9) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 21) calc($pixel * 9) $runner__shadow,
            calc($pixel * 22) calc($pixel * 9) $runner__shadow,
            calc($pixel * 23) calc($pixel * 9) $runner__shadow,
            calc($pixel * 5) calc($pixel * 10) $runner__shadow,
            calc($pixel * 6) calc($pixel * 10) $runner__shadow,
            calc($pixel * 7) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 10) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 24) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 23) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 21) calc($pixel * 22) $runner__shadow,
            calc($pixel * 22) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow;
        box-shadow: calc($pixel * 8) calc($pixel * 2) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 13) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 14) calc($pixel * 5) $runner__shadow,
            calc($pixel * 1) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 19) calc($pixel * 6) $runner__shadow,
            calc($pixel * 20) calc($pixel * 6) $runner__shadow,
            calc($pixel * 21) calc($pixel * 6) $runner__shadow,
            calc($pixel * 22) calc($pixel * 6) $runner__shadow,
            calc($pixel * 1) calc($pixel * 7) $runner__shadow,
            calc($pixel * 0) calc($pixel * 7) $runner__shadow,
            calc($pixel * 1) calc($pixel * 7) $runner__shadow,
            calc($pixel * 2) calc($pixel * 7) $runner__shadow,
            calc($pixel * 3) calc($pixel * 7) $runner__shadow,
            calc($pixel * 4) calc($pixel * 7) $runner__shadow,
            calc($pixel * 5) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 19) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 0) calc($pixel * 8) $runner__shadow,
            calc($pixel * 1) calc($pixel * 8) $runner__shadow,
            calc($pixel * 2) calc($pixel * 8) $runner__shadow,
            calc($pixel * 3) calc($pixel * 8) $runner__shadow,
            calc($pixel * 4) calc($pixel * 8) $runner__shadow,
            calc($pixel * 5) calc($pixel * 8) $runner__shadow,
            calc($pixel * 6) calc($pixel * 8) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 3) calc($pixel * 9) $runner__shadow,
            calc($pixel * 4) calc($pixel * 9) $runner__shadow,
            calc($pixel * 5) calc($pixel * 9) $runner__shadow,
            calc($pixel * 6) calc($pixel * 9) $runner__shadow,
            calc($pixel * 7) calc($pixel * 9) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 21) calc($pixel * 9) $runner__shadow,
            calc($pixel * 22) calc($pixel * 9) $runner__shadow,
            calc($pixel * 23) calc($pixel * 9) $runner__shadow,
            calc($pixel * 5) calc($pixel * 10) $runner__shadow,
            calc($pixel * 6) calc($pixel * 10) $runner__shadow,
            calc($pixel * 7) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 10) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 24) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 23) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 21) calc($pixel * 22) $runner__shadow,
            calc($pixel * 22) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow;
    }
    .runner__frame3 {
        -webkit-box-shadow: calc($pixel * 13) calc($pixel * -1) $runner__skin,
            calc($pixel * 14) calc($pixel * -1) $runner__skin,
            calc($pixel * 12) calc($pixel * -2) $runner__black,
            calc($pixel * 13) calc($pixel * -2) $runner__black,
            calc($pixel * 14) calc($pixel * -2) $runner__black,
            calc($pixel * 15) calc($pixel * -2) $runner__skin,
            calc($pixel * 11) calc($pixel * -3) $runner__black,
            calc($pixel * 12) calc($pixel * -3) $runner__black,
            calc($pixel * 13) calc($pixel * -3) $runner__black,
            calc($pixel * 9) calc($pixel * -4) $runner__black,
            calc($pixel * 10) calc($pixel * -4) $runner__black,
            calc($pixel * 11) calc($pixel * -4) $runner__black,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 13) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__black,
            calc($pixel * 9) calc($pixel * -5) $runner__black,
            calc($pixel * 10) calc($pixel * -5) $runner__black,
            calc($pixel * 11) calc($pixel * -5) $runner__skin,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__black,
            calc($pixel * 8) calc($pixel * -6) $runner__black,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__skin,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__black,
            calc($pixel * 4) calc($pixel * -7) $runner__black,
            calc($pixel * 5) calc($pixel * -7) $runner__black,
            calc($pixel * 6) calc($pixel * -7) $runner__suit,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__skin,
            calc($pixel * 10) calc($pixel * -7) $runner__skin,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__black,
            calc($pixel * 14) calc($pixel * -7) $runner__black,
            calc($pixel * 20) calc($pixel * -7) $runner__black,
            calc($pixel * 21) calc($pixel * -7) $runner__black,
            calc($pixel * 22) calc($pixel * -7) $runner__black,
            calc($pixel * 3) calc($pixel * -8) $runner__black,
            calc($pixel * 4) calc($pixel * -8) $runner__black,
            calc($pixel * 5) calc($pixel * -8) $runner__suit,
            calc($pixel * 6) calc($pixel * -8) $runner__black,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__skin,
            calc($pixel * 9) calc($pixel * -8) $runner__skin,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__black,
            calc($pixel * 13) calc($pixel * -8) $runner__black,
            calc($pixel * 14) calc($pixel * -8) $runner__black,
            calc($pixel * 15) calc($pixel * -8) $runner__black,
            calc($pixel * 16) calc($pixel * -8) $runner__black,
            calc($pixel * 18) calc($pixel * -8) $runner__black,
            calc($pixel * 19) calc($pixel * -8) $runner__black,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__suit,
            calc($pixel * 23) calc($pixel * -8) $runner__black,
            calc($pixel * 24) calc($pixel * -8) $runner__black,
            calc($pixel * 1) calc($pixel * -9) $runner__skin,
            calc($pixel * 2) calc($pixel * -9) $runner__black,
            calc($pixel * 3) calc($pixel * -9) $runner__black,
            calc($pixel * 4) calc($pixel * -9) $runner__suit,
            calc($pixel * 6) calc($pixel * -9) $runner__black,
            calc($pixel * 7) calc($pixel * -9) $runner__skin,
            calc($pixel * 8) calc($pixel * -9) $runner__skin,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__black,
            calc($pixel * 12) calc($pixel * -9) $runner__black,
            calc($pixel * 13) calc($pixel * -9) $runner__black,
            calc($pixel * 14) calc($pixel * -9) $runner__black,
            calc($pixel * 15) calc($pixel * -9) $runner__black,
            calc($pixel * 16) calc($pixel * -9) $runner__black,
            calc($pixel * 17) calc($pixel * -9) $runner__black,
            calc($pixel * 18) calc($pixel * -9) $runner__suit,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 21) calc($pixel * -9) $runner__suit,
            calc($pixel * 22) calc($pixel * -9) $runner__suit,
            calc($pixel * 23) calc($pixel * -9) $runner__suit,
            calc($pixel * 24) calc($pixel * -9) $runner__suit,
            calc($pixel * 25) calc($pixel * -9) $runner__black,
            calc($pixel * 2) calc($pixel * -10) $runner__skin,
            calc($pixel * 3) calc($pixel * -10) $runner__skin,
            calc($pixel * 6) calc($pixel * -10) $runner__skin,
            calc($pixel * 7) calc($pixel * -10) $runner__black,
            calc($pixel * 8) calc($pixel * -10) $runner__black,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__black,
            calc($pixel * 13) calc($pixel * -10) $runner__black,
            calc($pixel * 14) calc($pixel * -10) $runner__black,
            calc($pixel * 15) calc($pixel * -10) $runner__black,
            calc($pixel * 16) calc($pixel * -10) $runner__suit,
            calc($pixel * 17) calc($pixel * -10) $runner__suit,
            calc($pixel * 18) calc($pixel * -10) $runner__suit,
            calc($pixel * 19) calc($pixel * -10) $runner__suit,
            calc($pixel * 20) calc($pixel * -10) $runner__suit,
            calc($pixel * 21) calc($pixel * -10) $runner__suit,
            calc($pixel * 22) calc($pixel * -10) $runner__suit,
            calc($pixel * 23) calc($pixel * -10) $runner__suit,
            calc($pixel * 24) calc($pixel * -10) $runner__suit,
            calc($pixel * 25) calc($pixel * -10) $runner__suit,
            calc($pixel * 8) calc($pixel * -11) $runner__black,
            calc($pixel * 9) calc($pixel * -11) $runner__black,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__black,
            calc($pixel * 13) calc($pixel * -11) $runner__black,
            calc($pixel * 14) calc($pixel * -11) $runner__black,
            calc($pixel * 15) calc($pixel * -11) $runner__black,
            calc($pixel * 16) calc($pixel * -11) $runner__suit,
            calc($pixel * 17) calc($pixel * -11) $runner__suit,
            calc($pixel * 18) calc($pixel * -11) $runner__suit,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__suit,
            calc($pixel * 21) calc($pixel * -11) $runner__suit,
            calc($pixel * 22) calc($pixel * -11) $runner__suit,
            calc($pixel * 23) calc($pixel * -11) $runner__suit,
            calc($pixel * 24) calc($pixel * -11) $runner__suit,
            calc($pixel * 25) calc($pixel * -11) $runner__suit,
            calc($pixel * 9) calc($pixel * -12) $runner__suit,
            calc($pixel * 10) calc($pixel * -12) $runner__black,
            calc($pixel * 11) calc($pixel * -12) $runner__black,
            calc($pixel * 12) calc($pixel * -12) $runner__black,
            calc($pixel * 13) calc($pixel * -12) $runner__black,
            calc($pixel * 14) calc($pixel * -12) $runner__black,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__black,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__suit,
            calc($pixel * 19) calc($pixel * -12) $runner__suit,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 23) calc($pixel * -12) $runner__suit,
            calc($pixel * 24) calc($pixel * -12) $runner__suit,
            calc($pixel * 9) calc($pixel * -13) $runner__suit,
            calc($pixel * 10) calc($pixel * -13) $runner__suit,
            calc($pixel * 11) calc($pixel * -13) $runner__black,
            calc($pixel * 12) calc($pixel * -13) $runner__black,
            calc($pixel * 13) calc($pixel * -13) $runner__black,
            calc($pixel * 14) calc($pixel * -13) $runner__black,
            calc($pixel * 15) calc($pixel * -13) $runner__black,
            calc($pixel * 16) calc($pixel * -13) $runner__hair,
            calc($pixel * 17) calc($pixel * -13) $runner__hair,
            calc($pixel * 18) calc($pixel * -13) $runner__hair,
            calc($pixel * 19) calc($pixel * -13) $runner__hair,
            calc($pixel * 20) calc($pixel * -13) $runner__hair,
            calc($pixel * 21) calc($pixel * -13) $runner__skin,
            calc($pixel * 22) calc($pixel * -13) $runner__skin,
            calc($pixel * 10) calc($pixel * -14) $runner__black,
            calc($pixel * 11) calc($pixel * -14) $runner__black,
            calc($pixel * 12) calc($pixel * -14) $runner__black,
            calc($pixel * 13) calc($pixel * -14) $runner__black,
            calc($pixel * 14) calc($pixel * -14) $runner__hair,
            calc($pixel * 15) calc($pixel * -14) $runner__hair,
            calc($pixel * 16) calc($pixel * -14) $runner__hair,
            calc($pixel * 17) calc($pixel * -14) $runner__hair,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__hair,
            calc($pixel * 21) calc($pixel * -14) $runner__skin,
            calc($pixel * 22) calc($pixel * -14) $runner__eyes,
            calc($pixel * 9) calc($pixel * -15) $runner__black,
            calc($pixel * 10) calc($pixel * -15) $runner__black,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__hair,
            calc($pixel * 15) calc($pixel * -15) $runner__hair,
            calc($pixel * 16) calc($pixel * -15) $runner__hair,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__black,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__hair,
            calc($pixel * 22) calc($pixel * -15) $runner__hair,
            calc($pixel * 9) calc($pixel * -16) $runner__black,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__hair,
            calc($pixel * 15) calc($pixel * -16) $runner__hair,
            calc($pixel * 16) calc($pixel * -16) $runner__hair,
            calc($pixel * 17) calc($pixel * -16) $runner__suit,
            calc($pixel * 18) calc($pixel * -16) $runner__black,
            calc($pixel * 19) calc($pixel * -16) $runner__black,
            calc($pixel * 20) calc($pixel * -16) $runner__black,
            calc($pixel * 21) calc($pixel * -16) $runner__black,
            calc($pixel * 22) calc($pixel * -16) $runner__black,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 9) calc($pixel * -17) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__hair,
            calc($pixel * 16) calc($pixel * -17) $runner__hair,
            calc($pixel * 17) calc($pixel * -17) $runner__white,
            calc($pixel * 18) calc($pixel * -17) $runner__black,
            calc($pixel * 19) calc($pixel * -17) $runner__black,
            calc($pixel * 20) calc($pixel * -17) $runner__black,
            calc($pixel * 21) calc($pixel * -17) $runner__hair,
            calc($pixel * 22) calc($pixel * -17) $runner__black,
            calc($pixel * 23) calc($pixel * -17) $runner__black,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 9) calc($pixel * -18) $runner__hair,
            calc($pixel * 10) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__hair,
            calc($pixel * 15) calc($pixel * -18) $runner__hair,
            calc($pixel * 16) calc($pixel * -18) $runner__hair,
            calc($pixel * 17) calc($pixel * -18) $runner__hair,
            calc($pixel * 18) calc($pixel * -18) $runner__suit,
            calc($pixel * 19) calc($pixel * -18) $runner__suit,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__hair,
            calc($pixel * 24) calc($pixel * -18) $runner__black,
            calc($pixel * 10) calc($pixel * -19) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__hair,
            calc($pixel * 16) calc($pixel * -19) $runner__hair,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__hair,
            calc($pixel * 19) calc($pixel * -19) $runner__suit,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__hair,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 24) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__hair,
            calc($pixel * 15) calc($pixel * -20) $runner__white,
            calc($pixel * 16) calc($pixel * -20) $runner__white,
            calc($pixel * 17) calc($pixel * -20) $runner__white,
            calc($pixel * 18) calc($pixel * -20) $runner__white,
            calc($pixel * 19) calc($pixel * -20) $runner__suit,
            calc($pixel * 20) calc($pixel * -20) $runner__white,
            calc($pixel * 21) calc($pixel * -20) $runner__white,
            calc($pixel * 22) calc($pixel * -20) $runner__white,
            calc($pixel * 23) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -21) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__white,
            calc($pixel * 15) calc($pixel * -21) $runner__white,
            calc($pixel * 16) calc($pixel * -21) $runner__hair,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__suit,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 23) calc($pixel * -21) $runner__white,
            calc($pixel * 13) calc($pixel * -22) $runner__white,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__hair,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__hair,
            calc($pixel * 19) calc($pixel * -22) $runner__suit,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 21) calc($pixel * -22) $runner__hair,
            calc($pixel * 22) calc($pixel * -22) $runner__hair,
            calc($pixel * 14) calc($pixel * -23) $runner__hair,
            calc($pixel * 15) calc($pixel * -23) $runner__hair,
            calc($pixel * 16) calc($pixel * -23) $runner__hair,
            calc($pixel * 17) calc($pixel * -23) $runner__hair,
            calc($pixel * 18) calc($pixel * -23) $runner__hair,
            calc($pixel * 19) calc($pixel * -23) $runner__suit,
            calc($pixel * 20) calc($pixel * -23) $runner__hair,
            calc($pixel * 15) calc($pixel * -24) $runner__suit,
            calc($pixel * 16) calc($pixel * -24) $runner__suit,
            calc($pixel * 17) calc($pixel * -24) $runner__suit,
            calc($pixel * 19) calc($pixel * -24) $runner__suit,
            calc($pixel * 17) calc($pixel * -25) $runner__suit,
            calc($pixel * 18) calc($pixel * -25) $runner__suit,
            calc($pixel * 19) calc($pixel * -25) $runner__suit;
        box-shadow: calc($pixel * 13) calc($pixel * -1) $runner__skin,
            calc($pixel * 14) calc($pixel * -1) $runner__skin,
            calc($pixel * 12) calc($pixel * -2) $runner__black,
            calc($pixel * 13) calc($pixel * -2) $runner__black,
            calc($pixel * 14) calc($pixel * -2) $runner__black,
            calc($pixel * 15) calc($pixel * -2) $runner__skin,
            calc($pixel * 11) calc($pixel * -3) $runner__black,
            calc($pixel * 12) calc($pixel * -3) $runner__black,
            calc($pixel * 13) calc($pixel * -3) $runner__black,
            calc($pixel * 9) calc($pixel * -4) $runner__black,
            calc($pixel * 10) calc($pixel * -4) $runner__black,
            calc($pixel * 11) calc($pixel * -4) $runner__black,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 13) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__black,
            calc($pixel * 9) calc($pixel * -5) $runner__black,
            calc($pixel * 10) calc($pixel * -5) $runner__black,
            calc($pixel * 11) calc($pixel * -5) $runner__skin,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__black,
            calc($pixel * 8) calc($pixel * -6) $runner__black,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__skin,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__black,
            calc($pixel * 4) calc($pixel * -7) $runner__black,
            calc($pixel * 5) calc($pixel * -7) $runner__black,
            calc($pixel * 6) calc($pixel * -7) $runner__suit,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__skin,
            calc($pixel * 10) calc($pixel * -7) $runner__skin,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__black,
            calc($pixel * 14) calc($pixel * -7) $runner__black,
            calc($pixel * 20) calc($pixel * -7) $runner__black,
            calc($pixel * 21) calc($pixel * -7) $runner__black,
            calc($pixel * 22) calc($pixel * -7) $runner__black,
            calc($pixel * 3) calc($pixel * -8) $runner__black,
            calc($pixel * 4) calc($pixel * -8) $runner__black,
            calc($pixel * 5) calc($pixel * -8) $runner__suit,
            calc($pixel * 6) calc($pixel * -8) $runner__black,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__skin,
            calc($pixel * 9) calc($pixel * -8) $runner__skin,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__black,
            calc($pixel * 13) calc($pixel * -8) $runner__black,
            calc($pixel * 14) calc($pixel * -8) $runner__black,
            calc($pixel * 15) calc($pixel * -8) $runner__black,
            calc($pixel * 16) calc($pixel * -8) $runner__black,
            calc($pixel * 18) calc($pixel * -8) $runner__black,
            calc($pixel * 19) calc($pixel * -8) $runner__black,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__suit,
            calc($pixel * 23) calc($pixel * -8) $runner__black,
            calc($pixel * 24) calc($pixel * -8) $runner__black,
            calc($pixel * 1) calc($pixel * -9) $runner__skin,
            calc($pixel * 2) calc($pixel * -9) $runner__black,
            calc($pixel * 3) calc($pixel * -9) $runner__black,
            calc($pixel * 4) calc($pixel * -9) $runner__suit,
            calc($pixel * 6) calc($pixel * -9) $runner__black,
            calc($pixel * 7) calc($pixel * -9) $runner__skin,
            calc($pixel * 8) calc($pixel * -9) $runner__skin,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__black,
            calc($pixel * 12) calc($pixel * -9) $runner__black,
            calc($pixel * 13) calc($pixel * -9) $runner__black,
            calc($pixel * 14) calc($pixel * -9) $runner__black,
            calc($pixel * 15) calc($pixel * -9) $runner__black,
            calc($pixel * 16) calc($pixel * -9) $runner__black,
            calc($pixel * 17) calc($pixel * -9) $runner__black,
            calc($pixel * 18) calc($pixel * -9) $runner__suit,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 21) calc($pixel * -9) $runner__suit,
            calc($pixel * 22) calc($pixel * -9) $runner__suit,
            calc($pixel * 23) calc($pixel * -9) $runner__suit,
            calc($pixel * 24) calc($pixel * -9) $runner__suit,
            calc($pixel * 25) calc($pixel * -9) $runner__black,
            calc($pixel * 2) calc($pixel * -10) $runner__skin,
            calc($pixel * 3) calc($pixel * -10) $runner__skin,
            calc($pixel * 6) calc($pixel * -10) $runner__skin,
            calc($pixel * 7) calc($pixel * -10) $runner__black,
            calc($pixel * 8) calc($pixel * -10) $runner__black,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__black,
            calc($pixel * 13) calc($pixel * -10) $runner__black,
            calc($pixel * 14) calc($pixel * -10) $runner__black,
            calc($pixel * 15) calc($pixel * -10) $runner__black,
            calc($pixel * 16) calc($pixel * -10) $runner__suit,
            calc($pixel * 17) calc($pixel * -10) $runner__suit,
            calc($pixel * 18) calc($pixel * -10) $runner__suit,
            calc($pixel * 19) calc($pixel * -10) $runner__suit,
            calc($pixel * 20) calc($pixel * -10) $runner__suit,
            calc($pixel * 21) calc($pixel * -10) $runner__suit,
            calc($pixel * 22) calc($pixel * -10) $runner__suit,
            calc($pixel * 23) calc($pixel * -10) $runner__suit,
            calc($pixel * 24) calc($pixel * -10) $runner__suit,
            calc($pixel * 25) calc($pixel * -10) $runner__suit,
            calc($pixel * 8) calc($pixel * -11) $runner__black,
            calc($pixel * 9) calc($pixel * -11) $runner__black,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__black,
            calc($pixel * 13) calc($pixel * -11) $runner__black,
            calc($pixel * 14) calc($pixel * -11) $runner__black,
            calc($pixel * 15) calc($pixel * -11) $runner__black,
            calc($pixel * 16) calc($pixel * -11) $runner__suit,
            calc($pixel * 17) calc($pixel * -11) $runner__suit,
            calc($pixel * 18) calc($pixel * -11) $runner__suit,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__suit,
            calc($pixel * 21) calc($pixel * -11) $runner__suit,
            calc($pixel * 22) calc($pixel * -11) $runner__suit,
            calc($pixel * 23) calc($pixel * -11) $runner__suit,
            calc($pixel * 24) calc($pixel * -11) $runner__suit,
            calc($pixel * 25) calc($pixel * -11) $runner__suit,
            calc($pixel * 9) calc($pixel * -12) $runner__suit,
            calc($pixel * 10) calc($pixel * -12) $runner__black,
            calc($pixel * 11) calc($pixel * -12) $runner__black,
            calc($pixel * 12) calc($pixel * -12) $runner__black,
            calc($pixel * 13) calc($pixel * -12) $runner__black,
            calc($pixel * 14) calc($pixel * -12) $runner__black,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__black,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__suit,
            calc($pixel * 19) calc($pixel * -12) $runner__suit,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 23) calc($pixel * -12) $runner__suit,
            calc($pixel * 24) calc($pixel * -12) $runner__suit,
            calc($pixel * 9) calc($pixel * -13) $runner__suit,
            calc($pixel * 10) calc($pixel * -13) $runner__suit,
            calc($pixel * 11) calc($pixel * -13) $runner__black,
            calc($pixel * 12) calc($pixel * -13) $runner__black,
            calc($pixel * 13) calc($pixel * -13) $runner__black,
            calc($pixel * 14) calc($pixel * -13) $runner__black,
            calc($pixel * 15) calc($pixel * -13) $runner__black,
            calc($pixel * 16) calc($pixel * -13) $runner__hair,
            calc($pixel * 17) calc($pixel * -13) $runner__hair,
            calc($pixel * 18) calc($pixel * -13) $runner__hair,
            calc($pixel * 19) calc($pixel * -13) $runner__hair,
            calc($pixel * 20) calc($pixel * -13) $runner__hair,
            calc($pixel * 21) calc($pixel * -13) $runner__skin,
            calc($pixel * 22) calc($pixel * -13) $runner__skin,
            calc($pixel * 10) calc($pixel * -14) $runner__black,
            calc($pixel * 11) calc($pixel * -14) $runner__black,
            calc($pixel * 12) calc($pixel * -14) $runner__black,
            calc($pixel * 13) calc($pixel * -14) $runner__black,
            calc($pixel * 14) calc($pixel * -14) $runner__hair,
            calc($pixel * 15) calc($pixel * -14) $runner__hair,
            calc($pixel * 16) calc($pixel * -14) $runner__hair,
            calc($pixel * 17) calc($pixel * -14) $runner__hair,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__hair,
            calc($pixel * 21) calc($pixel * -14) $runner__skin,
            calc($pixel * 22) calc($pixel * -14) $runner__eyes,
            calc($pixel * 9) calc($pixel * -15) $runner__black,
            calc($pixel * 10) calc($pixel * -15) $runner__black,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__hair,
            calc($pixel * 15) calc($pixel * -15) $runner__hair,
            calc($pixel * 16) calc($pixel * -15) $runner__hair,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__black,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__hair,
            calc($pixel * 22) calc($pixel * -15) $runner__hair,
            calc($pixel * 9) calc($pixel * -16) $runner__black,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__hair,
            calc($pixel * 15) calc($pixel * -16) $runner__hair,
            calc($pixel * 16) calc($pixel * -16) $runner__hair,
            calc($pixel * 17) calc($pixel * -16) $runner__suit,
            calc($pixel * 18) calc($pixel * -16) $runner__black,
            calc($pixel * 19) calc($pixel * -16) $runner__black,
            calc($pixel * 20) calc($pixel * -16) $runner__black,
            calc($pixel * 21) calc($pixel * -16) $runner__black,
            calc($pixel * 22) calc($pixel * -16) $runner__black,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 9) calc($pixel * -17) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__hair,
            calc($pixel * 16) calc($pixel * -17) $runner__hair,
            calc($pixel * 17) calc($pixel * -17) $runner__white,
            calc($pixel * 18) calc($pixel * -17) $runner__black,
            calc($pixel * 19) calc($pixel * -17) $runner__black,
            calc($pixel * 20) calc($pixel * -17) $runner__black,
            calc($pixel * 21) calc($pixel * -17) $runner__hair,
            calc($pixel * 22) calc($pixel * -17) $runner__black,
            calc($pixel * 23) calc($pixel * -17) $runner__black,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 9) calc($pixel * -18) $runner__hair,
            calc($pixel * 10) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__hair,
            calc($pixel * 15) calc($pixel * -18) $runner__hair,
            calc($pixel * 16) calc($pixel * -18) $runner__hair,
            calc($pixel * 17) calc($pixel * -18) $runner__hair,
            calc($pixel * 18) calc($pixel * -18) $runner__suit,
            calc($pixel * 19) calc($pixel * -18) $runner__suit,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__hair,
            calc($pixel * 24) calc($pixel * -18) $runner__black,
            calc($pixel * 10) calc($pixel * -19) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__hair,
            calc($pixel * 16) calc($pixel * -19) $runner__hair,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__hair,
            calc($pixel * 19) calc($pixel * -19) $runner__suit,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__hair,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 24) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__hair,
            calc($pixel * 15) calc($pixel * -20) $runner__white,
            calc($pixel * 16) calc($pixel * -20) $runner__white,
            calc($pixel * 17) calc($pixel * -20) $runner__white,
            calc($pixel * 18) calc($pixel * -20) $runner__white,
            calc($pixel * 19) calc($pixel * -20) $runner__suit,
            calc($pixel * 20) calc($pixel * -20) $runner__white,
            calc($pixel * 21) calc($pixel * -20) $runner__white,
            calc($pixel * 22) calc($pixel * -20) $runner__white,
            calc($pixel * 23) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -21) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__white,
            calc($pixel * 15) calc($pixel * -21) $runner__white,
            calc($pixel * 16) calc($pixel * -21) $runner__hair,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__suit,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 23) calc($pixel * -21) $runner__white,
            calc($pixel * 13) calc($pixel * -22) $runner__white,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__hair,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__hair,
            calc($pixel * 19) calc($pixel * -22) $runner__suit,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 21) calc($pixel * -22) $runner__hair,
            calc($pixel * 22) calc($pixel * -22) $runner__hair,
            calc($pixel * 14) calc($pixel * -23) $runner__hair,
            calc($pixel * 15) calc($pixel * -23) $runner__hair,
            calc($pixel * 16) calc($pixel * -23) $runner__hair,
            calc($pixel * 17) calc($pixel * -23) $runner__hair,
            calc($pixel * 18) calc($pixel * -23) $runner__hair,
            calc($pixel * 19) calc($pixel * -23) $runner__suit,
            calc($pixel * 20) calc($pixel * -23) $runner__hair,
            calc($pixel * 15) calc($pixel * -24) $runner__suit,
            calc($pixel * 16) calc($pixel * -24) $runner__suit,
            calc($pixel * 17) calc($pixel * -24) $runner__suit,
            calc($pixel * 19) calc($pixel * -24) $runner__suit,
            calc($pixel * 17) calc($pixel * -25) $runner__suit,
            calc($pixel * 18) calc($pixel * -25) $runner__suit,
            calc($pixel * 19) calc($pixel * -25) $runner__suit;
    }
    .runner__frame3--shadow {
        -webkit-box-shadow: calc($pixel * 13) calc($pixel * 1) $runner__shadow,
            calc($pixel * 14) calc($pixel * 1) $runner__shadow,
            calc($pixel * 12) calc($pixel * 2) $runner__shadow,
            calc($pixel * 13) calc($pixel * 2) $runner__shadow,
            calc($pixel * 14) calc($pixel * 2) $runner__shadow,
            calc($pixel * 15) calc($pixel * 2) $runner__shadow,
            calc($pixel * 11) calc($pixel * 3) $runner__shadow,
            calc($pixel * 12) calc($pixel * 3) $runner__shadow,
            calc($pixel * 13) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 13) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 4) calc($pixel * 7) $runner__shadow,
            calc($pixel * 5) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 3) calc($pixel * 8) $runner__shadow,
            calc($pixel * 4) calc($pixel * 8) $runner__shadow,
            calc($pixel * 5) calc($pixel * 8) $runner__shadow,
            calc($pixel * 6) calc($pixel * 8) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 23) calc($pixel * 8) $runner__shadow,
            calc($pixel * 24) calc($pixel * 8) $runner__shadow,
            calc($pixel * 1) calc($pixel * 9) $runner__shadow,
            calc($pixel * 2) calc($pixel * 9) $runner__shadow,
            calc($pixel * 3) calc($pixel * 9) $runner__shadow,
            calc($pixel * 4) calc($pixel * 9) $runner__shadow,
            calc($pixel * 6) calc($pixel * 9) $runner__shadow,
            calc($pixel * 7) calc($pixel * 9) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 21) calc($pixel * 9) $runner__shadow,
            calc($pixel * 22) calc($pixel * 9) $runner__shadow,
            calc($pixel * 23) calc($pixel * 9) $runner__shadow,
            calc($pixel * 24) calc($pixel * 9) $runner__shadow,
            calc($pixel * 25) calc($pixel * 9) $runner__shadow,
            calc($pixel * 2) calc($pixel * 10) $runner__shadow,
            calc($pixel * 3) calc($pixel * 10) $runner__shadow,
            calc($pixel * 6) calc($pixel * 10) $runner__shadow,
            calc($pixel * 7) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 10) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 21) calc($pixel * 10) $runner__shadow,
            calc($pixel * 22) calc($pixel * 10) $runner__shadow,
            calc($pixel * 23) calc($pixel * 10) $runner__shadow,
            calc($pixel * 24) calc($pixel * 10) $runner__shadow,
            calc($pixel * 25) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 22) calc($pixel * 11) $runner__shadow,
            calc($pixel * 23) calc($pixel * 11) $runner__shadow,
            calc($pixel * 24) calc($pixel * 11) $runner__shadow,
            calc($pixel * 25) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 23) calc($pixel * 12) $runner__shadow,
            calc($pixel * 24) calc($pixel * 12) $runner__shadow,
            calc($pixel * 9) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 9) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 9) calc($pixel * 17) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 9) calc($pixel * 18) $runner__shadow,
            calc($pixel * 10) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 10) calc($pixel * 19) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 24) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 23) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 21) calc($pixel * 22) $runner__shadow,
            calc($pixel * 22) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 23) $runner__shadow,
            calc($pixel * 15) calc($pixel * 23) $runner__shadow,
            calc($pixel * 16) calc($pixel * 23) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow,
            calc($pixel * 19) calc($pixel * 23) $runner__shadow,
            calc($pixel * 20) calc($pixel * 23) $runner__shadow,
            calc($pixel * 15) calc($pixel * 24) $runner__shadow,
            calc($pixel * 16) calc($pixel * 24) $runner__shadow,
            calc($pixel * 17) calc($pixel * 24) $runner__shadow,
            calc($pixel * 19) calc($pixel * 24) $runner__shadow,
            calc($pixel * 17) calc($pixel * 25) $runner__shadow,
            calc($pixel * 18) calc($pixel * 25) $runner__shadow,
            calc($pixel * 19) calc($pixel * 25) $runner__shadow;
        box-shadow: calc($pixel * 13) calc($pixel * 1) $runner__shadow,
            calc($pixel * 14) calc($pixel * 1) $runner__shadow,
            calc($pixel * 12) calc($pixel * 2) $runner__shadow,
            calc($pixel * 13) calc($pixel * 2) $runner__shadow,
            calc($pixel * 14) calc($pixel * 2) $runner__shadow,
            calc($pixel * 15) calc($pixel * 2) $runner__shadow,
            calc($pixel * 11) calc($pixel * 3) $runner__shadow,
            calc($pixel * 12) calc($pixel * 3) $runner__shadow,
            calc($pixel * 13) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 13) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 4) calc($pixel * 7) $runner__shadow,
            calc($pixel * 5) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 3) calc($pixel * 8) $runner__shadow,
            calc($pixel * 4) calc($pixel * 8) $runner__shadow,
            calc($pixel * 5) calc($pixel * 8) $runner__shadow,
            calc($pixel * 6) calc($pixel * 8) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 23) calc($pixel * 8) $runner__shadow,
            calc($pixel * 24) calc($pixel * 8) $runner__shadow,
            calc($pixel * 1) calc($pixel * 9) $runner__shadow,
            calc($pixel * 2) calc($pixel * 9) $runner__shadow,
            calc($pixel * 3) calc($pixel * 9) $runner__shadow,
            calc($pixel * 4) calc($pixel * 9) $runner__shadow,
            calc($pixel * 6) calc($pixel * 9) $runner__shadow,
            calc($pixel * 7) calc($pixel * 9) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 21) calc($pixel * 9) $runner__shadow,
            calc($pixel * 22) calc($pixel * 9) $runner__shadow,
            calc($pixel * 23) calc($pixel * 9) $runner__shadow,
            calc($pixel * 24) calc($pixel * 9) $runner__shadow,
            calc($pixel * 25) calc($pixel * 9) $runner__shadow,
            calc($pixel * 2) calc($pixel * 10) $runner__shadow,
            calc($pixel * 3) calc($pixel * 10) $runner__shadow,
            calc($pixel * 6) calc($pixel * 10) $runner__shadow,
            calc($pixel * 7) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 10) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 21) calc($pixel * 10) $runner__shadow,
            calc($pixel * 22) calc($pixel * 10) $runner__shadow,
            calc($pixel * 23) calc($pixel * 10) $runner__shadow,
            calc($pixel * 24) calc($pixel * 10) $runner__shadow,
            calc($pixel * 25) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 22) calc($pixel * 11) $runner__shadow,
            calc($pixel * 23) calc($pixel * 11) $runner__shadow,
            calc($pixel * 24) calc($pixel * 11) $runner__shadow,
            calc($pixel * 25) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 23) calc($pixel * 12) $runner__shadow,
            calc($pixel * 24) calc($pixel * 12) $runner__shadow,
            calc($pixel * 9) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 9) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 9) calc($pixel * 17) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 9) calc($pixel * 18) $runner__shadow,
            calc($pixel * 10) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 10) calc($pixel * 19) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 24) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 23) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 21) calc($pixel * 22) $runner__shadow,
            calc($pixel * 22) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 23) $runner__shadow,
            calc($pixel * 15) calc($pixel * 23) $runner__shadow,
            calc($pixel * 16) calc($pixel * 23) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow,
            calc($pixel * 19) calc($pixel * 23) $runner__shadow,
            calc($pixel * 20) calc($pixel * 23) $runner__shadow,
            calc($pixel * 15) calc($pixel * 24) $runner__shadow,
            calc($pixel * 16) calc($pixel * 24) $runner__shadow,
            calc($pixel * 17) calc($pixel * 24) $runner__shadow,
            calc($pixel * 19) calc($pixel * 24) $runner__shadow,
            calc($pixel * 17) calc($pixel * 25) $runner__shadow,
            calc($pixel * 18) calc($pixel * 25) $runner__shadow,
            calc($pixel * 19) calc($pixel * 25) $runner__shadow;
    }
    .runner__frame4 {
        -webkit-box-shadow: calc($pixel * 9) calc($pixel * 0) $runner__black,
            calc($pixel * 10) calc($pixel * 0) $runner__black,
            calc($pixel * 11) calc($pixel * 0) $runner__skin,
            calc($pixel * 12) calc($pixel * 0) $runner__skin,
            calc($pixel * 9) calc($pixel * -1) $runner__black,
            calc($pixel * 10) calc($pixel * -1) $runner__black,
            calc($pixel * 11) calc($pixel * -1) $runner__black,
            calc($pixel * 9) calc($pixel * -2) $runner__black,
            calc($pixel * 10) calc($pixel * -2) $runner__black,
            calc($pixel * 11) calc($pixel * -2) $runner__black,
            calc($pixel * 8) calc($pixel * -3) $runner__black,
            calc($pixel * 9) calc($pixel * -3) $runner__black,
            calc($pixel * 10) calc($pixel * -3) $runner__suit,
            calc($pixel * 11) calc($pixel * -3) $runner__black,
            calc($pixel * 6) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -4) $runner__black,
            calc($pixel * 8) calc($pixel * -4) $runner__suit,
            calc($pixel * 9) calc($pixel * -4) $runner__suit,
            calc($pixel * 10) calc($pixel * -4) $runner__skin,
            calc($pixel * 11) calc($pixel * -4) $runner__skin,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 5) calc($pixel * -5) $runner__skin,
            calc($pixel * 6) calc($pixel * -5) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__suit,
            calc($pixel * 8) calc($pixel * -5) $runner__skin,
            calc($pixel * 9) calc($pixel * -5) $runner__skin,
            calc($pixel * 10) calc($pixel * -5) $runner__black,
            calc($pixel * 11) calc($pixel * -5) $runner__black,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 19) calc($pixel * -5) $runner__black,
            calc($pixel * 20) calc($pixel * -5) $runner__black,
            calc($pixel * 21) calc($pixel * -5) $runner__black,
            calc($pixel * 22) calc($pixel * -5) $runner__black,
            calc($pixel * 23) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -6) $runner__skin,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__skin,
            calc($pixel * 8) calc($pixel * -6) $runner__black,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__black,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__black,
            calc($pixel * 17) calc($pixel * -6) $runner__black,
            calc($pixel * 18) calc($pixel * -6) $runner__black,
            calc($pixel * 19) calc($pixel * -6) $runner__suit,
            calc($pixel * 20) calc($pixel * -6) $runner__suit,
            calc($pixel * 21) calc($pixel * -6) $runner__suit,
            calc($pixel * 22) calc($pixel * -6) $runner__suit,
            calc($pixel * 23) calc($pixel * -6) $runner__suit,
            calc($pixel * 6) calc($pixel * -7) $runner__skin,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__black,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__black,
            calc($pixel * 14) calc($pixel * -7) $runner__black,
            calc($pixel * 15) calc($pixel * -7) $runner__black,
            calc($pixel * 16) calc($pixel * -7) $runner__black,
            calc($pixel * 17) calc($pixel * -7) $runner__suit,
            calc($pixel * 18) calc($pixel * -7) $runner__suit,
            calc($pixel * 19) calc($pixel * -7) $runner__suit,
            calc($pixel * 20) calc($pixel * -7) $runner__suit,
            calc($pixel * 21) calc($pixel * -7) $runner__suit,
            calc($pixel * 22) calc($pixel * -7) $runner__suit,
            calc($pixel * 23) calc($pixel * -7) $runner__suit,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__black,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__black,
            calc($pixel * 13) calc($pixel * -8) $runner__black,
            calc($pixel * 14) calc($pixel * -8) $runner__black,
            calc($pixel * 15) calc($pixel * -8) $runner__black,
            calc($pixel * 16) calc($pixel * -8) $runner__suit,
            calc($pixel * 17) calc($pixel * -8) $runner__suit,
            calc($pixel * 18) calc($pixel * -8) $runner__suit,
            calc($pixel * 19) calc($pixel * -8) $runner__suit,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__suit,
            calc($pixel * 23) calc($pixel * -8) $runner__skin,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__black,
            calc($pixel * 12) calc($pixel * -9) $runner__black,
            calc($pixel * 13) calc($pixel * -9) $runner__black,
            calc($pixel * 14) calc($pixel * -9) $runner__black,
            calc($pixel * 15) calc($pixel * -9) $runner__suit,
            calc($pixel * 16) calc($pixel * -9) $runner__suit,
            calc($pixel * 17) calc($pixel * -9) $runner__suit,
            calc($pixel * 18) calc($pixel * -9) $runner__suit,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__black,
            calc($pixel * 13) calc($pixel * -10) $runner__black,
            calc($pixel * 14) calc($pixel * -10) $runner__black,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__suit,
            calc($pixel * 17) calc($pixel * -10) $runner__suit,
            calc($pixel * 18) calc($pixel * -10) $runner__suit,
            calc($pixel * 19) calc($pixel * -10) $runner__suit,
            calc($pixel * 20) calc($pixel * -10) $runner__suit,
            calc($pixel * 21) calc($pixel * -10) $runner__suit,
            calc($pixel * 8) calc($pixel * -11) $runner__suit,
            calc($pixel * 9) calc($pixel * -11) $runner__suit,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__black,
            calc($pixel * 13) calc($pixel * -11) $runner__black,
            calc($pixel * 14) calc($pixel * -11) $runner__black,
            calc($pixel * 15) calc($pixel * -11) $runner__black,
            calc($pixel * 16) calc($pixel * -11) $runner__suit,
            calc($pixel * 17) calc($pixel * -11) $runner__suit,
            calc($pixel * 18) calc($pixel * -11) $runner__suit,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__skin,
            calc($pixel * 21) calc($pixel * -11) $runner__skin,
            calc($pixel * 8) calc($pixel * -12) $runner__suit,
            calc($pixel * 9) calc($pixel * -12) $runner__suit,
            calc($pixel * 10) calc($pixel * -12) $runner__suit,
            calc($pixel * 11) calc($pixel * -12) $runner__black,
            calc($pixel * 12) calc($pixel * -12) $runner__black,
            calc($pixel * 13) calc($pixel * -12) $runner__black,
            calc($pixel * 14) calc($pixel * -12) $runner__black,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__black,
            calc($pixel * 17) calc($pixel * -12) $runner__hair,
            calc($pixel * 18) calc($pixel * -12) $runner__hair,
            calc($pixel * 19) calc($pixel * -12) $runner__hair,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 22) calc($pixel * -12) $runner__skin,
            calc($pixel * 9) calc($pixel * -13) $runner__suit,
            calc($pixel * 10) calc($pixel * -13) $runner__black,
            calc($pixel * 11) calc($pixel * -13) $runner__black,
            calc($pixel * 12) calc($pixel * -13) $runner__black,
            calc($pixel * 13) calc($pixel * -13) $runner__black,
            calc($pixel * 14) calc($pixel * -13) $runner__black,
            calc($pixel * 15) calc($pixel * -13) $runner__hair,
            calc($pixel * 16) calc($pixel * -13) $runner__hair,
            calc($pixel * 17) calc($pixel * -13) $runner__black,
            calc($pixel * 18) calc($pixel * -13) $runner__black,
            calc($pixel * 19) calc($pixel * -13) $runner__black,
            calc($pixel * 20) calc($pixel * -13) $runner__hair,
            calc($pixel * 21) calc($pixel * -13) $runner__skin,
            calc($pixel * 22) calc($pixel * -13) $runner__eyes,
            calc($pixel * 9) calc($pixel * -14) $runner__black,
            calc($pixel * 10) calc($pixel * -14) $runner__black,
            calc($pixel * 11) calc($pixel * -14) $runner__black,
            calc($pixel * 12) calc($pixel * -14) $runner__black,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__hair,
            calc($pixel * 15) calc($pixel * -14) $runner__hair,
            calc($pixel * 16) calc($pixel * -14) $runner__black,
            calc($pixel * 17) calc($pixel * -14) $runner__black,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__black,
            calc($pixel * 21) calc($pixel * -14) $runner__hair,
            calc($pixel * 22) calc($pixel * -14) $runner__eyes,
            calc($pixel * 8) calc($pixel * -15) $runner__black,
            calc($pixel * 9) calc($pixel * -15) $runner__black,
            calc($pixel * 10) calc($pixel * -15) $runner__black,
            calc($pixel * 11) calc($pixel * -15) $runner__black,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__hair,
            calc($pixel * 15) calc($pixel * -15) $runner__hair,
            calc($pixel * 16) calc($pixel * -15) $runner__suit,
            calc($pixel * 17) calc($pixel * -15) $runner__suit,
            calc($pixel * 18) calc($pixel * -15) $runner__black,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__black,
            calc($pixel * 22) calc($pixel * -15) $runner__hair,
            calc($pixel * 23) calc($pixel * -15) $runner__hair,
            calc($pixel * 8) calc($pixel * -16) $runner__black,
            calc($pixel * 9) calc($pixel * -16) $runner__black,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__hair,
            calc($pixel * 15) calc($pixel * -16) $runner__hair,
            calc($pixel * 16) calc($pixel * -16) $runner__suit,
            calc($pixel * 17) calc($pixel * -16) $runner__white,
            calc($pixel * 18) calc($pixel * -16) $runner__suit,
            calc($pixel * 19) calc($pixel * -16) $runner__black,
            calc($pixel * 20) calc($pixel * -16) $runner__black,
            calc($pixel * 21) calc($pixel * -16) $runner__black,
            calc($pixel * 22) calc($pixel * -16) $runner__black,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 24) calc($pixel * -16) $runner__hair,
            calc($pixel * 8) calc($pixel * -17) $runner__hair,
            calc($pixel * 9) calc($pixel * -17) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__hair,
            calc($pixel * 16) calc($pixel * -17) $runner__hair,
            calc($pixel * 17) calc($pixel * -17) $runner__suit,
            calc($pixel * 18) calc($pixel * -17) $runner__suit,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__hair,
            calc($pixel * 21) calc($pixel * -17) $runner__hair,
            calc($pixel * 22) calc($pixel * -17) $runner__hair,
            calc($pixel * 23) calc($pixel * -17) $runner__black,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 8) calc($pixel * -18) $runner__hair,
            calc($pixel * 9) calc($pixel * -18) $runner__hair,
            calc($pixel * 10) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__hair,
            calc($pixel * 15) calc($pixel * -18) $runner__hair,
            calc($pixel * 16) calc($pixel * -18) $runner__hair,
            calc($pixel * 17) calc($pixel * -18) $runner__hair,
            calc($pixel * 18) calc($pixel * -18) $runner__suit,
            calc($pixel * 19) calc($pixel * -18) $runner__hair,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__hair,
            calc($pixel * 24) calc($pixel * -18) $runner__hair,
            calc($pixel * 9) calc($pixel * -19) $runner__hair,
            calc($pixel * 10) calc($pixel * -19) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__white,
            calc($pixel * 16) calc($pixel * -19) $runner__white,
            calc($pixel * 17) calc($pixel * -19) $runner__white,
            calc($pixel * 18) calc($pixel * -19) $runner__suit,
            calc($pixel * 19) calc($pixel * -19) $runner__white,
            calc($pixel * 20) calc($pixel * -19) $runner__white,
            calc($pixel * 21) calc($pixel * -19) $runner__white,
            calc($pixel * 22) calc($pixel * -19) $runner__white,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 11) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__white,
            calc($pixel * 14) calc($pixel * -20) $runner__white,
            calc($pixel * 15) calc($pixel * -20) $runner__white,
            calc($pixel * 16) calc($pixel * -20) $runner__hair,
            calc($pixel * 17) calc($pixel * -20) $runner__hair,
            calc($pixel * 18) calc($pixel * -20) $runner__suit,
            calc($pixel * 19) calc($pixel * -20) $runner__hair,
            calc($pixel * 20) calc($pixel * -20) $runner__hair,
            calc($pixel * 21) calc($pixel * -20) $runner__hair,
            calc($pixel * 22) calc($pixel * -20) $runner__hair,
            calc($pixel * 23) calc($pixel * -20) $runner__white,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__hair,
            calc($pixel * 15) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -21) $runner__hair,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__suit,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__hair,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__suit,
            calc($pixel * 19) calc($pixel * -22) $runner__hair,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -23) $runner__suit,
            calc($pixel * 17) calc($pixel * -23) $runner__suit,
            calc($pixel * 18) calc($pixel * -23) $runner__suit;
        box-shadow: calc($pixel * 9) calc($pixel * 0) $runner__black,
            calc($pixel * 10) calc($pixel * 0) $runner__black,
            calc($pixel * 11) calc($pixel * 0) $runner__skin,
            calc($pixel * 12) calc($pixel * 0) $runner__skin,
            calc($pixel * 9) calc($pixel * -1) $runner__black,
            calc($pixel * 10) calc($pixel * -1) $runner__black,
            calc($pixel * 11) calc($pixel * -1) $runner__black,
            calc($pixel * 9) calc($pixel * -2) $runner__black,
            calc($pixel * 10) calc($pixel * -2) $runner__black,
            calc($pixel * 11) calc($pixel * -2) $runner__black,
            calc($pixel * 8) calc($pixel * -3) $runner__black,
            calc($pixel * 9) calc($pixel * -3) $runner__black,
            calc($pixel * 10) calc($pixel * -3) $runner__suit,
            calc($pixel * 11) calc($pixel * -3) $runner__black,
            calc($pixel * 6) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -4) $runner__black,
            calc($pixel * 8) calc($pixel * -4) $runner__suit,
            calc($pixel * 9) calc($pixel * -4) $runner__suit,
            calc($pixel * 10) calc($pixel * -4) $runner__skin,
            calc($pixel * 11) calc($pixel * -4) $runner__skin,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 5) calc($pixel * -5) $runner__skin,
            calc($pixel * 6) calc($pixel * -5) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__suit,
            calc($pixel * 8) calc($pixel * -5) $runner__skin,
            calc($pixel * 9) calc($pixel * -5) $runner__skin,
            calc($pixel * 10) calc($pixel * -5) $runner__black,
            calc($pixel * 11) calc($pixel * -5) $runner__black,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 19) calc($pixel * -5) $runner__black,
            calc($pixel * 20) calc($pixel * -5) $runner__black,
            calc($pixel * 21) calc($pixel * -5) $runner__black,
            calc($pixel * 22) calc($pixel * -5) $runner__black,
            calc($pixel * 23) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -6) $runner__skin,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__skin,
            calc($pixel * 8) calc($pixel * -6) $runner__black,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__black,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__black,
            calc($pixel * 17) calc($pixel * -6) $runner__black,
            calc($pixel * 18) calc($pixel * -6) $runner__black,
            calc($pixel * 19) calc($pixel * -6) $runner__suit,
            calc($pixel * 20) calc($pixel * -6) $runner__suit,
            calc($pixel * 21) calc($pixel * -6) $runner__suit,
            calc($pixel * 22) calc($pixel * -6) $runner__suit,
            calc($pixel * 23) calc($pixel * -6) $runner__suit,
            calc($pixel * 6) calc($pixel * -7) $runner__skin,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__black,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__black,
            calc($pixel * 14) calc($pixel * -7) $runner__black,
            calc($pixel * 15) calc($pixel * -7) $runner__black,
            calc($pixel * 16) calc($pixel * -7) $runner__black,
            calc($pixel * 17) calc($pixel * -7) $runner__suit,
            calc($pixel * 18) calc($pixel * -7) $runner__suit,
            calc($pixel * 19) calc($pixel * -7) $runner__suit,
            calc($pixel * 20) calc($pixel * -7) $runner__suit,
            calc($pixel * 21) calc($pixel * -7) $runner__suit,
            calc($pixel * 22) calc($pixel * -7) $runner__suit,
            calc($pixel * 23) calc($pixel * -7) $runner__suit,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__black,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__black,
            calc($pixel * 13) calc($pixel * -8) $runner__black,
            calc($pixel * 14) calc($pixel * -8) $runner__black,
            calc($pixel * 15) calc($pixel * -8) $runner__black,
            calc($pixel * 16) calc($pixel * -8) $runner__suit,
            calc($pixel * 17) calc($pixel * -8) $runner__suit,
            calc($pixel * 18) calc($pixel * -8) $runner__suit,
            calc($pixel * 19) calc($pixel * -8) $runner__suit,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__suit,
            calc($pixel * 23) calc($pixel * -8) $runner__skin,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__black,
            calc($pixel * 12) calc($pixel * -9) $runner__black,
            calc($pixel * 13) calc($pixel * -9) $runner__black,
            calc($pixel * 14) calc($pixel * -9) $runner__black,
            calc($pixel * 15) calc($pixel * -9) $runner__suit,
            calc($pixel * 16) calc($pixel * -9) $runner__suit,
            calc($pixel * 17) calc($pixel * -9) $runner__suit,
            calc($pixel * 18) calc($pixel * -9) $runner__suit,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__black,
            calc($pixel * 13) calc($pixel * -10) $runner__black,
            calc($pixel * 14) calc($pixel * -10) $runner__black,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__suit,
            calc($pixel * 17) calc($pixel * -10) $runner__suit,
            calc($pixel * 18) calc($pixel * -10) $runner__suit,
            calc($pixel * 19) calc($pixel * -10) $runner__suit,
            calc($pixel * 20) calc($pixel * -10) $runner__suit,
            calc($pixel * 21) calc($pixel * -10) $runner__suit,
            calc($pixel * 8) calc($pixel * -11) $runner__suit,
            calc($pixel * 9) calc($pixel * -11) $runner__suit,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__black,
            calc($pixel * 13) calc($pixel * -11) $runner__black,
            calc($pixel * 14) calc($pixel * -11) $runner__black,
            calc($pixel * 15) calc($pixel * -11) $runner__black,
            calc($pixel * 16) calc($pixel * -11) $runner__suit,
            calc($pixel * 17) calc($pixel * -11) $runner__suit,
            calc($pixel * 18) calc($pixel * -11) $runner__suit,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__skin,
            calc($pixel * 21) calc($pixel * -11) $runner__skin,
            calc($pixel * 8) calc($pixel * -12) $runner__suit,
            calc($pixel * 9) calc($pixel * -12) $runner__suit,
            calc($pixel * 10) calc($pixel * -12) $runner__suit,
            calc($pixel * 11) calc($pixel * -12) $runner__black,
            calc($pixel * 12) calc($pixel * -12) $runner__black,
            calc($pixel * 13) calc($pixel * -12) $runner__black,
            calc($pixel * 14) calc($pixel * -12) $runner__black,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__black,
            calc($pixel * 17) calc($pixel * -12) $runner__hair,
            calc($pixel * 18) calc($pixel * -12) $runner__hair,
            calc($pixel * 19) calc($pixel * -12) $runner__hair,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 22) calc($pixel * -12) $runner__skin,
            calc($pixel * 9) calc($pixel * -13) $runner__suit,
            calc($pixel * 10) calc($pixel * -13) $runner__black,
            calc($pixel * 11) calc($pixel * -13) $runner__black,
            calc($pixel * 12) calc($pixel * -13) $runner__black,
            calc($pixel * 13) calc($pixel * -13) $runner__black,
            calc($pixel * 14) calc($pixel * -13) $runner__black,
            calc($pixel * 15) calc($pixel * -13) $runner__hair,
            calc($pixel * 16) calc($pixel * -13) $runner__hair,
            calc($pixel * 17) calc($pixel * -13) $runner__black,
            calc($pixel * 18) calc($pixel * -13) $runner__black,
            calc($pixel * 19) calc($pixel * -13) $runner__black,
            calc($pixel * 20) calc($pixel * -13) $runner__hair,
            calc($pixel * 21) calc($pixel * -13) $runner__skin,
            calc($pixel * 22) calc($pixel * -13) $runner__eyes,
            calc($pixel * 9) calc($pixel * -14) $runner__black,
            calc($pixel * 10) calc($pixel * -14) $runner__black,
            calc($pixel * 11) calc($pixel * -14) $runner__black,
            calc($pixel * 12) calc($pixel * -14) $runner__black,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__hair,
            calc($pixel * 15) calc($pixel * -14) $runner__hair,
            calc($pixel * 16) calc($pixel * -14) $runner__black,
            calc($pixel * 17) calc($pixel * -14) $runner__black,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__black,
            calc($pixel * 21) calc($pixel * -14) $runner__hair,
            calc($pixel * 22) calc($pixel * -14) $runner__eyes,
            calc($pixel * 8) calc($pixel * -15) $runner__black,
            calc($pixel * 9) calc($pixel * -15) $runner__black,
            calc($pixel * 10) calc($pixel * -15) $runner__black,
            calc($pixel * 11) calc($pixel * -15) $runner__black,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__hair,
            calc($pixel * 15) calc($pixel * -15) $runner__hair,
            calc($pixel * 16) calc($pixel * -15) $runner__suit,
            calc($pixel * 17) calc($pixel * -15) $runner__suit,
            calc($pixel * 18) calc($pixel * -15) $runner__black,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__black,
            calc($pixel * 22) calc($pixel * -15) $runner__hair,
            calc($pixel * 23) calc($pixel * -15) $runner__hair,
            calc($pixel * 8) calc($pixel * -16) $runner__black,
            calc($pixel * 9) calc($pixel * -16) $runner__black,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__hair,
            calc($pixel * 15) calc($pixel * -16) $runner__hair,
            calc($pixel * 16) calc($pixel * -16) $runner__suit,
            calc($pixel * 17) calc($pixel * -16) $runner__white,
            calc($pixel * 18) calc($pixel * -16) $runner__suit,
            calc($pixel * 19) calc($pixel * -16) $runner__black,
            calc($pixel * 20) calc($pixel * -16) $runner__black,
            calc($pixel * 21) calc($pixel * -16) $runner__black,
            calc($pixel * 22) calc($pixel * -16) $runner__black,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 24) calc($pixel * -16) $runner__hair,
            calc($pixel * 8) calc($pixel * -17) $runner__hair,
            calc($pixel * 9) calc($pixel * -17) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__hair,
            calc($pixel * 16) calc($pixel * -17) $runner__hair,
            calc($pixel * 17) calc($pixel * -17) $runner__suit,
            calc($pixel * 18) calc($pixel * -17) $runner__suit,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__hair,
            calc($pixel * 21) calc($pixel * -17) $runner__hair,
            calc($pixel * 22) calc($pixel * -17) $runner__hair,
            calc($pixel * 23) calc($pixel * -17) $runner__black,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 8) calc($pixel * -18) $runner__hair,
            calc($pixel * 9) calc($pixel * -18) $runner__hair,
            calc($pixel * 10) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__hair,
            calc($pixel * 15) calc($pixel * -18) $runner__hair,
            calc($pixel * 16) calc($pixel * -18) $runner__hair,
            calc($pixel * 17) calc($pixel * -18) $runner__hair,
            calc($pixel * 18) calc($pixel * -18) $runner__suit,
            calc($pixel * 19) calc($pixel * -18) $runner__hair,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__hair,
            calc($pixel * 24) calc($pixel * -18) $runner__hair,
            calc($pixel * 9) calc($pixel * -19) $runner__hair,
            calc($pixel * 10) calc($pixel * -19) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__white,
            calc($pixel * 16) calc($pixel * -19) $runner__white,
            calc($pixel * 17) calc($pixel * -19) $runner__white,
            calc($pixel * 18) calc($pixel * -19) $runner__suit,
            calc($pixel * 19) calc($pixel * -19) $runner__white,
            calc($pixel * 20) calc($pixel * -19) $runner__white,
            calc($pixel * 21) calc($pixel * -19) $runner__white,
            calc($pixel * 22) calc($pixel * -19) $runner__white,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 11) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__white,
            calc($pixel * 14) calc($pixel * -20) $runner__white,
            calc($pixel * 15) calc($pixel * -20) $runner__white,
            calc($pixel * 16) calc($pixel * -20) $runner__hair,
            calc($pixel * 17) calc($pixel * -20) $runner__hair,
            calc($pixel * 18) calc($pixel * -20) $runner__suit,
            calc($pixel * 19) calc($pixel * -20) $runner__hair,
            calc($pixel * 20) calc($pixel * -20) $runner__hair,
            calc($pixel * 21) calc($pixel * -20) $runner__hair,
            calc($pixel * 22) calc($pixel * -20) $runner__hair,
            calc($pixel * 23) calc($pixel * -20) $runner__white,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__hair,
            calc($pixel * 15) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -21) $runner__hair,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__suit,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__hair,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__suit,
            calc($pixel * 19) calc($pixel * -22) $runner__hair,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -23) $runner__suit,
            calc($pixel * 17) calc($pixel * -23) $runner__suit,
            calc($pixel * 18) calc($pixel * -23) $runner__suit;
    }
    .runner__frame4--shadow {
        -webkit-box-shadow: calc($pixel * 9) calc($pixel * 0) $runner__shadow,
            calc($pixel * 10) calc($pixel * 0) $runner__shadow,
            calc($pixel * 11) calc($pixel * 0) $runner__shadow,
            calc($pixel * 12) calc($pixel * 0) $runner__shadow,
            calc($pixel * 9) calc($pixel * 1) $runner__shadow,
            calc($pixel * 10) calc($pixel * 1) $runner__shadow,
            calc($pixel * 11) calc($pixel * 1) $runner__shadow,
            calc($pixel * 9) calc($pixel * 2) $runner__shadow,
            calc($pixel * 10) calc($pixel * 2) $runner__shadow,
            calc($pixel * 11) calc($pixel * 2) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 11) calc($pixel * 3) $runner__shadow,
            calc($pixel * 6) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 4) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 5) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 5) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 19) calc($pixel * 5) $runner__shadow,
            calc($pixel * 20) calc($pixel * 5) $runner__shadow,
            calc($pixel * 21) calc($pixel * 5) $runner__shadow,
            calc($pixel * 22) calc($pixel * 5) $runner__shadow,
            calc($pixel * 23) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 17) calc($pixel * 6) $runner__shadow,
            calc($pixel * 18) calc($pixel * 6) $runner__shadow,
            calc($pixel * 19) calc($pixel * 6) $runner__shadow,
            calc($pixel * 20) calc($pixel * 6) $runner__shadow,
            calc($pixel * 21) calc($pixel * 6) $runner__shadow,
            calc($pixel * 22) calc($pixel * 6) $runner__shadow,
            calc($pixel * 23) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 16) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 19) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 23) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 23) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 21) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 8) calc($pixel * 12) $runner__shadow,
            calc($pixel * 9) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 22) calc($pixel * 12) $runner__shadow,
            calc($pixel * 9) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 8) calc($pixel * 15) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 23) calc($pixel * 15) $runner__shadow,
            calc($pixel * 8) calc($pixel * 16) $runner__shadow,
            calc($pixel * 9) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 24) calc($pixel * 16) $runner__shadow,
            calc($pixel * 8) calc($pixel * 17) $runner__shadow,
            calc($pixel * 9) calc($pixel * 17) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 8) calc($pixel * 18) $runner__shadow,
            calc($pixel * 9) calc($pixel * 18) $runner__shadow,
            calc($pixel * 10) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 9) calc($pixel * 19) $runner__shadow,
            calc($pixel * 10) calc($pixel * 19) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 11) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 23) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow;
        box-shadow: calc($pixel * 9) calc($pixel * 0) $runner__shadow,
            calc($pixel * 10) calc($pixel * 0) $runner__shadow,
            calc($pixel * 11) calc($pixel * 0) $runner__shadow,
            calc($pixel * 12) calc($pixel * 0) $runner__shadow,
            calc($pixel * 9) calc($pixel * 1) $runner__shadow,
            calc($pixel * 10) calc($pixel * 1) $runner__shadow,
            calc($pixel * 11) calc($pixel * 1) $runner__shadow,
            calc($pixel * 9) calc($pixel * 2) $runner__shadow,
            calc($pixel * 10) calc($pixel * 2) $runner__shadow,
            calc($pixel * 11) calc($pixel * 2) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 11) calc($pixel * 3) $runner__shadow,
            calc($pixel * 6) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 4) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 5) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 5) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 19) calc($pixel * 5) $runner__shadow,
            calc($pixel * 20) calc($pixel * 5) $runner__shadow,
            calc($pixel * 21) calc($pixel * 5) $runner__shadow,
            calc($pixel * 22) calc($pixel * 5) $runner__shadow,
            calc($pixel * 23) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 17) calc($pixel * 6) $runner__shadow,
            calc($pixel * 18) calc($pixel * 6) $runner__shadow,
            calc($pixel * 19) calc($pixel * 6) $runner__shadow,
            calc($pixel * 20) calc($pixel * 6) $runner__shadow,
            calc($pixel * 21) calc($pixel * 6) $runner__shadow,
            calc($pixel * 22) calc($pixel * 6) $runner__shadow,
            calc($pixel * 23) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 16) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 19) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 23) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 23) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 21) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 8) calc($pixel * 12) $runner__shadow,
            calc($pixel * 9) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 22) calc($pixel * 12) $runner__shadow,
            calc($pixel * 9) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 8) calc($pixel * 15) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 23) calc($pixel * 15) $runner__shadow,
            calc($pixel * 8) calc($pixel * 16) $runner__shadow,
            calc($pixel * 9) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 24) calc($pixel * 16) $runner__shadow,
            calc($pixel * 8) calc($pixel * 17) $runner__shadow,
            calc($pixel * 9) calc($pixel * 17) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 8) calc($pixel * 18) $runner__shadow,
            calc($pixel * 9) calc($pixel * 18) $runner__shadow,
            calc($pixel * 10) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 9) calc($pixel * 19) $runner__shadow,
            calc($pixel * 10) calc($pixel * 19) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 11) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 23) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow;
    }
    .runner__frame5 {
        -webkit-box-shadow: calc($pixel * 1) calc($pixel * 0) $runner__black,
            calc($pixel * 2) calc($pixel * 0) $runner__skin,
            calc($pixel * 3) calc($pixel * 0) $runner__skin,
            calc($pixel * 1) calc($pixel * -1) $runner__black,
            calc($pixel * 2) calc($pixel * -1) $runner__black,
            calc($pixel * 3) calc($pixel * -1) $runner__black,
            calc($pixel * 7) calc($pixel * -1) $runner__skin,
            calc($pixel * 1) calc($pixel * -2) $runner__black,
            calc($pixel * 2) calc($pixel * -2) $runner__black,
            calc($pixel * 3) calc($pixel * -2) $runner__black,
            calc($pixel * 4) calc($pixel * -2) $runner__black,
            calc($pixel * 5) calc($pixel * -2) $runner__black,
            calc($pixel * 6) calc($pixel * -2) $runner__skin,
            calc($pixel * 7) calc($pixel * -2) $runner__suit,
            calc($pixel * 8) calc($pixel * -2) $runner__suit,
            calc($pixel * 2) calc($pixel * -3) $runner__black,
            calc($pixel * 3) calc($pixel * -3) $runner__black,
            calc($pixel * 4) calc($pixel * -3) $runner__black,
            calc($pixel * 5) calc($pixel * -3) $runner__black,
            calc($pixel * 6) calc($pixel * -3) $runner__skin,
            calc($pixel * 7) calc($pixel * -3) $runner__suit,
            calc($pixel * 8) calc($pixel * -3) $runner__suit,
            calc($pixel * 9) calc($pixel * -3) $runner__suit,
            calc($pixel * 10) calc($pixel * -3) $runner__suit,
            calc($pixel * 18) calc($pixel * -3) $runner__black,
            calc($pixel * 19) calc($pixel * -3) $runner__black,
            calc($pixel * 20) calc($pixel * -3) $runner__black,
            calc($pixel * 3) calc($pixel * -4) $runner__black,
            calc($pixel * 4) calc($pixel * -4) $runner__black,
            calc($pixel * 5) calc($pixel * -4) $runner__black,
            calc($pixel * 6) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -4) $runner__suit,
            calc($pixel * 8) calc($pixel * -4) $runner__suit,
            calc($pixel * 9) calc($pixel * -4) $runner__suit,
            calc($pixel * 10) calc($pixel * -4) $runner__skin,
            calc($pixel * 11) calc($pixel * -4) $runner__black,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 15) calc($pixel * -4) $runner__black,
            calc($pixel * 16) calc($pixel * -4) $runner__black,
            calc($pixel * 17) calc($pixel * -4) $runner__black,
            calc($pixel * 18) calc($pixel * -4) $runner__suit,
            calc($pixel * 19) calc($pixel * -4) $runner__suit,
            calc($pixel * 20) calc($pixel * -4) $runner__suit,
            calc($pixel * 21) calc($pixel * -4) $runner__black,
            calc($pixel * 4) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -5) $runner__black,
            calc($pixel * 6) calc($pixel * -5) $runner__suit,
            calc($pixel * 7) calc($pixel * -5) $runner__suit,
            calc($pixel * 8) calc($pixel * -5) $runner__suit,
            calc($pixel * 9) calc($pixel * -5) $runner__skin,
            calc($pixel * 10) calc($pixel * -5) $runner__black,
            calc($pixel * 11) calc($pixel * -5) $runner__black,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 14) calc($pixel * -5) $runner__black,
            calc($pixel * 15) calc($pixel * -5) $runner__suit,
            calc($pixel * 16) calc($pixel * -5) $runner__suit,
            calc($pixel * 17) calc($pixel * -5) $runner__suit,
            calc($pixel * 18) calc($pixel * -5) $runner__suit,
            calc($pixel * 19) calc($pixel * -5) $runner__suit,
            calc($pixel * 20) calc($pixel * -5) $runner__suit,
            calc($pixel * 21) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -6) $runner__black,
            calc($pixel * 6) calc($pixel * -6) $runner__suit,
            calc($pixel * 7) calc($pixel * -6) $runner__skin,
            calc($pixel * 8) calc($pixel * -6) $runner__skin,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__black,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__suit,
            calc($pixel * 15) calc($pixel * -6) $runner__suit,
            calc($pixel * 16) calc($pixel * -6) $runner__suit,
            calc($pixel * 17) calc($pixel * -6) $runner__suit,
            calc($pixel * 18) calc($pixel * -6) $runner__suit,
            calc($pixel * 19) calc($pixel * -6) $runner__suit,
            calc($pixel * 20) calc($pixel * -6) $runner__suit,
            calc($pixel * 21) calc($pixel * -6) $runner__skin,
            calc($pixel * 5) calc($pixel * -7) $runner__skin,
            calc($pixel * 6) calc($pixel * -7) $runner__skin,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__black,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__suit,
            calc($pixel * 14) calc($pixel * -7) $runner__suit,
            calc($pixel * 15) calc($pixel * -7) $runner__suit,
            calc($pixel * 16) calc($pixel * -7) $runner__suit,
            calc($pixel * 17) calc($pixel * -7) $runner__suit,
            calc($pixel * 18) calc($pixel * -7) $runner__black,
            calc($pixel * 19) calc($pixel * -7) $runner__black,
            calc($pixel * 20) calc($pixel * -7) $runner__black,
            calc($pixel * 21) calc($pixel * -7) $runner__black,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__black,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__black,
            calc($pixel * 13) calc($pixel * -8) $runner__suit,
            calc($pixel * 14) calc($pixel * -8) $runner__suit,
            calc($pixel * 15) calc($pixel * -8) $runner__suit,
            calc($pixel * 16) calc($pixel * -8) $runner__suit,
            calc($pixel * 17) calc($pixel * -8) $runner__black,
            calc($pixel * 18) calc($pixel * -8) $runner__skin,
            calc($pixel * 19) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__black,
            calc($pixel * 12) calc($pixel * -9) $runner__black,
            calc($pixel * 13) calc($pixel * -9) $runner__suit,
            calc($pixel * 14) calc($pixel * -9) $runner__suit,
            calc($pixel * 15) calc($pixel * -9) $runner__suit,
            calc($pixel * 16) calc($pixel * -9) $runner__black,
            calc($pixel * 17) calc($pixel * -9) $runner__black,
            calc($pixel * 18) calc($pixel * -9) $runner__suit,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__black,
            calc($pixel * 13) calc($pixel * -10) $runner__black,
            calc($pixel * 14) calc($pixel * -10) $runner__black,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__suit,
            calc($pixel * 17) calc($pixel * -10) $runner__suit,
            calc($pixel * 18) calc($pixel * -10) $runner__suit,
            calc($pixel * 19) calc($pixel * -10) $runner__skin,
            calc($pixel * 20) calc($pixel * -10) $runner__skin,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__hair,
            calc($pixel * 13) calc($pixel * -11) $runner__hair,
            calc($pixel * 14) calc($pixel * -11) $runner__hair,
            calc($pixel * 15) calc($pixel * -11) $runner__hair,
            calc($pixel * 16) calc($pixel * -11) $runner__hair,
            calc($pixel * 17) calc($pixel * -11) $runner__hair,
            calc($pixel * 18) calc($pixel * -11) $runner__skin,
            calc($pixel * 19) calc($pixel * -11) $runner__skin,
            calc($pixel * 20) calc($pixel * -11) $runner__skin,
            calc($pixel * 21) calc($pixel * -11) $runner__skin,
            calc($pixel * 9) calc($pixel * -12) $runner__black,
            calc($pixel * 10) calc($pixel * -12) $runner__black,
            calc($pixel * 11) calc($pixel * -12) $runner__hair,
            calc($pixel * 12) calc($pixel * -12) $runner__hair,
            calc($pixel * 13) calc($pixel * -12) $runner__hair,
            calc($pixel * 14) calc($pixel * -12) $runner__hair,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__black,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__hair,
            calc($pixel * 19) calc($pixel * -12) $runner__skin,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__eyes,
            calc($pixel * 8) calc($pixel * -13) $runner__black,
            calc($pixel * 9) calc($pixel * -13) $runner__black,
            calc($pixel * 10) calc($pixel * -13) $runner__hair,
            calc($pixel * 11) calc($pixel * -13) $runner__hair,
            calc($pixel * 12) calc($pixel * -13) $runner__hair,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__black,
            calc($pixel * 15) calc($pixel * -13) $runner__black,
            calc($pixel * 16) calc($pixel * -13) $runner__black,
            calc($pixel * 17) calc($pixel * -13) $runner__black,
            calc($pixel * 18) calc($pixel * -13) $runner__black,
            calc($pixel * 19) calc($pixel * -13) $runner__skin,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__eyes,
            calc($pixel * 8) calc($pixel * -14) $runner__black,
            calc($pixel * 9) calc($pixel * -14) $runner__hair,
            calc($pixel * 10) calc($pixel * -14) $runner__hair,
            calc($pixel * 11) calc($pixel * -14) $runner__hair,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__black,
            calc($pixel * 15) calc($pixel * -14) $runner__suit,
            calc($pixel * 16) calc($pixel * -14) $runner__suit,
            calc($pixel * 17) calc($pixel * -14) $runner__black,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__hair,
            calc($pixel * 21) calc($pixel * -14) $runner__hair,
            calc($pixel * 22) calc($pixel * -14) $runner__hair,
            calc($pixel * 8) calc($pixel * -15) $runner__hair,
            calc($pixel * 9) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__black,
            calc($pixel * 15) calc($pixel * -15) $runner__white,
            calc($pixel * 16) calc($pixel * -15) $runner__suit,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__hair,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__black,
            calc($pixel * 22) calc($pixel * -15) $runner__hair,
            calc($pixel * 23) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__black,
            calc($pixel * 15) calc($pixel * -16) $runner__black,
            calc($pixel * 16) calc($pixel * -16) $runner__suit,
            calc($pixel * 17) calc($pixel * -16) $runner__hair,
            calc($pixel * 18) calc($pixel * -16) $runner__hair,
            calc($pixel * 19) calc($pixel * -16) $runner__hair,
            calc($pixel * 20) calc($pixel * -16) $runner__hair,
            calc($pixel * 21) calc($pixel * -16) $runner__hair,
            calc($pixel * 22) calc($pixel * -16) $runner__black,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__hair,
            calc($pixel * 16) calc($pixel * -17) $runner__suit,
            calc($pixel * 17) calc($pixel * -17) $runner__hair,
            calc($pixel * 18) calc($pixel * -17) $runner__hair,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__hair,
            calc($pixel * 21) calc($pixel * -17) $runner__hair,
            calc($pixel * 22) calc($pixel * -17) $runner__hair,
            calc($pixel * 23) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__white,
            calc($pixel * 15) calc($pixel * -18) $runner__white,
            calc($pixel * 16) calc($pixel * -18) $runner__suit,
            calc($pixel * 17) calc($pixel * -18) $runner__white,
            calc($pixel * 18) calc($pixel * -18) $runner__white,
            calc($pixel * 19) calc($pixel * -18) $runner__white,
            calc($pixel * 20) calc($pixel * -18) $runner__white,
            calc($pixel * 21) calc($pixel * -18) $runner__white,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__white,
            calc($pixel * 13) calc($pixel * -19) $runner__white,
            calc($pixel * 14) calc($pixel * -19) $runner__white,
            calc($pixel * 15) calc($pixel * -19) $runner__hair,
            calc($pixel * 16) calc($pixel * -19) $runner__suit,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__hair,
            calc($pixel * 19) calc($pixel * -19) $runner__hair,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__white,
            calc($pixel * 12) calc($pixel * -20) $runner__white,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__hair,
            calc($pixel * 15) calc($pixel * -20) $runner__hair,
            calc($pixel * 16) calc($pixel * -20) $runner__suit,
            calc($pixel * 17) calc($pixel * -20) $runner__hair,
            calc($pixel * 18) calc($pixel * -20) $runner__hair,
            calc($pixel * 19) calc($pixel * -20) $runner__hair,
            calc($pixel * 20) calc($pixel * -20) $runner__hair,
            calc($pixel * 21) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__hair,
            calc($pixel * 14) calc($pixel * -21) $runner__hair,
            calc($pixel * 15) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -21) $runner__suit,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__suit,
            calc($pixel * 17) calc($pixel * -22) $runner__black;
        box-shadow: calc($pixel * 1) calc($pixel * 0) $runner__black,
            calc($pixel * 2) calc($pixel * 0) $runner__skin,
            calc($pixel * 3) calc($pixel * 0) $runner__skin,
            calc($pixel * 1) calc($pixel * -1) $runner__black,
            calc($pixel * 2) calc($pixel * -1) $runner__black,
            calc($pixel * 3) calc($pixel * -1) $runner__black,
            calc($pixel * 7) calc($pixel * -1) $runner__skin,
            calc($pixel * 1) calc($pixel * -2) $runner__black,
            calc($pixel * 2) calc($pixel * -2) $runner__black,
            calc($pixel * 3) calc($pixel * -2) $runner__black,
            calc($pixel * 4) calc($pixel * -2) $runner__black,
            calc($pixel * 5) calc($pixel * -2) $runner__black,
            calc($pixel * 6) calc($pixel * -2) $runner__skin,
            calc($pixel * 7) calc($pixel * -2) $runner__suit,
            calc($pixel * 8) calc($pixel * -2) $runner__suit,
            calc($pixel * 2) calc($pixel * -3) $runner__black,
            calc($pixel * 3) calc($pixel * -3) $runner__black,
            calc($pixel * 4) calc($pixel * -3) $runner__black,
            calc($pixel * 5) calc($pixel * -3) $runner__black,
            calc($pixel * 6) calc($pixel * -3) $runner__skin,
            calc($pixel * 7) calc($pixel * -3) $runner__suit,
            calc($pixel * 8) calc($pixel * -3) $runner__suit,
            calc($pixel * 9) calc($pixel * -3) $runner__suit,
            calc($pixel * 10) calc($pixel * -3) $runner__suit,
            calc($pixel * 18) calc($pixel * -3) $runner__black,
            calc($pixel * 19) calc($pixel * -3) $runner__black,
            calc($pixel * 20) calc($pixel * -3) $runner__black,
            calc($pixel * 3) calc($pixel * -4) $runner__black,
            calc($pixel * 4) calc($pixel * -4) $runner__black,
            calc($pixel * 5) calc($pixel * -4) $runner__black,
            calc($pixel * 6) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -4) $runner__suit,
            calc($pixel * 8) calc($pixel * -4) $runner__suit,
            calc($pixel * 9) calc($pixel * -4) $runner__suit,
            calc($pixel * 10) calc($pixel * -4) $runner__skin,
            calc($pixel * 11) calc($pixel * -4) $runner__black,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 15) calc($pixel * -4) $runner__black,
            calc($pixel * 16) calc($pixel * -4) $runner__black,
            calc($pixel * 17) calc($pixel * -4) $runner__black,
            calc($pixel * 18) calc($pixel * -4) $runner__suit,
            calc($pixel * 19) calc($pixel * -4) $runner__suit,
            calc($pixel * 20) calc($pixel * -4) $runner__suit,
            calc($pixel * 21) calc($pixel * -4) $runner__black,
            calc($pixel * 4) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -5) $runner__black,
            calc($pixel * 6) calc($pixel * -5) $runner__suit,
            calc($pixel * 7) calc($pixel * -5) $runner__suit,
            calc($pixel * 8) calc($pixel * -5) $runner__suit,
            calc($pixel * 9) calc($pixel * -5) $runner__skin,
            calc($pixel * 10) calc($pixel * -5) $runner__black,
            calc($pixel * 11) calc($pixel * -5) $runner__black,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 14) calc($pixel * -5) $runner__black,
            calc($pixel * 15) calc($pixel * -5) $runner__suit,
            calc($pixel * 16) calc($pixel * -5) $runner__suit,
            calc($pixel * 17) calc($pixel * -5) $runner__suit,
            calc($pixel * 18) calc($pixel * -5) $runner__suit,
            calc($pixel * 19) calc($pixel * -5) $runner__suit,
            calc($pixel * 20) calc($pixel * -5) $runner__suit,
            calc($pixel * 21) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -6) $runner__black,
            calc($pixel * 6) calc($pixel * -6) $runner__suit,
            calc($pixel * 7) calc($pixel * -6) $runner__skin,
            calc($pixel * 8) calc($pixel * -6) $runner__skin,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__black,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__suit,
            calc($pixel * 15) calc($pixel * -6) $runner__suit,
            calc($pixel * 16) calc($pixel * -6) $runner__suit,
            calc($pixel * 17) calc($pixel * -6) $runner__suit,
            calc($pixel * 18) calc($pixel * -6) $runner__suit,
            calc($pixel * 19) calc($pixel * -6) $runner__suit,
            calc($pixel * 20) calc($pixel * -6) $runner__suit,
            calc($pixel * 21) calc($pixel * -6) $runner__skin,
            calc($pixel * 5) calc($pixel * -7) $runner__skin,
            calc($pixel * 6) calc($pixel * -7) $runner__skin,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__black,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__suit,
            calc($pixel * 14) calc($pixel * -7) $runner__suit,
            calc($pixel * 15) calc($pixel * -7) $runner__suit,
            calc($pixel * 16) calc($pixel * -7) $runner__suit,
            calc($pixel * 17) calc($pixel * -7) $runner__suit,
            calc($pixel * 18) calc($pixel * -7) $runner__black,
            calc($pixel * 19) calc($pixel * -7) $runner__black,
            calc($pixel * 20) calc($pixel * -7) $runner__black,
            calc($pixel * 21) calc($pixel * -7) $runner__black,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__black,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__black,
            calc($pixel * 13) calc($pixel * -8) $runner__suit,
            calc($pixel * 14) calc($pixel * -8) $runner__suit,
            calc($pixel * 15) calc($pixel * -8) $runner__suit,
            calc($pixel * 16) calc($pixel * -8) $runner__suit,
            calc($pixel * 17) calc($pixel * -8) $runner__black,
            calc($pixel * 18) calc($pixel * -8) $runner__skin,
            calc($pixel * 19) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__black,
            calc($pixel * 12) calc($pixel * -9) $runner__black,
            calc($pixel * 13) calc($pixel * -9) $runner__suit,
            calc($pixel * 14) calc($pixel * -9) $runner__suit,
            calc($pixel * 15) calc($pixel * -9) $runner__suit,
            calc($pixel * 16) calc($pixel * -9) $runner__black,
            calc($pixel * 17) calc($pixel * -9) $runner__black,
            calc($pixel * 18) calc($pixel * -9) $runner__suit,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__black,
            calc($pixel * 13) calc($pixel * -10) $runner__black,
            calc($pixel * 14) calc($pixel * -10) $runner__black,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__suit,
            calc($pixel * 17) calc($pixel * -10) $runner__suit,
            calc($pixel * 18) calc($pixel * -10) $runner__suit,
            calc($pixel * 19) calc($pixel * -10) $runner__skin,
            calc($pixel * 20) calc($pixel * -10) $runner__skin,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__hair,
            calc($pixel * 13) calc($pixel * -11) $runner__hair,
            calc($pixel * 14) calc($pixel * -11) $runner__hair,
            calc($pixel * 15) calc($pixel * -11) $runner__hair,
            calc($pixel * 16) calc($pixel * -11) $runner__hair,
            calc($pixel * 17) calc($pixel * -11) $runner__hair,
            calc($pixel * 18) calc($pixel * -11) $runner__skin,
            calc($pixel * 19) calc($pixel * -11) $runner__skin,
            calc($pixel * 20) calc($pixel * -11) $runner__skin,
            calc($pixel * 21) calc($pixel * -11) $runner__skin,
            calc($pixel * 9) calc($pixel * -12) $runner__black,
            calc($pixel * 10) calc($pixel * -12) $runner__black,
            calc($pixel * 11) calc($pixel * -12) $runner__hair,
            calc($pixel * 12) calc($pixel * -12) $runner__hair,
            calc($pixel * 13) calc($pixel * -12) $runner__hair,
            calc($pixel * 14) calc($pixel * -12) $runner__hair,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__black,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__hair,
            calc($pixel * 19) calc($pixel * -12) $runner__skin,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__eyes,
            calc($pixel * 8) calc($pixel * -13) $runner__black,
            calc($pixel * 9) calc($pixel * -13) $runner__black,
            calc($pixel * 10) calc($pixel * -13) $runner__hair,
            calc($pixel * 11) calc($pixel * -13) $runner__hair,
            calc($pixel * 12) calc($pixel * -13) $runner__hair,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__black,
            calc($pixel * 15) calc($pixel * -13) $runner__black,
            calc($pixel * 16) calc($pixel * -13) $runner__black,
            calc($pixel * 17) calc($pixel * -13) $runner__black,
            calc($pixel * 18) calc($pixel * -13) $runner__black,
            calc($pixel * 19) calc($pixel * -13) $runner__skin,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__eyes,
            calc($pixel * 8) calc($pixel * -14) $runner__black,
            calc($pixel * 9) calc($pixel * -14) $runner__hair,
            calc($pixel * 10) calc($pixel * -14) $runner__hair,
            calc($pixel * 11) calc($pixel * -14) $runner__hair,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__black,
            calc($pixel * 15) calc($pixel * -14) $runner__suit,
            calc($pixel * 16) calc($pixel * -14) $runner__suit,
            calc($pixel * 17) calc($pixel * -14) $runner__black,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__hair,
            calc($pixel * 21) calc($pixel * -14) $runner__hair,
            calc($pixel * 22) calc($pixel * -14) $runner__hair,
            calc($pixel * 8) calc($pixel * -15) $runner__hair,
            calc($pixel * 9) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__black,
            calc($pixel * 15) calc($pixel * -15) $runner__white,
            calc($pixel * 16) calc($pixel * -15) $runner__suit,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__hair,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__black,
            calc($pixel * 22) calc($pixel * -15) $runner__hair,
            calc($pixel * 23) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__black,
            calc($pixel * 15) calc($pixel * -16) $runner__black,
            calc($pixel * 16) calc($pixel * -16) $runner__suit,
            calc($pixel * 17) calc($pixel * -16) $runner__hair,
            calc($pixel * 18) calc($pixel * -16) $runner__hair,
            calc($pixel * 19) calc($pixel * -16) $runner__hair,
            calc($pixel * 20) calc($pixel * -16) $runner__hair,
            calc($pixel * 21) calc($pixel * -16) $runner__hair,
            calc($pixel * 22) calc($pixel * -16) $runner__black,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__hair,
            calc($pixel * 16) calc($pixel * -17) $runner__suit,
            calc($pixel * 17) calc($pixel * -17) $runner__hair,
            calc($pixel * 18) calc($pixel * -17) $runner__hair,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__hair,
            calc($pixel * 21) calc($pixel * -17) $runner__hair,
            calc($pixel * 22) calc($pixel * -17) $runner__hair,
            calc($pixel * 23) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__white,
            calc($pixel * 15) calc($pixel * -18) $runner__white,
            calc($pixel * 16) calc($pixel * -18) $runner__suit,
            calc($pixel * 17) calc($pixel * -18) $runner__white,
            calc($pixel * 18) calc($pixel * -18) $runner__white,
            calc($pixel * 19) calc($pixel * -18) $runner__white,
            calc($pixel * 20) calc($pixel * -18) $runner__white,
            calc($pixel * 21) calc($pixel * -18) $runner__white,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__white,
            calc($pixel * 13) calc($pixel * -19) $runner__white,
            calc($pixel * 14) calc($pixel * -19) $runner__white,
            calc($pixel * 15) calc($pixel * -19) $runner__hair,
            calc($pixel * 16) calc($pixel * -19) $runner__suit,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__hair,
            calc($pixel * 19) calc($pixel * -19) $runner__hair,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__white,
            calc($pixel * 12) calc($pixel * -20) $runner__white,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__hair,
            calc($pixel * 15) calc($pixel * -20) $runner__hair,
            calc($pixel * 16) calc($pixel * -20) $runner__suit,
            calc($pixel * 17) calc($pixel * -20) $runner__hair,
            calc($pixel * 18) calc($pixel * -20) $runner__hair,
            calc($pixel * 19) calc($pixel * -20) $runner__hair,
            calc($pixel * 20) calc($pixel * -20) $runner__hair,
            calc($pixel * 21) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__hair,
            calc($pixel * 14) calc($pixel * -21) $runner__hair,
            calc($pixel * 15) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -21) $runner__suit,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__suit,
            calc($pixel * 17) calc($pixel * -22) $runner__black;
    }

    .runner__frame5--shadow {
        -webkit-box-shadow: calc($pixel * 1) calc($pixel * 0) $runner__shadow,
            calc($pixel * 2) calc($pixel * 0) $runner__shadow,
            calc($pixel * 3) calc($pixel * 0) $runner__shadow,
            calc($pixel * 1) calc($pixel * 1) $runner__shadow,
            calc($pixel * 2) calc($pixel * 1) $runner__shadow,
            calc($pixel * 3) calc($pixel * 1) $runner__shadow,
            calc($pixel * 7) calc($pixel * 1) $runner__shadow,
            calc($pixel * 1) calc($pixel * 2) $runner__shadow,
            calc($pixel * 2) calc($pixel * 2) $runner__shadow,
            calc($pixel * 3) calc($pixel * 2) $runner__shadow,
            calc($pixel * 4) calc($pixel * 2) $runner__shadow,
            calc($pixel * 5) calc($pixel * 2) $runner__shadow,
            calc($pixel * 6) calc($pixel * 2) $runner__shadow,
            calc($pixel * 7) calc($pixel * 2) $runner__shadow,
            calc($pixel * 8) calc($pixel * 2) $runner__shadow,
            calc($pixel * 2) calc($pixel * 3) $runner__shadow,
            calc($pixel * 3) calc($pixel * 3) $runner__shadow,
            calc($pixel * 4) calc($pixel * 3) $runner__shadow,
            calc($pixel * 5) calc($pixel * 3) $runner__shadow,
            calc($pixel * 6) calc($pixel * 3) $runner__shadow,
            calc($pixel * 7) calc($pixel * 3) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 18) calc($pixel * 3) $runner__shadow,
            calc($pixel * 19) calc($pixel * 3) $runner__shadow,
            calc($pixel * 20) calc($pixel * 3) $runner__shadow,
            calc($pixel * 3) calc($pixel * 4) $runner__shadow,
            calc($pixel * 4) calc($pixel * 4) $runner__shadow,
            calc($pixel * 5) calc($pixel * 4) $runner__shadow,
            calc($pixel * 6) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 4) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 15) calc($pixel * 4) $runner__shadow,
            calc($pixel * 16) calc($pixel * 4) $runner__shadow,
            calc($pixel * 17) calc($pixel * 4) $runner__shadow,
            calc($pixel * 18) calc($pixel * 4) $runner__shadow,
            calc($pixel * 19) calc($pixel * 4) $runner__shadow,
            calc($pixel * 20) calc($pixel * 4) $runner__shadow,
            calc($pixel * 21) calc($pixel * 4) $runner__shadow,
            calc($pixel * 4) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 5) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 14) calc($pixel * 5) $runner__shadow,
            calc($pixel * 15) calc($pixel * 5) $runner__shadow,
            calc($pixel * 16) calc($pixel * 5) $runner__shadow,
            calc($pixel * 17) calc($pixel * 5) $runner__shadow,
            calc($pixel * 18) calc($pixel * 5) $runner__shadow,
            calc($pixel * 19) calc($pixel * 5) $runner__shadow,
            calc($pixel * 20) calc($pixel * 5) $runner__shadow,
            calc($pixel * 21) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 15) calc($pixel * 6) $runner__shadow,
            calc($pixel * 16) calc($pixel * 6) $runner__shadow,
            calc($pixel * 17) calc($pixel * 6) $runner__shadow,
            calc($pixel * 18) calc($pixel * 6) $runner__shadow,
            calc($pixel * 19) calc($pixel * 6) $runner__shadow,
            calc($pixel * 20) calc($pixel * 6) $runner__shadow,
            calc($pixel * 21) calc($pixel * 6) $runner__shadow,
            calc($pixel * 5) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 16) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 19) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 8) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 8) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 8) calc($pixel * 15) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 23) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow;
        box-shadow: calc($pixel * 1) calc($pixel * 0) $runner__shadow,
            calc($pixel * 2) calc($pixel * 0) $runner__shadow,
            calc($pixel * 3) calc($pixel * 0) $runner__shadow,
            calc($pixel * 1) calc($pixel * 1) $runner__shadow,
            calc($pixel * 2) calc($pixel * 1) $runner__shadow,
            calc($pixel * 3) calc($pixel * 1) $runner__shadow,
            calc($pixel * 7) calc($pixel * 1) $runner__shadow,
            calc($pixel * 1) calc($pixel * 2) $runner__shadow,
            calc($pixel * 2) calc($pixel * 2) $runner__shadow,
            calc($pixel * 3) calc($pixel * 2) $runner__shadow,
            calc($pixel * 4) calc($pixel * 2) $runner__shadow,
            calc($pixel * 5) calc($pixel * 2) $runner__shadow,
            calc($pixel * 6) calc($pixel * 2) $runner__shadow,
            calc($pixel * 7) calc($pixel * 2) $runner__shadow,
            calc($pixel * 8) calc($pixel * 2) $runner__shadow,
            calc($pixel * 2) calc($pixel * 3) $runner__shadow,
            calc($pixel * 3) calc($pixel * 3) $runner__shadow,
            calc($pixel * 4) calc($pixel * 3) $runner__shadow,
            calc($pixel * 5) calc($pixel * 3) $runner__shadow,
            calc($pixel * 6) calc($pixel * 3) $runner__shadow,
            calc($pixel * 7) calc($pixel * 3) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 18) calc($pixel * 3) $runner__shadow,
            calc($pixel * 19) calc($pixel * 3) $runner__shadow,
            calc($pixel * 20) calc($pixel * 3) $runner__shadow,
            calc($pixel * 3) calc($pixel * 4) $runner__shadow,
            calc($pixel * 4) calc($pixel * 4) $runner__shadow,
            calc($pixel * 5) calc($pixel * 4) $runner__shadow,
            calc($pixel * 6) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 4) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 15) calc($pixel * 4) $runner__shadow,
            calc($pixel * 16) calc($pixel * 4) $runner__shadow,
            calc($pixel * 17) calc($pixel * 4) $runner__shadow,
            calc($pixel * 18) calc($pixel * 4) $runner__shadow,
            calc($pixel * 19) calc($pixel * 4) $runner__shadow,
            calc($pixel * 20) calc($pixel * 4) $runner__shadow,
            calc($pixel * 21) calc($pixel * 4) $runner__shadow,
            calc($pixel * 4) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 5) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 14) calc($pixel * 5) $runner__shadow,
            calc($pixel * 15) calc($pixel * 5) $runner__shadow,
            calc($pixel * 16) calc($pixel * 5) $runner__shadow,
            calc($pixel * 17) calc($pixel * 5) $runner__shadow,
            calc($pixel * 18) calc($pixel * 5) $runner__shadow,
            calc($pixel * 19) calc($pixel * 5) $runner__shadow,
            calc($pixel * 20) calc($pixel * 5) $runner__shadow,
            calc($pixel * 21) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 15) calc($pixel * 6) $runner__shadow,
            calc($pixel * 16) calc($pixel * 6) $runner__shadow,
            calc($pixel * 17) calc($pixel * 6) $runner__shadow,
            calc($pixel * 18) calc($pixel * 6) $runner__shadow,
            calc($pixel * 19) calc($pixel * 6) $runner__shadow,
            calc($pixel * 20) calc($pixel * 6) $runner__shadow,
            calc($pixel * 21) calc($pixel * 6) $runner__shadow,
            calc($pixel * 5) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 16) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 19) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 8) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 8) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 8) calc($pixel * 15) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 23) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow;
    }
    .runner__frame6 {
        -webkit-box-shadow: calc($pixel * 8) calc($pixel * -2) $runner__skin,
            calc($pixel * 7) calc($pixel * -3) $runner__skin,
            calc($pixel * 8) calc($pixel * -3) $runner__suit,
            calc($pixel * 9) calc($pixel * -3) $runner__black,
            calc($pixel * 10) calc($pixel * -3) $runner__black,
            calc($pixel * 7) calc($pixel * -4) $runner__skin,
            calc($pixel * 8) calc($pixel * -4) $runner__suit,
            calc($pixel * 9) calc($pixel * -4) $runner__suit,
            calc($pixel * 10) calc($pixel * -4) $runner__suit,
            calc($pixel * 11) calc($pixel * -4) $runner__suit,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 13) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__suit,
            calc($pixel * 9) calc($pixel * -5) $runner__suit,
            calc($pixel * 10) calc($pixel * -5) $runner__suit,
            calc($pixel * 11) calc($pixel * -5) $runner__suit,
            calc($pixel * 12) calc($pixel * -5) $runner__suit,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 14) calc($pixel * -5) $runner__black,
            calc($pixel * -1) calc($pixel * -6) $runner__skin,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__black,
            calc($pixel * 8) calc($pixel * -6) $runner__black,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__suit,
            calc($pixel * 11) calc($pixel * -6) $runner__skin,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__black,
            calc($pixel * 15) calc($pixel * -6) $runner__black,
            calc($pixel * 16) calc($pixel * -6) $runner__black,
            calc($pixel * 17) calc($pixel * -6) $runner__black,
            calc($pixel * -1) calc($pixel * -7) $runner__skin,
            calc($pixel * 0) calc($pixel * -7) $runner__black,
            calc($pixel * 1) calc($pixel * -7) $runner__black,
            calc($pixel * 2) calc($pixel * -7) $runner__black,
            calc($pixel * 3) calc($pixel * -7) $runner__black,
            calc($pixel * 4) calc($pixel * -7) $runner__black,
            calc($pixel * 5) calc($pixel * -7) $runner__black,
            calc($pixel * 6) calc($pixel * -7) $runner__black,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__skin,
            calc($pixel * 9) calc($pixel * -7) $runner__skin,
            calc($pixel * 10) calc($pixel * -7) $runner__skin,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__black,
            calc($pixel * 14) calc($pixel * -7) $runner__black,
            calc($pixel * 15) calc($pixel * -7) $runner__suit,
            calc($pixel * 16) calc($pixel * -7) $runner__suit,
            calc($pixel * 17) calc($pixel * -7) $runner__black,
            calc($pixel * 18) calc($pixel * -7) $runner__black,
            calc($pixel * 20) calc($pixel * -7) $runner__black,
            calc($pixel * 21) calc($pixel * -7) $runner__black,
            calc($pixel * 22) calc($pixel * -7) $runner__black,
            calc($pixel * 23) calc($pixel * -7) $runner__black,
            calc($pixel * 24) calc($pixel * -7) $runner__black,
            calc($pixel * 0) calc($pixel * -8) $runner__skin,
            calc($pixel * 1) calc($pixel * -8) $runner__suit,
            calc($pixel * 2) calc($pixel * -8) $runner__black,
            calc($pixel * 3) calc($pixel * -8) $runner__black,
            calc($pixel * 4) calc($pixel * -8) $runner__black,
            calc($pixel * 5) calc($pixel * -8) $runner__black,
            calc($pixel * 6) calc($pixel * -8) $runner__black,
            calc($pixel * 7) calc($pixel * -8) $runner__skin,
            calc($pixel * 8) calc($pixel * -8) $runner__skin,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__black,
            calc($pixel * 13) calc($pixel * -8) $runner__suit,
            calc($pixel * 14) calc($pixel * -8) $runner__suit,
            calc($pixel * 15) calc($pixel * -8) $runner__suit,
            calc($pixel * 16) calc($pixel * -8) $runner__suit,
            calc($pixel * 17) calc($pixel * -8) $runner__skin,
            calc($pixel * 18) calc($pixel * -8) $runner__black,
            calc($pixel * 19) calc($pixel * -8) $runner__black,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__suit,
            calc($pixel * 23) calc($pixel * -8) $runner__black,
            calc($pixel * 24) calc($pixel * -8) $runner__black,
            calc($pixel * 3) calc($pixel * -9) $runner__black,
            calc($pixel * 4) calc($pixel * -9) $runner__black,
            calc($pixel * 5) calc($pixel * -9) $runner__black,
            calc($pixel * 6) calc($pixel * -9) $runner__skin,
            calc($pixel * 7) calc($pixel * -9) $runner__black,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__black,
            calc($pixel * 12) calc($pixel * -9) $runner__suit,
            calc($pixel * 13) calc($pixel * -9) $runner__suit,
            calc($pixel * 14) calc($pixel * -9) $runner__suit,
            calc($pixel * 15) calc($pixel * -9) $runner__suit,
            calc($pixel * 16) calc($pixel * -9) $runner__suit,
            calc($pixel * 17) calc($pixel * -9) $runner__suit,
            calc($pixel * 18) calc($pixel * -9) $runner__black,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 21) calc($pixel * -9) $runner__suit,
            calc($pixel * 22) calc($pixel * -9) $runner__suit,
            calc($pixel * 23) calc($pixel * -9) $runner__suit,
            calc($pixel * 24) calc($pixel * -9) $runner__black,
            calc($pixel * 5) calc($pixel * -10) $runner__black,
            calc($pixel * 6) calc($pixel * -10) $runner__skin,
            calc($pixel * 7) calc($pixel * -10) $runner__black,
            calc($pixel * 8) calc($pixel * -10) $runner__black,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__suit,
            calc($pixel * 12) calc($pixel * -10) $runner__suit,
            calc($pixel * 13) calc($pixel * -10) $runner__suit,
            calc($pixel * 14) calc($pixel * -10) $runner__suit,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__black,
            calc($pixel * 17) calc($pixel * -10) $runner__black,
            calc($pixel * 18) calc($pixel * -10) $runner__black,
            calc($pixel * 19) calc($pixel * -10) $runner__skin,
            calc($pixel * 20) calc($pixel * -10) $runner__suit,
            calc($pixel * 21) calc($pixel * -10) $runner__suit,
            calc($pixel * 22) calc($pixel * -10) $runner__suit,
            calc($pixel * 23) calc($pixel * -10) $runner__suit,
            calc($pixel * 24) calc($pixel * -10) $runner__skin,
            calc($pixel * 8) calc($pixel * -11) $runner__black,
            calc($pixel * 9) calc($pixel * -11) $runner__black,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__suit,
            calc($pixel * 12) calc($pixel * -11) $runner__suit,
            calc($pixel * 13) calc($pixel * -11) $runner__suit,
            calc($pixel * 14) calc($pixel * -11) $runner__suit,
            calc($pixel * 15) calc($pixel * -11) $runner__black,
            calc($pixel * 16) calc($pixel * -11) $runner__black,
            calc($pixel * 17) calc($pixel * -11) $runner__black,
            calc($pixel * 18) calc($pixel * -11) $runner__black,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__suit,
            calc($pixel * 21) calc($pixel * -11) $runner__suit,
            calc($pixel * 10) calc($pixel * -12) $runner__black,
            calc($pixel * 11) calc($pixel * -12) $runner__black,
            calc($pixel * 12) calc($pixel * -12) $runner__suit,
            calc($pixel * 13) calc($pixel * -12) $runner__suit,
            calc($pixel * 14) calc($pixel * -12) $runner__black,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__suit,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__suit,
            calc($pixel * 19) calc($pixel * -12) $runner__suit,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 9) calc($pixel * -13) $runner__black,
            calc($pixel * 10) calc($pixel * -13) $runner__black,
            calc($pixel * 11) calc($pixel * -13) $runner__black,
            calc($pixel * 12) calc($pixel * -13) $runner__black,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__hair,
            calc($pixel * 15) calc($pixel * -13) $runner__hair,
            calc($pixel * 16) calc($pixel * -13) $runner__hair,
            calc($pixel * 17) calc($pixel * -13) $runner__hair,
            calc($pixel * 18) calc($pixel * -13) $runner__hair,
            calc($pixel * 19) calc($pixel * -13) $runner__skin,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__skin,
            calc($pixel * 22) calc($pixel * -13) $runner__skin,
            calc($pixel * 9) calc($pixel * -14) $runner__black,
            calc($pixel * 10) calc($pixel * -14) $runner__hair,
            calc($pixel * 11) calc($pixel * -14) $runner__hair,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__hair,
            calc($pixel * 15) calc($pixel * -14) $runner__black,
            calc($pixel * 16) calc($pixel * -14) $runner__black,
            calc($pixel * 17) calc($pixel * -14) $runner__suit,
            calc($pixel * 18) calc($pixel * -14) $runner__hair,
            calc($pixel * 19) calc($pixel * -14) $runner__hair,
            calc($pixel * 20) calc($pixel * -14) $runner__skin,
            calc($pixel * 21) calc($pixel * -14) $runner__eyes,
            calc($pixel * 22) calc($pixel * -14) $runner__skin,
            calc($pixel * 9) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__black,
            calc($pixel * 15) calc($pixel * -15) $runner__black,
            calc($pixel * 16) calc($pixel * -15) $runner__black,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__black,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__skin,
            calc($pixel * 21) calc($pixel * -15) $runner__eyes,
            calc($pixel * 22) calc($pixel * -15) $runner__skin,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__suit,
            calc($pixel * 15) calc($pixel * -16) $runner__suit,
            calc($pixel * 16) calc($pixel * -16) $runner__black,
            calc($pixel * 17) calc($pixel * -16) $runner__black,
            calc($pixel * 18) calc($pixel * -16) $runner__hair,
            calc($pixel * 19) calc($pixel * -16) $runner__black,
            calc($pixel * 20) calc($pixel * -16) $runner__black,
            calc($pixel * 21) calc($pixel * -16) $runner__hair,
            calc($pixel * 22) calc($pixel * -16) $runner__hair,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__suit,
            calc($pixel * 15) calc($pixel * -17) $runner__white,
            calc($pixel * 16) calc($pixel * -17) $runner__suit,
            calc($pixel * 17) calc($pixel * -17) $runner__suit,
            calc($pixel * 18) calc($pixel * -17) $runner__hair,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__black,
            calc($pixel * 21) calc($pixel * -17) $runner__black,
            calc($pixel * 22) calc($pixel * -17) $runner__black,
            calc($pixel * 23) calc($pixel * -17) $runner__hair,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__hair,
            calc($pixel * 15) calc($pixel * -18) $runner__suit,
            calc($pixel * 16) calc($pixel * -18) $runner__suit,
            calc($pixel * 17) calc($pixel * -18) $runner__hair,
            calc($pixel * 18) calc($pixel * -18) $runner__hair,
            calc($pixel * 19) calc($pixel * -18) $runner__hair,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__black,
            calc($pixel * 24) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__hair,
            calc($pixel * 16) calc($pixel * -19) $runner__suit,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__hair,
            calc($pixel * 19) calc($pixel * -19) $runner__hair,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__hair,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 24) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__hair,
            calc($pixel * 15) calc($pixel * -20) $runner__white,
            calc($pixel * 16) calc($pixel * -20) $runner__suit,
            calc($pixel * 17) calc($pixel * -20) $runner__white,
            calc($pixel * 18) calc($pixel * -20) $runner__white,
            calc($pixel * 19) calc($pixel * -20) $runner__white,
            calc($pixel * 20) calc($pixel * -20) $runner__white,
            calc($pixel * 21) calc($pixel * -20) $runner__white,
            calc($pixel * 22) calc($pixel * -20) $runner__white,
            calc($pixel * 23) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -21) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__white,
            calc($pixel * 15) calc($pixel * -21) $runner__white,
            calc($pixel * 16) calc($pixel * -21) $runner__suit,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 23) calc($pixel * -21) $runner__white,
            calc($pixel * 13) calc($pixel * -22) $runner__white,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__suit,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__hair,
            calc($pixel * 19) calc($pixel * -22) $runner__hair,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 21) calc($pixel * -22) $runner__hair,
            calc($pixel * 22) calc($pixel * -22) $runner__hair,
            calc($pixel * 14) calc($pixel * -23) $runner__hair,
            calc($pixel * 15) calc($pixel * -23) $runner__hair,
            calc($pixel * 16) calc($pixel * -23) $runner__suit,
            calc($pixel * 17) calc($pixel * -23) $runner__hair,
            calc($pixel * 18) calc($pixel * -23) $runner__hair,
            calc($pixel * 19) calc($pixel * -23) $runner__hair,
            calc($pixel * 20) calc($pixel * -23) $runner__hair,
            calc($pixel * 16) calc($pixel * -24) $runner__suit,
            calc($pixel * 17) calc($pixel * -24) $runner__suit,
            calc($pixel * 18) calc($pixel * -24) $runner__black;
        box-shadow: calc($pixel * 8) calc($pixel * -2) $runner__skin,
            calc($pixel * 7) calc($pixel * -3) $runner__skin,
            calc($pixel * 8) calc($pixel * -3) $runner__suit,
            calc($pixel * 9) calc($pixel * -3) $runner__black,
            calc($pixel * 10) calc($pixel * -3) $runner__black,
            calc($pixel * 7) calc($pixel * -4) $runner__skin,
            calc($pixel * 8) calc($pixel * -4) $runner__suit,
            calc($pixel * 9) calc($pixel * -4) $runner__suit,
            calc($pixel * 10) calc($pixel * -4) $runner__suit,
            calc($pixel * 11) calc($pixel * -4) $runner__suit,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 13) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__suit,
            calc($pixel * 9) calc($pixel * -5) $runner__suit,
            calc($pixel * 10) calc($pixel * -5) $runner__suit,
            calc($pixel * 11) calc($pixel * -5) $runner__suit,
            calc($pixel * 12) calc($pixel * -5) $runner__suit,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 14) calc($pixel * -5) $runner__black,
            calc($pixel * -1) calc($pixel * -6) $runner__skin,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__black,
            calc($pixel * 8) calc($pixel * -6) $runner__black,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__suit,
            calc($pixel * 11) calc($pixel * -6) $runner__skin,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__black,
            calc($pixel * 15) calc($pixel * -6) $runner__black,
            calc($pixel * 16) calc($pixel * -6) $runner__black,
            calc($pixel * 17) calc($pixel * -6) $runner__black,
            calc($pixel * -1) calc($pixel * -7) $runner__skin,
            calc($pixel * 0) calc($pixel * -7) $runner__black,
            calc($pixel * 1) calc($pixel * -7) $runner__black,
            calc($pixel * 2) calc($pixel * -7) $runner__black,
            calc($pixel * 3) calc($pixel * -7) $runner__black,
            calc($pixel * 4) calc($pixel * -7) $runner__black,
            calc($pixel * 5) calc($pixel * -7) $runner__black,
            calc($pixel * 6) calc($pixel * -7) $runner__black,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__skin,
            calc($pixel * 9) calc($pixel * -7) $runner__skin,
            calc($pixel * 10) calc($pixel * -7) $runner__skin,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__black,
            calc($pixel * 14) calc($pixel * -7) $runner__black,
            calc($pixel * 15) calc($pixel * -7) $runner__suit,
            calc($pixel * 16) calc($pixel * -7) $runner__suit,
            calc($pixel * 17) calc($pixel * -7) $runner__black,
            calc($pixel * 18) calc($pixel * -7) $runner__black,
            calc($pixel * 20) calc($pixel * -7) $runner__black,
            calc($pixel * 21) calc($pixel * -7) $runner__black,
            calc($pixel * 22) calc($pixel * -7) $runner__black,
            calc($pixel * 23) calc($pixel * -7) $runner__black,
            calc($pixel * 24) calc($pixel * -7) $runner__black,
            calc($pixel * 0) calc($pixel * -8) $runner__skin,
            calc($pixel * 1) calc($pixel * -8) $runner__suit,
            calc($pixel * 2) calc($pixel * -8) $runner__black,
            calc($pixel * 3) calc($pixel * -8) $runner__black,
            calc($pixel * 4) calc($pixel * -8) $runner__black,
            calc($pixel * 5) calc($pixel * -8) $runner__black,
            calc($pixel * 6) calc($pixel * -8) $runner__black,
            calc($pixel * 7) calc($pixel * -8) $runner__skin,
            calc($pixel * 8) calc($pixel * -8) $runner__skin,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__black,
            calc($pixel * 13) calc($pixel * -8) $runner__suit,
            calc($pixel * 14) calc($pixel * -8) $runner__suit,
            calc($pixel * 15) calc($pixel * -8) $runner__suit,
            calc($pixel * 16) calc($pixel * -8) $runner__suit,
            calc($pixel * 17) calc($pixel * -8) $runner__skin,
            calc($pixel * 18) calc($pixel * -8) $runner__black,
            calc($pixel * 19) calc($pixel * -8) $runner__black,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__suit,
            calc($pixel * 23) calc($pixel * -8) $runner__black,
            calc($pixel * 24) calc($pixel * -8) $runner__black,
            calc($pixel * 3) calc($pixel * -9) $runner__black,
            calc($pixel * 4) calc($pixel * -9) $runner__black,
            calc($pixel * 5) calc($pixel * -9) $runner__black,
            calc($pixel * 6) calc($pixel * -9) $runner__skin,
            calc($pixel * 7) calc($pixel * -9) $runner__black,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__black,
            calc($pixel * 12) calc($pixel * -9) $runner__suit,
            calc($pixel * 13) calc($pixel * -9) $runner__suit,
            calc($pixel * 14) calc($pixel * -9) $runner__suit,
            calc($pixel * 15) calc($pixel * -9) $runner__suit,
            calc($pixel * 16) calc($pixel * -9) $runner__suit,
            calc($pixel * 17) calc($pixel * -9) $runner__suit,
            calc($pixel * 18) calc($pixel * -9) $runner__black,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 21) calc($pixel * -9) $runner__suit,
            calc($pixel * 22) calc($pixel * -9) $runner__suit,
            calc($pixel * 23) calc($pixel * -9) $runner__suit,
            calc($pixel * 24) calc($pixel * -9) $runner__black,
            calc($pixel * 5) calc($pixel * -10) $runner__black,
            calc($pixel * 6) calc($pixel * -10) $runner__skin,
            calc($pixel * 7) calc($pixel * -10) $runner__black,
            calc($pixel * 8) calc($pixel * -10) $runner__black,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__suit,
            calc($pixel * 12) calc($pixel * -10) $runner__suit,
            calc($pixel * 13) calc($pixel * -10) $runner__suit,
            calc($pixel * 14) calc($pixel * -10) $runner__suit,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__black,
            calc($pixel * 17) calc($pixel * -10) $runner__black,
            calc($pixel * 18) calc($pixel * -10) $runner__black,
            calc($pixel * 19) calc($pixel * -10) $runner__skin,
            calc($pixel * 20) calc($pixel * -10) $runner__suit,
            calc($pixel * 21) calc($pixel * -10) $runner__suit,
            calc($pixel * 22) calc($pixel * -10) $runner__suit,
            calc($pixel * 23) calc($pixel * -10) $runner__suit,
            calc($pixel * 24) calc($pixel * -10) $runner__skin,
            calc($pixel * 8) calc($pixel * -11) $runner__black,
            calc($pixel * 9) calc($pixel * -11) $runner__black,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__suit,
            calc($pixel * 12) calc($pixel * -11) $runner__suit,
            calc($pixel * 13) calc($pixel * -11) $runner__suit,
            calc($pixel * 14) calc($pixel * -11) $runner__suit,
            calc($pixel * 15) calc($pixel * -11) $runner__black,
            calc($pixel * 16) calc($pixel * -11) $runner__black,
            calc($pixel * 17) calc($pixel * -11) $runner__black,
            calc($pixel * 18) calc($pixel * -11) $runner__black,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__suit,
            calc($pixel * 21) calc($pixel * -11) $runner__suit,
            calc($pixel * 10) calc($pixel * -12) $runner__black,
            calc($pixel * 11) calc($pixel * -12) $runner__black,
            calc($pixel * 12) calc($pixel * -12) $runner__suit,
            calc($pixel * 13) calc($pixel * -12) $runner__suit,
            calc($pixel * 14) calc($pixel * -12) $runner__black,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__suit,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__suit,
            calc($pixel * 19) calc($pixel * -12) $runner__suit,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 9) calc($pixel * -13) $runner__black,
            calc($pixel * 10) calc($pixel * -13) $runner__black,
            calc($pixel * 11) calc($pixel * -13) $runner__black,
            calc($pixel * 12) calc($pixel * -13) $runner__black,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__hair,
            calc($pixel * 15) calc($pixel * -13) $runner__hair,
            calc($pixel * 16) calc($pixel * -13) $runner__hair,
            calc($pixel * 17) calc($pixel * -13) $runner__hair,
            calc($pixel * 18) calc($pixel * -13) $runner__hair,
            calc($pixel * 19) calc($pixel * -13) $runner__skin,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__skin,
            calc($pixel * 22) calc($pixel * -13) $runner__skin,
            calc($pixel * 9) calc($pixel * -14) $runner__black,
            calc($pixel * 10) calc($pixel * -14) $runner__hair,
            calc($pixel * 11) calc($pixel * -14) $runner__hair,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__hair,
            calc($pixel * 15) calc($pixel * -14) $runner__black,
            calc($pixel * 16) calc($pixel * -14) $runner__black,
            calc($pixel * 17) calc($pixel * -14) $runner__suit,
            calc($pixel * 18) calc($pixel * -14) $runner__hair,
            calc($pixel * 19) calc($pixel * -14) $runner__hair,
            calc($pixel * 20) calc($pixel * -14) $runner__skin,
            calc($pixel * 21) calc($pixel * -14) $runner__eyes,
            calc($pixel * 22) calc($pixel * -14) $runner__skin,
            calc($pixel * 9) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__black,
            calc($pixel * 15) calc($pixel * -15) $runner__black,
            calc($pixel * 16) calc($pixel * -15) $runner__black,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__black,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__skin,
            calc($pixel * 21) calc($pixel * -15) $runner__eyes,
            calc($pixel * 22) calc($pixel * -15) $runner__skin,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__suit,
            calc($pixel * 15) calc($pixel * -16) $runner__suit,
            calc($pixel * 16) calc($pixel * -16) $runner__black,
            calc($pixel * 17) calc($pixel * -16) $runner__black,
            calc($pixel * 18) calc($pixel * -16) $runner__hair,
            calc($pixel * 19) calc($pixel * -16) $runner__black,
            calc($pixel * 20) calc($pixel * -16) $runner__black,
            calc($pixel * 21) calc($pixel * -16) $runner__hair,
            calc($pixel * 22) calc($pixel * -16) $runner__hair,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__suit,
            calc($pixel * 15) calc($pixel * -17) $runner__white,
            calc($pixel * 16) calc($pixel * -17) $runner__suit,
            calc($pixel * 17) calc($pixel * -17) $runner__suit,
            calc($pixel * 18) calc($pixel * -17) $runner__hair,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__black,
            calc($pixel * 21) calc($pixel * -17) $runner__black,
            calc($pixel * 22) calc($pixel * -17) $runner__black,
            calc($pixel * 23) calc($pixel * -17) $runner__hair,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__hair,
            calc($pixel * 15) calc($pixel * -18) $runner__suit,
            calc($pixel * 16) calc($pixel * -18) $runner__suit,
            calc($pixel * 17) calc($pixel * -18) $runner__hair,
            calc($pixel * 18) calc($pixel * -18) $runner__hair,
            calc($pixel * 19) calc($pixel * -18) $runner__hair,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__black,
            calc($pixel * 24) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__hair,
            calc($pixel * 16) calc($pixel * -19) $runner__suit,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__hair,
            calc($pixel * 19) calc($pixel * -19) $runner__hair,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__hair,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 24) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__hair,
            calc($pixel * 15) calc($pixel * -20) $runner__white,
            calc($pixel * 16) calc($pixel * -20) $runner__suit,
            calc($pixel * 17) calc($pixel * -20) $runner__white,
            calc($pixel * 18) calc($pixel * -20) $runner__white,
            calc($pixel * 19) calc($pixel * -20) $runner__white,
            calc($pixel * 20) calc($pixel * -20) $runner__white,
            calc($pixel * 21) calc($pixel * -20) $runner__white,
            calc($pixel * 22) calc($pixel * -20) $runner__white,
            calc($pixel * 23) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -21) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__white,
            calc($pixel * 15) calc($pixel * -21) $runner__white,
            calc($pixel * 16) calc($pixel * -21) $runner__suit,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 23) calc($pixel * -21) $runner__white,
            calc($pixel * 13) calc($pixel * -22) $runner__white,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__suit,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__hair,
            calc($pixel * 19) calc($pixel * -22) $runner__hair,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 21) calc($pixel * -22) $runner__hair,
            calc($pixel * 22) calc($pixel * -22) $runner__hair,
            calc($pixel * 14) calc($pixel * -23) $runner__hair,
            calc($pixel * 15) calc($pixel * -23) $runner__hair,
            calc($pixel * 16) calc($pixel * -23) $runner__suit,
            calc($pixel * 17) calc($pixel * -23) $runner__hair,
            calc($pixel * 18) calc($pixel * -23) $runner__hair,
            calc($pixel * 19) calc($pixel * -23) $runner__hair,
            calc($pixel * 20) calc($pixel * -23) $runner__hair,
            calc($pixel * 16) calc($pixel * -24) $runner__suit,
            calc($pixel * 17) calc($pixel * -24) $runner__suit,
            calc($pixel * 18) calc($pixel * -24) $runner__black;
    }
    .runner__frame6--shadow {
        -webkit-box-shadow: calc($pixel * 8) calc($pixel * 2) $runner__shadow,
            calc($pixel * 7) calc($pixel * 3) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 7) calc($pixel * 4) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 13) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 14) calc($pixel * 5) $runner__shadow,
            calc($pixel * 1) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 15) calc($pixel * 6) $runner__shadow,
            calc($pixel * 16) calc($pixel * 6) $runner__shadow,
            calc($pixel * 17) calc($pixel * 6) $runner__shadow,
            calc($pixel * 1) calc($pixel * 7) $runner__shadow,
            calc($pixel * 0) calc($pixel * 7) $runner__shadow,
            calc($pixel * 1) calc($pixel * 7) $runner__shadow,
            calc($pixel * 2) calc($pixel * 7) $runner__shadow,
            calc($pixel * 3) calc($pixel * 7) $runner__shadow,
            calc($pixel * 4) calc($pixel * 7) $runner__shadow,
            calc($pixel * 5) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 16) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 23) calc($pixel * 7) $runner__shadow,
            calc($pixel * 24) calc($pixel * 7) $runner__shadow,
            calc($pixel * 0) calc($pixel * 8) $runner__shadow,
            calc($pixel * 1) calc($pixel * 8) $runner__shadow,
            calc($pixel * 2) calc($pixel * 8) $runner__shadow,
            calc($pixel * 3) calc($pixel * 8) $runner__shadow,
            calc($pixel * 4) calc($pixel * 8) $runner__shadow,
            calc($pixel * 5) calc($pixel * 8) $runner__shadow,
            calc($pixel * 6) calc($pixel * 8) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 23) calc($pixel * 8) $runner__shadow,
            calc($pixel * 24) calc($pixel * 8) $runner__shadow,
            calc($pixel * 3) calc($pixel * 9) $runner__shadow,
            calc($pixel * 4) calc($pixel * 9) $runner__shadow,
            calc($pixel * 5) calc($pixel * 9) $runner__shadow,
            calc($pixel * 6) calc($pixel * 9) $runner__shadow,
            calc($pixel * 7) calc($pixel * 9) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 21) calc($pixel * 9) $runner__shadow,
            calc($pixel * 22) calc($pixel * 9) $runner__shadow,
            calc($pixel * 23) calc($pixel * 9) $runner__shadow,
            calc($pixel * 24) calc($pixel * 9) $runner__shadow,
            calc($pixel * 5) calc($pixel * 10) $runner__shadow,
            calc($pixel * 6) calc($pixel * 10) $runner__shadow,
            calc($pixel * 7) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 10) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 21) calc($pixel * 10) $runner__shadow,
            calc($pixel * 22) calc($pixel * 10) $runner__shadow,
            calc($pixel * 23) calc($pixel * 10) $runner__shadow,
            calc($pixel * 24) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 9) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 24) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 23) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 21) calc($pixel * 22) $runner__shadow,
            calc($pixel * 22) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 23) $runner__shadow,
            calc($pixel * 15) calc($pixel * 23) $runner__shadow,
            calc($pixel * 16) calc($pixel * 23) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow,
            calc($pixel * 19) calc($pixel * 23) $runner__shadow,
            calc($pixel * 20) calc($pixel * 23) $runner__shadow,
            calc($pixel * 16) calc($pixel * 24) $runner__shadow,
            calc($pixel * 17) calc($pixel * 24) $runner__shadow,
            calc($pixel * 18) calc($pixel * 24) $runner__shadow;
        box-shadow: calc($pixel * 8) calc($pixel * 2) $runner__shadow,
            calc($pixel * 7) calc($pixel * 3) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 7) calc($pixel * 4) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 13) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 14) calc($pixel * 5) $runner__shadow,
            calc($pixel * 1) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 15) calc($pixel * 6) $runner__shadow,
            calc($pixel * 16) calc($pixel * 6) $runner__shadow,
            calc($pixel * 17) calc($pixel * 6) $runner__shadow,
            calc($pixel * 1) calc($pixel * 7) $runner__shadow,
            calc($pixel * 0) calc($pixel * 7) $runner__shadow,
            calc($pixel * 1) calc($pixel * 7) $runner__shadow,
            calc($pixel * 2) calc($pixel * 7) $runner__shadow,
            calc($pixel * 3) calc($pixel * 7) $runner__shadow,
            calc($pixel * 4) calc($pixel * 7) $runner__shadow,
            calc($pixel * 5) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 16) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 23) calc($pixel * 7) $runner__shadow,
            calc($pixel * 24) calc($pixel * 7) $runner__shadow,
            calc($pixel * 0) calc($pixel * 8) $runner__shadow,
            calc($pixel * 1) calc($pixel * 8) $runner__shadow,
            calc($pixel * 2) calc($pixel * 8) $runner__shadow,
            calc($pixel * 3) calc($pixel * 8) $runner__shadow,
            calc($pixel * 4) calc($pixel * 8) $runner__shadow,
            calc($pixel * 5) calc($pixel * 8) $runner__shadow,
            calc($pixel * 6) calc($pixel * 8) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 23) calc($pixel * 8) $runner__shadow,
            calc($pixel * 24) calc($pixel * 8) $runner__shadow,
            calc($pixel * 3) calc($pixel * 9) $runner__shadow,
            calc($pixel * 4) calc($pixel * 9) $runner__shadow,
            calc($pixel * 5) calc($pixel * 9) $runner__shadow,
            calc($pixel * 6) calc($pixel * 9) $runner__shadow,
            calc($pixel * 7) calc($pixel * 9) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 21) calc($pixel * 9) $runner__shadow,
            calc($pixel * 22) calc($pixel * 9) $runner__shadow,
            calc($pixel * 23) calc($pixel * 9) $runner__shadow,
            calc($pixel * 24) calc($pixel * 9) $runner__shadow,
            calc($pixel * 5) calc($pixel * 10) $runner__shadow,
            calc($pixel * 6) calc($pixel * 10) $runner__shadow,
            calc($pixel * 7) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 10) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 21) calc($pixel * 10) $runner__shadow,
            calc($pixel * 22) calc($pixel * 10) $runner__shadow,
            calc($pixel * 23) calc($pixel * 10) $runner__shadow,
            calc($pixel * 24) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 9) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 24) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 23) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 21) calc($pixel * 22) $runner__shadow,
            calc($pixel * 22) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 23) $runner__shadow,
            calc($pixel * 15) calc($pixel * 23) $runner__shadow,
            calc($pixel * 16) calc($pixel * 23) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow,
            calc($pixel * 19) calc($pixel * 23) $runner__shadow,
            calc($pixel * 20) calc($pixel * 23) $runner__shadow,
            calc($pixel * 16) calc($pixel * 24) $runner__shadow,
            calc($pixel * 17) calc($pixel * 24) $runner__shadow,
            calc($pixel * 18) calc($pixel * 24) $runner__shadow;
    }
    .runner__frame7 {
        -webkit-box-shadow: calc($pixel * 13) calc($pixel * -1) $runner__black,
            calc($pixel * 14) calc($pixel * -1) $runner__skin,
            calc($pixel * 12) calc($pixel * -2) $runner__black,
            calc($pixel * 13) calc($pixel * -2) $runner__suit,
            calc($pixel * 14) calc($pixel * -2) $runner__black,
            calc($pixel * 15) calc($pixel * -2) $runner__skin,
            calc($pixel * 11) calc($pixel * -3) $runner__black,
            calc($pixel * 12) calc($pixel * -3) $runner__suit,
            calc($pixel * 13) calc($pixel * -3) $runner__black,
            calc($pixel * 9) calc($pixel * -4) $runner__black,
            calc($pixel * 10) calc($pixel * -4) $runner__black,
            calc($pixel * 11) calc($pixel * -4) $runner__suit,
            calc($pixel * 12) calc($pixel * -4) $runner__suit,
            calc($pixel * 13) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__black,
            calc($pixel * 9) calc($pixel * -5) $runner__black,
            calc($pixel * 10) calc($pixel * -5) $runner__suit,
            calc($pixel * 11) calc($pixel * -5) $runner__suit,
            calc($pixel * 12) calc($pixel * -5) $runner__suit,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__black,
            calc($pixel * 8) calc($pixel * -6) $runner__black,
            calc($pixel * 9) calc($pixel * -6) $runner__suit,
            calc($pixel * 10) calc($pixel * -6) $runner__suit,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__black,
            calc($pixel * 5) calc($pixel * -7) $runner__black,
            calc($pixel * 6) calc($pixel * -7) $runner__black,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__skin,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__black,
            calc($pixel * 14) calc($pixel * -7) $runner__black,
            calc($pixel * 15) calc($pixel * -7) $runner__black,
            calc($pixel * 20) calc($pixel * -7) $runner__black,
            calc($pixel * 21) calc($pixel * -7) $runner__black,
            calc($pixel * 22) calc($pixel * -7) $runner__black,
            calc($pixel * 23) calc($pixel * -7) $runner__black,
            calc($pixel * 24) calc($pixel * -7) $runner__black,
            calc($pixel * 4) calc($pixel * -8) $runner__black,
            calc($pixel * 5) calc($pixel * -8) $runner__black,
            calc($pixel * 6) calc($pixel * -8) $runner__black,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__skin,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__suit,
            calc($pixel * 13) calc($pixel * -8) $runner__suit,
            calc($pixel * 14) calc($pixel * -8) $runner__black,
            calc($pixel * 15) calc($pixel * -8) $runner__black,
            calc($pixel * 16) calc($pixel * -8) $runner__black,
            calc($pixel * 18) calc($pixel * -8) $runner__black,
            calc($pixel * 19) calc($pixel * -8) $runner__black,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__suit,
            calc($pixel * 23) calc($pixel * -8) $runner__suit,
            calc($pixel * 24) calc($pixel * -8) $runner__suit,
            calc($pixel * 25) calc($pixel * -8) $runner__black,
            calc($pixel * 3) calc($pixel * -9) $runner__black,
            calc($pixel * 4) calc($pixel * -9) $runner__black,
            calc($pixel * 5) calc($pixel * -9) $runner__black,
            calc($pixel * 6) calc($pixel * -9) $runner__black,
            calc($pixel * 7) calc($pixel * -9) $runner__skin,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__suit,
            calc($pixel * 12) calc($pixel * -9) $runner__suit,
            calc($pixel * 13) calc($pixel * -9) $runner__suit,
            calc($pixel * 14) calc($pixel * -9) $runner__black,
            calc($pixel * 15) calc($pixel * -9) $runner__skin,
            calc($pixel * 16) calc($pixel * -9) $runner__black,
            calc($pixel * 17) calc($pixel * -9) $runner__black,
            calc($pixel * 18) calc($pixel * -9) $runner__black,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 21) calc($pixel * -9) $runner__suit,
            calc($pixel * 22) calc($pixel * -9) $runner__suit,
            calc($pixel * 23) calc($pixel * -9) $runner__black,
            calc($pixel * 24) calc($pixel * -9) $runner__black,
            calc($pixel * 25) calc($pixel * -9) $runner__suit,
            calc($pixel * 1) calc($pixel * -10) $runner__skin,
            calc($pixel * 2) calc($pixel * -10) $runner__black,
            calc($pixel * 3) calc($pixel * -10) $runner__black,
            calc($pixel * 4) calc($pixel * -10) $runner__black,
            calc($pixel * 6) calc($pixel * -10) $runner__skin,
            calc($pixel * 7) calc($pixel * -10) $runner__skin,
            calc($pixel * 8) calc($pixel * -10) $runner__black,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__suit,
            calc($pixel * 11) calc($pixel * -10) $runner__suit,
            calc($pixel * 12) calc($pixel * -10) $runner__suit,
            calc($pixel * 13) calc($pixel * -10) $runner__suit,
            calc($pixel * 14) calc($pixel * -10) $runner__suit,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__black,
            calc($pixel * 17) calc($pixel * -10) $runner__black,
            calc($pixel * 18) calc($pixel * -10) $runner__black,
            calc($pixel * 19) calc($pixel * -10) $runner__skin,
            calc($pixel * 20) calc($pixel * -10) $runner__suit,
            calc($pixel * 21) calc($pixel * -10) $runner__suit,
            calc($pixel * 22) calc($pixel * -10) $runner__suit,
            calc($pixel * 23) calc($pixel * -10) $runner__black,
            calc($pixel * 24) calc($pixel * -10) $runner__black,
            calc($pixel * 25) calc($pixel * -10) $runner__suit,
            calc($pixel * 2) calc($pixel * -11) $runner__skin,
            calc($pixel * 3) calc($pixel * -11) $runner__skin,
            calc($pixel * 6) calc($pixel * -11) $runner__skin,
            calc($pixel * 8) calc($pixel * -11) $runner__black,
            calc($pixel * 9) calc($pixel * -11) $runner__suit,
            calc($pixel * 10) calc($pixel * -11) $runner__suit,
            calc($pixel * 11) calc($pixel * -11) $runner__suit,
            calc($pixel * 12) calc($pixel * -11) $runner__suit,
            calc($pixel * 13) calc($pixel * -11) $runner__black,
            calc($pixel * 14) calc($pixel * -11) $runner__black,
            calc($pixel * 15) calc($pixel * -11) $runner__black,
            calc($pixel * 16) calc($pixel * -11) $runner__black,
            calc($pixel * 17) calc($pixel * -11) $runner__black,
            calc($pixel * 18) calc($pixel * -11) $runner__black,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__suit,
            calc($pixel * 21) calc($pixel * -11) $runner__suit,
            calc($pixel * 22) calc($pixel * -11) $runner__suit,
            calc($pixel * 23) calc($pixel * -11) $runner__black,
            calc($pixel * 24) calc($pixel * -11) $runner__skin,
            calc($pixel * 25) calc($pixel * -11) $runner__suit,
            calc($pixel * 9) calc($pixel * -12) $runner__suit,
            calc($pixel * 10) calc($pixel * -12) $runner__suit,
            calc($pixel * 11) calc($pixel * -12) $runner__suit,
            calc($pixel * 12) calc($pixel * -12) $runner__suit,
            calc($pixel * 13) calc($pixel * -12) $runner__suit,
            calc($pixel * 14) calc($pixel * -12) $runner__black,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__suit,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__suit,
            calc($pixel * 19) calc($pixel * -12) $runner__suit,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 23) calc($pixel * -12) $runner__suit,
            calc($pixel * 24) calc($pixel * -12) $runner__suit,
            calc($pixel * 10) calc($pixel * -13) $runner__suit,
            calc($pixel * 11) calc($pixel * -13) $runner__suit,
            calc($pixel * 12) calc($pixel * -13) $runner__suit,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__hair,
            calc($pixel * 15) calc($pixel * -13) $runner__hair,
            calc($pixel * 16) calc($pixel * -13) $runner__hair,
            calc($pixel * 17) calc($pixel * -13) $runner__hair,
            calc($pixel * 18) calc($pixel * -13) $runner__skin,
            calc($pixel * 19) calc($pixel * -13) $runner__skin,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__skin,
            calc($pixel * 22) calc($pixel * -13) $runner__skin,
            calc($pixel * 10) calc($pixel * -14) $runner__black,
            calc($pixel * 11) calc($pixel * -14) $runner__black,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__black,
            calc($pixel * 15) calc($pixel * -14) $runner__black,
            calc($pixel * 16) calc($pixel * -14) $runner__suit,
            calc($pixel * 17) calc($pixel * -14) $runner__hair,
            calc($pixel * 18) calc($pixel * -14) $runner__hair,
            calc($pixel * 19) calc($pixel * -14) $runner__skin,
            calc($pixel * 20) calc($pixel * -14) $runner__eyes,
            calc($pixel * 21) calc($pixel * -14) $runner__skin,
            calc($pixel * 22) calc($pixel * -14) $runner__skin,
            calc($pixel * 9) calc($pixel * -15) $runner__black,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__suit,
            calc($pixel * 14) calc($pixel * -15) $runner__suit,
            calc($pixel * 15) calc($pixel * -15) $runner__suit,
            calc($pixel * 16) calc($pixel * -15) $runner__black,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__black,
            calc($pixel * 19) calc($pixel * -15) $runner__skin,
            calc($pixel * 20) calc($pixel * -15) $runner__eyes,
            calc($pixel * 21) calc($pixel * -15) $runner__skin,
            calc($pixel * 22) calc($pixel * -15) $runner__skin,
            calc($pixel * 9) calc($pixel * -16) $runner__hair,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__suit,
            calc($pixel * 14) calc($pixel * -16) $runner__suit,
            calc($pixel * 15) calc($pixel * -16) $runner__suit,
            calc($pixel * 16) calc($pixel * -16) $runner__black,
            calc($pixel * 17) calc($pixel * -16) $runner__hair,
            calc($pixel * 18) calc($pixel * -16) $runner__black,
            calc($pixel * 19) calc($pixel * -16) $runner__black,
            calc($pixel * 20) calc($pixel * -16) $runner__hair,
            calc($pixel * 21) calc($pixel * -16) $runner__hair,
            calc($pixel * 22) calc($pixel * -16) $runner__skin,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 9) calc($pixel * -17) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__suit,
            calc($pixel * 14) calc($pixel * -17) $runner__skin,
            calc($pixel * 15) calc($pixel * -17) $runner__suit,
            calc($pixel * 16) calc($pixel * -17) $runner__black,
            calc($pixel * 17) calc($pixel * -17) $runner__hair,
            calc($pixel * 18) calc($pixel * -17) $runner__hair,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__black,
            calc($pixel * 21) calc($pixel * -17) $runner__black,
            calc($pixel * 22) calc($pixel * -17) $runner__black,
            calc($pixel * 23) calc($pixel * -17) $runner__hair,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 9) calc($pixel * -18) $runner__hair,
            calc($pixel * 10) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__suit,
            calc($pixel * 15) calc($pixel * -18) $runner__suit,
            calc($pixel * 16) calc($pixel * -18) $runner__hair,
            calc($pixel * 17) calc($pixel * -18) $runner__hair,
            calc($pixel * 18) calc($pixel * -18) $runner__hair,
            calc($pixel * 19) calc($pixel * -18) $runner__hair,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__black,
            calc($pixel * 24) calc($pixel * -18) $runner__hair,
            calc($pixel * 9) calc($pixel * -19) $runner__hair,
            calc($pixel * 10) calc($pixel * -19) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__suit,
            calc($pixel * 16) calc($pixel * -19) $runner__hair,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__hair,
            calc($pixel * 19) calc($pixel * -19) $runner__hair,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__hair,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 24) calc($pixel * -19) $runner__hair,
            calc($pixel * 10) calc($pixel * -20) $runner__hair,
            calc($pixel * 11) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__white,
            calc($pixel * 15) calc($pixel * -20) $runner__suit,
            calc($pixel * 16) calc($pixel * -20) $runner__white,
            calc($pixel * 17) calc($pixel * -20) $runner__white,
            calc($pixel * 18) calc($pixel * -20) $runner__white,
            calc($pixel * 19) calc($pixel * -20) $runner__white,
            calc($pixel * 20) calc($pixel * -20) $runner__white,
            calc($pixel * 21) calc($pixel * -20) $runner__white,
            calc($pixel * 22) calc($pixel * -20) $runner__white,
            calc($pixel * 23) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -21) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__white,
            calc($pixel * 15) calc($pixel * -21) $runner__suit,
            calc($pixel * 16) calc($pixel * -21) $runner__hair,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 23) calc($pixel * -21) $runner__white,
            calc($pixel * 13) calc($pixel * -22) $runner__hair,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__suit,
            calc($pixel * 16) calc($pixel * -22) $runner__hair,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__hair,
            calc($pixel * 19) calc($pixel * -22) $runner__hair,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 21) calc($pixel * -22) $runner__hair,
            calc($pixel * 22) calc($pixel * -22) $runner__hair,
            calc($pixel * 14) calc($pixel * -23) $runner__hair,
            calc($pixel * 15) calc($pixel * -23) $runner__suit,
            calc($pixel * 16) calc($pixel * -23) $runner__hair,
            calc($pixel * 17) calc($pixel * -23) $runner__hair,
            calc($pixel * 18) calc($pixel * -23) $runner__hair,
            calc($pixel * 19) calc($pixel * -23) $runner__hair,
            calc($pixel * 20) calc($pixel * -23) $runner__hair,
            calc($pixel * 15) calc($pixel * -24) $runner__suit,
            calc($pixel * 16) calc($pixel * -24) $runner__black,
            calc($pixel * 17) calc($pixel * -24) $runner__black,
            calc($pixel * 19) calc($pixel * -24) $runner__black,
            calc($pixel * 17) calc($pixel * -25) $runner__black,
            calc($pixel * 18) calc($pixel * -25) $runner__black,
            calc($pixel * 19) calc($pixel * -25) $runner__black;
        box-shadow: calc($pixel * 13) calc($pixel * -1) $runner__black,
            calc($pixel * 14) calc($pixel * -1) $runner__skin,
            calc($pixel * 12) calc($pixel * -2) $runner__black,
            calc($pixel * 13) calc($pixel * -2) $runner__suit,
            calc($pixel * 14) calc($pixel * -2) $runner__black,
            calc($pixel * 15) calc($pixel * -2) $runner__skin,
            calc($pixel * 11) calc($pixel * -3) $runner__black,
            calc($pixel * 12) calc($pixel * -3) $runner__suit,
            calc($pixel * 13) calc($pixel * -3) $runner__black,
            calc($pixel * 9) calc($pixel * -4) $runner__black,
            calc($pixel * 10) calc($pixel * -4) $runner__black,
            calc($pixel * 11) calc($pixel * -4) $runner__suit,
            calc($pixel * 12) calc($pixel * -4) $runner__suit,
            calc($pixel * 13) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__black,
            calc($pixel * 9) calc($pixel * -5) $runner__black,
            calc($pixel * 10) calc($pixel * -5) $runner__suit,
            calc($pixel * 11) calc($pixel * -5) $runner__suit,
            calc($pixel * 12) calc($pixel * -5) $runner__suit,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__black,
            calc($pixel * 8) calc($pixel * -6) $runner__black,
            calc($pixel * 9) calc($pixel * -6) $runner__suit,
            calc($pixel * 10) calc($pixel * -6) $runner__suit,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__black,
            calc($pixel * 14) calc($pixel * -6) $runner__black,
            calc($pixel * 5) calc($pixel * -7) $runner__black,
            calc($pixel * 6) calc($pixel * -7) $runner__black,
            calc($pixel * 7) calc($pixel * -7) $runner__black,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__skin,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__black,
            calc($pixel * 12) calc($pixel * -7) $runner__black,
            calc($pixel * 13) calc($pixel * -7) $runner__black,
            calc($pixel * 14) calc($pixel * -7) $runner__black,
            calc($pixel * 15) calc($pixel * -7) $runner__black,
            calc($pixel * 20) calc($pixel * -7) $runner__black,
            calc($pixel * 21) calc($pixel * -7) $runner__black,
            calc($pixel * 22) calc($pixel * -7) $runner__black,
            calc($pixel * 23) calc($pixel * -7) $runner__black,
            calc($pixel * 24) calc($pixel * -7) $runner__black,
            calc($pixel * 4) calc($pixel * -8) $runner__black,
            calc($pixel * 5) calc($pixel * -8) $runner__black,
            calc($pixel * 6) calc($pixel * -8) $runner__black,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__skin,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__black,
            calc($pixel * 12) calc($pixel * -8) $runner__suit,
            calc($pixel * 13) calc($pixel * -8) $runner__suit,
            calc($pixel * 14) calc($pixel * -8) $runner__black,
            calc($pixel * 15) calc($pixel * -8) $runner__black,
            calc($pixel * 16) calc($pixel * -8) $runner__black,
            calc($pixel * 18) calc($pixel * -8) $runner__black,
            calc($pixel * 19) calc($pixel * -8) $runner__black,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__suit,
            calc($pixel * 23) calc($pixel * -8) $runner__suit,
            calc($pixel * 24) calc($pixel * -8) $runner__suit,
            calc($pixel * 25) calc($pixel * -8) $runner__black,
            calc($pixel * 3) calc($pixel * -9) $runner__black,
            calc($pixel * 4) calc($pixel * -9) $runner__black,
            calc($pixel * 5) calc($pixel * -9) $runner__black,
            calc($pixel * 6) calc($pixel * -9) $runner__black,
            calc($pixel * 7) calc($pixel * -9) $runner__skin,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__suit,
            calc($pixel * 12) calc($pixel * -9) $runner__suit,
            calc($pixel * 13) calc($pixel * -9) $runner__suit,
            calc($pixel * 14) calc($pixel * -9) $runner__black,
            calc($pixel * 15) calc($pixel * -9) $runner__skin,
            calc($pixel * 16) calc($pixel * -9) $runner__black,
            calc($pixel * 17) calc($pixel * -9) $runner__black,
            calc($pixel * 18) calc($pixel * -9) $runner__black,
            calc($pixel * 19) calc($pixel * -9) $runner__suit,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 21) calc($pixel * -9) $runner__suit,
            calc($pixel * 22) calc($pixel * -9) $runner__suit,
            calc($pixel * 23) calc($pixel * -9) $runner__black,
            calc($pixel * 24) calc($pixel * -9) $runner__black,
            calc($pixel * 25) calc($pixel * -9) $runner__suit,
            calc($pixel * 1) calc($pixel * -10) $runner__skin,
            calc($pixel * 2) calc($pixel * -10) $runner__black,
            calc($pixel * 3) calc($pixel * -10) $runner__black,
            calc($pixel * 4) calc($pixel * -10) $runner__black,
            calc($pixel * 6) calc($pixel * -10) $runner__skin,
            calc($pixel * 7) calc($pixel * -10) $runner__skin,
            calc($pixel * 8) calc($pixel * -10) $runner__black,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__suit,
            calc($pixel * 11) calc($pixel * -10) $runner__suit,
            calc($pixel * 12) calc($pixel * -10) $runner__suit,
            calc($pixel * 13) calc($pixel * -10) $runner__suit,
            calc($pixel * 14) calc($pixel * -10) $runner__suit,
            calc($pixel * 15) calc($pixel * -10) $runner__suit,
            calc($pixel * 16) calc($pixel * -10) $runner__black,
            calc($pixel * 17) calc($pixel * -10) $runner__black,
            calc($pixel * 18) calc($pixel * -10) $runner__black,
            calc($pixel * 19) calc($pixel * -10) $runner__skin,
            calc($pixel * 20) calc($pixel * -10) $runner__suit,
            calc($pixel * 21) calc($pixel * -10) $runner__suit,
            calc($pixel * 22) calc($pixel * -10) $runner__suit,
            calc($pixel * 23) calc($pixel * -10) $runner__black,
            calc($pixel * 24) calc($pixel * -10) $runner__black,
            calc($pixel * 25) calc($pixel * -10) $runner__suit,
            calc($pixel * 2) calc($pixel * -11) $runner__skin,
            calc($pixel * 3) calc($pixel * -11) $runner__skin,
            calc($pixel * 6) calc($pixel * -11) $runner__skin,
            calc($pixel * 8) calc($pixel * -11) $runner__black,
            calc($pixel * 9) calc($pixel * -11) $runner__suit,
            calc($pixel * 10) calc($pixel * -11) $runner__suit,
            calc($pixel * 11) calc($pixel * -11) $runner__suit,
            calc($pixel * 12) calc($pixel * -11) $runner__suit,
            calc($pixel * 13) calc($pixel * -11) $runner__black,
            calc($pixel * 14) calc($pixel * -11) $runner__black,
            calc($pixel * 15) calc($pixel * -11) $runner__black,
            calc($pixel * 16) calc($pixel * -11) $runner__black,
            calc($pixel * 17) calc($pixel * -11) $runner__black,
            calc($pixel * 18) calc($pixel * -11) $runner__black,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__suit,
            calc($pixel * 21) calc($pixel * -11) $runner__suit,
            calc($pixel * 22) calc($pixel * -11) $runner__suit,
            calc($pixel * 23) calc($pixel * -11) $runner__black,
            calc($pixel * 24) calc($pixel * -11) $runner__skin,
            calc($pixel * 25) calc($pixel * -11) $runner__suit,
            calc($pixel * 9) calc($pixel * -12) $runner__suit,
            calc($pixel * 10) calc($pixel * -12) $runner__suit,
            calc($pixel * 11) calc($pixel * -12) $runner__suit,
            calc($pixel * 12) calc($pixel * -12) $runner__suit,
            calc($pixel * 13) calc($pixel * -12) $runner__suit,
            calc($pixel * 14) calc($pixel * -12) $runner__black,
            calc($pixel * 15) calc($pixel * -12) $runner__black,
            calc($pixel * 16) calc($pixel * -12) $runner__suit,
            calc($pixel * 17) calc($pixel * -12) $runner__suit,
            calc($pixel * 18) calc($pixel * -12) $runner__suit,
            calc($pixel * 19) calc($pixel * -12) $runner__suit,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 23) calc($pixel * -12) $runner__suit,
            calc($pixel * 24) calc($pixel * -12) $runner__suit,
            calc($pixel * 10) calc($pixel * -13) $runner__suit,
            calc($pixel * 11) calc($pixel * -13) $runner__suit,
            calc($pixel * 12) calc($pixel * -13) $runner__suit,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__hair,
            calc($pixel * 15) calc($pixel * -13) $runner__hair,
            calc($pixel * 16) calc($pixel * -13) $runner__hair,
            calc($pixel * 17) calc($pixel * -13) $runner__hair,
            calc($pixel * 18) calc($pixel * -13) $runner__skin,
            calc($pixel * 19) calc($pixel * -13) $runner__skin,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__skin,
            calc($pixel * 22) calc($pixel * -13) $runner__skin,
            calc($pixel * 10) calc($pixel * -14) $runner__black,
            calc($pixel * 11) calc($pixel * -14) $runner__black,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__black,
            calc($pixel * 15) calc($pixel * -14) $runner__black,
            calc($pixel * 16) calc($pixel * -14) $runner__suit,
            calc($pixel * 17) calc($pixel * -14) $runner__hair,
            calc($pixel * 18) calc($pixel * -14) $runner__hair,
            calc($pixel * 19) calc($pixel * -14) $runner__skin,
            calc($pixel * 20) calc($pixel * -14) $runner__eyes,
            calc($pixel * 21) calc($pixel * -14) $runner__skin,
            calc($pixel * 22) calc($pixel * -14) $runner__skin,
            calc($pixel * 9) calc($pixel * -15) $runner__black,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__suit,
            calc($pixel * 14) calc($pixel * -15) $runner__suit,
            calc($pixel * 15) calc($pixel * -15) $runner__suit,
            calc($pixel * 16) calc($pixel * -15) $runner__black,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__black,
            calc($pixel * 19) calc($pixel * -15) $runner__skin,
            calc($pixel * 20) calc($pixel * -15) $runner__eyes,
            calc($pixel * 21) calc($pixel * -15) $runner__skin,
            calc($pixel * 22) calc($pixel * -15) $runner__skin,
            calc($pixel * 9) calc($pixel * -16) $runner__hair,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__suit,
            calc($pixel * 14) calc($pixel * -16) $runner__suit,
            calc($pixel * 15) calc($pixel * -16) $runner__suit,
            calc($pixel * 16) calc($pixel * -16) $runner__black,
            calc($pixel * 17) calc($pixel * -16) $runner__hair,
            calc($pixel * 18) calc($pixel * -16) $runner__black,
            calc($pixel * 19) calc($pixel * -16) $runner__black,
            calc($pixel * 20) calc($pixel * -16) $runner__hair,
            calc($pixel * 21) calc($pixel * -16) $runner__hair,
            calc($pixel * 22) calc($pixel * -16) $runner__skin,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 9) calc($pixel * -17) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__suit,
            calc($pixel * 14) calc($pixel * -17) $runner__skin,
            calc($pixel * 15) calc($pixel * -17) $runner__suit,
            calc($pixel * 16) calc($pixel * -17) $runner__black,
            calc($pixel * 17) calc($pixel * -17) $runner__hair,
            calc($pixel * 18) calc($pixel * -17) $runner__hair,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__black,
            calc($pixel * 21) calc($pixel * -17) $runner__black,
            calc($pixel * 22) calc($pixel * -17) $runner__black,
            calc($pixel * 23) calc($pixel * -17) $runner__hair,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 9) calc($pixel * -18) $runner__hair,
            calc($pixel * 10) calc($pixel * -18) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__suit,
            calc($pixel * 15) calc($pixel * -18) $runner__suit,
            calc($pixel * 16) calc($pixel * -18) $runner__hair,
            calc($pixel * 17) calc($pixel * -18) $runner__hair,
            calc($pixel * 18) calc($pixel * -18) $runner__hair,
            calc($pixel * 19) calc($pixel * -18) $runner__hair,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__black,
            calc($pixel * 24) calc($pixel * -18) $runner__hair,
            calc($pixel * 9) calc($pixel * -19) $runner__hair,
            calc($pixel * 10) calc($pixel * -19) $runner__hair,
            calc($pixel * 11) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__suit,
            calc($pixel * 16) calc($pixel * -19) $runner__hair,
            calc($pixel * 17) calc($pixel * -19) $runner__hair,
            calc($pixel * 18) calc($pixel * -19) $runner__hair,
            calc($pixel * 19) calc($pixel * -19) $runner__hair,
            calc($pixel * 20) calc($pixel * -19) $runner__hair,
            calc($pixel * 21) calc($pixel * -19) $runner__hair,
            calc($pixel * 22) calc($pixel * -19) $runner__hair,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 24) calc($pixel * -19) $runner__hair,
            calc($pixel * 10) calc($pixel * -20) $runner__hair,
            calc($pixel * 11) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__hair,
            calc($pixel * 14) calc($pixel * -20) $runner__white,
            calc($pixel * 15) calc($pixel * -20) $runner__suit,
            calc($pixel * 16) calc($pixel * -20) $runner__white,
            calc($pixel * 17) calc($pixel * -20) $runner__white,
            calc($pixel * 18) calc($pixel * -20) $runner__white,
            calc($pixel * 19) calc($pixel * -20) $runner__white,
            calc($pixel * 20) calc($pixel * -20) $runner__white,
            calc($pixel * 21) calc($pixel * -20) $runner__white,
            calc($pixel * 22) calc($pixel * -20) $runner__white,
            calc($pixel * 23) calc($pixel * -20) $runner__hair,
            calc($pixel * 12) calc($pixel * -21) $runner__hair,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__white,
            calc($pixel * 15) calc($pixel * -21) $runner__suit,
            calc($pixel * 16) calc($pixel * -21) $runner__hair,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 23) calc($pixel * -21) $runner__white,
            calc($pixel * 13) calc($pixel * -22) $runner__hair,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__suit,
            calc($pixel * 16) calc($pixel * -22) $runner__hair,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__hair,
            calc($pixel * 19) calc($pixel * -22) $runner__hair,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 21) calc($pixel * -22) $runner__hair,
            calc($pixel * 22) calc($pixel * -22) $runner__hair,
            calc($pixel * 14) calc($pixel * -23) $runner__hair,
            calc($pixel * 15) calc($pixel * -23) $runner__suit,
            calc($pixel * 16) calc($pixel * -23) $runner__hair,
            calc($pixel * 17) calc($pixel * -23) $runner__hair,
            calc($pixel * 18) calc($pixel * -23) $runner__hair,
            calc($pixel * 19) calc($pixel * -23) $runner__hair,
            calc($pixel * 20) calc($pixel * -23) $runner__hair,
            calc($pixel * 15) calc($pixel * -24) $runner__suit,
            calc($pixel * 16) calc($pixel * -24) $runner__black,
            calc($pixel * 17) calc($pixel * -24) $runner__black,
            calc($pixel * 19) calc($pixel * -24) $runner__black,
            calc($pixel * 17) calc($pixel * -25) $runner__black,
            calc($pixel * 18) calc($pixel * -25) $runner__black,
            calc($pixel * 19) calc($pixel * -25) $runner__black;
    }
    .runner__frame7--shadow {
        -webkit-box-shadow: calc($pixel * 13) calc($pixel * 1) $runner__shadow,
            calc($pixel * 14) calc($pixel * 1) $runner__shadow,
            calc($pixel * 12) calc($pixel * 2) $runner__shadow,
            calc($pixel * 13) calc($pixel * 2) $runner__shadow,
            calc($pixel * 14) calc($pixel * 2) $runner__shadow,
            calc($pixel * 15) calc($pixel * 2) $runner__shadow,
            calc($pixel * 11) calc($pixel * 3) $runner__shadow,
            calc($pixel * 12) calc($pixel * 3) $runner__shadow,
            calc($pixel * 13) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 13) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 5) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 23) calc($pixel * 7) $runner__shadow,
            calc($pixel * 24) calc($pixel * 7) $runner__shadow,
            calc($pixel * 4) calc($pixel * 8) $runner__shadow,
            calc($pixel * 5) calc($pixel * 8) $runner__shadow,
            calc($pixel * 6) calc($pixel * 8) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 23) calc($pixel * 8) $runner__shadow,
            calc($pixel * 24) calc($pixel * 8) $runner__shadow,
            calc($pixel * 25) calc($pixel * 8) $runner__shadow,
            calc($pixel * 3) calc($pixel * 9) $runner__shadow,
            calc($pixel * 4) calc($pixel * 9) $runner__shadow,
            calc($pixel * 5) calc($pixel * 9) $runner__shadow,
            calc($pixel * 6) calc($pixel * 9) $runner__shadow,
            calc($pixel * 7) calc($pixel * 9) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 21) calc($pixel * 9) $runner__shadow,
            calc($pixel * 22) calc($pixel * 9) $runner__shadow,
            calc($pixel * 23) calc($pixel * 9) $runner__shadow,
            calc($pixel * 24) calc($pixel * 9) $runner__shadow,
            calc($pixel * 25) calc($pixel * 9) $runner__shadow,
            calc($pixel * 1) calc($pixel * 10) $runner__shadow,
            calc($pixel * 2) calc($pixel * 10) $runner__shadow,
            calc($pixel * 3) calc($pixel * 10) $runner__shadow,
            calc($pixel * 4) calc($pixel * 10) $runner__shadow,
            calc($pixel * 6) calc($pixel * 10) $runner__shadow,
            calc($pixel * 7) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 10) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 21) calc($pixel * 10) $runner__shadow,
            calc($pixel * 22) calc($pixel * 10) $runner__shadow,
            calc($pixel * 23) calc($pixel * 10) $runner__shadow,
            calc($pixel * 24) calc($pixel * 10) $runner__shadow,
            calc($pixel * 25) calc($pixel * 10) $runner__shadow,
            calc($pixel * 2) calc($pixel * 11) $runner__shadow,
            calc($pixel * 3) calc($pixel * 11) $runner__shadow,
            calc($pixel * 6) calc($pixel * 11) $runner__shadow,
            calc($pixel * 8) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 22) calc($pixel * 11) $runner__shadow,
            calc($pixel * 23) calc($pixel * 11) $runner__shadow,
            calc($pixel * 24) calc($pixel * 11) $runner__shadow,
            calc($pixel * 25) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 23) calc($pixel * 12) $runner__shadow,
            calc($pixel * 24) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 9) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 9) calc($pixel * 17) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 9) calc($pixel * 18) $runner__shadow,
            calc($pixel * 10) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 9) calc($pixel * 19) $runner__shadow,
            calc($pixel * 10) calc($pixel * 19) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 24) calc($pixel * 19) $runner__shadow,
            calc($pixel * 10) calc($pixel * 20) $runner__shadow,
            calc($pixel * 11) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 23) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 21) calc($pixel * 22) $runner__shadow,
            calc($pixel * 22) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 23) $runner__shadow,
            calc($pixel * 15) calc($pixel * 23) $runner__shadow,
            calc($pixel * 16) calc($pixel * 23) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow,
            calc($pixel * 19) calc($pixel * 23) $runner__shadow,
            calc($pixel * 20) calc($pixel * 23) $runner__shadow,
            calc($pixel * 15) calc($pixel * 24) $runner__shadow,
            calc($pixel * 16) calc($pixel * 24) $runner__shadow,
            calc($pixel * 17) calc($pixel * 24) $runner__shadow,
            calc($pixel * 19) calc($pixel * 24) $runner__shadow,
            calc($pixel * 17) calc($pixel * 25) $runner__shadow,
            calc($pixel * 18) calc($pixel * 25) $runner__shadow,
            calc($pixel * 19) calc($pixel * 25) $runner__shadow;
        box-shadow: calc($pixel * 13) calc($pixel * 1) $runner__shadow,
            calc($pixel * 14) calc($pixel * 1) $runner__shadow,
            calc($pixel * 12) calc($pixel * 2) $runner__shadow,
            calc($pixel * 13) calc($pixel * 2) $runner__shadow,
            calc($pixel * 14) calc($pixel * 2) $runner__shadow,
            calc($pixel * 15) calc($pixel * 2) $runner__shadow,
            calc($pixel * 11) calc($pixel * 3) $runner__shadow,
            calc($pixel * 12) calc($pixel * 3) $runner__shadow,
            calc($pixel * 13) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 13) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 5) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 23) calc($pixel * 7) $runner__shadow,
            calc($pixel * 24) calc($pixel * 7) $runner__shadow,
            calc($pixel * 4) calc($pixel * 8) $runner__shadow,
            calc($pixel * 5) calc($pixel * 8) $runner__shadow,
            calc($pixel * 6) calc($pixel * 8) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 23) calc($pixel * 8) $runner__shadow,
            calc($pixel * 24) calc($pixel * 8) $runner__shadow,
            calc($pixel * 25) calc($pixel * 8) $runner__shadow,
            calc($pixel * 3) calc($pixel * 9) $runner__shadow,
            calc($pixel * 4) calc($pixel * 9) $runner__shadow,
            calc($pixel * 5) calc($pixel * 9) $runner__shadow,
            calc($pixel * 6) calc($pixel * 9) $runner__shadow,
            calc($pixel * 7) calc($pixel * 9) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 21) calc($pixel * 9) $runner__shadow,
            calc($pixel * 22) calc($pixel * 9) $runner__shadow,
            calc($pixel * 23) calc($pixel * 9) $runner__shadow,
            calc($pixel * 24) calc($pixel * 9) $runner__shadow,
            calc($pixel * 25) calc($pixel * 9) $runner__shadow,
            calc($pixel * 1) calc($pixel * 10) $runner__shadow,
            calc($pixel * 2) calc($pixel * 10) $runner__shadow,
            calc($pixel * 3) calc($pixel * 10) $runner__shadow,
            calc($pixel * 4) calc($pixel * 10) $runner__shadow,
            calc($pixel * 6) calc($pixel * 10) $runner__shadow,
            calc($pixel * 7) calc($pixel * 10) $runner__shadow,
            calc($pixel * 8) calc($pixel * 10) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 21) calc($pixel * 10) $runner__shadow,
            calc($pixel * 22) calc($pixel * 10) $runner__shadow,
            calc($pixel * 23) calc($pixel * 10) $runner__shadow,
            calc($pixel * 24) calc($pixel * 10) $runner__shadow,
            calc($pixel * 25) calc($pixel * 10) $runner__shadow,
            calc($pixel * 2) calc($pixel * 11) $runner__shadow,
            calc($pixel * 3) calc($pixel * 11) $runner__shadow,
            calc($pixel * 6) calc($pixel * 11) $runner__shadow,
            calc($pixel * 8) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 11) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 22) calc($pixel * 11) $runner__shadow,
            calc($pixel * 23) calc($pixel * 11) $runner__shadow,
            calc($pixel * 24) calc($pixel * 11) $runner__shadow,
            calc($pixel * 25) calc($pixel * 11) $runner__shadow,
            calc($pixel * 9) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 12) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 23) calc($pixel * 12) $runner__shadow,
            calc($pixel * 24) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 9) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 9) calc($pixel * 17) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 9) calc($pixel * 18) $runner__shadow,
            calc($pixel * 10) calc($pixel * 18) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 9) calc($pixel * 19) $runner__shadow,
            calc($pixel * 10) calc($pixel * 19) $runner__shadow,
            calc($pixel * 11) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 24) calc($pixel * 19) $runner__shadow,
            calc($pixel * 10) calc($pixel * 20) $runner__shadow,
            calc($pixel * 11) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 12) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 23) calc($pixel * 21) $runner__shadow,
            calc($pixel * 13) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 21) calc($pixel * 22) $runner__shadow,
            calc($pixel * 22) calc($pixel * 22) $runner__shadow,
            calc($pixel * 14) calc($pixel * 23) $runner__shadow,
            calc($pixel * 15) calc($pixel * 23) $runner__shadow,
            calc($pixel * 16) calc($pixel * 23) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow,
            calc($pixel * 19) calc($pixel * 23) $runner__shadow,
            calc($pixel * 20) calc($pixel * 23) $runner__shadow,
            calc($pixel * 15) calc($pixel * 24) $runner__shadow,
            calc($pixel * 16) calc($pixel * 24) $runner__shadow,
            calc($pixel * 17) calc($pixel * 24) $runner__shadow,
            calc($pixel * 19) calc($pixel * 24) $runner__shadow,
            calc($pixel * 17) calc($pixel * 25) $runner__shadow,
            calc($pixel * 18) calc($pixel * 25) $runner__shadow,
            calc($pixel * 19) calc($pixel * 25) $runner__shadow;
    }
    .runner__frame8 {
        -webkit-box-shadow: calc($pixel * 9) calc($pixel * 0) $runner__black,
            calc($pixel * 10) calc($pixel * 0) $runner__black,
            calc($pixel * 11) calc($pixel * 0) $runner__skin,
            calc($pixel * 12) calc($pixel * 0) $runner__skin,
            calc($pixel * 9) calc($pixel * -1) $runner__black,
            calc($pixel * 10) calc($pixel * -1) $runner__suit,
            calc($pixel * 11) calc($pixel * -1) $runner__black,
            calc($pixel * 8) calc($pixel * -2) $runner__black,
            calc($pixel * 9) calc($pixel * -2) $runner__suit,
            calc($pixel * 10) calc($pixel * -2) $runner__suit,
            calc($pixel * 11) calc($pixel * -2) $runner__black,
            calc($pixel * 8) calc($pixel * -3) $runner__black,
            calc($pixel * 9) calc($pixel * -3) $runner__black,
            calc($pixel * 10) calc($pixel * -3) $runner__black,
            calc($pixel * 11) calc($pixel * -3) $runner__black,
            calc($pixel * 6) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -4) $runner__black,
            calc($pixel * 8) calc($pixel * -4) $runner__black,
            calc($pixel * 9) calc($pixel * -4) $runner__black,
            calc($pixel * 10) calc($pixel * -4) $runner__black,
            calc($pixel * 11) calc($pixel * -4) $runner__black,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 5) calc($pixel * -5) $runner__skin,
            calc($pixel * 6) calc($pixel * -5) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__black,
            calc($pixel * 9) calc($pixel * -5) $runner__skin,
            calc($pixel * 10) calc($pixel * -5) $runner__skin,
            calc($pixel * 11) calc($pixel * -5) $runner__black,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 14) calc($pixel * -5) $runner__black,
            calc($pixel * 15) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -6) $runner__skin,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__skin,
            calc($pixel * 8) calc($pixel * -6) $runner__skin,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__black,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__suit,
            calc($pixel * 14) calc($pixel * -6) $runner__suit,
            calc($pixel * 15) calc($pixel * -6) $runner__black,
            calc($pixel * 16) calc($pixel * -6) $runner__black,
            calc($pixel * 19) calc($pixel * -6) $runner__black,
            calc($pixel * 20) calc($pixel * -6) $runner__black,
            calc($pixel * 21) calc($pixel * -6) $runner__black,
            calc($pixel * 22) calc($pixel * -6) $runner__black,
            calc($pixel * 23) calc($pixel * -6) $runner__black,
            calc($pixel * 6) calc($pixel * -7) $runner__skin,
            calc($pixel * 7) calc($pixel * -7) $runner__suit,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__black,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__suit,
            calc($pixel * 12) calc($pixel * -7) $runner__suit,
            calc($pixel * 13) calc($pixel * -7) $runner__suit,
            calc($pixel * 14) calc($pixel * -7) $runner__suit,
            calc($pixel * 15) calc($pixel * -7) $runner__black,
            calc($pixel * 16) calc($pixel * -7) $runner__skin,
            calc($pixel * 17) calc($pixel * -7) $runner__black,
            calc($pixel * 18) calc($pixel * -7) $runner__black,
            calc($pixel * 19) calc($pixel * -7) $runner__black,
            calc($pixel * 20) calc($pixel * -7) $runner__suit,
            calc($pixel * 21) calc($pixel * -7) $runner__suit,
            calc($pixel * 22) calc($pixel * -7) $runner__black,
            calc($pixel * 23) calc($pixel * -7) $runner__black,
            calc($pixel * 24) calc($pixel * -7) $runner__suit,
            calc($pixel * 6) calc($pixel * -8) $runner__skin,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__black,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__suit,
            calc($pixel * 12) calc($pixel * -8) $runner__suit,
            calc($pixel * 13) calc($pixel * -8) $runner__suit,
            calc($pixel * 14) calc($pixel * -8) $runner__suit,
            calc($pixel * 15) calc($pixel * -8) $runner__black,
            calc($pixel * 16) calc($pixel * -8) $runner__black,
            calc($pixel * 17) calc($pixel * -8) $runner__black,
            calc($pixel * 18) calc($pixel * -8) $runner__black,
            calc($pixel * 19) calc($pixel * -8) $runner__suit,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__black,
            calc($pixel * 23) calc($pixel * -8) $runner__skin,
            calc($pixel * 24) calc($pixel * -8) $runner__suit,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__suit,
            calc($pixel * 12) calc($pixel * -9) $runner__suit,
            calc($pixel * 13) calc($pixel * -9) $runner__suit,
            calc($pixel * 14) calc($pixel * -9) $runner__suit,
            calc($pixel * 15) calc($pixel * -9) $runner__suit,
            calc($pixel * 16) calc($pixel * -9) $runner__black,
            calc($pixel * 17) calc($pixel * -9) $runner__black,
            calc($pixel * 18) calc($pixel * -9) $runner__black,
            calc($pixel * 19) calc($pixel * -9) $runner__skin,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 21) calc($pixel * -9) $runner__suit,
            calc($pixel * 22) calc($pixel * -9) $runner__suit,
            calc($pixel * 23) calc($pixel * -9) $runner__suit,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__suit,
            calc($pixel * 13) calc($pixel * -10) $runner__suit,
            calc($pixel * 14) calc($pixel * -10) $runner__black,
            calc($pixel * 15) calc($pixel * -10) $runner__black,
            calc($pixel * 16) calc($pixel * -10) $runner__black,
            calc($pixel * 17) calc($pixel * -10) $runner__black,
            calc($pixel * 18) calc($pixel * -10) $runner__black,
            calc($pixel * 19) calc($pixel * -10) $runner__suit,
            calc($pixel * 20) calc($pixel * -10) $runner__suit,
            calc($pixel * 21) calc($pixel * -10) $runner__suit,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__black,
            calc($pixel * 13) calc($pixel * -11) $runner__suit,
            calc($pixel * 14) calc($pixel * -11) $runner__suit,
            calc($pixel * 15) calc($pixel * -11) $runner__black,
            calc($pixel * 16) calc($pixel * -11) $runner__suit,
            calc($pixel * 17) calc($pixel * -11) $runner__suit,
            calc($pixel * 18) calc($pixel * -11) $runner__suit,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__skin,
            calc($pixel * 21) calc($pixel * -11) $runner__skin,
            calc($pixel * 11) calc($pixel * -12) $runner__black,
            calc($pixel * 12) calc($pixel * -12) $runner__black,
            calc($pixel * 13) calc($pixel * -12) $runner__hair,
            calc($pixel * 14) calc($pixel * -12) $runner__hair,
            calc($pixel * 15) calc($pixel * -12) $runner__hair,
            calc($pixel * 16) calc($pixel * -12) $runner__hair,
            calc($pixel * 17) calc($pixel * -12) $runner__hair,
            calc($pixel * 18) calc($pixel * -12) $runner__hair,
            calc($pixel * 19) calc($pixel * -12) $runner__skin,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 22) calc($pixel * -12) $runner__skin,
            calc($pixel * 10) calc($pixel * -13) $runner__black,
            calc($pixel * 11) calc($pixel * -13) $runner__hair,
            calc($pixel * 12) calc($pixel * -13) $runner__hair,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__hair,
            calc($pixel * 15) calc($pixel * -13) $runner__black,
            calc($pixel * 16) calc($pixel * -13) $runner__black,
            calc($pixel * 17) calc($pixel * -13) $runner__suit,
            calc($pixel * 18) calc($pixel * -13) $runner__hair,
            calc($pixel * 19) calc($pixel * -13) $runner__hair,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__eyes,
            calc($pixel * 22) calc($pixel * -13) $runner__skin,
            calc($pixel * 9) calc($pixel * -14) $runner__black,
            calc($pixel * 10) calc($pixel * -14) $runner__hair,
            calc($pixel * 11) calc($pixel * -14) $runner__hair,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__black,
            calc($pixel * 15) calc($pixel * -14) $runner__black,
            calc($pixel * 16) calc($pixel * -14) $runner__black,
            calc($pixel * 17) calc($pixel * -14) $runner__black,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__skin,
            calc($pixel * 21) calc($pixel * -14) $runner__eyes,
            calc($pixel * 22) calc($pixel * -14) $runner__skin,
            calc($pixel * 9) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__black,
            calc($pixel * 15) calc($pixel * -15) $runner__suit,
            calc($pixel * 16) calc($pixel * -15) $runner__suit,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__hair,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__hair,
            calc($pixel * 22) calc($pixel * -15) $runner__hair,
            calc($pixel * 23) calc($pixel * -15) $runner__hair,
            calc($pixel * 9) calc($pixel * -16) $runner__hair,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__suit,
            calc($pixel * 15) calc($pixel * -16) $runner__white,
            calc($pixel * 16) calc($pixel * -16) $runner__suit,
            calc($pixel * 17) calc($pixel * -16) $runner__black,
            calc($pixel * 18) calc($pixel * -16) $runner__hair,
            calc($pixel * 19) calc($pixel * -16) $runner__hair,
            calc($pixel * 20) calc($pixel * -16) $runner__black,
            calc($pixel * 21) calc($pixel * -16) $runner__black,
            calc($pixel * 22) calc($pixel * -16) $runner__black,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 24) calc($pixel * -16) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__suit,
            calc($pixel * 16) calc($pixel * -17) $runner__suit,
            calc($pixel * 17) calc($pixel * -17) $runner__hair,
            calc($pixel * 18) calc($pixel * -17) $runner__hair,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__hair,
            calc($pixel * 21) calc($pixel * -17) $runner__hair,
            calc($pixel * 22) calc($pixel * -17) $runner__hair,
            calc($pixel * 23) calc($pixel * -17) $runner__black,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__hair,
            calc($pixel * 15) calc($pixel * -18) $runner__hair,
            calc($pixel * 16) calc($pixel * -18) $runner__suit,
            calc($pixel * 17) calc($pixel * -18) $runner__hair,
            calc($pixel * 18) calc($pixel * -18) $runner__hair,
            calc($pixel * 19) calc($pixel * -18) $runner__hair,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__hair,
            calc($pixel * 24) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__white,
            calc($pixel * 16) calc($pixel * -19) $runner__suit,
            calc($pixel * 17) calc($pixel * -19) $runner__white,
            calc($pixel * 18) calc($pixel * -19) $runner__white,
            calc($pixel * 19) calc($pixel * -19) $runner__white,
            calc($pixel * 20) calc($pixel * -19) $runner__white,
            calc($pixel * 21) calc($pixel * -19) $runner__white,
            calc($pixel * 22) calc($pixel * -19) $runner__white,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__white,
            calc($pixel * 14) calc($pixel * -20) $runner__white,
            calc($pixel * 15) calc($pixel * -20) $runner__white,
            calc($pixel * 16) calc($pixel * -20) $runner__suit,
            calc($pixel * 17) calc($pixel * -20) $runner__hair,
            calc($pixel * 18) calc($pixel * -20) $runner__hair,
            calc($pixel * 19) calc($pixel * -20) $runner__hair,
            calc($pixel * 20) calc($pixel * -20) $runner__hair,
            calc($pixel * 21) calc($pixel * -20) $runner__hair,
            calc($pixel * 22) calc($pixel * -20) $runner__hair,
            calc($pixel * 23) calc($pixel * -20) $runner__white,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__hair,
            calc($pixel * 15) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -21) $runner__suit,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__suit,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__hair,
            calc($pixel * 19) calc($pixel * -22) $runner__hair,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -23) $runner__suit,
            calc($pixel * 17) calc($pixel * -23) $runner__black,
            calc($pixel * 18) calc($pixel * -23) $runner__black;
        box-shadow: calc($pixel * 9) calc($pixel * 0) $runner__black,
            calc($pixel * 10) calc($pixel * 0) $runner__black,
            calc($pixel * 11) calc($pixel * 0) $runner__skin,
            calc($pixel * 12) calc($pixel * 0) $runner__skin,
            calc($pixel * 9) calc($pixel * -1) $runner__black,
            calc($pixel * 10) calc($pixel * -1) $runner__suit,
            calc($pixel * 11) calc($pixel * -1) $runner__black,
            calc($pixel * 8) calc($pixel * -2) $runner__black,
            calc($pixel * 9) calc($pixel * -2) $runner__suit,
            calc($pixel * 10) calc($pixel * -2) $runner__suit,
            calc($pixel * 11) calc($pixel * -2) $runner__black,
            calc($pixel * 8) calc($pixel * -3) $runner__black,
            calc($pixel * 9) calc($pixel * -3) $runner__black,
            calc($pixel * 10) calc($pixel * -3) $runner__black,
            calc($pixel * 11) calc($pixel * -3) $runner__black,
            calc($pixel * 6) calc($pixel * -4) $runner__black,
            calc($pixel * 7) calc($pixel * -4) $runner__black,
            calc($pixel * 8) calc($pixel * -4) $runner__black,
            calc($pixel * 9) calc($pixel * -4) $runner__black,
            calc($pixel * 10) calc($pixel * -4) $runner__black,
            calc($pixel * 11) calc($pixel * -4) $runner__black,
            calc($pixel * 12) calc($pixel * -4) $runner__black,
            calc($pixel * 5) calc($pixel * -5) $runner__skin,
            calc($pixel * 6) calc($pixel * -5) $runner__black,
            calc($pixel * 7) calc($pixel * -5) $runner__black,
            calc($pixel * 8) calc($pixel * -5) $runner__black,
            calc($pixel * 9) calc($pixel * -5) $runner__skin,
            calc($pixel * 10) calc($pixel * -5) $runner__skin,
            calc($pixel * 11) calc($pixel * -5) $runner__black,
            calc($pixel * 12) calc($pixel * -5) $runner__black,
            calc($pixel * 13) calc($pixel * -5) $runner__black,
            calc($pixel * 14) calc($pixel * -5) $runner__black,
            calc($pixel * 15) calc($pixel * -5) $runner__black,
            calc($pixel * 5) calc($pixel * -6) $runner__skin,
            calc($pixel * 6) calc($pixel * -6) $runner__black,
            calc($pixel * 7) calc($pixel * -6) $runner__skin,
            calc($pixel * 8) calc($pixel * -6) $runner__skin,
            calc($pixel * 9) calc($pixel * -6) $runner__black,
            calc($pixel * 10) calc($pixel * -6) $runner__black,
            calc($pixel * 11) calc($pixel * -6) $runner__black,
            calc($pixel * 12) calc($pixel * -6) $runner__black,
            calc($pixel * 13) calc($pixel * -6) $runner__suit,
            calc($pixel * 14) calc($pixel * -6) $runner__suit,
            calc($pixel * 15) calc($pixel * -6) $runner__black,
            calc($pixel * 16) calc($pixel * -6) $runner__black,
            calc($pixel * 19) calc($pixel * -6) $runner__black,
            calc($pixel * 20) calc($pixel * -6) $runner__black,
            calc($pixel * 21) calc($pixel * -6) $runner__black,
            calc($pixel * 22) calc($pixel * -6) $runner__black,
            calc($pixel * 23) calc($pixel * -6) $runner__black,
            calc($pixel * 6) calc($pixel * -7) $runner__skin,
            calc($pixel * 7) calc($pixel * -7) $runner__suit,
            calc($pixel * 8) calc($pixel * -7) $runner__black,
            calc($pixel * 9) calc($pixel * -7) $runner__black,
            calc($pixel * 10) calc($pixel * -7) $runner__black,
            calc($pixel * 11) calc($pixel * -7) $runner__suit,
            calc($pixel * 12) calc($pixel * -7) $runner__suit,
            calc($pixel * 13) calc($pixel * -7) $runner__suit,
            calc($pixel * 14) calc($pixel * -7) $runner__suit,
            calc($pixel * 15) calc($pixel * -7) $runner__black,
            calc($pixel * 16) calc($pixel * -7) $runner__skin,
            calc($pixel * 17) calc($pixel * -7) $runner__black,
            calc($pixel * 18) calc($pixel * -7) $runner__black,
            calc($pixel * 19) calc($pixel * -7) $runner__black,
            calc($pixel * 20) calc($pixel * -7) $runner__suit,
            calc($pixel * 21) calc($pixel * -7) $runner__suit,
            calc($pixel * 22) calc($pixel * -7) $runner__black,
            calc($pixel * 23) calc($pixel * -7) $runner__black,
            calc($pixel * 24) calc($pixel * -7) $runner__suit,
            calc($pixel * 6) calc($pixel * -8) $runner__skin,
            calc($pixel * 7) calc($pixel * -8) $runner__black,
            calc($pixel * 8) calc($pixel * -8) $runner__black,
            calc($pixel * 9) calc($pixel * -8) $runner__black,
            calc($pixel * 10) calc($pixel * -8) $runner__black,
            calc($pixel * 11) calc($pixel * -8) $runner__suit,
            calc($pixel * 12) calc($pixel * -8) $runner__suit,
            calc($pixel * 13) calc($pixel * -8) $runner__suit,
            calc($pixel * 14) calc($pixel * -8) $runner__suit,
            calc($pixel * 15) calc($pixel * -8) $runner__black,
            calc($pixel * 16) calc($pixel * -8) $runner__black,
            calc($pixel * 17) calc($pixel * -8) $runner__black,
            calc($pixel * 18) calc($pixel * -8) $runner__black,
            calc($pixel * 19) calc($pixel * -8) $runner__suit,
            calc($pixel * 20) calc($pixel * -8) $runner__suit,
            calc($pixel * 21) calc($pixel * -8) $runner__suit,
            calc($pixel * 22) calc($pixel * -8) $runner__black,
            calc($pixel * 23) calc($pixel * -8) $runner__skin,
            calc($pixel * 24) calc($pixel * -8) $runner__suit,
            calc($pixel * 8) calc($pixel * -9) $runner__black,
            calc($pixel * 9) calc($pixel * -9) $runner__black,
            calc($pixel * 10) calc($pixel * -9) $runner__black,
            calc($pixel * 11) calc($pixel * -9) $runner__suit,
            calc($pixel * 12) calc($pixel * -9) $runner__suit,
            calc($pixel * 13) calc($pixel * -9) $runner__suit,
            calc($pixel * 14) calc($pixel * -9) $runner__suit,
            calc($pixel * 15) calc($pixel * -9) $runner__suit,
            calc($pixel * 16) calc($pixel * -9) $runner__black,
            calc($pixel * 17) calc($pixel * -9) $runner__black,
            calc($pixel * 18) calc($pixel * -9) $runner__black,
            calc($pixel * 19) calc($pixel * -9) $runner__skin,
            calc($pixel * 20) calc($pixel * -9) $runner__suit,
            calc($pixel * 21) calc($pixel * -9) $runner__suit,
            calc($pixel * 22) calc($pixel * -9) $runner__suit,
            calc($pixel * 23) calc($pixel * -9) $runner__suit,
            calc($pixel * 9) calc($pixel * -10) $runner__black,
            calc($pixel * 10) calc($pixel * -10) $runner__black,
            calc($pixel * 11) calc($pixel * -10) $runner__black,
            calc($pixel * 12) calc($pixel * -10) $runner__suit,
            calc($pixel * 13) calc($pixel * -10) $runner__suit,
            calc($pixel * 14) calc($pixel * -10) $runner__black,
            calc($pixel * 15) calc($pixel * -10) $runner__black,
            calc($pixel * 16) calc($pixel * -10) $runner__black,
            calc($pixel * 17) calc($pixel * -10) $runner__black,
            calc($pixel * 18) calc($pixel * -10) $runner__black,
            calc($pixel * 19) calc($pixel * -10) $runner__suit,
            calc($pixel * 20) calc($pixel * -10) $runner__suit,
            calc($pixel * 21) calc($pixel * -10) $runner__suit,
            calc($pixel * 10) calc($pixel * -11) $runner__black,
            calc($pixel * 11) calc($pixel * -11) $runner__black,
            calc($pixel * 12) calc($pixel * -11) $runner__black,
            calc($pixel * 13) calc($pixel * -11) $runner__suit,
            calc($pixel * 14) calc($pixel * -11) $runner__suit,
            calc($pixel * 15) calc($pixel * -11) $runner__black,
            calc($pixel * 16) calc($pixel * -11) $runner__suit,
            calc($pixel * 17) calc($pixel * -11) $runner__suit,
            calc($pixel * 18) calc($pixel * -11) $runner__suit,
            calc($pixel * 19) calc($pixel * -11) $runner__suit,
            calc($pixel * 20) calc($pixel * -11) $runner__skin,
            calc($pixel * 21) calc($pixel * -11) $runner__skin,
            calc($pixel * 11) calc($pixel * -12) $runner__black,
            calc($pixel * 12) calc($pixel * -12) $runner__black,
            calc($pixel * 13) calc($pixel * -12) $runner__hair,
            calc($pixel * 14) calc($pixel * -12) $runner__hair,
            calc($pixel * 15) calc($pixel * -12) $runner__hair,
            calc($pixel * 16) calc($pixel * -12) $runner__hair,
            calc($pixel * 17) calc($pixel * -12) $runner__hair,
            calc($pixel * 18) calc($pixel * -12) $runner__hair,
            calc($pixel * 19) calc($pixel * -12) $runner__skin,
            calc($pixel * 20) calc($pixel * -12) $runner__skin,
            calc($pixel * 21) calc($pixel * -12) $runner__skin,
            calc($pixel * 22) calc($pixel * -12) $runner__skin,
            calc($pixel * 10) calc($pixel * -13) $runner__black,
            calc($pixel * 11) calc($pixel * -13) $runner__hair,
            calc($pixel * 12) calc($pixel * -13) $runner__hair,
            calc($pixel * 13) calc($pixel * -13) $runner__hair,
            calc($pixel * 14) calc($pixel * -13) $runner__hair,
            calc($pixel * 15) calc($pixel * -13) $runner__black,
            calc($pixel * 16) calc($pixel * -13) $runner__black,
            calc($pixel * 17) calc($pixel * -13) $runner__suit,
            calc($pixel * 18) calc($pixel * -13) $runner__hair,
            calc($pixel * 19) calc($pixel * -13) $runner__hair,
            calc($pixel * 20) calc($pixel * -13) $runner__skin,
            calc($pixel * 21) calc($pixel * -13) $runner__eyes,
            calc($pixel * 22) calc($pixel * -13) $runner__skin,
            calc($pixel * 9) calc($pixel * -14) $runner__black,
            calc($pixel * 10) calc($pixel * -14) $runner__hair,
            calc($pixel * 11) calc($pixel * -14) $runner__hair,
            calc($pixel * 12) calc($pixel * -14) $runner__hair,
            calc($pixel * 13) calc($pixel * -14) $runner__hair,
            calc($pixel * 14) calc($pixel * -14) $runner__black,
            calc($pixel * 15) calc($pixel * -14) $runner__black,
            calc($pixel * 16) calc($pixel * -14) $runner__black,
            calc($pixel * 17) calc($pixel * -14) $runner__black,
            calc($pixel * 18) calc($pixel * -14) $runner__black,
            calc($pixel * 19) calc($pixel * -14) $runner__black,
            calc($pixel * 20) calc($pixel * -14) $runner__skin,
            calc($pixel * 21) calc($pixel * -14) $runner__eyes,
            calc($pixel * 22) calc($pixel * -14) $runner__skin,
            calc($pixel * 9) calc($pixel * -15) $runner__hair,
            calc($pixel * 10) calc($pixel * -15) $runner__hair,
            calc($pixel * 11) calc($pixel * -15) $runner__hair,
            calc($pixel * 12) calc($pixel * -15) $runner__hair,
            calc($pixel * 13) calc($pixel * -15) $runner__hair,
            calc($pixel * 14) calc($pixel * -15) $runner__black,
            calc($pixel * 15) calc($pixel * -15) $runner__suit,
            calc($pixel * 16) calc($pixel * -15) $runner__suit,
            calc($pixel * 17) calc($pixel * -15) $runner__black,
            calc($pixel * 18) calc($pixel * -15) $runner__hair,
            calc($pixel * 19) calc($pixel * -15) $runner__black,
            calc($pixel * 20) calc($pixel * -15) $runner__black,
            calc($pixel * 21) calc($pixel * -15) $runner__hair,
            calc($pixel * 22) calc($pixel * -15) $runner__hair,
            calc($pixel * 23) calc($pixel * -15) $runner__hair,
            calc($pixel * 9) calc($pixel * -16) $runner__hair,
            calc($pixel * 10) calc($pixel * -16) $runner__hair,
            calc($pixel * 11) calc($pixel * -16) $runner__hair,
            calc($pixel * 12) calc($pixel * -16) $runner__hair,
            calc($pixel * 13) calc($pixel * -16) $runner__hair,
            calc($pixel * 14) calc($pixel * -16) $runner__suit,
            calc($pixel * 15) calc($pixel * -16) $runner__white,
            calc($pixel * 16) calc($pixel * -16) $runner__suit,
            calc($pixel * 17) calc($pixel * -16) $runner__black,
            calc($pixel * 18) calc($pixel * -16) $runner__hair,
            calc($pixel * 19) calc($pixel * -16) $runner__hair,
            calc($pixel * 20) calc($pixel * -16) $runner__black,
            calc($pixel * 21) calc($pixel * -16) $runner__black,
            calc($pixel * 22) calc($pixel * -16) $runner__black,
            calc($pixel * 23) calc($pixel * -16) $runner__hair,
            calc($pixel * 24) calc($pixel * -16) $runner__hair,
            calc($pixel * 10) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -17) $runner__hair,
            calc($pixel * 12) calc($pixel * -17) $runner__hair,
            calc($pixel * 13) calc($pixel * -17) $runner__hair,
            calc($pixel * 14) calc($pixel * -17) $runner__hair,
            calc($pixel * 15) calc($pixel * -17) $runner__suit,
            calc($pixel * 16) calc($pixel * -17) $runner__suit,
            calc($pixel * 17) calc($pixel * -17) $runner__hair,
            calc($pixel * 18) calc($pixel * -17) $runner__hair,
            calc($pixel * 19) calc($pixel * -17) $runner__hair,
            calc($pixel * 20) calc($pixel * -17) $runner__hair,
            calc($pixel * 21) calc($pixel * -17) $runner__hair,
            calc($pixel * 22) calc($pixel * -17) $runner__hair,
            calc($pixel * 23) calc($pixel * -17) $runner__black,
            calc($pixel * 24) calc($pixel * -17) $runner__hair,
            calc($pixel * 11) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -18) $runner__hair,
            calc($pixel * 13) calc($pixel * -18) $runner__hair,
            calc($pixel * 14) calc($pixel * -18) $runner__hair,
            calc($pixel * 15) calc($pixel * -18) $runner__hair,
            calc($pixel * 16) calc($pixel * -18) $runner__suit,
            calc($pixel * 17) calc($pixel * -18) $runner__hair,
            calc($pixel * 18) calc($pixel * -18) $runner__hair,
            calc($pixel * 19) calc($pixel * -18) $runner__hair,
            calc($pixel * 20) calc($pixel * -18) $runner__hair,
            calc($pixel * 21) calc($pixel * -18) $runner__hair,
            calc($pixel * 22) calc($pixel * -18) $runner__hair,
            calc($pixel * 23) calc($pixel * -18) $runner__hair,
            calc($pixel * 24) calc($pixel * -18) $runner__hair,
            calc($pixel * 12) calc($pixel * -19) $runner__hair,
            calc($pixel * 13) calc($pixel * -19) $runner__hair,
            calc($pixel * 14) calc($pixel * -19) $runner__hair,
            calc($pixel * 15) calc($pixel * -19) $runner__white,
            calc($pixel * 16) calc($pixel * -19) $runner__suit,
            calc($pixel * 17) calc($pixel * -19) $runner__white,
            calc($pixel * 18) calc($pixel * -19) $runner__white,
            calc($pixel * 19) calc($pixel * -19) $runner__white,
            calc($pixel * 20) calc($pixel * -19) $runner__white,
            calc($pixel * 21) calc($pixel * -19) $runner__white,
            calc($pixel * 22) calc($pixel * -19) $runner__white,
            calc($pixel * 23) calc($pixel * -19) $runner__hair,
            calc($pixel * 12) calc($pixel * -20) $runner__hair,
            calc($pixel * 13) calc($pixel * -20) $runner__white,
            calc($pixel * 14) calc($pixel * -20) $runner__white,
            calc($pixel * 15) calc($pixel * -20) $runner__white,
            calc($pixel * 16) calc($pixel * -20) $runner__suit,
            calc($pixel * 17) calc($pixel * -20) $runner__hair,
            calc($pixel * 18) calc($pixel * -20) $runner__hair,
            calc($pixel * 19) calc($pixel * -20) $runner__hair,
            calc($pixel * 20) calc($pixel * -20) $runner__hair,
            calc($pixel * 21) calc($pixel * -20) $runner__hair,
            calc($pixel * 22) calc($pixel * -20) $runner__hair,
            calc($pixel * 23) calc($pixel * -20) $runner__white,
            calc($pixel * 13) calc($pixel * -21) $runner__white,
            calc($pixel * 14) calc($pixel * -21) $runner__hair,
            calc($pixel * 15) calc($pixel * -21) $runner__hair,
            calc($pixel * 16) calc($pixel * -21) $runner__suit,
            calc($pixel * 17) calc($pixel * -21) $runner__hair,
            calc($pixel * 18) calc($pixel * -21) $runner__hair,
            calc($pixel * 19) calc($pixel * -21) $runner__hair,
            calc($pixel * 20) calc($pixel * -21) $runner__hair,
            calc($pixel * 21) calc($pixel * -21) $runner__hair,
            calc($pixel * 22) calc($pixel * -21) $runner__hair,
            calc($pixel * 14) calc($pixel * -22) $runner__hair,
            calc($pixel * 15) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -22) $runner__suit,
            calc($pixel * 17) calc($pixel * -22) $runner__hair,
            calc($pixel * 18) calc($pixel * -22) $runner__hair,
            calc($pixel * 19) calc($pixel * -22) $runner__hair,
            calc($pixel * 20) calc($pixel * -22) $runner__hair,
            calc($pixel * 16) calc($pixel * -23) $runner__suit,
            calc($pixel * 17) calc($pixel * -23) $runner__black,
            calc($pixel * 18) calc($pixel * -23) $runner__black;
    }
    .runner__frame8--shadow {
        -webkit-box-shadow: calc($pixel * 9) calc($pixel * 0) $runner__shadow,
            calc($pixel * 10) calc($pixel * 0) $runner__shadow,
            calc($pixel * 11) calc($pixel * 0) $runner__shadow,
            calc($pixel * 12) calc($pixel * 0) $runner__shadow,
            calc($pixel * 9) calc($pixel * 1) $runner__shadow,
            calc($pixel * 10) calc($pixel * 1) $runner__shadow,
            calc($pixel * 11) calc($pixel * 1) $runner__shadow,
            calc($pixel * 8) calc($pixel * 2) $runner__shadow,
            calc($pixel * 9) calc($pixel * 2) $runner__shadow,
            calc($pixel * 10) calc($pixel * 2) $runner__shadow,
            calc($pixel * 11) calc($pixel * 2) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 11) calc($pixel * 3) $runner__shadow,
            calc($pixel * 6) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 4) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 5) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 5) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 14) calc($pixel * 5) $runner__shadow,
            calc($pixel * 15) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 15) calc($pixel * 6) $runner__shadow,
            calc($pixel * 16) calc($pixel * 6) $runner__shadow,
            calc($pixel * 19) calc($pixel * 6) $runner__shadow,
            calc($pixel * 20) calc($pixel * 6) $runner__shadow,
            calc($pixel * 21) calc($pixel * 6) $runner__shadow,
            calc($pixel * 22) calc($pixel * 6) $runner__shadow,
            calc($pixel * 23) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 16) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 19) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 23) calc($pixel * 7) $runner__shadow,
            calc($pixel * 24) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 8) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 23) calc($pixel * 8) $runner__shadow,
            calc($pixel * 24) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 21) calc($pixel * 9) $runner__shadow,
            calc($pixel * 22) calc($pixel * 9) $runner__shadow,
            calc($pixel * 23) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 21) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 22) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 23) calc($pixel * 15) $runner__shadow,
            calc($pixel * 9) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 24) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 23) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow;
        box-shadow: calc($pixel * 9) calc($pixel * 0) $runner__shadow,
            calc($pixel * 10) calc($pixel * 0) $runner__shadow,
            calc($pixel * 11) calc($pixel * 0) $runner__shadow,
            calc($pixel * 12) calc($pixel * 0) $runner__shadow,
            calc($pixel * 9) calc($pixel * 1) $runner__shadow,
            calc($pixel * 10) calc($pixel * 1) $runner__shadow,
            calc($pixel * 11) calc($pixel * 1) $runner__shadow,
            calc($pixel * 8) calc($pixel * 2) $runner__shadow,
            calc($pixel * 9) calc($pixel * 2) $runner__shadow,
            calc($pixel * 10) calc($pixel * 2) $runner__shadow,
            calc($pixel * 11) calc($pixel * 2) $runner__shadow,
            calc($pixel * 8) calc($pixel * 3) $runner__shadow,
            calc($pixel * 9) calc($pixel * 3) $runner__shadow,
            calc($pixel * 10) calc($pixel * 3) $runner__shadow,
            calc($pixel * 11) calc($pixel * 3) $runner__shadow,
            calc($pixel * 6) calc($pixel * 4) $runner__shadow,
            calc($pixel * 7) calc($pixel * 4) $runner__shadow,
            calc($pixel * 8) calc($pixel * 4) $runner__shadow,
            calc($pixel * 9) calc($pixel * 4) $runner__shadow,
            calc($pixel * 10) calc($pixel * 4) $runner__shadow,
            calc($pixel * 11) calc($pixel * 4) $runner__shadow,
            calc($pixel * 12) calc($pixel * 4) $runner__shadow,
            calc($pixel * 5) calc($pixel * 5) $runner__shadow,
            calc($pixel * 6) calc($pixel * 5) $runner__shadow,
            calc($pixel * 7) calc($pixel * 5) $runner__shadow,
            calc($pixel * 8) calc($pixel * 5) $runner__shadow,
            calc($pixel * 9) calc($pixel * 5) $runner__shadow,
            calc($pixel * 10) calc($pixel * 5) $runner__shadow,
            calc($pixel * 11) calc($pixel * 5) $runner__shadow,
            calc($pixel * 12) calc($pixel * 5) $runner__shadow,
            calc($pixel * 13) calc($pixel * 5) $runner__shadow,
            calc($pixel * 14) calc($pixel * 5) $runner__shadow,
            calc($pixel * 15) calc($pixel * 5) $runner__shadow,
            calc($pixel * 5) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 6) $runner__shadow,
            calc($pixel * 7) calc($pixel * 6) $runner__shadow,
            calc($pixel * 8) calc($pixel * 6) $runner__shadow,
            calc($pixel * 9) calc($pixel * 6) $runner__shadow,
            calc($pixel * 10) calc($pixel * 6) $runner__shadow,
            calc($pixel * 11) calc($pixel * 6) $runner__shadow,
            calc($pixel * 12) calc($pixel * 6) $runner__shadow,
            calc($pixel * 13) calc($pixel * 6) $runner__shadow,
            calc($pixel * 14) calc($pixel * 6) $runner__shadow,
            calc($pixel * 15) calc($pixel * 6) $runner__shadow,
            calc($pixel * 16) calc($pixel * 6) $runner__shadow,
            calc($pixel * 19) calc($pixel * 6) $runner__shadow,
            calc($pixel * 20) calc($pixel * 6) $runner__shadow,
            calc($pixel * 21) calc($pixel * 6) $runner__shadow,
            calc($pixel * 22) calc($pixel * 6) $runner__shadow,
            calc($pixel * 23) calc($pixel * 6) $runner__shadow,
            calc($pixel * 6) calc($pixel * 7) $runner__shadow,
            calc($pixel * 7) calc($pixel * 7) $runner__shadow,
            calc($pixel * 8) calc($pixel * 7) $runner__shadow,
            calc($pixel * 9) calc($pixel * 7) $runner__shadow,
            calc($pixel * 10) calc($pixel * 7) $runner__shadow,
            calc($pixel * 11) calc($pixel * 7) $runner__shadow,
            calc($pixel * 12) calc($pixel * 7) $runner__shadow,
            calc($pixel * 13) calc($pixel * 7) $runner__shadow,
            calc($pixel * 14) calc($pixel * 7) $runner__shadow,
            calc($pixel * 15) calc($pixel * 7) $runner__shadow,
            calc($pixel * 16) calc($pixel * 7) $runner__shadow,
            calc($pixel * 17) calc($pixel * 7) $runner__shadow,
            calc($pixel * 18) calc($pixel * 7) $runner__shadow,
            calc($pixel * 19) calc($pixel * 7) $runner__shadow,
            calc($pixel * 20) calc($pixel * 7) $runner__shadow,
            calc($pixel * 21) calc($pixel * 7) $runner__shadow,
            calc($pixel * 22) calc($pixel * 7) $runner__shadow,
            calc($pixel * 23) calc($pixel * 7) $runner__shadow,
            calc($pixel * 24) calc($pixel * 7) $runner__shadow,
            calc($pixel * 6) calc($pixel * 8) $runner__shadow,
            calc($pixel * 7) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 8) $runner__shadow,
            calc($pixel * 9) calc($pixel * 8) $runner__shadow,
            calc($pixel * 10) calc($pixel * 8) $runner__shadow,
            calc($pixel * 11) calc($pixel * 8) $runner__shadow,
            calc($pixel * 12) calc($pixel * 8) $runner__shadow,
            calc($pixel * 13) calc($pixel * 8) $runner__shadow,
            calc($pixel * 14) calc($pixel * 8) $runner__shadow,
            calc($pixel * 15) calc($pixel * 8) $runner__shadow,
            calc($pixel * 16) calc($pixel * 8) $runner__shadow,
            calc($pixel * 17) calc($pixel * 8) $runner__shadow,
            calc($pixel * 18) calc($pixel * 8) $runner__shadow,
            calc($pixel * 19) calc($pixel * 8) $runner__shadow,
            calc($pixel * 20) calc($pixel * 8) $runner__shadow,
            calc($pixel * 21) calc($pixel * 8) $runner__shadow,
            calc($pixel * 22) calc($pixel * 8) $runner__shadow,
            calc($pixel * 23) calc($pixel * 8) $runner__shadow,
            calc($pixel * 24) calc($pixel * 8) $runner__shadow,
            calc($pixel * 8) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 9) $runner__shadow,
            calc($pixel * 10) calc($pixel * 9) $runner__shadow,
            calc($pixel * 11) calc($pixel * 9) $runner__shadow,
            calc($pixel * 12) calc($pixel * 9) $runner__shadow,
            calc($pixel * 13) calc($pixel * 9) $runner__shadow,
            calc($pixel * 14) calc($pixel * 9) $runner__shadow,
            calc($pixel * 15) calc($pixel * 9) $runner__shadow,
            calc($pixel * 16) calc($pixel * 9) $runner__shadow,
            calc($pixel * 17) calc($pixel * 9) $runner__shadow,
            calc($pixel * 18) calc($pixel * 9) $runner__shadow,
            calc($pixel * 19) calc($pixel * 9) $runner__shadow,
            calc($pixel * 20) calc($pixel * 9) $runner__shadow,
            calc($pixel * 21) calc($pixel * 9) $runner__shadow,
            calc($pixel * 22) calc($pixel * 9) $runner__shadow,
            calc($pixel * 23) calc($pixel * 9) $runner__shadow,
            calc($pixel * 9) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 10) $runner__shadow,
            calc($pixel * 11) calc($pixel * 10) $runner__shadow,
            calc($pixel * 12) calc($pixel * 10) $runner__shadow,
            calc($pixel * 13) calc($pixel * 10) $runner__shadow,
            calc($pixel * 14) calc($pixel * 10) $runner__shadow,
            calc($pixel * 15) calc($pixel * 10) $runner__shadow,
            calc($pixel * 16) calc($pixel * 10) $runner__shadow,
            calc($pixel * 17) calc($pixel * 10) $runner__shadow,
            calc($pixel * 18) calc($pixel * 10) $runner__shadow,
            calc($pixel * 19) calc($pixel * 10) $runner__shadow,
            calc($pixel * 20) calc($pixel * 10) $runner__shadow,
            calc($pixel * 21) calc($pixel * 10) $runner__shadow,
            calc($pixel * 10) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 11) $runner__shadow,
            calc($pixel * 12) calc($pixel * 11) $runner__shadow,
            calc($pixel * 13) calc($pixel * 11) $runner__shadow,
            calc($pixel * 14) calc($pixel * 11) $runner__shadow,
            calc($pixel * 15) calc($pixel * 11) $runner__shadow,
            calc($pixel * 16) calc($pixel * 11) $runner__shadow,
            calc($pixel * 17) calc($pixel * 11) $runner__shadow,
            calc($pixel * 18) calc($pixel * 11) $runner__shadow,
            calc($pixel * 19) calc($pixel * 11) $runner__shadow,
            calc($pixel * 20) calc($pixel * 11) $runner__shadow,
            calc($pixel * 21) calc($pixel * 11) $runner__shadow,
            calc($pixel * 11) calc($pixel * 12) $runner__shadow,
            calc($pixel * 12) calc($pixel * 12) $runner__shadow,
            calc($pixel * 13) calc($pixel * 12) $runner__shadow,
            calc($pixel * 14) calc($pixel * 12) $runner__shadow,
            calc($pixel * 15) calc($pixel * 12) $runner__shadow,
            calc($pixel * 16) calc($pixel * 12) $runner__shadow,
            calc($pixel * 17) calc($pixel * 12) $runner__shadow,
            calc($pixel * 18) calc($pixel * 12) $runner__shadow,
            calc($pixel * 19) calc($pixel * 12) $runner__shadow,
            calc($pixel * 20) calc($pixel * 12) $runner__shadow,
            calc($pixel * 21) calc($pixel * 12) $runner__shadow,
            calc($pixel * 22) calc($pixel * 12) $runner__shadow,
            calc($pixel * 10) calc($pixel * 13) $runner__shadow,
            calc($pixel * 11) calc($pixel * 13) $runner__shadow,
            calc($pixel * 12) calc($pixel * 13) $runner__shadow,
            calc($pixel * 13) calc($pixel * 13) $runner__shadow,
            calc($pixel * 14) calc($pixel * 13) $runner__shadow,
            calc($pixel * 15) calc($pixel * 13) $runner__shadow,
            calc($pixel * 16) calc($pixel * 13) $runner__shadow,
            calc($pixel * 17) calc($pixel * 13) $runner__shadow,
            calc($pixel * 18) calc($pixel * 13) $runner__shadow,
            calc($pixel * 19) calc($pixel * 13) $runner__shadow,
            calc($pixel * 20) calc($pixel * 13) $runner__shadow,
            calc($pixel * 21) calc($pixel * 13) $runner__shadow,
            calc($pixel * 22) calc($pixel * 13) $runner__shadow,
            calc($pixel * 9) calc($pixel * 14) $runner__shadow,
            calc($pixel * 10) calc($pixel * 14) $runner__shadow,
            calc($pixel * 11) calc($pixel * 14) $runner__shadow,
            calc($pixel * 12) calc($pixel * 14) $runner__shadow,
            calc($pixel * 13) calc($pixel * 14) $runner__shadow,
            calc($pixel * 14) calc($pixel * 14) $runner__shadow,
            calc($pixel * 15) calc($pixel * 14) $runner__shadow,
            calc($pixel * 16) calc($pixel * 14) $runner__shadow,
            calc($pixel * 17) calc($pixel * 14) $runner__shadow,
            calc($pixel * 18) calc($pixel * 14) $runner__shadow,
            calc($pixel * 19) calc($pixel * 14) $runner__shadow,
            calc($pixel * 20) calc($pixel * 14) $runner__shadow,
            calc($pixel * 21) calc($pixel * 14) $runner__shadow,
            calc($pixel * 22) calc($pixel * 14) $runner__shadow,
            calc($pixel * 9) calc($pixel * 15) $runner__shadow,
            calc($pixel * 10) calc($pixel * 15) $runner__shadow,
            calc($pixel * 11) calc($pixel * 15) $runner__shadow,
            calc($pixel * 12) calc($pixel * 15) $runner__shadow,
            calc($pixel * 13) calc($pixel * 15) $runner__shadow,
            calc($pixel * 14) calc($pixel * 15) $runner__shadow,
            calc($pixel * 15) calc($pixel * 15) $runner__shadow,
            calc($pixel * 16) calc($pixel * 15) $runner__shadow,
            calc($pixel * 17) calc($pixel * 15) $runner__shadow,
            calc($pixel * 18) calc($pixel * 15) $runner__shadow,
            calc($pixel * 19) calc($pixel * 15) $runner__shadow,
            calc($pixel * 20) calc($pixel * 15) $runner__shadow,
            calc($pixel * 21) calc($pixel * 15) $runner__shadow,
            calc($pixel * 22) calc($pixel * 15) $runner__shadow,
            calc($pixel * 23) calc($pixel * 15) $runner__shadow,
            calc($pixel * 9) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 16) $runner__shadow,
            calc($pixel * 11) calc($pixel * 16) $runner__shadow,
            calc($pixel * 12) calc($pixel * 16) $runner__shadow,
            calc($pixel * 13) calc($pixel * 16) $runner__shadow,
            calc($pixel * 14) calc($pixel * 16) $runner__shadow,
            calc($pixel * 15) calc($pixel * 16) $runner__shadow,
            calc($pixel * 16) calc($pixel * 16) $runner__shadow,
            calc($pixel * 17) calc($pixel * 16) $runner__shadow,
            calc($pixel * 18) calc($pixel * 16) $runner__shadow,
            calc($pixel * 19) calc($pixel * 16) $runner__shadow,
            calc($pixel * 20) calc($pixel * 16) $runner__shadow,
            calc($pixel * 21) calc($pixel * 16) $runner__shadow,
            calc($pixel * 22) calc($pixel * 16) $runner__shadow,
            calc($pixel * 23) calc($pixel * 16) $runner__shadow,
            calc($pixel * 24) calc($pixel * 16) $runner__shadow,
            calc($pixel * 10) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 17) $runner__shadow,
            calc($pixel * 12) calc($pixel * 17) $runner__shadow,
            calc($pixel * 13) calc($pixel * 17) $runner__shadow,
            calc($pixel * 14) calc($pixel * 17) $runner__shadow,
            calc($pixel * 15) calc($pixel * 17) $runner__shadow,
            calc($pixel * 16) calc($pixel * 17) $runner__shadow,
            calc($pixel * 17) calc($pixel * 17) $runner__shadow,
            calc($pixel * 18) calc($pixel * 17) $runner__shadow,
            calc($pixel * 19) calc($pixel * 17) $runner__shadow,
            calc($pixel * 20) calc($pixel * 17) $runner__shadow,
            calc($pixel * 21) calc($pixel * 17) $runner__shadow,
            calc($pixel * 22) calc($pixel * 17) $runner__shadow,
            calc($pixel * 23) calc($pixel * 17) $runner__shadow,
            calc($pixel * 24) calc($pixel * 17) $runner__shadow,
            calc($pixel * 11) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 18) $runner__shadow,
            calc($pixel * 13) calc($pixel * 18) $runner__shadow,
            calc($pixel * 14) calc($pixel * 18) $runner__shadow,
            calc($pixel * 15) calc($pixel * 18) $runner__shadow,
            calc($pixel * 16) calc($pixel * 18) $runner__shadow,
            calc($pixel * 17) calc($pixel * 18) $runner__shadow,
            calc($pixel * 18) calc($pixel * 18) $runner__shadow,
            calc($pixel * 19) calc($pixel * 18) $runner__shadow,
            calc($pixel * 20) calc($pixel * 18) $runner__shadow,
            calc($pixel * 21) calc($pixel * 18) $runner__shadow,
            calc($pixel * 22) calc($pixel * 18) $runner__shadow,
            calc($pixel * 23) calc($pixel * 18) $runner__shadow,
            calc($pixel * 24) calc($pixel * 18) $runner__shadow,
            calc($pixel * 12) calc($pixel * 19) $runner__shadow,
            calc($pixel * 13) calc($pixel * 19) $runner__shadow,
            calc($pixel * 14) calc($pixel * 19) $runner__shadow,
            calc($pixel * 15) calc($pixel * 19) $runner__shadow,
            calc($pixel * 16) calc($pixel * 19) $runner__shadow,
            calc($pixel * 17) calc($pixel * 19) $runner__shadow,
            calc($pixel * 18) calc($pixel * 19) $runner__shadow,
            calc($pixel * 19) calc($pixel * 19) $runner__shadow,
            calc($pixel * 20) calc($pixel * 19) $runner__shadow,
            calc($pixel * 21) calc($pixel * 19) $runner__shadow,
            calc($pixel * 22) calc($pixel * 19) $runner__shadow,
            calc($pixel * 23) calc($pixel * 19) $runner__shadow,
            calc($pixel * 12) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 20) $runner__shadow,
            calc($pixel * 14) calc($pixel * 20) $runner__shadow,
            calc($pixel * 15) calc($pixel * 20) $runner__shadow,
            calc($pixel * 16) calc($pixel * 20) $runner__shadow,
            calc($pixel * 17) calc($pixel * 20) $runner__shadow,
            calc($pixel * 18) calc($pixel * 20) $runner__shadow,
            calc($pixel * 19) calc($pixel * 20) $runner__shadow,
            calc($pixel * 20) calc($pixel * 20) $runner__shadow,
            calc($pixel * 21) calc($pixel * 20) $runner__shadow,
            calc($pixel * 22) calc($pixel * 20) $runner__shadow,
            calc($pixel * 23) calc($pixel * 20) $runner__shadow,
            calc($pixel * 13) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 21) $runner__shadow,
            calc($pixel * 15) calc($pixel * 21) $runner__shadow,
            calc($pixel * 16) calc($pixel * 21) $runner__shadow,
            calc($pixel * 17) calc($pixel * 21) $runner__shadow,
            calc($pixel * 18) calc($pixel * 21) $runner__shadow,
            calc($pixel * 19) calc($pixel * 21) $runner__shadow,
            calc($pixel * 20) calc($pixel * 21) $runner__shadow,
            calc($pixel * 21) calc($pixel * 21) $runner__shadow,
            calc($pixel * 22) calc($pixel * 21) $runner__shadow,
            calc($pixel * 14) calc($pixel * 22) $runner__shadow,
            calc($pixel * 15) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 22) $runner__shadow,
            calc($pixel * 17) calc($pixel * 22) $runner__shadow,
            calc($pixel * 18) calc($pixel * 22) $runner__shadow,
            calc($pixel * 19) calc($pixel * 22) $runner__shadow,
            calc($pixel * 20) calc($pixel * 22) $runner__shadow,
            calc($pixel * 16) calc($pixel * 23) $runner__shadow,
            calc($pixel * 17) calc($pixel * 23) $runner__shadow,
            calc($pixel * 18) calc($pixel * 23) $runner__shadow;
    }
}

.loader.small {
    width: 150px;
    height: 150px;

    .runner__frame1,
    .runner__frame2,
    .runner__frame3,
    .runner__frame4,
    .runner__frame5,
    .runner__frame6,
    .runner__frame7,
    .runner__frame8,
    .runner__frame1--shadow,
    .runner__frame2--shadow,
    .runner__frame3--shadow,
    .runner__frame4--shadow,
    .runner__frame5--shadow,
    .runner__frame6--shadow,
    .runner__frame7--shadow,
    .runner__frame8--shadow {
        width: 3px;
        height: 3px;
    }

    .runner__frame1 {
        -webkit-box-shadow: calc(3px * 1) calc(3px * 0) $runner__black,
            calc(3px * 2) calc(3px * 0) $runner__skin,
            calc(3px * 3) calc(3px * 0) $runner__skin,
            calc(3px * 1) calc(3px * -1) $runner__black,
            calc(3px * 2) calc(3px * -1) $runner__suit,
            calc(3px * 3) calc(3px * -1) $runner__black,
            calc(3px * 1) calc(3px * -2) $runner__black,
            calc(3px * 2) calc(3px * -2) $runner__suit,
            calc(3px * 3) calc(3px * -2) $runner__suit,
            calc(3px * 4) calc(3px * -2) $runner__black,
            calc(3px * 5) calc(3px * -2) $runner__black,
            calc(3px * 7) calc(3px * -2) $runner__skin,
            calc(3px * 2) calc(3px * -3) $runner__black,
            calc(3px * 3) calc(3px * -3) $runner__suit,
            calc(3px * 4) calc(3px * -3) $runner__suit,
            calc(3px * 5) calc(3px * -3) $runner__black,
            calc(3px * 6) calc(3px * -3) $runner__black,
            calc(3px * 7) calc(3px * -3) $runner__black,
            calc(3px * 8) calc(3px * -3) $runner__black,
            calc(3px * 9) calc(3px * -3) $runner__black,
            calc(3px * 10) calc(3px * -3) $runner__black,
            calc(3px * 11) calc(3px * -3) $runner__black,
            calc(3px * 3) calc(3px * -4) $runner__black,
            calc(3px * 4) calc(3px * -4) $runner__suit,
            calc(3px * 5) calc(3px * -4) $runner__black,
            calc(3px * 6) calc(3px * -4) $runner__skin,
            calc(3px * 7) calc(3px * -4) $runner__skin,
            calc(3px * 8) calc(3px * -4) $runner__skin,
            calc(3px * 9) calc(3px * -4) $runner__skin,
            calc(3px * 10) calc(3px * -4) $runner__skin,
            calc(3px * 11) calc(3px * -4) $runner__black,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 13) calc(3px * -4) $runner__black,
            calc(3px * 4) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -5) $runner__skin,
            calc(3px * 6) calc(3px * -5) $runner__skin,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__black,
            calc(3px * 9) calc(3px * -5) $runner__black,
            calc(3px * 10) calc(3px * -5) $runner__black,
            calc(3px * 11) calc(3px * -5) $runner__black,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 14) calc(3px * -5) $runner__black,
            calc(3px * 15) calc(3px * -5) $runner__black,
            calc(3px * 16) calc(3px * -5) $runner__black,
            calc(3px * 17) calc(3px * -5) $runner__black,
            calc(3px * 18) calc(3px * -5) $runner__black,
            calc(3px * 19) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -6) $runner__black,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__black,
            calc(3px * 8) calc(3px * -6) $runner__black,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__black,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__suit,
            calc(3px * 13) calc(3px * -6) $runner__suit,
            calc(3px * 14) calc(3px * -6) $runner__suit,
            calc(3px * 15) calc(3px * -6) $runner__suit,
            calc(3px * 16) calc(3px * -6) $runner__black,
            calc(3px * 17) calc(3px * -6) $runner__skin,
            calc(3px * 18) calc(3px * -6) $runner__black,
            calc(3px * 19) calc(3px * -6) $runner__suit,
            calc(3px * 6) calc(3px * -7) $runner__black,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__black,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__suit,
            calc(3px * 12) calc(3px * -7) $runner__suit,
            calc(3px * 13) calc(3px * -7) $runner__suit,
            calc(3px * 14) calc(3px * -7) $runner__suit,
            calc(3px * 15) calc(3px * -7) $runner__suit,
            calc(3px * 16) calc(3px * -7) $runner__black,
            calc(3px * 17) calc(3px * -7) $runner__black,
            calc(3px * 18) calc(3px * -7) $runner__black,
            calc(3px * 19) calc(3px * -7) $runner__suit,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__black,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__suit,
            calc(3px * 12) calc(3px * -8) $runner__suit,
            calc(3px * 13) calc(3px * -8) $runner__black,
            calc(3px * 14) calc(3px * -8) $runner__suit,
            calc(3px * 15) calc(3px * -8) $runner__suit,
            calc(3px * 16) calc(3px * -8) $runner__suit,
            calc(3px * 17) calc(3px * -8) $runner__black,
            calc(3px * 18) calc(3px * -8) $runner__skin,
            calc(3px * 19) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__suit,
            calc(3px * 12) calc(3px * -9) $runner__suit,
            calc(3px * 13) calc(3px * -9) $runner__suit,
            calc(3px * 14) calc(3px * -9) $runner__black,
            calc(3px * 15) calc(3px * -9) $runner__black,
            calc(3px * 16) calc(3px * -9) $runner__black,
            calc(3px * 17) calc(3px * -9) $runner__black,
            calc(3px * 18) calc(3px * -9) $runner__suit,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__suit,
            calc(3px * 13) calc(3px * -10) $runner__suit,
            calc(3px * 14) calc(3px * -10) $runner__suit,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__suit,
            calc(3px * 17) calc(3px * -10) $runner__suit,
            calc(3px * 18) calc(3px * -10) $runner__suit,
            calc(3px * 19) calc(3px * -10) $runner__skin,
            calc(3px * 20) calc(3px * -10) $runner__skin,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__hair,
            calc(3px * 13) calc(3px * -11) $runner__hair,
            calc(3px * 14) calc(3px * -11) $runner__hair,
            calc(3px * 15) calc(3px * -11) $runner__hair,
            calc(3px * 16) calc(3px * -11) $runner__hair,
            calc(3px * 17) calc(3px * -11) $runner__hair,
            calc(3px * 18) calc(3px * -11) $runner__skin,
            calc(3px * 19) calc(3px * -11) $runner__skin,
            calc(3px * 20) calc(3px * -11) $runner__skin,
            calc(3px * 21) calc(3px * -11) $runner__skin,
            calc(3px * 9) calc(3px * -12) $runner__black,
            calc(3px * 10) calc(3px * -12) $runner__hair,
            calc(3px * 11) calc(3px * -12) $runner__hair,
            calc(3px * 12) calc(3px * -12) $runner__hair,
            calc(3px * 13) calc(3px * -12) $runner__hair,
            calc(3px * 14) calc(3px * -12) $runner__hair,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__black,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__hair,
            calc(3px * 19) calc(3px * -12) $runner__skin,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__eyes,
            calc(3px * 8) calc(3px * -13) $runner__black,
            calc(3px * 9) calc(3px * -13) $runner__hair,
            calc(3px * 10) calc(3px * -13) $runner__hair,
            calc(3px * 11) calc(3px * -13) $runner__hair,
            calc(3px * 12) calc(3px * -13) $runner__hair,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__black,
            calc(3px * 15) calc(3px * -13) $runner__black,
            calc(3px * 16) calc(3px * -13) $runner__black,
            calc(3px * 17) calc(3px * -13) $runner__black,
            calc(3px * 18) calc(3px * -13) $runner__black,
            calc(3px * 19) calc(3px * -13) $runner__skin,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__eyes,
            calc(3px * 8) calc(3px * -14) $runner__hair,
            calc(3px * 9) calc(3px * -14) $runner__hair,
            calc(3px * 10) calc(3px * -14) $runner__hair,
            calc(3px * 11) calc(3px * -14) $runner__hair,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__black,
            calc(3px * 15) calc(3px * -14) $runner__suit,
            calc(3px * 16) calc(3px * -14) $runner__suit,
            calc(3px * 17) calc(3px * -14) $runner__black,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__hair,
            calc(3px * 21) calc(3px * -14) $runner__hair,
            calc(3px * 22) calc(3px * -14) $runner__hair,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__black,
            calc(3px * 15) calc(3px * -15) $runner__white,
            calc(3px * 16) calc(3px * -15) $runner__suit,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__hair,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__black,
            calc(3px * 22) calc(3px * -15) $runner__hair,
            calc(3px * 23) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__black,
            calc(3px * 15) calc(3px * -16) $runner__black,
            calc(3px * 16) calc(3px * -16) $runner__suit,
            calc(3px * 17) calc(3px * -16) $runner__hair,
            calc(3px * 18) calc(3px * -16) $runner__hair,
            calc(3px * 19) calc(3px * -16) $runner__hair,
            calc(3px * 20) calc(3px * -16) $runner__hair,
            calc(3px * 21) calc(3px * -16) $runner__hair,
            calc(3px * 22) calc(3px * -16) $runner__black,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__hair,
            calc(3px * 16) calc(3px * -17) $runner__suit,
            calc(3px * 17) calc(3px * -17) $runner__hair,
            calc(3px * 18) calc(3px * -17) $runner__hair,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__hair,
            calc(3px * 21) calc(3px * -17) $runner__hair,
            calc(3px * 22) calc(3px * -17) $runner__hair,
            calc(3px * 23) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__white,
            calc(3px * 15) calc(3px * -18) $runner__white,
            calc(3px * 16) calc(3px * -18) $runner__suit,
            calc(3px * 17) calc(3px * -18) $runner__white,
            calc(3px * 18) calc(3px * -18) $runner__white,
            calc(3px * 19) calc(3px * -18) $runner__white,
            calc(3px * 20) calc(3px * -18) $runner__white,
            calc(3px * 21) calc(3px * -18) $runner__white,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__white,
            calc(3px * 13) calc(3px * -19) $runner__white,
            calc(3px * 14) calc(3px * -19) $runner__white,
            calc(3px * 15) calc(3px * -19) $runner__hair,
            calc(3px * 16) calc(3px * -19) $runner__suit,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__hair,
            calc(3px * 19) calc(3px * -19) $runner__hair,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__white,
            calc(3px * 12) calc(3px * -20) $runner__white,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__hair,
            calc(3px * 15) calc(3px * -20) $runner__hair,
            calc(3px * 16) calc(3px * -20) $runner__suit,
            calc(3px * 17) calc(3px * -20) $runner__hair,
            calc(3px * 18) calc(3px * -20) $runner__hair,
            calc(3px * 19) calc(3px * -20) $runner__hair,
            calc(3px * 20) calc(3px * -20) $runner__hair,
            calc(3px * 21) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__hair,
            calc(3px * 14) calc(3px * -21) $runner__hair,
            calc(3px * 15) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -21) $runner__suit,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__suit,
            calc(3px * 17) calc(3px * -22) $runner__black;
        box-shadow: calc(3px * 1) calc(3px * 0) $runner__black,
            calc(3px * 2) calc(3px * 0) $runner__skin,
            calc(3px * 3) calc(3px * 0) $runner__skin,
            calc(3px * 1) calc(3px * -1) $runner__black,
            calc(3px * 2) calc(3px * -1) $runner__suit,
            calc(3px * 3) calc(3px * -1) $runner__black,
            calc(3px * 1) calc(3px * -2) $runner__black,
            calc(3px * 2) calc(3px * -2) $runner__suit,
            calc(3px * 3) calc(3px * -2) $runner__suit,
            calc(3px * 4) calc(3px * -2) $runner__black,
            calc(3px * 5) calc(3px * -2) $runner__black,
            calc(3px * 7) calc(3px * -2) $runner__skin,
            calc(3px * 2) calc(3px * -3) $runner__black,
            calc(3px * 3) calc(3px * -3) $runner__suit,
            calc(3px * 4) calc(3px * -3) $runner__suit,
            calc(3px * 5) calc(3px * -3) $runner__black,
            calc(3px * 6) calc(3px * -3) $runner__black,
            calc(3px * 7) calc(3px * -3) $runner__black,
            calc(3px * 8) calc(3px * -3) $runner__black,
            calc(3px * 9) calc(3px * -3) $runner__black,
            calc(3px * 10) calc(3px * -3) $runner__black,
            calc(3px * 11) calc(3px * -3) $runner__black,
            calc(3px * 3) calc(3px * -4) $runner__black,
            calc(3px * 4) calc(3px * -4) $runner__suit,
            calc(3px * 5) calc(3px * -4) $runner__black,
            calc(3px * 6) calc(3px * -4) $runner__skin,
            calc(3px * 7) calc(3px * -4) $runner__skin,
            calc(3px * 8) calc(3px * -4) $runner__skin,
            calc(3px * 9) calc(3px * -4) $runner__skin,
            calc(3px * 10) calc(3px * -4) $runner__skin,
            calc(3px * 11) calc(3px * -4) $runner__black,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 13) calc(3px * -4) $runner__black,
            calc(3px * 4) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -5) $runner__skin,
            calc(3px * 6) calc(3px * -5) $runner__skin,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__black,
            calc(3px * 9) calc(3px * -5) $runner__black,
            calc(3px * 10) calc(3px * -5) $runner__black,
            calc(3px * 11) calc(3px * -5) $runner__black,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 14) calc(3px * -5) $runner__black,
            calc(3px * 15) calc(3px * -5) $runner__black,
            calc(3px * 16) calc(3px * -5) $runner__black,
            calc(3px * 17) calc(3px * -5) $runner__black,
            calc(3px * 18) calc(3px * -5) $runner__black,
            calc(3px * 19) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -6) $runner__black,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__black,
            calc(3px * 8) calc(3px * -6) $runner__black,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__black,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__suit,
            calc(3px * 13) calc(3px * -6) $runner__suit,
            calc(3px * 14) calc(3px * -6) $runner__suit,
            calc(3px * 15) calc(3px * -6) $runner__suit,
            calc(3px * 16) calc(3px * -6) $runner__black,
            calc(3px * 17) calc(3px * -6) $runner__skin,
            calc(3px * 18) calc(3px * -6) $runner__black,
            calc(3px * 19) calc(3px * -6) $runner__suit,
            calc(3px * 6) calc(3px * -7) $runner__black,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__black,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__suit,
            calc(3px * 12) calc(3px * -7) $runner__suit,
            calc(3px * 13) calc(3px * -7) $runner__suit,
            calc(3px * 14) calc(3px * -7) $runner__suit,
            calc(3px * 15) calc(3px * -7) $runner__suit,
            calc(3px * 16) calc(3px * -7) $runner__black,
            calc(3px * 17) calc(3px * -7) $runner__black,
            calc(3px * 18) calc(3px * -7) $runner__black,
            calc(3px * 19) calc(3px * -7) $runner__suit,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__black,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__suit,
            calc(3px * 12) calc(3px * -8) $runner__suit,
            calc(3px * 13) calc(3px * -8) $runner__black,
            calc(3px * 14) calc(3px * -8) $runner__suit,
            calc(3px * 15) calc(3px * -8) $runner__suit,
            calc(3px * 16) calc(3px * -8) $runner__suit,
            calc(3px * 17) calc(3px * -8) $runner__black,
            calc(3px * 18) calc(3px * -8) $runner__skin,
            calc(3px * 19) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__suit,
            calc(3px * 12) calc(3px * -9) $runner__suit,
            calc(3px * 13) calc(3px * -9) $runner__suit,
            calc(3px * 14) calc(3px * -9) $runner__black,
            calc(3px * 15) calc(3px * -9) $runner__black,
            calc(3px * 16) calc(3px * -9) $runner__black,
            calc(3px * 17) calc(3px * -9) $runner__black,
            calc(3px * 18) calc(3px * -9) $runner__suit,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__suit,
            calc(3px * 13) calc(3px * -10) $runner__suit,
            calc(3px * 14) calc(3px * -10) $runner__suit,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__suit,
            calc(3px * 17) calc(3px * -10) $runner__suit,
            calc(3px * 18) calc(3px * -10) $runner__suit,
            calc(3px * 19) calc(3px * -10) $runner__skin,
            calc(3px * 20) calc(3px * -10) $runner__skin,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__hair,
            calc(3px * 13) calc(3px * -11) $runner__hair,
            calc(3px * 14) calc(3px * -11) $runner__hair,
            calc(3px * 15) calc(3px * -11) $runner__hair,
            calc(3px * 16) calc(3px * -11) $runner__hair,
            calc(3px * 17) calc(3px * -11) $runner__hair,
            calc(3px * 18) calc(3px * -11) $runner__skin,
            calc(3px * 19) calc(3px * -11) $runner__skin,
            calc(3px * 20) calc(3px * -11) $runner__skin,
            calc(3px * 21) calc(3px * -11) $runner__skin,
            calc(3px * 9) calc(3px * -12) $runner__black,
            calc(3px * 10) calc(3px * -12) $runner__hair,
            calc(3px * 11) calc(3px * -12) $runner__hair,
            calc(3px * 12) calc(3px * -12) $runner__hair,
            calc(3px * 13) calc(3px * -12) $runner__hair,
            calc(3px * 14) calc(3px * -12) $runner__hair,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__black,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__hair,
            calc(3px * 19) calc(3px * -12) $runner__skin,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__eyes,
            calc(3px * 8) calc(3px * -13) $runner__black,
            calc(3px * 9) calc(3px * -13) $runner__hair,
            calc(3px * 10) calc(3px * -13) $runner__hair,
            calc(3px * 11) calc(3px * -13) $runner__hair,
            calc(3px * 12) calc(3px * -13) $runner__hair,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__black,
            calc(3px * 15) calc(3px * -13) $runner__black,
            calc(3px * 16) calc(3px * -13) $runner__black,
            calc(3px * 17) calc(3px * -13) $runner__black,
            calc(3px * 18) calc(3px * -13) $runner__black,
            calc(3px * 19) calc(3px * -13) $runner__skin,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__eyes,
            calc(3px * 8) calc(3px * -14) $runner__hair,
            calc(3px * 9) calc(3px * -14) $runner__hair,
            calc(3px * 10) calc(3px * -14) $runner__hair,
            calc(3px * 11) calc(3px * -14) $runner__hair,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__black,
            calc(3px * 15) calc(3px * -14) $runner__suit,
            calc(3px * 16) calc(3px * -14) $runner__suit,
            calc(3px * 17) calc(3px * -14) $runner__black,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__hair,
            calc(3px * 21) calc(3px * -14) $runner__hair,
            calc(3px * 22) calc(3px * -14) $runner__hair,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__black,
            calc(3px * 15) calc(3px * -15) $runner__white,
            calc(3px * 16) calc(3px * -15) $runner__suit,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__hair,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__black,
            calc(3px * 22) calc(3px * -15) $runner__hair,
            calc(3px * 23) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__black,
            calc(3px * 15) calc(3px * -16) $runner__black,
            calc(3px * 16) calc(3px * -16) $runner__suit,
            calc(3px * 17) calc(3px * -16) $runner__hair,
            calc(3px * 18) calc(3px * -16) $runner__hair,
            calc(3px * 19) calc(3px * -16) $runner__hair,
            calc(3px * 20) calc(3px * -16) $runner__hair,
            calc(3px * 21) calc(3px * -16) $runner__hair,
            calc(3px * 22) calc(3px * -16) $runner__black,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__hair,
            calc(3px * 16) calc(3px * -17) $runner__suit,
            calc(3px * 17) calc(3px * -17) $runner__hair,
            calc(3px * 18) calc(3px * -17) $runner__hair,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__hair,
            calc(3px * 21) calc(3px * -17) $runner__hair,
            calc(3px * 22) calc(3px * -17) $runner__hair,
            calc(3px * 23) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__white,
            calc(3px * 15) calc(3px * -18) $runner__white,
            calc(3px * 16) calc(3px * -18) $runner__suit,
            calc(3px * 17) calc(3px * -18) $runner__white,
            calc(3px * 18) calc(3px * -18) $runner__white,
            calc(3px * 19) calc(3px * -18) $runner__white,
            calc(3px * 20) calc(3px * -18) $runner__white,
            calc(3px * 21) calc(3px * -18) $runner__white,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__white,
            calc(3px * 13) calc(3px * -19) $runner__white,
            calc(3px * 14) calc(3px * -19) $runner__white,
            calc(3px * 15) calc(3px * -19) $runner__hair,
            calc(3px * 16) calc(3px * -19) $runner__suit,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__hair,
            calc(3px * 19) calc(3px * -19) $runner__hair,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__white,
            calc(3px * 12) calc(3px * -20) $runner__white,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__hair,
            calc(3px * 15) calc(3px * -20) $runner__hair,
            calc(3px * 16) calc(3px * -20) $runner__suit,
            calc(3px * 17) calc(3px * -20) $runner__hair,
            calc(3px * 18) calc(3px * -20) $runner__hair,
            calc(3px * 19) calc(3px * -20) $runner__hair,
            calc(3px * 20) calc(3px * -20) $runner__hair,
            calc(3px * 21) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__hair,
            calc(3px * 14) calc(3px * -21) $runner__hair,
            calc(3px * 15) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -21) $runner__suit,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__suit,
            calc(3px * 17) calc(3px * -22) $runner__black;
    }
    .runner__frame1--shadow {
        -webkit-box-shadow: calc(3px * 1) calc(3px * 0) $runner__shadow,
            calc(3px * 2) calc(3px * 0) $runner__shadow,
            calc(3px * 3) calc(3px * 0) $runner__shadow,
            calc(3px * 1) calc(3px * 1) $runner__shadow,
            calc(3px * 2) calc(3px * 1) $runner__shadow,
            calc(3px * 3) calc(3px * 1) $runner__shadow,
            calc(3px * 1) calc(3px * 2) $runner__shadow,
            calc(3px * 2) calc(3px * 2) $runner__shadow,
            calc(3px * 3) calc(3px * 2) $runner__shadow,
            calc(3px * 4) calc(3px * 2) $runner__shadow,
            calc(3px * 5) calc(3px * 2) $runner__shadow,
            calc(3px * 7) calc(3px * 2) $runner__shadow,
            calc(3px * 2) calc(3px * 3) $runner__shadow,
            calc(3px * 3) calc(3px * 3) $runner__shadow,
            calc(3px * 4) calc(3px * 3) $runner__shadow,
            calc(3px * 5) calc(3px * 3) $runner__shadow,
            calc(3px * 6) calc(3px * 3) $runner__shadow,
            calc(3px * 7) calc(3px * 3) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 11) calc(3px * 3) $runner__shadow,
            calc(3px * 3) calc(3px * 4) $runner__shadow,
            calc(3px * 4) calc(3px * 4) $runner__shadow,
            calc(3px * 5) calc(3px * 4) $runner__shadow,
            calc(3px * 6) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 4) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 13) calc(3px * 4) $runner__shadow,
            calc(3px * 4) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 5) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 14) calc(3px * 5) $runner__shadow,
            calc(3px * 15) calc(3px * 5) $runner__shadow,
            calc(3px * 16) calc(3px * 5) $runner__shadow,
            calc(3px * 17) calc(3px * 5) $runner__shadow,
            calc(3px * 18) calc(3px * 5) $runner__shadow,
            calc(3px * 19) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 15) calc(3px * 6) $runner__shadow,
            calc(3px * 16) calc(3px * 6) $runner__shadow,
            calc(3px * 17) calc(3px * 6) $runner__shadow,
            calc(3px * 18) calc(3px * 6) $runner__shadow,
            calc(3px * 19) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 16) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 19) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 8) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 8) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 23) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow;
        box-shadow: calc(3px * 1) calc(3px * 0) $runner__shadow,
            calc(3px * 2) calc(3px * 0) $runner__shadow,
            calc(3px * 3) calc(3px * 0) $runner__shadow,
            calc(3px * 1) calc(3px * 1) $runner__shadow,
            calc(3px * 2) calc(3px * 1) $runner__shadow,
            calc(3px * 3) calc(3px * 1) $runner__shadow,
            calc(3px * 1) calc(3px * 2) $runner__shadow,
            calc(3px * 2) calc(3px * 2) $runner__shadow,
            calc(3px * 3) calc(3px * 2) $runner__shadow,
            calc(3px * 4) calc(3px * 2) $runner__shadow,
            calc(3px * 5) calc(3px * 2) $runner__shadow,
            calc(3px * 7) calc(3px * 2) $runner__shadow,
            calc(3px * 2) calc(3px * 3) $runner__shadow,
            calc(3px * 3) calc(3px * 3) $runner__shadow,
            calc(3px * 4) calc(3px * 3) $runner__shadow,
            calc(3px * 5) calc(3px * 3) $runner__shadow,
            calc(3px * 6) calc(3px * 3) $runner__shadow,
            calc(3px * 7) calc(3px * 3) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 11) calc(3px * 3) $runner__shadow,
            calc(3px * 3) calc(3px * 4) $runner__shadow,
            calc(3px * 4) calc(3px * 4) $runner__shadow,
            calc(3px * 5) calc(3px * 4) $runner__shadow,
            calc(3px * 6) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 4) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 13) calc(3px * 4) $runner__shadow,
            calc(3px * 4) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 5) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 14) calc(3px * 5) $runner__shadow,
            calc(3px * 15) calc(3px * 5) $runner__shadow,
            calc(3px * 16) calc(3px * 5) $runner__shadow,
            calc(3px * 17) calc(3px * 5) $runner__shadow,
            calc(3px * 18) calc(3px * 5) $runner__shadow,
            calc(3px * 19) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 15) calc(3px * 6) $runner__shadow,
            calc(3px * 16) calc(3px * 6) $runner__shadow,
            calc(3px * 17) calc(3px * 6) $runner__shadow,
            calc(3px * 18) calc(3px * 6) $runner__shadow,
            calc(3px * 19) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 16) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 19) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 8) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 8) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 23) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow;
    }
    .runner__frame2 {
        -webkit-box-shadow: calc(3px * 8) calc(3px * -2) $runner__skin,
            calc(3px * 8) calc(3px * -3) $runner__skin,
            calc(3px * 9) calc(3px * -3) $runner__black,
            calc(3px * 10) calc(3px * -3) $runner__black,
            calc(3px * 8) calc(3px * -4) $runner__black,
            calc(3px * 9) calc(3px * -4) $runner__black,
            calc(3px * 10) calc(3px * -4) $runner__black,
            calc(3px * 11) calc(3px * -4) $runner__black,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 13) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__black,
            calc(3px * 9) calc(3px * -5) $runner__black,
            calc(3px * 10) calc(3px * -5) $runner__skin,
            calc(3px * 11) calc(3px * -5) $runner__skin,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 14) calc(3px * -5) $runner__black,
            calc(3px * -1) calc(3px * -6) $runner__skin,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__black,
            calc(3px * 8) calc(3px * -6) $runner__skin,
            calc(3px * 9) calc(3px * -6) $runner__skin,
            calc(3px * 10) calc(3px * -6) $runner__black,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__skin,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__black,
            calc(3px * 19) calc(3px * -6) $runner__black,
            calc(3px * 20) calc(3px * -6) $runner__black,
            calc(3px * 21) calc(3px * -6) $runner__black,
            calc(3px * 22) calc(3px * -6) $runner__black,
            calc(3px * -1) calc(3px * -7) $runner__skin,
            calc(3px * 0) calc(3px * -7) $runner__black,
            calc(3px * 1) calc(3px * -7) $runner__black,
            calc(3px * 2) calc(3px * -7) $runner__black,
            calc(3px * 3) calc(3px * -7) $runner__black,
            calc(3px * 4) calc(3px * -7) $runner__black,
            calc(3px * 5) calc(3px * -7) $runner__black,
            calc(3px * 6) calc(3px * -7) $runner__black,
            calc(3px * 7) calc(3px * -7) $runner__skin,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__black,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__black,
            calc(3px * 14) calc(3px * -7) $runner__black,
            calc(3px * 17) calc(3px * -7) $runner__black,
            calc(3px * 18) calc(3px * -7) $runner__black,
            calc(3px * 19) calc(3px * -7) $runner__suit,
            calc(3px * 20) calc(3px * -7) $runner__suit,
            calc(3px * 21) calc(3px * -7) $runner__suit,
            calc(3px * 22) calc(3px * -7) $runner__suit,
            calc(3px * 0) calc(3px * -8) $runner__skin,
            calc(3px * 1) calc(3px * -8) $runner__suit,
            calc(3px * 2) calc(3px * -8) $runner__suit,
            calc(3px * 3) calc(3px * -8) $runner__suit,
            calc(3px * 4) calc(3px * -8) $runner__black,
            calc(3px * 5) calc(3px * -8) $runner__black,
            calc(3px * 6) calc(3px * -8) $runner__skin,
            calc(3px * 7) calc(3px * -8) $runner__suit,
            calc(3px * 8) calc(3px * -8) $runner__suit,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__black,
            calc(3px * 13) calc(3px * -8) $runner__black,
            calc(3px * 14) calc(3px * -8) $runner__black,
            calc(3px * 15) calc(3px * -8) $runner__black,
            calc(3px * 16) calc(3px * -8) $runner__black,
            calc(3px * 17) calc(3px * -8) $runner__suit,
            calc(3px * 18) calc(3px * -8) $runner__suit,
            calc(3px * 19) calc(3px * -8) $runner__suit,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__suit,
            calc(3px * 3) calc(3px * -9) $runner__suit,
            calc(3px * 4) calc(3px * -9) $runner__suit,
            calc(3px * 5) calc(3px * -9) $runner__skin,
            calc(3px * 6) calc(3px * -9) $runner__suit,
            calc(3px * 7) calc(3px * -9) $runner__suit,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__black,
            calc(3px * 12) calc(3px * -9) $runner__black,
            calc(3px * 13) calc(3px * -9) $runner__black,
            calc(3px * 14) calc(3px * -9) $runner__black,
            calc(3px * 15) calc(3px * -9) $runner__black,
            calc(3px * 16) calc(3px * -9) $runner__suit,
            calc(3px * 17) calc(3px * -9) $runner__suit,
            calc(3px * 18) calc(3px * -9) $runner__suit,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 21) calc(3px * -9) $runner__suit,
            calc(3px * 22) calc(3px * -9) $runner__suit,
            calc(3px * 23) calc(3px * -9) $runner__skin,
            calc(3px * 5) calc(3px * -10) $runner__skin,
            calc(3px * 6) calc(3px * -10) $runner__suit,
            calc(3px * 7) calc(3px * -10) $runner__black,
            calc(3px * 8) calc(3px * -10) $runner__black,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__black,
            calc(3px * 13) calc(3px * -10) $runner__black,
            calc(3px * 14) calc(3px * -10) $runner__black,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__suit,
            calc(3px * 17) calc(3px * -10) $runner__suit,
            calc(3px * 18) calc(3px * -10) $runner__suit,
            calc(3px * 19) calc(3px * -10) $runner__suit,
            calc(3px * 20) calc(3px * -10) $runner__black,
            calc(3px * 8) calc(3px * -11) $runner__black,
            calc(3px * 9) calc(3px * -11) $runner__black,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__black,
            calc(3px * 13) calc(3px * -11) $runner__black,
            calc(3px * 14) calc(3px * -11) $runner__black,
            calc(3px * 15) calc(3px * -11) $runner__suit,
            calc(3px * 16) calc(3px * -11) $runner__suit,
            calc(3px * 17) calc(3px * -11) $runner__suit,
            calc(3px * 18) calc(3px * -11) $runner__suit,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__suit,
            calc(3px * 21) calc(3px * -11) $runner__suit,
            calc(3px * 10) calc(3px * -12) $runner__black,
            calc(3px * 11) calc(3px * -12) $runner__black,
            calc(3px * 12) calc(3px * -12) $runner__hair,
            calc(3px * 13) calc(3px * -12) $runner__hair,
            calc(3px * 14) calc(3px * -12) $runner__hair,
            calc(3px * 15) calc(3px * -12) $runner__hair,
            calc(3px * 16) calc(3px * -12) $runner__suit,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__suit,
            calc(3px * 19) calc(3px * -12) $runner__suit,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 10) calc(3px * -13) $runner__hair,
            calc(3px * 11) calc(3px * -13) $runner__hair,
            calc(3px * 12) calc(3px * -13) $runner__hair,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__hair,
            calc(3px * 15) calc(3px * -13) $runner__hair,
            calc(3px * 16) calc(3px * -13) $runner__hair,
            calc(3px * 17) calc(3px * -13) $runner__hair,
            calc(3px * 18) calc(3px * -13) $runner__hair,
            calc(3px * 19) calc(3px * -13) $runner__hair,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__skin,
            calc(3px * 22) calc(3px * -13) $runner__skin,
            calc(3px * 9) calc(3px * -14) $runner__hair,
            calc(3px * 10) calc(3px * -14) $runner__hair,
            calc(3px * 11) calc(3px * -14) $runner__hair,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__hair,
            calc(3px * 15) calc(3px * -14) $runner__hair,
            calc(3px * 16) calc(3px * -14) $runner__hair,
            calc(3px * 17) calc(3px * -14) $runner__black,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__hair,
            calc(3px * 21) calc(3px * -14) $runner__skin,
            calc(3px * 22) calc(3px * -14) $runner__eyes,
            calc(3px * 9) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__hair,
            calc(3px * 15) calc(3px * -15) $runner__hair,
            calc(3px * 16) calc(3px * -15) $runner__black,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__black,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__hair,
            calc(3px * 22) calc(3px * -15) $runner__eyes,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__hair,
            calc(3px * 15) calc(3px * -16) $runner__hair,
            calc(3px * 16) calc(3px * -16) $runner__suit,
            calc(3px * 17) calc(3px * -16) $runner__suit,
            calc(3px * 18) calc(3px * -16) $runner__black,
            calc(3px * 19) calc(3px * -16) $runner__black,
            calc(3px * 20) calc(3px * -16) $runner__black,
            calc(3px * 21) calc(3px * -16) $runner__black,
            calc(3px * 22) calc(3px * -16) $runner__hair,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__hair,
            calc(3px * 16) calc(3px * -17) $runner__white,
            calc(3px * 17) calc(3px * -17) $runner__suit,
            calc(3px * 18) calc(3px * -17) $runner__suit,
            calc(3px * 19) calc(3px * -17) $runner__black,
            calc(3px * 20) calc(3px * -17) $runner__black,
            calc(3px * 21) calc(3px * -17) $runner__black,
            calc(3px * 22) calc(3px * -17) $runner__black,
            calc(3px * 23) calc(3px * -17) $runner__hair,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__hair,
            calc(3px * 15) calc(3px * -18) $runner__hair,
            calc(3px * 16) calc(3px * -18) $runner__hair,
            calc(3px * 17) calc(3px * -18) $runner__suit,
            calc(3px * 18) calc(3px * -18) $runner__suit,
            calc(3px * 19) calc(3px * -18) $runner__hair,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__black,
            calc(3px * 24) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__hair,
            calc(3px * 16) calc(3px * -19) $runner__hair,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__suit,
            calc(3px * 19) calc(3px * -19) $runner__hair,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__hair,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 24) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__hair,
            calc(3px * 15) calc(3px * -20) $runner__white,
            calc(3px * 16) calc(3px * -20) $runner__white,
            calc(3px * 17) calc(3px * -20) $runner__white,
            calc(3px * 18) calc(3px * -20) $runner__suit,
            calc(3px * 19) calc(3px * -20) $runner__white,
            calc(3px * 20) calc(3px * -20) $runner__white,
            calc(3px * 21) calc(3px * -20) $runner__white,
            calc(3px * 22) calc(3px * -20) $runner__white,
            calc(3px * 23) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -21) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__white,
            calc(3px * 15) calc(3px * -21) $runner__white,
            calc(3px * 16) calc(3px * -21) $runner__hair,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__suit,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 23) calc(3px * -21) $runner__white,
            calc(3px * 13) calc(3px * -22) $runner__hair,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__hair,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__suit,
            calc(3px * 19) calc(3px * -22) $runner__hair,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 21) calc(3px * -22) $runner__hair,
            calc(3px * 22) calc(3px * -22) $runner__hair,
            calc(3px * 17) calc(3px * -23) $runner__suit,
            calc(3px * 18) calc(3px * -23) $runner__suit;
        box-shadow: calc(3px * 8) calc(3px * -2) $runner__skin,
            calc(3px * 8) calc(3px * -3) $runner__skin,
            calc(3px * 9) calc(3px * -3) $runner__black,
            calc(3px * 10) calc(3px * -3) $runner__black,
            calc(3px * 8) calc(3px * -4) $runner__black,
            calc(3px * 9) calc(3px * -4) $runner__black,
            calc(3px * 10) calc(3px * -4) $runner__black,
            calc(3px * 11) calc(3px * -4) $runner__black,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 13) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__black,
            calc(3px * 9) calc(3px * -5) $runner__black,
            calc(3px * 10) calc(3px * -5) $runner__skin,
            calc(3px * 11) calc(3px * -5) $runner__skin,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 14) calc(3px * -5) $runner__black,
            calc(3px * -1) calc(3px * -6) $runner__skin,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__black,
            calc(3px * 8) calc(3px * -6) $runner__skin,
            calc(3px * 9) calc(3px * -6) $runner__skin,
            calc(3px * 10) calc(3px * -6) $runner__black,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__skin,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__black,
            calc(3px * 19) calc(3px * -6) $runner__black,
            calc(3px * 20) calc(3px * -6) $runner__black,
            calc(3px * 21) calc(3px * -6) $runner__black,
            calc(3px * 22) calc(3px * -6) $runner__black,
            calc(3px * -1) calc(3px * -7) $runner__skin,
            calc(3px * 0) calc(3px * -7) $runner__black,
            calc(3px * 1) calc(3px * -7) $runner__black,
            calc(3px * 2) calc(3px * -7) $runner__black,
            calc(3px * 3) calc(3px * -7) $runner__black,
            calc(3px * 4) calc(3px * -7) $runner__black,
            calc(3px * 5) calc(3px * -7) $runner__black,
            calc(3px * 6) calc(3px * -7) $runner__black,
            calc(3px * 7) calc(3px * -7) $runner__skin,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__black,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__black,
            calc(3px * 14) calc(3px * -7) $runner__black,
            calc(3px * 17) calc(3px * -7) $runner__black,
            calc(3px * 18) calc(3px * -7) $runner__black,
            calc(3px * 19) calc(3px * -7) $runner__suit,
            calc(3px * 20) calc(3px * -7) $runner__suit,
            calc(3px * 21) calc(3px * -7) $runner__suit,
            calc(3px * 22) calc(3px * -7) $runner__suit,
            calc(3px * 0) calc(3px * -8) $runner__skin,
            calc(3px * 1) calc(3px * -8) $runner__suit,
            calc(3px * 2) calc(3px * -8) $runner__suit,
            calc(3px * 3) calc(3px * -8) $runner__suit,
            calc(3px * 4) calc(3px * -8) $runner__black,
            calc(3px * 5) calc(3px * -8) $runner__black,
            calc(3px * 6) calc(3px * -8) $runner__skin,
            calc(3px * 7) calc(3px * -8) $runner__suit,
            calc(3px * 8) calc(3px * -8) $runner__suit,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__black,
            calc(3px * 13) calc(3px * -8) $runner__black,
            calc(3px * 14) calc(3px * -8) $runner__black,
            calc(3px * 15) calc(3px * -8) $runner__black,
            calc(3px * 16) calc(3px * -8) $runner__black,
            calc(3px * 17) calc(3px * -8) $runner__suit,
            calc(3px * 18) calc(3px * -8) $runner__suit,
            calc(3px * 19) calc(3px * -8) $runner__suit,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__suit,
            calc(3px * 3) calc(3px * -9) $runner__suit,
            calc(3px * 4) calc(3px * -9) $runner__suit,
            calc(3px * 5) calc(3px * -9) $runner__skin,
            calc(3px * 6) calc(3px * -9) $runner__suit,
            calc(3px * 7) calc(3px * -9) $runner__suit,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__black,
            calc(3px * 12) calc(3px * -9) $runner__black,
            calc(3px * 13) calc(3px * -9) $runner__black,
            calc(3px * 14) calc(3px * -9) $runner__black,
            calc(3px * 15) calc(3px * -9) $runner__black,
            calc(3px * 16) calc(3px * -9) $runner__suit,
            calc(3px * 17) calc(3px * -9) $runner__suit,
            calc(3px * 18) calc(3px * -9) $runner__suit,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 21) calc(3px * -9) $runner__suit,
            calc(3px * 22) calc(3px * -9) $runner__suit,
            calc(3px * 23) calc(3px * -9) $runner__skin,
            calc(3px * 5) calc(3px * -10) $runner__skin,
            calc(3px * 6) calc(3px * -10) $runner__suit,
            calc(3px * 7) calc(3px * -10) $runner__black,
            calc(3px * 8) calc(3px * -10) $runner__black,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__black,
            calc(3px * 13) calc(3px * -10) $runner__black,
            calc(3px * 14) calc(3px * -10) $runner__black,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__suit,
            calc(3px * 17) calc(3px * -10) $runner__suit,
            calc(3px * 18) calc(3px * -10) $runner__suit,
            calc(3px * 19) calc(3px * -10) $runner__suit,
            calc(3px * 20) calc(3px * -10) $runner__black,
            calc(3px * 8) calc(3px * -11) $runner__black,
            calc(3px * 9) calc(3px * -11) $runner__black,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__black,
            calc(3px * 13) calc(3px * -11) $runner__black,
            calc(3px * 14) calc(3px * -11) $runner__black,
            calc(3px * 15) calc(3px * -11) $runner__suit,
            calc(3px * 16) calc(3px * -11) $runner__suit,
            calc(3px * 17) calc(3px * -11) $runner__suit,
            calc(3px * 18) calc(3px * -11) $runner__suit,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__suit,
            calc(3px * 21) calc(3px * -11) $runner__suit,
            calc(3px * 10) calc(3px * -12) $runner__black,
            calc(3px * 11) calc(3px * -12) $runner__black,
            calc(3px * 12) calc(3px * -12) $runner__hair,
            calc(3px * 13) calc(3px * -12) $runner__hair,
            calc(3px * 14) calc(3px * -12) $runner__hair,
            calc(3px * 15) calc(3px * -12) $runner__hair,
            calc(3px * 16) calc(3px * -12) $runner__suit,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__suit,
            calc(3px * 19) calc(3px * -12) $runner__suit,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 10) calc(3px * -13) $runner__hair,
            calc(3px * 11) calc(3px * -13) $runner__hair,
            calc(3px * 12) calc(3px * -13) $runner__hair,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__hair,
            calc(3px * 15) calc(3px * -13) $runner__hair,
            calc(3px * 16) calc(3px * -13) $runner__hair,
            calc(3px * 17) calc(3px * -13) $runner__hair,
            calc(3px * 18) calc(3px * -13) $runner__hair,
            calc(3px * 19) calc(3px * -13) $runner__hair,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__skin,
            calc(3px * 22) calc(3px * -13) $runner__skin,
            calc(3px * 9) calc(3px * -14) $runner__hair,
            calc(3px * 10) calc(3px * -14) $runner__hair,
            calc(3px * 11) calc(3px * -14) $runner__hair,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__hair,
            calc(3px * 15) calc(3px * -14) $runner__hair,
            calc(3px * 16) calc(3px * -14) $runner__hair,
            calc(3px * 17) calc(3px * -14) $runner__black,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__hair,
            calc(3px * 21) calc(3px * -14) $runner__skin,
            calc(3px * 22) calc(3px * -14) $runner__eyes,
            calc(3px * 9) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__hair,
            calc(3px * 15) calc(3px * -15) $runner__hair,
            calc(3px * 16) calc(3px * -15) $runner__black,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__black,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__hair,
            calc(3px * 22) calc(3px * -15) $runner__eyes,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__hair,
            calc(3px * 15) calc(3px * -16) $runner__hair,
            calc(3px * 16) calc(3px * -16) $runner__suit,
            calc(3px * 17) calc(3px * -16) $runner__suit,
            calc(3px * 18) calc(3px * -16) $runner__black,
            calc(3px * 19) calc(3px * -16) $runner__black,
            calc(3px * 20) calc(3px * -16) $runner__black,
            calc(3px * 21) calc(3px * -16) $runner__black,
            calc(3px * 22) calc(3px * -16) $runner__hair,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__hair,
            calc(3px * 16) calc(3px * -17) $runner__white,
            calc(3px * 17) calc(3px * -17) $runner__suit,
            calc(3px * 18) calc(3px * -17) $runner__suit,
            calc(3px * 19) calc(3px * -17) $runner__black,
            calc(3px * 20) calc(3px * -17) $runner__black,
            calc(3px * 21) calc(3px * -17) $runner__black,
            calc(3px * 22) calc(3px * -17) $runner__black,
            calc(3px * 23) calc(3px * -17) $runner__hair,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__hair,
            calc(3px * 15) calc(3px * -18) $runner__hair,
            calc(3px * 16) calc(3px * -18) $runner__hair,
            calc(3px * 17) calc(3px * -18) $runner__suit,
            calc(3px * 18) calc(3px * -18) $runner__suit,
            calc(3px * 19) calc(3px * -18) $runner__hair,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__black,
            calc(3px * 24) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__hair,
            calc(3px * 16) calc(3px * -19) $runner__hair,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__suit,
            calc(3px * 19) calc(3px * -19) $runner__hair,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__hair,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 24) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__hair,
            calc(3px * 15) calc(3px * -20) $runner__white,
            calc(3px * 16) calc(3px * -20) $runner__white,
            calc(3px * 17) calc(3px * -20) $runner__white,
            calc(3px * 18) calc(3px * -20) $runner__suit,
            calc(3px * 19) calc(3px * -20) $runner__white,
            calc(3px * 20) calc(3px * -20) $runner__white,
            calc(3px * 21) calc(3px * -20) $runner__white,
            calc(3px * 22) calc(3px * -20) $runner__white,
            calc(3px * 23) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -21) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__white,
            calc(3px * 15) calc(3px * -21) $runner__white,
            calc(3px * 16) calc(3px * -21) $runner__hair,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__suit,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 23) calc(3px * -21) $runner__white,
            calc(3px * 13) calc(3px * -22) $runner__hair,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__hair,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__suit,
            calc(3px * 19) calc(3px * -22) $runner__hair,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 21) calc(3px * -22) $runner__hair,
            calc(3px * 22) calc(3px * -22) $runner__hair,
            calc(3px * 17) calc(3px * -23) $runner__suit,
            calc(3px * 18) calc(3px * -23) $runner__suit;
    }
    .runner__frame2--shadow {
        -webkit-box-shadow: calc(3px * 8) calc(3px * 2) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 13) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 14) calc(3px * 5) $runner__shadow,
            calc(3px * 1) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 19) calc(3px * 6) $runner__shadow,
            calc(3px * 20) calc(3px * 6) $runner__shadow,
            calc(3px * 21) calc(3px * 6) $runner__shadow,
            calc(3px * 22) calc(3px * 6) $runner__shadow,
            calc(3px * 1) calc(3px * 7) $runner__shadow,
            calc(3px * 0) calc(3px * 7) $runner__shadow,
            calc(3px * 1) calc(3px * 7) $runner__shadow,
            calc(3px * 2) calc(3px * 7) $runner__shadow,
            calc(3px * 3) calc(3px * 7) $runner__shadow,
            calc(3px * 4) calc(3px * 7) $runner__shadow,
            calc(3px * 5) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 19) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 0) calc(3px * 8) $runner__shadow,
            calc(3px * 1) calc(3px * 8) $runner__shadow,
            calc(3px * 2) calc(3px * 8) $runner__shadow,
            calc(3px * 3) calc(3px * 8) $runner__shadow,
            calc(3px * 4) calc(3px * 8) $runner__shadow,
            calc(3px * 5) calc(3px * 8) $runner__shadow,
            calc(3px * 6) calc(3px * 8) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 3) calc(3px * 9) $runner__shadow,
            calc(3px * 4) calc(3px * 9) $runner__shadow,
            calc(3px * 5) calc(3px * 9) $runner__shadow,
            calc(3px * 6) calc(3px * 9) $runner__shadow,
            calc(3px * 7) calc(3px * 9) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 21) calc(3px * 9) $runner__shadow,
            calc(3px * 22) calc(3px * 9) $runner__shadow,
            calc(3px * 23) calc(3px * 9) $runner__shadow,
            calc(3px * 5) calc(3px * 10) $runner__shadow,
            calc(3px * 6) calc(3px * 10) $runner__shadow,
            calc(3px * 7) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 10) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 24) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 23) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 21) calc(3px * 22) $runner__shadow,
            calc(3px * 22) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow;
        box-shadow: calc(3px * 8) calc(3px * 2) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 13) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 14) calc(3px * 5) $runner__shadow,
            calc(3px * 1) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 19) calc(3px * 6) $runner__shadow,
            calc(3px * 20) calc(3px * 6) $runner__shadow,
            calc(3px * 21) calc(3px * 6) $runner__shadow,
            calc(3px * 22) calc(3px * 6) $runner__shadow,
            calc(3px * 1) calc(3px * 7) $runner__shadow,
            calc(3px * 0) calc(3px * 7) $runner__shadow,
            calc(3px * 1) calc(3px * 7) $runner__shadow,
            calc(3px * 2) calc(3px * 7) $runner__shadow,
            calc(3px * 3) calc(3px * 7) $runner__shadow,
            calc(3px * 4) calc(3px * 7) $runner__shadow,
            calc(3px * 5) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 19) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 0) calc(3px * 8) $runner__shadow,
            calc(3px * 1) calc(3px * 8) $runner__shadow,
            calc(3px * 2) calc(3px * 8) $runner__shadow,
            calc(3px * 3) calc(3px * 8) $runner__shadow,
            calc(3px * 4) calc(3px * 8) $runner__shadow,
            calc(3px * 5) calc(3px * 8) $runner__shadow,
            calc(3px * 6) calc(3px * 8) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 3) calc(3px * 9) $runner__shadow,
            calc(3px * 4) calc(3px * 9) $runner__shadow,
            calc(3px * 5) calc(3px * 9) $runner__shadow,
            calc(3px * 6) calc(3px * 9) $runner__shadow,
            calc(3px * 7) calc(3px * 9) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 21) calc(3px * 9) $runner__shadow,
            calc(3px * 22) calc(3px * 9) $runner__shadow,
            calc(3px * 23) calc(3px * 9) $runner__shadow,
            calc(3px * 5) calc(3px * 10) $runner__shadow,
            calc(3px * 6) calc(3px * 10) $runner__shadow,
            calc(3px * 7) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 10) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 24) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 23) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 21) calc(3px * 22) $runner__shadow,
            calc(3px * 22) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow;
    }
    .runner__frame3 {
        -webkit-box-shadow: calc(3px * 13) calc(3px * -1) $runner__skin,
            calc(3px * 14) calc(3px * -1) $runner__skin,
            calc(3px * 12) calc(3px * -2) $runner__black,
            calc(3px * 13) calc(3px * -2) $runner__black,
            calc(3px * 14) calc(3px * -2) $runner__black,
            calc(3px * 15) calc(3px * -2) $runner__skin,
            calc(3px * 11) calc(3px * -3) $runner__black,
            calc(3px * 12) calc(3px * -3) $runner__black,
            calc(3px * 13) calc(3px * -3) $runner__black,
            calc(3px * 9) calc(3px * -4) $runner__black,
            calc(3px * 10) calc(3px * -4) $runner__black,
            calc(3px * 11) calc(3px * -4) $runner__black,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 13) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__black,
            calc(3px * 9) calc(3px * -5) $runner__black,
            calc(3px * 10) calc(3px * -5) $runner__black,
            calc(3px * 11) calc(3px * -5) $runner__skin,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__black,
            calc(3px * 8) calc(3px * -6) $runner__black,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__skin,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__black,
            calc(3px * 4) calc(3px * -7) $runner__black,
            calc(3px * 5) calc(3px * -7) $runner__black,
            calc(3px * 6) calc(3px * -7) $runner__suit,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__skin,
            calc(3px * 10) calc(3px * -7) $runner__skin,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__black,
            calc(3px * 14) calc(3px * -7) $runner__black,
            calc(3px * 20) calc(3px * -7) $runner__black,
            calc(3px * 21) calc(3px * -7) $runner__black,
            calc(3px * 22) calc(3px * -7) $runner__black,
            calc(3px * 3) calc(3px * -8) $runner__black,
            calc(3px * 4) calc(3px * -8) $runner__black,
            calc(3px * 5) calc(3px * -8) $runner__suit,
            calc(3px * 6) calc(3px * -8) $runner__black,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__skin,
            calc(3px * 9) calc(3px * -8) $runner__skin,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__black,
            calc(3px * 13) calc(3px * -8) $runner__black,
            calc(3px * 14) calc(3px * -8) $runner__black,
            calc(3px * 15) calc(3px * -8) $runner__black,
            calc(3px * 16) calc(3px * -8) $runner__black,
            calc(3px * 18) calc(3px * -8) $runner__black,
            calc(3px * 19) calc(3px * -8) $runner__black,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__suit,
            calc(3px * 23) calc(3px * -8) $runner__black,
            calc(3px * 24) calc(3px * -8) $runner__black,
            calc(3px * 1) calc(3px * -9) $runner__skin,
            calc(3px * 2) calc(3px * -9) $runner__black,
            calc(3px * 3) calc(3px * -9) $runner__black,
            calc(3px * 4) calc(3px * -9) $runner__suit,
            calc(3px * 6) calc(3px * -9) $runner__black,
            calc(3px * 7) calc(3px * -9) $runner__skin,
            calc(3px * 8) calc(3px * -9) $runner__skin,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__black,
            calc(3px * 12) calc(3px * -9) $runner__black,
            calc(3px * 13) calc(3px * -9) $runner__black,
            calc(3px * 14) calc(3px * -9) $runner__black,
            calc(3px * 15) calc(3px * -9) $runner__black,
            calc(3px * 16) calc(3px * -9) $runner__black,
            calc(3px * 17) calc(3px * -9) $runner__black,
            calc(3px * 18) calc(3px * -9) $runner__suit,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 21) calc(3px * -9) $runner__suit,
            calc(3px * 22) calc(3px * -9) $runner__suit,
            calc(3px * 23) calc(3px * -9) $runner__suit,
            calc(3px * 24) calc(3px * -9) $runner__suit,
            calc(3px * 25) calc(3px * -9) $runner__black,
            calc(3px * 2) calc(3px * -10) $runner__skin,
            calc(3px * 3) calc(3px * -10) $runner__skin,
            calc(3px * 6) calc(3px * -10) $runner__skin,
            calc(3px * 7) calc(3px * -10) $runner__black,
            calc(3px * 8) calc(3px * -10) $runner__black,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__black,
            calc(3px * 13) calc(3px * -10) $runner__black,
            calc(3px * 14) calc(3px * -10) $runner__black,
            calc(3px * 15) calc(3px * -10) $runner__black,
            calc(3px * 16) calc(3px * -10) $runner__suit,
            calc(3px * 17) calc(3px * -10) $runner__suit,
            calc(3px * 18) calc(3px * -10) $runner__suit,
            calc(3px * 19) calc(3px * -10) $runner__suit,
            calc(3px * 20) calc(3px * -10) $runner__suit,
            calc(3px * 21) calc(3px * -10) $runner__suit,
            calc(3px * 22) calc(3px * -10) $runner__suit,
            calc(3px * 23) calc(3px * -10) $runner__suit,
            calc(3px * 24) calc(3px * -10) $runner__suit,
            calc(3px * 25) calc(3px * -10) $runner__suit,
            calc(3px * 8) calc(3px * -11) $runner__black,
            calc(3px * 9) calc(3px * -11) $runner__black,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__black,
            calc(3px * 13) calc(3px * -11) $runner__black,
            calc(3px * 14) calc(3px * -11) $runner__black,
            calc(3px * 15) calc(3px * -11) $runner__black,
            calc(3px * 16) calc(3px * -11) $runner__suit,
            calc(3px * 17) calc(3px * -11) $runner__suit,
            calc(3px * 18) calc(3px * -11) $runner__suit,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__suit,
            calc(3px * 21) calc(3px * -11) $runner__suit,
            calc(3px * 22) calc(3px * -11) $runner__suit,
            calc(3px * 23) calc(3px * -11) $runner__suit,
            calc(3px * 24) calc(3px * -11) $runner__suit,
            calc(3px * 25) calc(3px * -11) $runner__suit,
            calc(3px * 9) calc(3px * -12) $runner__suit,
            calc(3px * 10) calc(3px * -12) $runner__black,
            calc(3px * 11) calc(3px * -12) $runner__black,
            calc(3px * 12) calc(3px * -12) $runner__black,
            calc(3px * 13) calc(3px * -12) $runner__black,
            calc(3px * 14) calc(3px * -12) $runner__black,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__black,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__suit,
            calc(3px * 19) calc(3px * -12) $runner__suit,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 23) calc(3px * -12) $runner__suit,
            calc(3px * 24) calc(3px * -12) $runner__suit,
            calc(3px * 9) calc(3px * -13) $runner__suit,
            calc(3px * 10) calc(3px * -13) $runner__suit,
            calc(3px * 11) calc(3px * -13) $runner__black,
            calc(3px * 12) calc(3px * -13) $runner__black,
            calc(3px * 13) calc(3px * -13) $runner__black,
            calc(3px * 14) calc(3px * -13) $runner__black,
            calc(3px * 15) calc(3px * -13) $runner__black,
            calc(3px * 16) calc(3px * -13) $runner__hair,
            calc(3px * 17) calc(3px * -13) $runner__hair,
            calc(3px * 18) calc(3px * -13) $runner__hair,
            calc(3px * 19) calc(3px * -13) $runner__hair,
            calc(3px * 20) calc(3px * -13) $runner__hair,
            calc(3px * 21) calc(3px * -13) $runner__skin,
            calc(3px * 22) calc(3px * -13) $runner__skin,
            calc(3px * 10) calc(3px * -14) $runner__black,
            calc(3px * 11) calc(3px * -14) $runner__black,
            calc(3px * 12) calc(3px * -14) $runner__black,
            calc(3px * 13) calc(3px * -14) $runner__black,
            calc(3px * 14) calc(3px * -14) $runner__hair,
            calc(3px * 15) calc(3px * -14) $runner__hair,
            calc(3px * 16) calc(3px * -14) $runner__hair,
            calc(3px * 17) calc(3px * -14) $runner__hair,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__hair,
            calc(3px * 21) calc(3px * -14) $runner__skin,
            calc(3px * 22) calc(3px * -14) $runner__eyes,
            calc(3px * 9) calc(3px * -15) $runner__black,
            calc(3px * 10) calc(3px * -15) $runner__black,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__hair,
            calc(3px * 15) calc(3px * -15) $runner__hair,
            calc(3px * 16) calc(3px * -15) $runner__hair,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__black,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__hair,
            calc(3px * 22) calc(3px * -15) $runner__hair,
            calc(3px * 9) calc(3px * -16) $runner__black,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__hair,
            calc(3px * 15) calc(3px * -16) $runner__hair,
            calc(3px * 16) calc(3px * -16) $runner__hair,
            calc(3px * 17) calc(3px * -16) $runner__suit,
            calc(3px * 18) calc(3px * -16) $runner__black,
            calc(3px * 19) calc(3px * -16) $runner__black,
            calc(3px * 20) calc(3px * -16) $runner__black,
            calc(3px * 21) calc(3px * -16) $runner__black,
            calc(3px * 22) calc(3px * -16) $runner__black,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 9) calc(3px * -17) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__hair,
            calc(3px * 16) calc(3px * -17) $runner__hair,
            calc(3px * 17) calc(3px * -17) $runner__white,
            calc(3px * 18) calc(3px * -17) $runner__black,
            calc(3px * 19) calc(3px * -17) $runner__black,
            calc(3px * 20) calc(3px * -17) $runner__black,
            calc(3px * 21) calc(3px * -17) $runner__hair,
            calc(3px * 22) calc(3px * -17) $runner__black,
            calc(3px * 23) calc(3px * -17) $runner__black,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 9) calc(3px * -18) $runner__hair,
            calc(3px * 10) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__hair,
            calc(3px * 15) calc(3px * -18) $runner__hair,
            calc(3px * 16) calc(3px * -18) $runner__hair,
            calc(3px * 17) calc(3px * -18) $runner__hair,
            calc(3px * 18) calc(3px * -18) $runner__suit,
            calc(3px * 19) calc(3px * -18) $runner__suit,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__hair,
            calc(3px * 24) calc(3px * -18) $runner__black,
            calc(3px * 10) calc(3px * -19) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__hair,
            calc(3px * 16) calc(3px * -19) $runner__hair,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__hair,
            calc(3px * 19) calc(3px * -19) $runner__suit,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__hair,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 24) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__hair,
            calc(3px * 15) calc(3px * -20) $runner__white,
            calc(3px * 16) calc(3px * -20) $runner__white,
            calc(3px * 17) calc(3px * -20) $runner__white,
            calc(3px * 18) calc(3px * -20) $runner__white,
            calc(3px * 19) calc(3px * -20) $runner__suit,
            calc(3px * 20) calc(3px * -20) $runner__white,
            calc(3px * 21) calc(3px * -20) $runner__white,
            calc(3px * 22) calc(3px * -20) $runner__white,
            calc(3px * 23) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -21) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__white,
            calc(3px * 15) calc(3px * -21) $runner__white,
            calc(3px * 16) calc(3px * -21) $runner__hair,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__suit,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 23) calc(3px * -21) $runner__white,
            calc(3px * 13) calc(3px * -22) $runner__white,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__hair,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__hair,
            calc(3px * 19) calc(3px * -22) $runner__suit,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 21) calc(3px * -22) $runner__hair,
            calc(3px * 22) calc(3px * -22) $runner__hair,
            calc(3px * 14) calc(3px * -23) $runner__hair,
            calc(3px * 15) calc(3px * -23) $runner__hair,
            calc(3px * 16) calc(3px * -23) $runner__hair,
            calc(3px * 17) calc(3px * -23) $runner__hair,
            calc(3px * 18) calc(3px * -23) $runner__hair,
            calc(3px * 19) calc(3px * -23) $runner__suit,
            calc(3px * 20) calc(3px * -23) $runner__hair,
            calc(3px * 15) calc(3px * -24) $runner__suit,
            calc(3px * 16) calc(3px * -24) $runner__suit,
            calc(3px * 17) calc(3px * -24) $runner__suit,
            calc(3px * 19) calc(3px * -24) $runner__suit,
            calc(3px * 17) calc(3px * -25) $runner__suit,
            calc(3px * 18) calc(3px * -25) $runner__suit,
            calc(3px * 19) calc(3px * -25) $runner__suit;
        box-shadow: calc(3px * 13) calc(3px * -1) $runner__skin,
            calc(3px * 14) calc(3px * -1) $runner__skin,
            calc(3px * 12) calc(3px * -2) $runner__black,
            calc(3px * 13) calc(3px * -2) $runner__black,
            calc(3px * 14) calc(3px * -2) $runner__black,
            calc(3px * 15) calc(3px * -2) $runner__skin,
            calc(3px * 11) calc(3px * -3) $runner__black,
            calc(3px * 12) calc(3px * -3) $runner__black,
            calc(3px * 13) calc(3px * -3) $runner__black,
            calc(3px * 9) calc(3px * -4) $runner__black,
            calc(3px * 10) calc(3px * -4) $runner__black,
            calc(3px * 11) calc(3px * -4) $runner__black,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 13) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__black,
            calc(3px * 9) calc(3px * -5) $runner__black,
            calc(3px * 10) calc(3px * -5) $runner__black,
            calc(3px * 11) calc(3px * -5) $runner__skin,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__black,
            calc(3px * 8) calc(3px * -6) $runner__black,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__skin,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__black,
            calc(3px * 4) calc(3px * -7) $runner__black,
            calc(3px * 5) calc(3px * -7) $runner__black,
            calc(3px * 6) calc(3px * -7) $runner__suit,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__skin,
            calc(3px * 10) calc(3px * -7) $runner__skin,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__black,
            calc(3px * 14) calc(3px * -7) $runner__black,
            calc(3px * 20) calc(3px * -7) $runner__black,
            calc(3px * 21) calc(3px * -7) $runner__black,
            calc(3px * 22) calc(3px * -7) $runner__black,
            calc(3px * 3) calc(3px * -8) $runner__black,
            calc(3px * 4) calc(3px * -8) $runner__black,
            calc(3px * 5) calc(3px * -8) $runner__suit,
            calc(3px * 6) calc(3px * -8) $runner__black,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__skin,
            calc(3px * 9) calc(3px * -8) $runner__skin,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__black,
            calc(3px * 13) calc(3px * -8) $runner__black,
            calc(3px * 14) calc(3px * -8) $runner__black,
            calc(3px * 15) calc(3px * -8) $runner__black,
            calc(3px * 16) calc(3px * -8) $runner__black,
            calc(3px * 18) calc(3px * -8) $runner__black,
            calc(3px * 19) calc(3px * -8) $runner__black,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__suit,
            calc(3px * 23) calc(3px * -8) $runner__black,
            calc(3px * 24) calc(3px * -8) $runner__black,
            calc(3px * 1) calc(3px * -9) $runner__skin,
            calc(3px * 2) calc(3px * -9) $runner__black,
            calc(3px * 3) calc(3px * -9) $runner__black,
            calc(3px * 4) calc(3px * -9) $runner__suit,
            calc(3px * 6) calc(3px * -9) $runner__black,
            calc(3px * 7) calc(3px * -9) $runner__skin,
            calc(3px * 8) calc(3px * -9) $runner__skin,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__black,
            calc(3px * 12) calc(3px * -9) $runner__black,
            calc(3px * 13) calc(3px * -9) $runner__black,
            calc(3px * 14) calc(3px * -9) $runner__black,
            calc(3px * 15) calc(3px * -9) $runner__black,
            calc(3px * 16) calc(3px * -9) $runner__black,
            calc(3px * 17) calc(3px * -9) $runner__black,
            calc(3px * 18) calc(3px * -9) $runner__suit,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 21) calc(3px * -9) $runner__suit,
            calc(3px * 22) calc(3px * -9) $runner__suit,
            calc(3px * 23) calc(3px * -9) $runner__suit,
            calc(3px * 24) calc(3px * -9) $runner__suit,
            calc(3px * 25) calc(3px * -9) $runner__black,
            calc(3px * 2) calc(3px * -10) $runner__skin,
            calc(3px * 3) calc(3px * -10) $runner__skin,
            calc(3px * 6) calc(3px * -10) $runner__skin,
            calc(3px * 7) calc(3px * -10) $runner__black,
            calc(3px * 8) calc(3px * -10) $runner__black,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__black,
            calc(3px * 13) calc(3px * -10) $runner__black,
            calc(3px * 14) calc(3px * -10) $runner__black,
            calc(3px * 15) calc(3px * -10) $runner__black,
            calc(3px * 16) calc(3px * -10) $runner__suit,
            calc(3px * 17) calc(3px * -10) $runner__suit,
            calc(3px * 18) calc(3px * -10) $runner__suit,
            calc(3px * 19) calc(3px * -10) $runner__suit,
            calc(3px * 20) calc(3px * -10) $runner__suit,
            calc(3px * 21) calc(3px * -10) $runner__suit,
            calc(3px * 22) calc(3px * -10) $runner__suit,
            calc(3px * 23) calc(3px * -10) $runner__suit,
            calc(3px * 24) calc(3px * -10) $runner__suit,
            calc(3px * 25) calc(3px * -10) $runner__suit,
            calc(3px * 8) calc(3px * -11) $runner__black,
            calc(3px * 9) calc(3px * -11) $runner__black,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__black,
            calc(3px * 13) calc(3px * -11) $runner__black,
            calc(3px * 14) calc(3px * -11) $runner__black,
            calc(3px * 15) calc(3px * -11) $runner__black,
            calc(3px * 16) calc(3px * -11) $runner__suit,
            calc(3px * 17) calc(3px * -11) $runner__suit,
            calc(3px * 18) calc(3px * -11) $runner__suit,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__suit,
            calc(3px * 21) calc(3px * -11) $runner__suit,
            calc(3px * 22) calc(3px * -11) $runner__suit,
            calc(3px * 23) calc(3px * -11) $runner__suit,
            calc(3px * 24) calc(3px * -11) $runner__suit,
            calc(3px * 25) calc(3px * -11) $runner__suit,
            calc(3px * 9) calc(3px * -12) $runner__suit,
            calc(3px * 10) calc(3px * -12) $runner__black,
            calc(3px * 11) calc(3px * -12) $runner__black,
            calc(3px * 12) calc(3px * -12) $runner__black,
            calc(3px * 13) calc(3px * -12) $runner__black,
            calc(3px * 14) calc(3px * -12) $runner__black,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__black,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__suit,
            calc(3px * 19) calc(3px * -12) $runner__suit,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 23) calc(3px * -12) $runner__suit,
            calc(3px * 24) calc(3px * -12) $runner__suit,
            calc(3px * 9) calc(3px * -13) $runner__suit,
            calc(3px * 10) calc(3px * -13) $runner__suit,
            calc(3px * 11) calc(3px * -13) $runner__black,
            calc(3px * 12) calc(3px * -13) $runner__black,
            calc(3px * 13) calc(3px * -13) $runner__black,
            calc(3px * 14) calc(3px * -13) $runner__black,
            calc(3px * 15) calc(3px * -13) $runner__black,
            calc(3px * 16) calc(3px * -13) $runner__hair,
            calc(3px * 17) calc(3px * -13) $runner__hair,
            calc(3px * 18) calc(3px * -13) $runner__hair,
            calc(3px * 19) calc(3px * -13) $runner__hair,
            calc(3px * 20) calc(3px * -13) $runner__hair,
            calc(3px * 21) calc(3px * -13) $runner__skin,
            calc(3px * 22) calc(3px * -13) $runner__skin,
            calc(3px * 10) calc(3px * -14) $runner__black,
            calc(3px * 11) calc(3px * -14) $runner__black,
            calc(3px * 12) calc(3px * -14) $runner__black,
            calc(3px * 13) calc(3px * -14) $runner__black,
            calc(3px * 14) calc(3px * -14) $runner__hair,
            calc(3px * 15) calc(3px * -14) $runner__hair,
            calc(3px * 16) calc(3px * -14) $runner__hair,
            calc(3px * 17) calc(3px * -14) $runner__hair,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__hair,
            calc(3px * 21) calc(3px * -14) $runner__skin,
            calc(3px * 22) calc(3px * -14) $runner__eyes,
            calc(3px * 9) calc(3px * -15) $runner__black,
            calc(3px * 10) calc(3px * -15) $runner__black,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__hair,
            calc(3px * 15) calc(3px * -15) $runner__hair,
            calc(3px * 16) calc(3px * -15) $runner__hair,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__black,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__hair,
            calc(3px * 22) calc(3px * -15) $runner__hair,
            calc(3px * 9) calc(3px * -16) $runner__black,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__hair,
            calc(3px * 15) calc(3px * -16) $runner__hair,
            calc(3px * 16) calc(3px * -16) $runner__hair,
            calc(3px * 17) calc(3px * -16) $runner__suit,
            calc(3px * 18) calc(3px * -16) $runner__black,
            calc(3px * 19) calc(3px * -16) $runner__black,
            calc(3px * 20) calc(3px * -16) $runner__black,
            calc(3px * 21) calc(3px * -16) $runner__black,
            calc(3px * 22) calc(3px * -16) $runner__black,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 9) calc(3px * -17) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__hair,
            calc(3px * 16) calc(3px * -17) $runner__hair,
            calc(3px * 17) calc(3px * -17) $runner__white,
            calc(3px * 18) calc(3px * -17) $runner__black,
            calc(3px * 19) calc(3px * -17) $runner__black,
            calc(3px * 20) calc(3px * -17) $runner__black,
            calc(3px * 21) calc(3px * -17) $runner__hair,
            calc(3px * 22) calc(3px * -17) $runner__black,
            calc(3px * 23) calc(3px * -17) $runner__black,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 9) calc(3px * -18) $runner__hair,
            calc(3px * 10) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__hair,
            calc(3px * 15) calc(3px * -18) $runner__hair,
            calc(3px * 16) calc(3px * -18) $runner__hair,
            calc(3px * 17) calc(3px * -18) $runner__hair,
            calc(3px * 18) calc(3px * -18) $runner__suit,
            calc(3px * 19) calc(3px * -18) $runner__suit,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__hair,
            calc(3px * 24) calc(3px * -18) $runner__black,
            calc(3px * 10) calc(3px * -19) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__hair,
            calc(3px * 16) calc(3px * -19) $runner__hair,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__hair,
            calc(3px * 19) calc(3px * -19) $runner__suit,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__hair,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 24) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__hair,
            calc(3px * 15) calc(3px * -20) $runner__white,
            calc(3px * 16) calc(3px * -20) $runner__white,
            calc(3px * 17) calc(3px * -20) $runner__white,
            calc(3px * 18) calc(3px * -20) $runner__white,
            calc(3px * 19) calc(3px * -20) $runner__suit,
            calc(3px * 20) calc(3px * -20) $runner__white,
            calc(3px * 21) calc(3px * -20) $runner__white,
            calc(3px * 22) calc(3px * -20) $runner__white,
            calc(3px * 23) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -21) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__white,
            calc(3px * 15) calc(3px * -21) $runner__white,
            calc(3px * 16) calc(3px * -21) $runner__hair,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__suit,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 23) calc(3px * -21) $runner__white,
            calc(3px * 13) calc(3px * -22) $runner__white,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__hair,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__hair,
            calc(3px * 19) calc(3px * -22) $runner__suit,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 21) calc(3px * -22) $runner__hair,
            calc(3px * 22) calc(3px * -22) $runner__hair,
            calc(3px * 14) calc(3px * -23) $runner__hair,
            calc(3px * 15) calc(3px * -23) $runner__hair,
            calc(3px * 16) calc(3px * -23) $runner__hair,
            calc(3px * 17) calc(3px * -23) $runner__hair,
            calc(3px * 18) calc(3px * -23) $runner__hair,
            calc(3px * 19) calc(3px * -23) $runner__suit,
            calc(3px * 20) calc(3px * -23) $runner__hair,
            calc(3px * 15) calc(3px * -24) $runner__suit,
            calc(3px * 16) calc(3px * -24) $runner__suit,
            calc(3px * 17) calc(3px * -24) $runner__suit,
            calc(3px * 19) calc(3px * -24) $runner__suit,
            calc(3px * 17) calc(3px * -25) $runner__suit,
            calc(3px * 18) calc(3px * -25) $runner__suit,
            calc(3px * 19) calc(3px * -25) $runner__suit;
    }
    .runner__frame3--shadow {
        -webkit-box-shadow: calc(3px * 13) calc(3px * 1) $runner__shadow,
            calc(3px * 14) calc(3px * 1) $runner__shadow,
            calc(3px * 12) calc(3px * 2) $runner__shadow,
            calc(3px * 13) calc(3px * 2) $runner__shadow,
            calc(3px * 14) calc(3px * 2) $runner__shadow,
            calc(3px * 15) calc(3px * 2) $runner__shadow,
            calc(3px * 11) calc(3px * 3) $runner__shadow,
            calc(3px * 12) calc(3px * 3) $runner__shadow,
            calc(3px * 13) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 13) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 4) calc(3px * 7) $runner__shadow,
            calc(3px * 5) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 3) calc(3px * 8) $runner__shadow,
            calc(3px * 4) calc(3px * 8) $runner__shadow,
            calc(3px * 5) calc(3px * 8) $runner__shadow,
            calc(3px * 6) calc(3px * 8) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 23) calc(3px * 8) $runner__shadow,
            calc(3px * 24) calc(3px * 8) $runner__shadow,
            calc(3px * 1) calc(3px * 9) $runner__shadow,
            calc(3px * 2) calc(3px * 9) $runner__shadow,
            calc(3px * 3) calc(3px * 9) $runner__shadow,
            calc(3px * 4) calc(3px * 9) $runner__shadow,
            calc(3px * 6) calc(3px * 9) $runner__shadow,
            calc(3px * 7) calc(3px * 9) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 21) calc(3px * 9) $runner__shadow,
            calc(3px * 22) calc(3px * 9) $runner__shadow,
            calc(3px * 23) calc(3px * 9) $runner__shadow,
            calc(3px * 24) calc(3px * 9) $runner__shadow,
            calc(3px * 25) calc(3px * 9) $runner__shadow,
            calc(3px * 2) calc(3px * 10) $runner__shadow,
            calc(3px * 3) calc(3px * 10) $runner__shadow,
            calc(3px * 6) calc(3px * 10) $runner__shadow,
            calc(3px * 7) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 10) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 21) calc(3px * 10) $runner__shadow,
            calc(3px * 22) calc(3px * 10) $runner__shadow,
            calc(3px * 23) calc(3px * 10) $runner__shadow,
            calc(3px * 24) calc(3px * 10) $runner__shadow,
            calc(3px * 25) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 22) calc(3px * 11) $runner__shadow,
            calc(3px * 23) calc(3px * 11) $runner__shadow,
            calc(3px * 24) calc(3px * 11) $runner__shadow,
            calc(3px * 25) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 23) calc(3px * 12) $runner__shadow,
            calc(3px * 24) calc(3px * 12) $runner__shadow,
            calc(3px * 9) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 9) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 9) calc(3px * 17) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 9) calc(3px * 18) $runner__shadow,
            calc(3px * 10) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 10) calc(3px * 19) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 24) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 23) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 21) calc(3px * 22) $runner__shadow,
            calc(3px * 22) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 23) $runner__shadow,
            calc(3px * 15) calc(3px * 23) $runner__shadow,
            calc(3px * 16) calc(3px * 23) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow,
            calc(3px * 19) calc(3px * 23) $runner__shadow,
            calc(3px * 20) calc(3px * 23) $runner__shadow,
            calc(3px * 15) calc(3px * 24) $runner__shadow,
            calc(3px * 16) calc(3px * 24) $runner__shadow,
            calc(3px * 17) calc(3px * 24) $runner__shadow,
            calc(3px * 19) calc(3px * 24) $runner__shadow,
            calc(3px * 17) calc(3px * 25) $runner__shadow,
            calc(3px * 18) calc(3px * 25) $runner__shadow,
            calc(3px * 19) calc(3px * 25) $runner__shadow;
        box-shadow: calc(3px * 13) calc(3px * 1) $runner__shadow,
            calc(3px * 14) calc(3px * 1) $runner__shadow,
            calc(3px * 12) calc(3px * 2) $runner__shadow,
            calc(3px * 13) calc(3px * 2) $runner__shadow,
            calc(3px * 14) calc(3px * 2) $runner__shadow,
            calc(3px * 15) calc(3px * 2) $runner__shadow,
            calc(3px * 11) calc(3px * 3) $runner__shadow,
            calc(3px * 12) calc(3px * 3) $runner__shadow,
            calc(3px * 13) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 13) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 4) calc(3px * 7) $runner__shadow,
            calc(3px * 5) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 3) calc(3px * 8) $runner__shadow,
            calc(3px * 4) calc(3px * 8) $runner__shadow,
            calc(3px * 5) calc(3px * 8) $runner__shadow,
            calc(3px * 6) calc(3px * 8) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 23) calc(3px * 8) $runner__shadow,
            calc(3px * 24) calc(3px * 8) $runner__shadow,
            calc(3px * 1) calc(3px * 9) $runner__shadow,
            calc(3px * 2) calc(3px * 9) $runner__shadow,
            calc(3px * 3) calc(3px * 9) $runner__shadow,
            calc(3px * 4) calc(3px * 9) $runner__shadow,
            calc(3px * 6) calc(3px * 9) $runner__shadow,
            calc(3px * 7) calc(3px * 9) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 21) calc(3px * 9) $runner__shadow,
            calc(3px * 22) calc(3px * 9) $runner__shadow,
            calc(3px * 23) calc(3px * 9) $runner__shadow,
            calc(3px * 24) calc(3px * 9) $runner__shadow,
            calc(3px * 25) calc(3px * 9) $runner__shadow,
            calc(3px * 2) calc(3px * 10) $runner__shadow,
            calc(3px * 3) calc(3px * 10) $runner__shadow,
            calc(3px * 6) calc(3px * 10) $runner__shadow,
            calc(3px * 7) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 10) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 21) calc(3px * 10) $runner__shadow,
            calc(3px * 22) calc(3px * 10) $runner__shadow,
            calc(3px * 23) calc(3px * 10) $runner__shadow,
            calc(3px * 24) calc(3px * 10) $runner__shadow,
            calc(3px * 25) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 22) calc(3px * 11) $runner__shadow,
            calc(3px * 23) calc(3px * 11) $runner__shadow,
            calc(3px * 24) calc(3px * 11) $runner__shadow,
            calc(3px * 25) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 23) calc(3px * 12) $runner__shadow,
            calc(3px * 24) calc(3px * 12) $runner__shadow,
            calc(3px * 9) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 9) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 9) calc(3px * 17) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 9) calc(3px * 18) $runner__shadow,
            calc(3px * 10) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 10) calc(3px * 19) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 24) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 23) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 21) calc(3px * 22) $runner__shadow,
            calc(3px * 22) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 23) $runner__shadow,
            calc(3px * 15) calc(3px * 23) $runner__shadow,
            calc(3px * 16) calc(3px * 23) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow,
            calc(3px * 19) calc(3px * 23) $runner__shadow,
            calc(3px * 20) calc(3px * 23) $runner__shadow,
            calc(3px * 15) calc(3px * 24) $runner__shadow,
            calc(3px * 16) calc(3px * 24) $runner__shadow,
            calc(3px * 17) calc(3px * 24) $runner__shadow,
            calc(3px * 19) calc(3px * 24) $runner__shadow,
            calc(3px * 17) calc(3px * 25) $runner__shadow,
            calc(3px * 18) calc(3px * 25) $runner__shadow,
            calc(3px * 19) calc(3px * 25) $runner__shadow;
    }
    .runner__frame4 {
        -webkit-box-shadow: calc(3px * 9) calc(3px * 0) $runner__black,
            calc(3px * 10) calc(3px * 0) $runner__black,
            calc(3px * 11) calc(3px * 0) $runner__skin,
            calc(3px * 12) calc(3px * 0) $runner__skin,
            calc(3px * 9) calc(3px * -1) $runner__black,
            calc(3px * 10) calc(3px * -1) $runner__black,
            calc(3px * 11) calc(3px * -1) $runner__black,
            calc(3px * 9) calc(3px * -2) $runner__black,
            calc(3px * 10) calc(3px * -2) $runner__black,
            calc(3px * 11) calc(3px * -2) $runner__black,
            calc(3px * 8) calc(3px * -3) $runner__black,
            calc(3px * 9) calc(3px * -3) $runner__black,
            calc(3px * 10) calc(3px * -3) $runner__suit,
            calc(3px * 11) calc(3px * -3) $runner__black,
            calc(3px * 6) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -4) $runner__black,
            calc(3px * 8) calc(3px * -4) $runner__suit,
            calc(3px * 9) calc(3px * -4) $runner__suit,
            calc(3px * 10) calc(3px * -4) $runner__skin,
            calc(3px * 11) calc(3px * -4) $runner__skin,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 5) calc(3px * -5) $runner__skin,
            calc(3px * 6) calc(3px * -5) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__suit,
            calc(3px * 8) calc(3px * -5) $runner__skin,
            calc(3px * 9) calc(3px * -5) $runner__skin,
            calc(3px * 10) calc(3px * -5) $runner__black,
            calc(3px * 11) calc(3px * -5) $runner__black,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 19) calc(3px * -5) $runner__black,
            calc(3px * 20) calc(3px * -5) $runner__black,
            calc(3px * 21) calc(3px * -5) $runner__black,
            calc(3px * 22) calc(3px * -5) $runner__black,
            calc(3px * 23) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -6) $runner__skin,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__skin,
            calc(3px * 8) calc(3px * -6) $runner__black,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__black,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__black,
            calc(3px * 17) calc(3px * -6) $runner__black,
            calc(3px * 18) calc(3px * -6) $runner__black,
            calc(3px * 19) calc(3px * -6) $runner__suit,
            calc(3px * 20) calc(3px * -6) $runner__suit,
            calc(3px * 21) calc(3px * -6) $runner__suit,
            calc(3px * 22) calc(3px * -6) $runner__suit,
            calc(3px * 23) calc(3px * -6) $runner__suit,
            calc(3px * 6) calc(3px * -7) $runner__skin,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__black,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__black,
            calc(3px * 14) calc(3px * -7) $runner__black,
            calc(3px * 15) calc(3px * -7) $runner__black,
            calc(3px * 16) calc(3px * -7) $runner__black,
            calc(3px * 17) calc(3px * -7) $runner__suit,
            calc(3px * 18) calc(3px * -7) $runner__suit,
            calc(3px * 19) calc(3px * -7) $runner__suit,
            calc(3px * 20) calc(3px * -7) $runner__suit,
            calc(3px * 21) calc(3px * -7) $runner__suit,
            calc(3px * 22) calc(3px * -7) $runner__suit,
            calc(3px * 23) calc(3px * -7) $runner__suit,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__black,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__black,
            calc(3px * 13) calc(3px * -8) $runner__black,
            calc(3px * 14) calc(3px * -8) $runner__black,
            calc(3px * 15) calc(3px * -8) $runner__black,
            calc(3px * 16) calc(3px * -8) $runner__suit,
            calc(3px * 17) calc(3px * -8) $runner__suit,
            calc(3px * 18) calc(3px * -8) $runner__suit,
            calc(3px * 19) calc(3px * -8) $runner__suit,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__suit,
            calc(3px * 23) calc(3px * -8) $runner__skin,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__black,
            calc(3px * 12) calc(3px * -9) $runner__black,
            calc(3px * 13) calc(3px * -9) $runner__black,
            calc(3px * 14) calc(3px * -9) $runner__black,
            calc(3px * 15) calc(3px * -9) $runner__suit,
            calc(3px * 16) calc(3px * -9) $runner__suit,
            calc(3px * 17) calc(3px * -9) $runner__suit,
            calc(3px * 18) calc(3px * -9) $runner__suit,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__black,
            calc(3px * 13) calc(3px * -10) $runner__black,
            calc(3px * 14) calc(3px * -10) $runner__black,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__suit,
            calc(3px * 17) calc(3px * -10) $runner__suit,
            calc(3px * 18) calc(3px * -10) $runner__suit,
            calc(3px * 19) calc(3px * -10) $runner__suit,
            calc(3px * 20) calc(3px * -10) $runner__suit,
            calc(3px * 21) calc(3px * -10) $runner__suit,
            calc(3px * 8) calc(3px * -11) $runner__suit,
            calc(3px * 9) calc(3px * -11) $runner__suit,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__black,
            calc(3px * 13) calc(3px * -11) $runner__black,
            calc(3px * 14) calc(3px * -11) $runner__black,
            calc(3px * 15) calc(3px * -11) $runner__black,
            calc(3px * 16) calc(3px * -11) $runner__suit,
            calc(3px * 17) calc(3px * -11) $runner__suit,
            calc(3px * 18) calc(3px * -11) $runner__suit,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__skin,
            calc(3px * 21) calc(3px * -11) $runner__skin,
            calc(3px * 8) calc(3px * -12) $runner__suit,
            calc(3px * 9) calc(3px * -12) $runner__suit,
            calc(3px * 10) calc(3px * -12) $runner__suit,
            calc(3px * 11) calc(3px * -12) $runner__black,
            calc(3px * 12) calc(3px * -12) $runner__black,
            calc(3px * 13) calc(3px * -12) $runner__black,
            calc(3px * 14) calc(3px * -12) $runner__black,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__black,
            calc(3px * 17) calc(3px * -12) $runner__hair,
            calc(3px * 18) calc(3px * -12) $runner__hair,
            calc(3px * 19) calc(3px * -12) $runner__hair,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 22) calc(3px * -12) $runner__skin,
            calc(3px * 9) calc(3px * -13) $runner__suit,
            calc(3px * 10) calc(3px * -13) $runner__black,
            calc(3px * 11) calc(3px * -13) $runner__black,
            calc(3px * 12) calc(3px * -13) $runner__black,
            calc(3px * 13) calc(3px * -13) $runner__black,
            calc(3px * 14) calc(3px * -13) $runner__black,
            calc(3px * 15) calc(3px * -13) $runner__hair,
            calc(3px * 16) calc(3px * -13) $runner__hair,
            calc(3px * 17) calc(3px * -13) $runner__black,
            calc(3px * 18) calc(3px * -13) $runner__black,
            calc(3px * 19) calc(3px * -13) $runner__black,
            calc(3px * 20) calc(3px * -13) $runner__hair,
            calc(3px * 21) calc(3px * -13) $runner__skin,
            calc(3px * 22) calc(3px * -13) $runner__eyes,
            calc(3px * 9) calc(3px * -14) $runner__black,
            calc(3px * 10) calc(3px * -14) $runner__black,
            calc(3px * 11) calc(3px * -14) $runner__black,
            calc(3px * 12) calc(3px * -14) $runner__black,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__hair,
            calc(3px * 15) calc(3px * -14) $runner__hair,
            calc(3px * 16) calc(3px * -14) $runner__black,
            calc(3px * 17) calc(3px * -14) $runner__black,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__black,
            calc(3px * 21) calc(3px * -14) $runner__hair,
            calc(3px * 22) calc(3px * -14) $runner__eyes,
            calc(3px * 8) calc(3px * -15) $runner__black,
            calc(3px * 9) calc(3px * -15) $runner__black,
            calc(3px * 10) calc(3px * -15) $runner__black,
            calc(3px * 11) calc(3px * -15) $runner__black,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__hair,
            calc(3px * 15) calc(3px * -15) $runner__hair,
            calc(3px * 16) calc(3px * -15) $runner__suit,
            calc(3px * 17) calc(3px * -15) $runner__suit,
            calc(3px * 18) calc(3px * -15) $runner__black,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__black,
            calc(3px * 22) calc(3px * -15) $runner__hair,
            calc(3px * 23) calc(3px * -15) $runner__hair,
            calc(3px * 8) calc(3px * -16) $runner__black,
            calc(3px * 9) calc(3px * -16) $runner__black,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__hair,
            calc(3px * 15) calc(3px * -16) $runner__hair,
            calc(3px * 16) calc(3px * -16) $runner__suit,
            calc(3px * 17) calc(3px * -16) $runner__white,
            calc(3px * 18) calc(3px * -16) $runner__suit,
            calc(3px * 19) calc(3px * -16) $runner__black,
            calc(3px * 20) calc(3px * -16) $runner__black,
            calc(3px * 21) calc(3px * -16) $runner__black,
            calc(3px * 22) calc(3px * -16) $runner__black,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 24) calc(3px * -16) $runner__hair,
            calc(3px * 8) calc(3px * -17) $runner__hair,
            calc(3px * 9) calc(3px * -17) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__hair,
            calc(3px * 16) calc(3px * -17) $runner__hair,
            calc(3px * 17) calc(3px * -17) $runner__suit,
            calc(3px * 18) calc(3px * -17) $runner__suit,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__hair,
            calc(3px * 21) calc(3px * -17) $runner__hair,
            calc(3px * 22) calc(3px * -17) $runner__hair,
            calc(3px * 23) calc(3px * -17) $runner__black,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 8) calc(3px * -18) $runner__hair,
            calc(3px * 9) calc(3px * -18) $runner__hair,
            calc(3px * 10) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__hair,
            calc(3px * 15) calc(3px * -18) $runner__hair,
            calc(3px * 16) calc(3px * -18) $runner__hair,
            calc(3px * 17) calc(3px * -18) $runner__hair,
            calc(3px * 18) calc(3px * -18) $runner__suit,
            calc(3px * 19) calc(3px * -18) $runner__hair,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__hair,
            calc(3px * 24) calc(3px * -18) $runner__hair,
            calc(3px * 9) calc(3px * -19) $runner__hair,
            calc(3px * 10) calc(3px * -19) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__white,
            calc(3px * 16) calc(3px * -19) $runner__white,
            calc(3px * 17) calc(3px * -19) $runner__white,
            calc(3px * 18) calc(3px * -19) $runner__suit,
            calc(3px * 19) calc(3px * -19) $runner__white,
            calc(3px * 20) calc(3px * -19) $runner__white,
            calc(3px * 21) calc(3px * -19) $runner__white,
            calc(3px * 22) calc(3px * -19) $runner__white,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 11) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__white,
            calc(3px * 14) calc(3px * -20) $runner__white,
            calc(3px * 15) calc(3px * -20) $runner__white,
            calc(3px * 16) calc(3px * -20) $runner__hair,
            calc(3px * 17) calc(3px * -20) $runner__hair,
            calc(3px * 18) calc(3px * -20) $runner__suit,
            calc(3px * 19) calc(3px * -20) $runner__hair,
            calc(3px * 20) calc(3px * -20) $runner__hair,
            calc(3px * 21) calc(3px * -20) $runner__hair,
            calc(3px * 22) calc(3px * -20) $runner__hair,
            calc(3px * 23) calc(3px * -20) $runner__white,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__hair,
            calc(3px * 15) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -21) $runner__hair,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__suit,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__hair,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__suit,
            calc(3px * 19) calc(3px * -22) $runner__hair,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -23) $runner__suit,
            calc(3px * 17) calc(3px * -23) $runner__suit,
            calc(3px * 18) calc(3px * -23) $runner__suit;
        box-shadow: calc(3px * 9) calc(3px * 0) $runner__black,
            calc(3px * 10) calc(3px * 0) $runner__black,
            calc(3px * 11) calc(3px * 0) $runner__skin,
            calc(3px * 12) calc(3px * 0) $runner__skin,
            calc(3px * 9) calc(3px * -1) $runner__black,
            calc(3px * 10) calc(3px * -1) $runner__black,
            calc(3px * 11) calc(3px * -1) $runner__black,
            calc(3px * 9) calc(3px * -2) $runner__black,
            calc(3px * 10) calc(3px * -2) $runner__black,
            calc(3px * 11) calc(3px * -2) $runner__black,
            calc(3px * 8) calc(3px * -3) $runner__black,
            calc(3px * 9) calc(3px * -3) $runner__black,
            calc(3px * 10) calc(3px * -3) $runner__suit,
            calc(3px * 11) calc(3px * -3) $runner__black,
            calc(3px * 6) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -4) $runner__black,
            calc(3px * 8) calc(3px * -4) $runner__suit,
            calc(3px * 9) calc(3px * -4) $runner__suit,
            calc(3px * 10) calc(3px * -4) $runner__skin,
            calc(3px * 11) calc(3px * -4) $runner__skin,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 5) calc(3px * -5) $runner__skin,
            calc(3px * 6) calc(3px * -5) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__suit,
            calc(3px * 8) calc(3px * -5) $runner__skin,
            calc(3px * 9) calc(3px * -5) $runner__skin,
            calc(3px * 10) calc(3px * -5) $runner__black,
            calc(3px * 11) calc(3px * -5) $runner__black,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 19) calc(3px * -5) $runner__black,
            calc(3px * 20) calc(3px * -5) $runner__black,
            calc(3px * 21) calc(3px * -5) $runner__black,
            calc(3px * 22) calc(3px * -5) $runner__black,
            calc(3px * 23) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -6) $runner__skin,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__skin,
            calc(3px * 8) calc(3px * -6) $runner__black,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__black,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__black,
            calc(3px * 17) calc(3px * -6) $runner__black,
            calc(3px * 18) calc(3px * -6) $runner__black,
            calc(3px * 19) calc(3px * -6) $runner__suit,
            calc(3px * 20) calc(3px * -6) $runner__suit,
            calc(3px * 21) calc(3px * -6) $runner__suit,
            calc(3px * 22) calc(3px * -6) $runner__suit,
            calc(3px * 23) calc(3px * -6) $runner__suit,
            calc(3px * 6) calc(3px * -7) $runner__skin,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__black,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__black,
            calc(3px * 14) calc(3px * -7) $runner__black,
            calc(3px * 15) calc(3px * -7) $runner__black,
            calc(3px * 16) calc(3px * -7) $runner__black,
            calc(3px * 17) calc(3px * -7) $runner__suit,
            calc(3px * 18) calc(3px * -7) $runner__suit,
            calc(3px * 19) calc(3px * -7) $runner__suit,
            calc(3px * 20) calc(3px * -7) $runner__suit,
            calc(3px * 21) calc(3px * -7) $runner__suit,
            calc(3px * 22) calc(3px * -7) $runner__suit,
            calc(3px * 23) calc(3px * -7) $runner__suit,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__black,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__black,
            calc(3px * 13) calc(3px * -8) $runner__black,
            calc(3px * 14) calc(3px * -8) $runner__black,
            calc(3px * 15) calc(3px * -8) $runner__black,
            calc(3px * 16) calc(3px * -8) $runner__suit,
            calc(3px * 17) calc(3px * -8) $runner__suit,
            calc(3px * 18) calc(3px * -8) $runner__suit,
            calc(3px * 19) calc(3px * -8) $runner__suit,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__suit,
            calc(3px * 23) calc(3px * -8) $runner__skin,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__black,
            calc(3px * 12) calc(3px * -9) $runner__black,
            calc(3px * 13) calc(3px * -9) $runner__black,
            calc(3px * 14) calc(3px * -9) $runner__black,
            calc(3px * 15) calc(3px * -9) $runner__suit,
            calc(3px * 16) calc(3px * -9) $runner__suit,
            calc(3px * 17) calc(3px * -9) $runner__suit,
            calc(3px * 18) calc(3px * -9) $runner__suit,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__black,
            calc(3px * 13) calc(3px * -10) $runner__black,
            calc(3px * 14) calc(3px * -10) $runner__black,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__suit,
            calc(3px * 17) calc(3px * -10) $runner__suit,
            calc(3px * 18) calc(3px * -10) $runner__suit,
            calc(3px * 19) calc(3px * -10) $runner__suit,
            calc(3px * 20) calc(3px * -10) $runner__suit,
            calc(3px * 21) calc(3px * -10) $runner__suit,
            calc(3px * 8) calc(3px * -11) $runner__suit,
            calc(3px * 9) calc(3px * -11) $runner__suit,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__black,
            calc(3px * 13) calc(3px * -11) $runner__black,
            calc(3px * 14) calc(3px * -11) $runner__black,
            calc(3px * 15) calc(3px * -11) $runner__black,
            calc(3px * 16) calc(3px * -11) $runner__suit,
            calc(3px * 17) calc(3px * -11) $runner__suit,
            calc(3px * 18) calc(3px * -11) $runner__suit,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__skin,
            calc(3px * 21) calc(3px * -11) $runner__skin,
            calc(3px * 8) calc(3px * -12) $runner__suit,
            calc(3px * 9) calc(3px * -12) $runner__suit,
            calc(3px * 10) calc(3px * -12) $runner__suit,
            calc(3px * 11) calc(3px * -12) $runner__black,
            calc(3px * 12) calc(3px * -12) $runner__black,
            calc(3px * 13) calc(3px * -12) $runner__black,
            calc(3px * 14) calc(3px * -12) $runner__black,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__black,
            calc(3px * 17) calc(3px * -12) $runner__hair,
            calc(3px * 18) calc(3px * -12) $runner__hair,
            calc(3px * 19) calc(3px * -12) $runner__hair,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 22) calc(3px * -12) $runner__skin,
            calc(3px * 9) calc(3px * -13) $runner__suit,
            calc(3px * 10) calc(3px * -13) $runner__black,
            calc(3px * 11) calc(3px * -13) $runner__black,
            calc(3px * 12) calc(3px * -13) $runner__black,
            calc(3px * 13) calc(3px * -13) $runner__black,
            calc(3px * 14) calc(3px * -13) $runner__black,
            calc(3px * 15) calc(3px * -13) $runner__hair,
            calc(3px * 16) calc(3px * -13) $runner__hair,
            calc(3px * 17) calc(3px * -13) $runner__black,
            calc(3px * 18) calc(3px * -13) $runner__black,
            calc(3px * 19) calc(3px * -13) $runner__black,
            calc(3px * 20) calc(3px * -13) $runner__hair,
            calc(3px * 21) calc(3px * -13) $runner__skin,
            calc(3px * 22) calc(3px * -13) $runner__eyes,
            calc(3px * 9) calc(3px * -14) $runner__black,
            calc(3px * 10) calc(3px * -14) $runner__black,
            calc(3px * 11) calc(3px * -14) $runner__black,
            calc(3px * 12) calc(3px * -14) $runner__black,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__hair,
            calc(3px * 15) calc(3px * -14) $runner__hair,
            calc(3px * 16) calc(3px * -14) $runner__black,
            calc(3px * 17) calc(3px * -14) $runner__black,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__black,
            calc(3px * 21) calc(3px * -14) $runner__hair,
            calc(3px * 22) calc(3px * -14) $runner__eyes,
            calc(3px * 8) calc(3px * -15) $runner__black,
            calc(3px * 9) calc(3px * -15) $runner__black,
            calc(3px * 10) calc(3px * -15) $runner__black,
            calc(3px * 11) calc(3px * -15) $runner__black,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__hair,
            calc(3px * 15) calc(3px * -15) $runner__hair,
            calc(3px * 16) calc(3px * -15) $runner__suit,
            calc(3px * 17) calc(3px * -15) $runner__suit,
            calc(3px * 18) calc(3px * -15) $runner__black,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__black,
            calc(3px * 22) calc(3px * -15) $runner__hair,
            calc(3px * 23) calc(3px * -15) $runner__hair,
            calc(3px * 8) calc(3px * -16) $runner__black,
            calc(3px * 9) calc(3px * -16) $runner__black,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__hair,
            calc(3px * 15) calc(3px * -16) $runner__hair,
            calc(3px * 16) calc(3px * -16) $runner__suit,
            calc(3px * 17) calc(3px * -16) $runner__white,
            calc(3px * 18) calc(3px * -16) $runner__suit,
            calc(3px * 19) calc(3px * -16) $runner__black,
            calc(3px * 20) calc(3px * -16) $runner__black,
            calc(3px * 21) calc(3px * -16) $runner__black,
            calc(3px * 22) calc(3px * -16) $runner__black,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 24) calc(3px * -16) $runner__hair,
            calc(3px * 8) calc(3px * -17) $runner__hair,
            calc(3px * 9) calc(3px * -17) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__hair,
            calc(3px * 16) calc(3px * -17) $runner__hair,
            calc(3px * 17) calc(3px * -17) $runner__suit,
            calc(3px * 18) calc(3px * -17) $runner__suit,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__hair,
            calc(3px * 21) calc(3px * -17) $runner__hair,
            calc(3px * 22) calc(3px * -17) $runner__hair,
            calc(3px * 23) calc(3px * -17) $runner__black,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 8) calc(3px * -18) $runner__hair,
            calc(3px * 9) calc(3px * -18) $runner__hair,
            calc(3px * 10) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__hair,
            calc(3px * 15) calc(3px * -18) $runner__hair,
            calc(3px * 16) calc(3px * -18) $runner__hair,
            calc(3px * 17) calc(3px * -18) $runner__hair,
            calc(3px * 18) calc(3px * -18) $runner__suit,
            calc(3px * 19) calc(3px * -18) $runner__hair,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__hair,
            calc(3px * 24) calc(3px * -18) $runner__hair,
            calc(3px * 9) calc(3px * -19) $runner__hair,
            calc(3px * 10) calc(3px * -19) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__white,
            calc(3px * 16) calc(3px * -19) $runner__white,
            calc(3px * 17) calc(3px * -19) $runner__white,
            calc(3px * 18) calc(3px * -19) $runner__suit,
            calc(3px * 19) calc(3px * -19) $runner__white,
            calc(3px * 20) calc(3px * -19) $runner__white,
            calc(3px * 21) calc(3px * -19) $runner__white,
            calc(3px * 22) calc(3px * -19) $runner__white,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 11) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__white,
            calc(3px * 14) calc(3px * -20) $runner__white,
            calc(3px * 15) calc(3px * -20) $runner__white,
            calc(3px * 16) calc(3px * -20) $runner__hair,
            calc(3px * 17) calc(3px * -20) $runner__hair,
            calc(3px * 18) calc(3px * -20) $runner__suit,
            calc(3px * 19) calc(3px * -20) $runner__hair,
            calc(3px * 20) calc(3px * -20) $runner__hair,
            calc(3px * 21) calc(3px * -20) $runner__hair,
            calc(3px * 22) calc(3px * -20) $runner__hair,
            calc(3px * 23) calc(3px * -20) $runner__white,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__hair,
            calc(3px * 15) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -21) $runner__hair,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__suit,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__hair,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__suit,
            calc(3px * 19) calc(3px * -22) $runner__hair,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -23) $runner__suit,
            calc(3px * 17) calc(3px * -23) $runner__suit,
            calc(3px * 18) calc(3px * -23) $runner__suit;
    }
    .runner__frame4--shadow {
        -webkit-box-shadow: calc(3px * 9) calc(3px * 0) $runner__shadow,
            calc(3px * 10) calc(3px * 0) $runner__shadow,
            calc(3px * 11) calc(3px * 0) $runner__shadow,
            calc(3px * 12) calc(3px * 0) $runner__shadow,
            calc(3px * 9) calc(3px * 1) $runner__shadow,
            calc(3px * 10) calc(3px * 1) $runner__shadow,
            calc(3px * 11) calc(3px * 1) $runner__shadow,
            calc(3px * 9) calc(3px * 2) $runner__shadow,
            calc(3px * 10) calc(3px * 2) $runner__shadow,
            calc(3px * 11) calc(3px * 2) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 11) calc(3px * 3) $runner__shadow,
            calc(3px * 6) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 4) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 5) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 5) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 19) calc(3px * 5) $runner__shadow,
            calc(3px * 20) calc(3px * 5) $runner__shadow,
            calc(3px * 21) calc(3px * 5) $runner__shadow,
            calc(3px * 22) calc(3px * 5) $runner__shadow,
            calc(3px * 23) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 17) calc(3px * 6) $runner__shadow,
            calc(3px * 18) calc(3px * 6) $runner__shadow,
            calc(3px * 19) calc(3px * 6) $runner__shadow,
            calc(3px * 20) calc(3px * 6) $runner__shadow,
            calc(3px * 21) calc(3px * 6) $runner__shadow,
            calc(3px * 22) calc(3px * 6) $runner__shadow,
            calc(3px * 23) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 16) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 19) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 23) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 23) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 21) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 8) calc(3px * 12) $runner__shadow,
            calc(3px * 9) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 22) calc(3px * 12) $runner__shadow,
            calc(3px * 9) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 8) calc(3px * 15) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 23) calc(3px * 15) $runner__shadow,
            calc(3px * 8) calc(3px * 16) $runner__shadow,
            calc(3px * 9) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 24) calc(3px * 16) $runner__shadow,
            calc(3px * 8) calc(3px * 17) $runner__shadow,
            calc(3px * 9) calc(3px * 17) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 8) calc(3px * 18) $runner__shadow,
            calc(3px * 9) calc(3px * 18) $runner__shadow,
            calc(3px * 10) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 9) calc(3px * 19) $runner__shadow,
            calc(3px * 10) calc(3px * 19) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 11) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 23) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow;
        box-shadow: calc(3px * 9) calc(3px * 0) $runner__shadow,
            calc(3px * 10) calc(3px * 0) $runner__shadow,
            calc(3px * 11) calc(3px * 0) $runner__shadow,
            calc(3px * 12) calc(3px * 0) $runner__shadow,
            calc(3px * 9) calc(3px * 1) $runner__shadow,
            calc(3px * 10) calc(3px * 1) $runner__shadow,
            calc(3px * 11) calc(3px * 1) $runner__shadow,
            calc(3px * 9) calc(3px * 2) $runner__shadow,
            calc(3px * 10) calc(3px * 2) $runner__shadow,
            calc(3px * 11) calc(3px * 2) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 11) calc(3px * 3) $runner__shadow,
            calc(3px * 6) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 4) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 5) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 5) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 19) calc(3px * 5) $runner__shadow,
            calc(3px * 20) calc(3px * 5) $runner__shadow,
            calc(3px * 21) calc(3px * 5) $runner__shadow,
            calc(3px * 22) calc(3px * 5) $runner__shadow,
            calc(3px * 23) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 17) calc(3px * 6) $runner__shadow,
            calc(3px * 18) calc(3px * 6) $runner__shadow,
            calc(3px * 19) calc(3px * 6) $runner__shadow,
            calc(3px * 20) calc(3px * 6) $runner__shadow,
            calc(3px * 21) calc(3px * 6) $runner__shadow,
            calc(3px * 22) calc(3px * 6) $runner__shadow,
            calc(3px * 23) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 16) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 19) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 23) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 23) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 21) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 8) calc(3px * 12) $runner__shadow,
            calc(3px * 9) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 22) calc(3px * 12) $runner__shadow,
            calc(3px * 9) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 8) calc(3px * 15) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 23) calc(3px * 15) $runner__shadow,
            calc(3px * 8) calc(3px * 16) $runner__shadow,
            calc(3px * 9) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 24) calc(3px * 16) $runner__shadow,
            calc(3px * 8) calc(3px * 17) $runner__shadow,
            calc(3px * 9) calc(3px * 17) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 8) calc(3px * 18) $runner__shadow,
            calc(3px * 9) calc(3px * 18) $runner__shadow,
            calc(3px * 10) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 9) calc(3px * 19) $runner__shadow,
            calc(3px * 10) calc(3px * 19) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 11) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 23) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow;
    }
    .runner__frame5 {
        -webkit-box-shadow: calc(3px * 1) calc(3px * 0) $runner__black,
            calc(3px * 2) calc(3px * 0) $runner__skin,
            calc(3px * 3) calc(3px * 0) $runner__skin,
            calc(3px * 1) calc(3px * -1) $runner__black,
            calc(3px * 2) calc(3px * -1) $runner__black,
            calc(3px * 3) calc(3px * -1) $runner__black,
            calc(3px * 7) calc(3px * -1) $runner__skin,
            calc(3px * 1) calc(3px * -2) $runner__black,
            calc(3px * 2) calc(3px * -2) $runner__black,
            calc(3px * 3) calc(3px * -2) $runner__black,
            calc(3px * 4) calc(3px * -2) $runner__black,
            calc(3px * 5) calc(3px * -2) $runner__black,
            calc(3px * 6) calc(3px * -2) $runner__skin,
            calc(3px * 7) calc(3px * -2) $runner__suit,
            calc(3px * 8) calc(3px * -2) $runner__suit,
            calc(3px * 2) calc(3px * -3) $runner__black,
            calc(3px * 3) calc(3px * -3) $runner__black,
            calc(3px * 4) calc(3px * -3) $runner__black,
            calc(3px * 5) calc(3px * -3) $runner__black,
            calc(3px * 6) calc(3px * -3) $runner__skin,
            calc(3px * 7) calc(3px * -3) $runner__suit,
            calc(3px * 8) calc(3px * -3) $runner__suit,
            calc(3px * 9) calc(3px * -3) $runner__suit,
            calc(3px * 10) calc(3px * -3) $runner__suit,
            calc(3px * 18) calc(3px * -3) $runner__black,
            calc(3px * 19) calc(3px * -3) $runner__black,
            calc(3px * 20) calc(3px * -3) $runner__black,
            calc(3px * 3) calc(3px * -4) $runner__black,
            calc(3px * 4) calc(3px * -4) $runner__black,
            calc(3px * 5) calc(3px * -4) $runner__black,
            calc(3px * 6) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -4) $runner__suit,
            calc(3px * 8) calc(3px * -4) $runner__suit,
            calc(3px * 9) calc(3px * -4) $runner__suit,
            calc(3px * 10) calc(3px * -4) $runner__skin,
            calc(3px * 11) calc(3px * -4) $runner__black,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 15) calc(3px * -4) $runner__black,
            calc(3px * 16) calc(3px * -4) $runner__black,
            calc(3px * 17) calc(3px * -4) $runner__black,
            calc(3px * 18) calc(3px * -4) $runner__suit,
            calc(3px * 19) calc(3px * -4) $runner__suit,
            calc(3px * 20) calc(3px * -4) $runner__suit,
            calc(3px * 21) calc(3px * -4) $runner__black,
            calc(3px * 4) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -5) $runner__black,
            calc(3px * 6) calc(3px * -5) $runner__suit,
            calc(3px * 7) calc(3px * -5) $runner__suit,
            calc(3px * 8) calc(3px * -5) $runner__suit,
            calc(3px * 9) calc(3px * -5) $runner__skin,
            calc(3px * 10) calc(3px * -5) $runner__black,
            calc(3px * 11) calc(3px * -5) $runner__black,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 14) calc(3px * -5) $runner__black,
            calc(3px * 15) calc(3px * -5) $runner__suit,
            calc(3px * 16) calc(3px * -5) $runner__suit,
            calc(3px * 17) calc(3px * -5) $runner__suit,
            calc(3px * 18) calc(3px * -5) $runner__suit,
            calc(3px * 19) calc(3px * -5) $runner__suit,
            calc(3px * 20) calc(3px * -5) $runner__suit,
            calc(3px * 21) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -6) $runner__black,
            calc(3px * 6) calc(3px * -6) $runner__suit,
            calc(3px * 7) calc(3px * -6) $runner__skin,
            calc(3px * 8) calc(3px * -6) $runner__skin,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__black,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__suit,
            calc(3px * 15) calc(3px * -6) $runner__suit,
            calc(3px * 16) calc(3px * -6) $runner__suit,
            calc(3px * 17) calc(3px * -6) $runner__suit,
            calc(3px * 18) calc(3px * -6) $runner__suit,
            calc(3px * 19) calc(3px * -6) $runner__suit,
            calc(3px * 20) calc(3px * -6) $runner__suit,
            calc(3px * 21) calc(3px * -6) $runner__skin,
            calc(3px * 5) calc(3px * -7) $runner__skin,
            calc(3px * 6) calc(3px * -7) $runner__skin,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__black,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__suit,
            calc(3px * 14) calc(3px * -7) $runner__suit,
            calc(3px * 15) calc(3px * -7) $runner__suit,
            calc(3px * 16) calc(3px * -7) $runner__suit,
            calc(3px * 17) calc(3px * -7) $runner__suit,
            calc(3px * 18) calc(3px * -7) $runner__black,
            calc(3px * 19) calc(3px * -7) $runner__black,
            calc(3px * 20) calc(3px * -7) $runner__black,
            calc(3px * 21) calc(3px * -7) $runner__black,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__black,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__black,
            calc(3px * 13) calc(3px * -8) $runner__suit,
            calc(3px * 14) calc(3px * -8) $runner__suit,
            calc(3px * 15) calc(3px * -8) $runner__suit,
            calc(3px * 16) calc(3px * -8) $runner__suit,
            calc(3px * 17) calc(3px * -8) $runner__black,
            calc(3px * 18) calc(3px * -8) $runner__skin,
            calc(3px * 19) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__black,
            calc(3px * 12) calc(3px * -9) $runner__black,
            calc(3px * 13) calc(3px * -9) $runner__suit,
            calc(3px * 14) calc(3px * -9) $runner__suit,
            calc(3px * 15) calc(3px * -9) $runner__suit,
            calc(3px * 16) calc(3px * -9) $runner__black,
            calc(3px * 17) calc(3px * -9) $runner__black,
            calc(3px * 18) calc(3px * -9) $runner__suit,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__black,
            calc(3px * 13) calc(3px * -10) $runner__black,
            calc(3px * 14) calc(3px * -10) $runner__black,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__suit,
            calc(3px * 17) calc(3px * -10) $runner__suit,
            calc(3px * 18) calc(3px * -10) $runner__suit,
            calc(3px * 19) calc(3px * -10) $runner__skin,
            calc(3px * 20) calc(3px * -10) $runner__skin,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__hair,
            calc(3px * 13) calc(3px * -11) $runner__hair,
            calc(3px * 14) calc(3px * -11) $runner__hair,
            calc(3px * 15) calc(3px * -11) $runner__hair,
            calc(3px * 16) calc(3px * -11) $runner__hair,
            calc(3px * 17) calc(3px * -11) $runner__hair,
            calc(3px * 18) calc(3px * -11) $runner__skin,
            calc(3px * 19) calc(3px * -11) $runner__skin,
            calc(3px * 20) calc(3px * -11) $runner__skin,
            calc(3px * 21) calc(3px * -11) $runner__skin,
            calc(3px * 9) calc(3px * -12) $runner__black,
            calc(3px * 10) calc(3px * -12) $runner__black,
            calc(3px * 11) calc(3px * -12) $runner__hair,
            calc(3px * 12) calc(3px * -12) $runner__hair,
            calc(3px * 13) calc(3px * -12) $runner__hair,
            calc(3px * 14) calc(3px * -12) $runner__hair,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__black,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__hair,
            calc(3px * 19) calc(3px * -12) $runner__skin,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__eyes,
            calc(3px * 8) calc(3px * -13) $runner__black,
            calc(3px * 9) calc(3px * -13) $runner__black,
            calc(3px * 10) calc(3px * -13) $runner__hair,
            calc(3px * 11) calc(3px * -13) $runner__hair,
            calc(3px * 12) calc(3px * -13) $runner__hair,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__black,
            calc(3px * 15) calc(3px * -13) $runner__black,
            calc(3px * 16) calc(3px * -13) $runner__black,
            calc(3px * 17) calc(3px * -13) $runner__black,
            calc(3px * 18) calc(3px * -13) $runner__black,
            calc(3px * 19) calc(3px * -13) $runner__skin,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__eyes,
            calc(3px * 8) calc(3px * -14) $runner__black,
            calc(3px * 9) calc(3px * -14) $runner__hair,
            calc(3px * 10) calc(3px * -14) $runner__hair,
            calc(3px * 11) calc(3px * -14) $runner__hair,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__black,
            calc(3px * 15) calc(3px * -14) $runner__suit,
            calc(3px * 16) calc(3px * -14) $runner__suit,
            calc(3px * 17) calc(3px * -14) $runner__black,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__hair,
            calc(3px * 21) calc(3px * -14) $runner__hair,
            calc(3px * 22) calc(3px * -14) $runner__hair,
            calc(3px * 8) calc(3px * -15) $runner__hair,
            calc(3px * 9) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__black,
            calc(3px * 15) calc(3px * -15) $runner__white,
            calc(3px * 16) calc(3px * -15) $runner__suit,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__hair,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__black,
            calc(3px * 22) calc(3px * -15) $runner__hair,
            calc(3px * 23) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__black,
            calc(3px * 15) calc(3px * -16) $runner__black,
            calc(3px * 16) calc(3px * -16) $runner__suit,
            calc(3px * 17) calc(3px * -16) $runner__hair,
            calc(3px * 18) calc(3px * -16) $runner__hair,
            calc(3px * 19) calc(3px * -16) $runner__hair,
            calc(3px * 20) calc(3px * -16) $runner__hair,
            calc(3px * 21) calc(3px * -16) $runner__hair,
            calc(3px * 22) calc(3px * -16) $runner__black,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__hair,
            calc(3px * 16) calc(3px * -17) $runner__suit,
            calc(3px * 17) calc(3px * -17) $runner__hair,
            calc(3px * 18) calc(3px * -17) $runner__hair,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__hair,
            calc(3px * 21) calc(3px * -17) $runner__hair,
            calc(3px * 22) calc(3px * -17) $runner__hair,
            calc(3px * 23) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__white,
            calc(3px * 15) calc(3px * -18) $runner__white,
            calc(3px * 16) calc(3px * -18) $runner__suit,
            calc(3px * 17) calc(3px * -18) $runner__white,
            calc(3px * 18) calc(3px * -18) $runner__white,
            calc(3px * 19) calc(3px * -18) $runner__white,
            calc(3px * 20) calc(3px * -18) $runner__white,
            calc(3px * 21) calc(3px * -18) $runner__white,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__white,
            calc(3px * 13) calc(3px * -19) $runner__white,
            calc(3px * 14) calc(3px * -19) $runner__white,
            calc(3px * 15) calc(3px * -19) $runner__hair,
            calc(3px * 16) calc(3px * -19) $runner__suit,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__hair,
            calc(3px * 19) calc(3px * -19) $runner__hair,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__white,
            calc(3px * 12) calc(3px * -20) $runner__white,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__hair,
            calc(3px * 15) calc(3px * -20) $runner__hair,
            calc(3px * 16) calc(3px * -20) $runner__suit,
            calc(3px * 17) calc(3px * -20) $runner__hair,
            calc(3px * 18) calc(3px * -20) $runner__hair,
            calc(3px * 19) calc(3px * -20) $runner__hair,
            calc(3px * 20) calc(3px * -20) $runner__hair,
            calc(3px * 21) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__hair,
            calc(3px * 14) calc(3px * -21) $runner__hair,
            calc(3px * 15) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -21) $runner__suit,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__suit,
            calc(3px * 17) calc(3px * -22) $runner__black;
        box-shadow: calc(3px * 1) calc(3px * 0) $runner__black,
            calc(3px * 2) calc(3px * 0) $runner__skin,
            calc(3px * 3) calc(3px * 0) $runner__skin,
            calc(3px * 1) calc(3px * -1) $runner__black,
            calc(3px * 2) calc(3px * -1) $runner__black,
            calc(3px * 3) calc(3px * -1) $runner__black,
            calc(3px * 7) calc(3px * -1) $runner__skin,
            calc(3px * 1) calc(3px * -2) $runner__black,
            calc(3px * 2) calc(3px * -2) $runner__black,
            calc(3px * 3) calc(3px * -2) $runner__black,
            calc(3px * 4) calc(3px * -2) $runner__black,
            calc(3px * 5) calc(3px * -2) $runner__black,
            calc(3px * 6) calc(3px * -2) $runner__skin,
            calc(3px * 7) calc(3px * -2) $runner__suit,
            calc(3px * 8) calc(3px * -2) $runner__suit,
            calc(3px * 2) calc(3px * -3) $runner__black,
            calc(3px * 3) calc(3px * -3) $runner__black,
            calc(3px * 4) calc(3px * -3) $runner__black,
            calc(3px * 5) calc(3px * -3) $runner__black,
            calc(3px * 6) calc(3px * -3) $runner__skin,
            calc(3px * 7) calc(3px * -3) $runner__suit,
            calc(3px * 8) calc(3px * -3) $runner__suit,
            calc(3px * 9) calc(3px * -3) $runner__suit,
            calc(3px * 10) calc(3px * -3) $runner__suit,
            calc(3px * 18) calc(3px * -3) $runner__black,
            calc(3px * 19) calc(3px * -3) $runner__black,
            calc(3px * 20) calc(3px * -3) $runner__black,
            calc(3px * 3) calc(3px * -4) $runner__black,
            calc(3px * 4) calc(3px * -4) $runner__black,
            calc(3px * 5) calc(3px * -4) $runner__black,
            calc(3px * 6) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -4) $runner__suit,
            calc(3px * 8) calc(3px * -4) $runner__suit,
            calc(3px * 9) calc(3px * -4) $runner__suit,
            calc(3px * 10) calc(3px * -4) $runner__skin,
            calc(3px * 11) calc(3px * -4) $runner__black,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 15) calc(3px * -4) $runner__black,
            calc(3px * 16) calc(3px * -4) $runner__black,
            calc(3px * 17) calc(3px * -4) $runner__black,
            calc(3px * 18) calc(3px * -4) $runner__suit,
            calc(3px * 19) calc(3px * -4) $runner__suit,
            calc(3px * 20) calc(3px * -4) $runner__suit,
            calc(3px * 21) calc(3px * -4) $runner__black,
            calc(3px * 4) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -5) $runner__black,
            calc(3px * 6) calc(3px * -5) $runner__suit,
            calc(3px * 7) calc(3px * -5) $runner__suit,
            calc(3px * 8) calc(3px * -5) $runner__suit,
            calc(3px * 9) calc(3px * -5) $runner__skin,
            calc(3px * 10) calc(3px * -5) $runner__black,
            calc(3px * 11) calc(3px * -5) $runner__black,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 14) calc(3px * -5) $runner__black,
            calc(3px * 15) calc(3px * -5) $runner__suit,
            calc(3px * 16) calc(3px * -5) $runner__suit,
            calc(3px * 17) calc(3px * -5) $runner__suit,
            calc(3px * 18) calc(3px * -5) $runner__suit,
            calc(3px * 19) calc(3px * -5) $runner__suit,
            calc(3px * 20) calc(3px * -5) $runner__suit,
            calc(3px * 21) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -6) $runner__black,
            calc(3px * 6) calc(3px * -6) $runner__suit,
            calc(3px * 7) calc(3px * -6) $runner__skin,
            calc(3px * 8) calc(3px * -6) $runner__skin,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__black,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__suit,
            calc(3px * 15) calc(3px * -6) $runner__suit,
            calc(3px * 16) calc(3px * -6) $runner__suit,
            calc(3px * 17) calc(3px * -6) $runner__suit,
            calc(3px * 18) calc(3px * -6) $runner__suit,
            calc(3px * 19) calc(3px * -6) $runner__suit,
            calc(3px * 20) calc(3px * -6) $runner__suit,
            calc(3px * 21) calc(3px * -6) $runner__skin,
            calc(3px * 5) calc(3px * -7) $runner__skin,
            calc(3px * 6) calc(3px * -7) $runner__skin,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__black,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__suit,
            calc(3px * 14) calc(3px * -7) $runner__suit,
            calc(3px * 15) calc(3px * -7) $runner__suit,
            calc(3px * 16) calc(3px * -7) $runner__suit,
            calc(3px * 17) calc(3px * -7) $runner__suit,
            calc(3px * 18) calc(3px * -7) $runner__black,
            calc(3px * 19) calc(3px * -7) $runner__black,
            calc(3px * 20) calc(3px * -7) $runner__black,
            calc(3px * 21) calc(3px * -7) $runner__black,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__black,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__black,
            calc(3px * 13) calc(3px * -8) $runner__suit,
            calc(3px * 14) calc(3px * -8) $runner__suit,
            calc(3px * 15) calc(3px * -8) $runner__suit,
            calc(3px * 16) calc(3px * -8) $runner__suit,
            calc(3px * 17) calc(3px * -8) $runner__black,
            calc(3px * 18) calc(3px * -8) $runner__skin,
            calc(3px * 19) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__black,
            calc(3px * 12) calc(3px * -9) $runner__black,
            calc(3px * 13) calc(3px * -9) $runner__suit,
            calc(3px * 14) calc(3px * -9) $runner__suit,
            calc(3px * 15) calc(3px * -9) $runner__suit,
            calc(3px * 16) calc(3px * -9) $runner__black,
            calc(3px * 17) calc(3px * -9) $runner__black,
            calc(3px * 18) calc(3px * -9) $runner__suit,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__black,
            calc(3px * 13) calc(3px * -10) $runner__black,
            calc(3px * 14) calc(3px * -10) $runner__black,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__suit,
            calc(3px * 17) calc(3px * -10) $runner__suit,
            calc(3px * 18) calc(3px * -10) $runner__suit,
            calc(3px * 19) calc(3px * -10) $runner__skin,
            calc(3px * 20) calc(3px * -10) $runner__skin,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__hair,
            calc(3px * 13) calc(3px * -11) $runner__hair,
            calc(3px * 14) calc(3px * -11) $runner__hair,
            calc(3px * 15) calc(3px * -11) $runner__hair,
            calc(3px * 16) calc(3px * -11) $runner__hair,
            calc(3px * 17) calc(3px * -11) $runner__hair,
            calc(3px * 18) calc(3px * -11) $runner__skin,
            calc(3px * 19) calc(3px * -11) $runner__skin,
            calc(3px * 20) calc(3px * -11) $runner__skin,
            calc(3px * 21) calc(3px * -11) $runner__skin,
            calc(3px * 9) calc(3px * -12) $runner__black,
            calc(3px * 10) calc(3px * -12) $runner__black,
            calc(3px * 11) calc(3px * -12) $runner__hair,
            calc(3px * 12) calc(3px * -12) $runner__hair,
            calc(3px * 13) calc(3px * -12) $runner__hair,
            calc(3px * 14) calc(3px * -12) $runner__hair,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__black,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__hair,
            calc(3px * 19) calc(3px * -12) $runner__skin,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__eyes,
            calc(3px * 8) calc(3px * -13) $runner__black,
            calc(3px * 9) calc(3px * -13) $runner__black,
            calc(3px * 10) calc(3px * -13) $runner__hair,
            calc(3px * 11) calc(3px * -13) $runner__hair,
            calc(3px * 12) calc(3px * -13) $runner__hair,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__black,
            calc(3px * 15) calc(3px * -13) $runner__black,
            calc(3px * 16) calc(3px * -13) $runner__black,
            calc(3px * 17) calc(3px * -13) $runner__black,
            calc(3px * 18) calc(3px * -13) $runner__black,
            calc(3px * 19) calc(3px * -13) $runner__skin,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__eyes,
            calc(3px * 8) calc(3px * -14) $runner__black,
            calc(3px * 9) calc(3px * -14) $runner__hair,
            calc(3px * 10) calc(3px * -14) $runner__hair,
            calc(3px * 11) calc(3px * -14) $runner__hair,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__black,
            calc(3px * 15) calc(3px * -14) $runner__suit,
            calc(3px * 16) calc(3px * -14) $runner__suit,
            calc(3px * 17) calc(3px * -14) $runner__black,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__hair,
            calc(3px * 21) calc(3px * -14) $runner__hair,
            calc(3px * 22) calc(3px * -14) $runner__hair,
            calc(3px * 8) calc(3px * -15) $runner__hair,
            calc(3px * 9) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__black,
            calc(3px * 15) calc(3px * -15) $runner__white,
            calc(3px * 16) calc(3px * -15) $runner__suit,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__hair,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__black,
            calc(3px * 22) calc(3px * -15) $runner__hair,
            calc(3px * 23) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__black,
            calc(3px * 15) calc(3px * -16) $runner__black,
            calc(3px * 16) calc(3px * -16) $runner__suit,
            calc(3px * 17) calc(3px * -16) $runner__hair,
            calc(3px * 18) calc(3px * -16) $runner__hair,
            calc(3px * 19) calc(3px * -16) $runner__hair,
            calc(3px * 20) calc(3px * -16) $runner__hair,
            calc(3px * 21) calc(3px * -16) $runner__hair,
            calc(3px * 22) calc(3px * -16) $runner__black,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__hair,
            calc(3px * 16) calc(3px * -17) $runner__suit,
            calc(3px * 17) calc(3px * -17) $runner__hair,
            calc(3px * 18) calc(3px * -17) $runner__hair,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__hair,
            calc(3px * 21) calc(3px * -17) $runner__hair,
            calc(3px * 22) calc(3px * -17) $runner__hair,
            calc(3px * 23) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__white,
            calc(3px * 15) calc(3px * -18) $runner__white,
            calc(3px * 16) calc(3px * -18) $runner__suit,
            calc(3px * 17) calc(3px * -18) $runner__white,
            calc(3px * 18) calc(3px * -18) $runner__white,
            calc(3px * 19) calc(3px * -18) $runner__white,
            calc(3px * 20) calc(3px * -18) $runner__white,
            calc(3px * 21) calc(3px * -18) $runner__white,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__white,
            calc(3px * 13) calc(3px * -19) $runner__white,
            calc(3px * 14) calc(3px * -19) $runner__white,
            calc(3px * 15) calc(3px * -19) $runner__hair,
            calc(3px * 16) calc(3px * -19) $runner__suit,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__hair,
            calc(3px * 19) calc(3px * -19) $runner__hair,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__white,
            calc(3px * 12) calc(3px * -20) $runner__white,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__hair,
            calc(3px * 15) calc(3px * -20) $runner__hair,
            calc(3px * 16) calc(3px * -20) $runner__suit,
            calc(3px * 17) calc(3px * -20) $runner__hair,
            calc(3px * 18) calc(3px * -20) $runner__hair,
            calc(3px * 19) calc(3px * -20) $runner__hair,
            calc(3px * 20) calc(3px * -20) $runner__hair,
            calc(3px * 21) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__hair,
            calc(3px * 14) calc(3px * -21) $runner__hair,
            calc(3px * 15) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -21) $runner__suit,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__suit,
            calc(3px * 17) calc(3px * -22) $runner__black;
    }

    .runner__frame5--shadow {
        -webkit-box-shadow: calc(3px * 1) calc(3px * 0) $runner__shadow,
            calc(3px * 2) calc(3px * 0) $runner__shadow,
            calc(3px * 3) calc(3px * 0) $runner__shadow,
            calc(3px * 1) calc(3px * 1) $runner__shadow,
            calc(3px * 2) calc(3px * 1) $runner__shadow,
            calc(3px * 3) calc(3px * 1) $runner__shadow,
            calc(3px * 7) calc(3px * 1) $runner__shadow,
            calc(3px * 1) calc(3px * 2) $runner__shadow,
            calc(3px * 2) calc(3px * 2) $runner__shadow,
            calc(3px * 3) calc(3px * 2) $runner__shadow,
            calc(3px * 4) calc(3px * 2) $runner__shadow,
            calc(3px * 5) calc(3px * 2) $runner__shadow,
            calc(3px * 6) calc(3px * 2) $runner__shadow,
            calc(3px * 7) calc(3px * 2) $runner__shadow,
            calc(3px * 8) calc(3px * 2) $runner__shadow,
            calc(3px * 2) calc(3px * 3) $runner__shadow,
            calc(3px * 3) calc(3px * 3) $runner__shadow,
            calc(3px * 4) calc(3px * 3) $runner__shadow,
            calc(3px * 5) calc(3px * 3) $runner__shadow,
            calc(3px * 6) calc(3px * 3) $runner__shadow,
            calc(3px * 7) calc(3px * 3) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 18) calc(3px * 3) $runner__shadow,
            calc(3px * 19) calc(3px * 3) $runner__shadow,
            calc(3px * 20) calc(3px * 3) $runner__shadow,
            calc(3px * 3) calc(3px * 4) $runner__shadow,
            calc(3px * 4) calc(3px * 4) $runner__shadow,
            calc(3px * 5) calc(3px * 4) $runner__shadow,
            calc(3px * 6) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 4) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 15) calc(3px * 4) $runner__shadow,
            calc(3px * 16) calc(3px * 4) $runner__shadow,
            calc(3px * 17) calc(3px * 4) $runner__shadow,
            calc(3px * 18) calc(3px * 4) $runner__shadow,
            calc(3px * 19) calc(3px * 4) $runner__shadow,
            calc(3px * 20) calc(3px * 4) $runner__shadow,
            calc(3px * 21) calc(3px * 4) $runner__shadow,
            calc(3px * 4) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 5) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 14) calc(3px * 5) $runner__shadow,
            calc(3px * 15) calc(3px * 5) $runner__shadow,
            calc(3px * 16) calc(3px * 5) $runner__shadow,
            calc(3px * 17) calc(3px * 5) $runner__shadow,
            calc(3px * 18) calc(3px * 5) $runner__shadow,
            calc(3px * 19) calc(3px * 5) $runner__shadow,
            calc(3px * 20) calc(3px * 5) $runner__shadow,
            calc(3px * 21) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 15) calc(3px * 6) $runner__shadow,
            calc(3px * 16) calc(3px * 6) $runner__shadow,
            calc(3px * 17) calc(3px * 6) $runner__shadow,
            calc(3px * 18) calc(3px * 6) $runner__shadow,
            calc(3px * 19) calc(3px * 6) $runner__shadow,
            calc(3px * 20) calc(3px * 6) $runner__shadow,
            calc(3px * 21) calc(3px * 6) $runner__shadow,
            calc(3px * 5) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 16) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 19) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 8) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 8) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 8) calc(3px * 15) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 23) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow;
        box-shadow: calc(3px * 1) calc(3px * 0) $runner__shadow,
            calc(3px * 2) calc(3px * 0) $runner__shadow,
            calc(3px * 3) calc(3px * 0) $runner__shadow,
            calc(3px * 1) calc(3px * 1) $runner__shadow,
            calc(3px * 2) calc(3px * 1) $runner__shadow,
            calc(3px * 3) calc(3px * 1) $runner__shadow,
            calc(3px * 7) calc(3px * 1) $runner__shadow,
            calc(3px * 1) calc(3px * 2) $runner__shadow,
            calc(3px * 2) calc(3px * 2) $runner__shadow,
            calc(3px * 3) calc(3px * 2) $runner__shadow,
            calc(3px * 4) calc(3px * 2) $runner__shadow,
            calc(3px * 5) calc(3px * 2) $runner__shadow,
            calc(3px * 6) calc(3px * 2) $runner__shadow,
            calc(3px * 7) calc(3px * 2) $runner__shadow,
            calc(3px * 8) calc(3px * 2) $runner__shadow,
            calc(3px * 2) calc(3px * 3) $runner__shadow,
            calc(3px * 3) calc(3px * 3) $runner__shadow,
            calc(3px * 4) calc(3px * 3) $runner__shadow,
            calc(3px * 5) calc(3px * 3) $runner__shadow,
            calc(3px * 6) calc(3px * 3) $runner__shadow,
            calc(3px * 7) calc(3px * 3) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 18) calc(3px * 3) $runner__shadow,
            calc(3px * 19) calc(3px * 3) $runner__shadow,
            calc(3px * 20) calc(3px * 3) $runner__shadow,
            calc(3px * 3) calc(3px * 4) $runner__shadow,
            calc(3px * 4) calc(3px * 4) $runner__shadow,
            calc(3px * 5) calc(3px * 4) $runner__shadow,
            calc(3px * 6) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 4) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 15) calc(3px * 4) $runner__shadow,
            calc(3px * 16) calc(3px * 4) $runner__shadow,
            calc(3px * 17) calc(3px * 4) $runner__shadow,
            calc(3px * 18) calc(3px * 4) $runner__shadow,
            calc(3px * 19) calc(3px * 4) $runner__shadow,
            calc(3px * 20) calc(3px * 4) $runner__shadow,
            calc(3px * 21) calc(3px * 4) $runner__shadow,
            calc(3px * 4) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 5) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 14) calc(3px * 5) $runner__shadow,
            calc(3px * 15) calc(3px * 5) $runner__shadow,
            calc(3px * 16) calc(3px * 5) $runner__shadow,
            calc(3px * 17) calc(3px * 5) $runner__shadow,
            calc(3px * 18) calc(3px * 5) $runner__shadow,
            calc(3px * 19) calc(3px * 5) $runner__shadow,
            calc(3px * 20) calc(3px * 5) $runner__shadow,
            calc(3px * 21) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 15) calc(3px * 6) $runner__shadow,
            calc(3px * 16) calc(3px * 6) $runner__shadow,
            calc(3px * 17) calc(3px * 6) $runner__shadow,
            calc(3px * 18) calc(3px * 6) $runner__shadow,
            calc(3px * 19) calc(3px * 6) $runner__shadow,
            calc(3px * 20) calc(3px * 6) $runner__shadow,
            calc(3px * 21) calc(3px * 6) $runner__shadow,
            calc(3px * 5) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 16) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 19) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 8) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 8) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 8) calc(3px * 15) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 23) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow;
    }
    .runner__frame6 {
        -webkit-box-shadow: calc(3px * 8) calc(3px * -2) $runner__skin,
            calc(3px * 7) calc(3px * -3) $runner__skin,
            calc(3px * 8) calc(3px * -3) $runner__suit,
            calc(3px * 9) calc(3px * -3) $runner__black,
            calc(3px * 10) calc(3px * -3) $runner__black,
            calc(3px * 7) calc(3px * -4) $runner__skin,
            calc(3px * 8) calc(3px * -4) $runner__suit,
            calc(3px * 9) calc(3px * -4) $runner__suit,
            calc(3px * 10) calc(3px * -4) $runner__suit,
            calc(3px * 11) calc(3px * -4) $runner__suit,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 13) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__suit,
            calc(3px * 9) calc(3px * -5) $runner__suit,
            calc(3px * 10) calc(3px * -5) $runner__suit,
            calc(3px * 11) calc(3px * -5) $runner__suit,
            calc(3px * 12) calc(3px * -5) $runner__suit,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 14) calc(3px * -5) $runner__black,
            calc(3px * -1) calc(3px * -6) $runner__skin,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__black,
            calc(3px * 8) calc(3px * -6) $runner__black,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__suit,
            calc(3px * 11) calc(3px * -6) $runner__skin,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__black,
            calc(3px * 15) calc(3px * -6) $runner__black,
            calc(3px * 16) calc(3px * -6) $runner__black,
            calc(3px * 17) calc(3px * -6) $runner__black,
            calc(3px * -1) calc(3px * -7) $runner__skin,
            calc(3px * 0) calc(3px * -7) $runner__black,
            calc(3px * 1) calc(3px * -7) $runner__black,
            calc(3px * 2) calc(3px * -7) $runner__black,
            calc(3px * 3) calc(3px * -7) $runner__black,
            calc(3px * 4) calc(3px * -7) $runner__black,
            calc(3px * 5) calc(3px * -7) $runner__black,
            calc(3px * 6) calc(3px * -7) $runner__black,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__skin,
            calc(3px * 9) calc(3px * -7) $runner__skin,
            calc(3px * 10) calc(3px * -7) $runner__skin,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__black,
            calc(3px * 14) calc(3px * -7) $runner__black,
            calc(3px * 15) calc(3px * -7) $runner__suit,
            calc(3px * 16) calc(3px * -7) $runner__suit,
            calc(3px * 17) calc(3px * -7) $runner__black,
            calc(3px * 18) calc(3px * -7) $runner__black,
            calc(3px * 20) calc(3px * -7) $runner__black,
            calc(3px * 21) calc(3px * -7) $runner__black,
            calc(3px * 22) calc(3px * -7) $runner__black,
            calc(3px * 23) calc(3px * -7) $runner__black,
            calc(3px * 24) calc(3px * -7) $runner__black,
            calc(3px * 0) calc(3px * -8) $runner__skin,
            calc(3px * 1) calc(3px * -8) $runner__suit,
            calc(3px * 2) calc(3px * -8) $runner__black,
            calc(3px * 3) calc(3px * -8) $runner__black,
            calc(3px * 4) calc(3px * -8) $runner__black,
            calc(3px * 5) calc(3px * -8) $runner__black,
            calc(3px * 6) calc(3px * -8) $runner__black,
            calc(3px * 7) calc(3px * -8) $runner__skin,
            calc(3px * 8) calc(3px * -8) $runner__skin,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__black,
            calc(3px * 13) calc(3px * -8) $runner__suit,
            calc(3px * 14) calc(3px * -8) $runner__suit,
            calc(3px * 15) calc(3px * -8) $runner__suit,
            calc(3px * 16) calc(3px * -8) $runner__suit,
            calc(3px * 17) calc(3px * -8) $runner__skin,
            calc(3px * 18) calc(3px * -8) $runner__black,
            calc(3px * 19) calc(3px * -8) $runner__black,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__suit,
            calc(3px * 23) calc(3px * -8) $runner__black,
            calc(3px * 24) calc(3px * -8) $runner__black,
            calc(3px * 3) calc(3px * -9) $runner__black,
            calc(3px * 4) calc(3px * -9) $runner__black,
            calc(3px * 5) calc(3px * -9) $runner__black,
            calc(3px * 6) calc(3px * -9) $runner__skin,
            calc(3px * 7) calc(3px * -9) $runner__black,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__black,
            calc(3px * 12) calc(3px * -9) $runner__suit,
            calc(3px * 13) calc(3px * -9) $runner__suit,
            calc(3px * 14) calc(3px * -9) $runner__suit,
            calc(3px * 15) calc(3px * -9) $runner__suit,
            calc(3px * 16) calc(3px * -9) $runner__suit,
            calc(3px * 17) calc(3px * -9) $runner__suit,
            calc(3px * 18) calc(3px * -9) $runner__black,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 21) calc(3px * -9) $runner__suit,
            calc(3px * 22) calc(3px * -9) $runner__suit,
            calc(3px * 23) calc(3px * -9) $runner__suit,
            calc(3px * 24) calc(3px * -9) $runner__black,
            calc(3px * 5) calc(3px * -10) $runner__black,
            calc(3px * 6) calc(3px * -10) $runner__skin,
            calc(3px * 7) calc(3px * -10) $runner__black,
            calc(3px * 8) calc(3px * -10) $runner__black,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__suit,
            calc(3px * 12) calc(3px * -10) $runner__suit,
            calc(3px * 13) calc(3px * -10) $runner__suit,
            calc(3px * 14) calc(3px * -10) $runner__suit,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__black,
            calc(3px * 17) calc(3px * -10) $runner__black,
            calc(3px * 18) calc(3px * -10) $runner__black,
            calc(3px * 19) calc(3px * -10) $runner__skin,
            calc(3px * 20) calc(3px * -10) $runner__suit,
            calc(3px * 21) calc(3px * -10) $runner__suit,
            calc(3px * 22) calc(3px * -10) $runner__suit,
            calc(3px * 23) calc(3px * -10) $runner__suit,
            calc(3px * 24) calc(3px * -10) $runner__skin,
            calc(3px * 8) calc(3px * -11) $runner__black,
            calc(3px * 9) calc(3px * -11) $runner__black,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__suit,
            calc(3px * 12) calc(3px * -11) $runner__suit,
            calc(3px * 13) calc(3px * -11) $runner__suit,
            calc(3px * 14) calc(3px * -11) $runner__suit,
            calc(3px * 15) calc(3px * -11) $runner__black,
            calc(3px * 16) calc(3px * -11) $runner__black,
            calc(3px * 17) calc(3px * -11) $runner__black,
            calc(3px * 18) calc(3px * -11) $runner__black,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__suit,
            calc(3px * 21) calc(3px * -11) $runner__suit,
            calc(3px * 10) calc(3px * -12) $runner__black,
            calc(3px * 11) calc(3px * -12) $runner__black,
            calc(3px * 12) calc(3px * -12) $runner__suit,
            calc(3px * 13) calc(3px * -12) $runner__suit,
            calc(3px * 14) calc(3px * -12) $runner__black,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__suit,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__suit,
            calc(3px * 19) calc(3px * -12) $runner__suit,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 9) calc(3px * -13) $runner__black,
            calc(3px * 10) calc(3px * -13) $runner__black,
            calc(3px * 11) calc(3px * -13) $runner__black,
            calc(3px * 12) calc(3px * -13) $runner__black,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__hair,
            calc(3px * 15) calc(3px * -13) $runner__hair,
            calc(3px * 16) calc(3px * -13) $runner__hair,
            calc(3px * 17) calc(3px * -13) $runner__hair,
            calc(3px * 18) calc(3px * -13) $runner__hair,
            calc(3px * 19) calc(3px * -13) $runner__skin,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__skin,
            calc(3px * 22) calc(3px * -13) $runner__skin,
            calc(3px * 9) calc(3px * -14) $runner__black,
            calc(3px * 10) calc(3px * -14) $runner__hair,
            calc(3px * 11) calc(3px * -14) $runner__hair,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__hair,
            calc(3px * 15) calc(3px * -14) $runner__black,
            calc(3px * 16) calc(3px * -14) $runner__black,
            calc(3px * 17) calc(3px * -14) $runner__suit,
            calc(3px * 18) calc(3px * -14) $runner__hair,
            calc(3px * 19) calc(3px * -14) $runner__hair,
            calc(3px * 20) calc(3px * -14) $runner__skin,
            calc(3px * 21) calc(3px * -14) $runner__eyes,
            calc(3px * 22) calc(3px * -14) $runner__skin,
            calc(3px * 9) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__black,
            calc(3px * 15) calc(3px * -15) $runner__black,
            calc(3px * 16) calc(3px * -15) $runner__black,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__black,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__skin,
            calc(3px * 21) calc(3px * -15) $runner__eyes,
            calc(3px * 22) calc(3px * -15) $runner__skin,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__suit,
            calc(3px * 15) calc(3px * -16) $runner__suit,
            calc(3px * 16) calc(3px * -16) $runner__black,
            calc(3px * 17) calc(3px * -16) $runner__black,
            calc(3px * 18) calc(3px * -16) $runner__hair,
            calc(3px * 19) calc(3px * -16) $runner__black,
            calc(3px * 20) calc(3px * -16) $runner__black,
            calc(3px * 21) calc(3px * -16) $runner__hair,
            calc(3px * 22) calc(3px * -16) $runner__hair,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__suit,
            calc(3px * 15) calc(3px * -17) $runner__white,
            calc(3px * 16) calc(3px * -17) $runner__suit,
            calc(3px * 17) calc(3px * -17) $runner__suit,
            calc(3px * 18) calc(3px * -17) $runner__hair,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__black,
            calc(3px * 21) calc(3px * -17) $runner__black,
            calc(3px * 22) calc(3px * -17) $runner__black,
            calc(3px * 23) calc(3px * -17) $runner__hair,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__hair,
            calc(3px * 15) calc(3px * -18) $runner__suit,
            calc(3px * 16) calc(3px * -18) $runner__suit,
            calc(3px * 17) calc(3px * -18) $runner__hair,
            calc(3px * 18) calc(3px * -18) $runner__hair,
            calc(3px * 19) calc(3px * -18) $runner__hair,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__black,
            calc(3px * 24) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__hair,
            calc(3px * 16) calc(3px * -19) $runner__suit,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__hair,
            calc(3px * 19) calc(3px * -19) $runner__hair,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__hair,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 24) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__hair,
            calc(3px * 15) calc(3px * -20) $runner__white,
            calc(3px * 16) calc(3px * -20) $runner__suit,
            calc(3px * 17) calc(3px * -20) $runner__white,
            calc(3px * 18) calc(3px * -20) $runner__white,
            calc(3px * 19) calc(3px * -20) $runner__white,
            calc(3px * 20) calc(3px * -20) $runner__white,
            calc(3px * 21) calc(3px * -20) $runner__white,
            calc(3px * 22) calc(3px * -20) $runner__white,
            calc(3px * 23) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -21) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__white,
            calc(3px * 15) calc(3px * -21) $runner__white,
            calc(3px * 16) calc(3px * -21) $runner__suit,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 23) calc(3px * -21) $runner__white,
            calc(3px * 13) calc(3px * -22) $runner__white,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__suit,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__hair,
            calc(3px * 19) calc(3px * -22) $runner__hair,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 21) calc(3px * -22) $runner__hair,
            calc(3px * 22) calc(3px * -22) $runner__hair,
            calc(3px * 14) calc(3px * -23) $runner__hair,
            calc(3px * 15) calc(3px * -23) $runner__hair,
            calc(3px * 16) calc(3px * -23) $runner__suit,
            calc(3px * 17) calc(3px * -23) $runner__hair,
            calc(3px * 18) calc(3px * -23) $runner__hair,
            calc(3px * 19) calc(3px * -23) $runner__hair,
            calc(3px * 20) calc(3px * -23) $runner__hair,
            calc(3px * 16) calc(3px * -24) $runner__suit,
            calc(3px * 17) calc(3px * -24) $runner__suit,
            calc(3px * 18) calc(3px * -24) $runner__black;
        box-shadow: calc(3px * 8) calc(3px * -2) $runner__skin,
            calc(3px * 7) calc(3px * -3) $runner__skin,
            calc(3px * 8) calc(3px * -3) $runner__suit,
            calc(3px * 9) calc(3px * -3) $runner__black,
            calc(3px * 10) calc(3px * -3) $runner__black,
            calc(3px * 7) calc(3px * -4) $runner__skin,
            calc(3px * 8) calc(3px * -4) $runner__suit,
            calc(3px * 9) calc(3px * -4) $runner__suit,
            calc(3px * 10) calc(3px * -4) $runner__suit,
            calc(3px * 11) calc(3px * -4) $runner__suit,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 13) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__suit,
            calc(3px * 9) calc(3px * -5) $runner__suit,
            calc(3px * 10) calc(3px * -5) $runner__suit,
            calc(3px * 11) calc(3px * -5) $runner__suit,
            calc(3px * 12) calc(3px * -5) $runner__suit,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 14) calc(3px * -5) $runner__black,
            calc(3px * -1) calc(3px * -6) $runner__skin,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__black,
            calc(3px * 8) calc(3px * -6) $runner__black,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__suit,
            calc(3px * 11) calc(3px * -6) $runner__skin,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__black,
            calc(3px * 15) calc(3px * -6) $runner__black,
            calc(3px * 16) calc(3px * -6) $runner__black,
            calc(3px * 17) calc(3px * -6) $runner__black,
            calc(3px * -1) calc(3px * -7) $runner__skin,
            calc(3px * 0) calc(3px * -7) $runner__black,
            calc(3px * 1) calc(3px * -7) $runner__black,
            calc(3px * 2) calc(3px * -7) $runner__black,
            calc(3px * 3) calc(3px * -7) $runner__black,
            calc(3px * 4) calc(3px * -7) $runner__black,
            calc(3px * 5) calc(3px * -7) $runner__black,
            calc(3px * 6) calc(3px * -7) $runner__black,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__skin,
            calc(3px * 9) calc(3px * -7) $runner__skin,
            calc(3px * 10) calc(3px * -7) $runner__skin,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__black,
            calc(3px * 14) calc(3px * -7) $runner__black,
            calc(3px * 15) calc(3px * -7) $runner__suit,
            calc(3px * 16) calc(3px * -7) $runner__suit,
            calc(3px * 17) calc(3px * -7) $runner__black,
            calc(3px * 18) calc(3px * -7) $runner__black,
            calc(3px * 20) calc(3px * -7) $runner__black,
            calc(3px * 21) calc(3px * -7) $runner__black,
            calc(3px * 22) calc(3px * -7) $runner__black,
            calc(3px * 23) calc(3px * -7) $runner__black,
            calc(3px * 24) calc(3px * -7) $runner__black,
            calc(3px * 0) calc(3px * -8) $runner__skin,
            calc(3px * 1) calc(3px * -8) $runner__suit,
            calc(3px * 2) calc(3px * -8) $runner__black,
            calc(3px * 3) calc(3px * -8) $runner__black,
            calc(3px * 4) calc(3px * -8) $runner__black,
            calc(3px * 5) calc(3px * -8) $runner__black,
            calc(3px * 6) calc(3px * -8) $runner__black,
            calc(3px * 7) calc(3px * -8) $runner__skin,
            calc(3px * 8) calc(3px * -8) $runner__skin,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__black,
            calc(3px * 13) calc(3px * -8) $runner__suit,
            calc(3px * 14) calc(3px * -8) $runner__suit,
            calc(3px * 15) calc(3px * -8) $runner__suit,
            calc(3px * 16) calc(3px * -8) $runner__suit,
            calc(3px * 17) calc(3px * -8) $runner__skin,
            calc(3px * 18) calc(3px * -8) $runner__black,
            calc(3px * 19) calc(3px * -8) $runner__black,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__suit,
            calc(3px * 23) calc(3px * -8) $runner__black,
            calc(3px * 24) calc(3px * -8) $runner__black,
            calc(3px * 3) calc(3px * -9) $runner__black,
            calc(3px * 4) calc(3px * -9) $runner__black,
            calc(3px * 5) calc(3px * -9) $runner__black,
            calc(3px * 6) calc(3px * -9) $runner__skin,
            calc(3px * 7) calc(3px * -9) $runner__black,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__black,
            calc(3px * 12) calc(3px * -9) $runner__suit,
            calc(3px * 13) calc(3px * -9) $runner__suit,
            calc(3px * 14) calc(3px * -9) $runner__suit,
            calc(3px * 15) calc(3px * -9) $runner__suit,
            calc(3px * 16) calc(3px * -9) $runner__suit,
            calc(3px * 17) calc(3px * -9) $runner__suit,
            calc(3px * 18) calc(3px * -9) $runner__black,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 21) calc(3px * -9) $runner__suit,
            calc(3px * 22) calc(3px * -9) $runner__suit,
            calc(3px * 23) calc(3px * -9) $runner__suit,
            calc(3px * 24) calc(3px * -9) $runner__black,
            calc(3px * 5) calc(3px * -10) $runner__black,
            calc(3px * 6) calc(3px * -10) $runner__skin,
            calc(3px * 7) calc(3px * -10) $runner__black,
            calc(3px * 8) calc(3px * -10) $runner__black,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__suit,
            calc(3px * 12) calc(3px * -10) $runner__suit,
            calc(3px * 13) calc(3px * -10) $runner__suit,
            calc(3px * 14) calc(3px * -10) $runner__suit,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__black,
            calc(3px * 17) calc(3px * -10) $runner__black,
            calc(3px * 18) calc(3px * -10) $runner__black,
            calc(3px * 19) calc(3px * -10) $runner__skin,
            calc(3px * 20) calc(3px * -10) $runner__suit,
            calc(3px * 21) calc(3px * -10) $runner__suit,
            calc(3px * 22) calc(3px * -10) $runner__suit,
            calc(3px * 23) calc(3px * -10) $runner__suit,
            calc(3px * 24) calc(3px * -10) $runner__skin,
            calc(3px * 8) calc(3px * -11) $runner__black,
            calc(3px * 9) calc(3px * -11) $runner__black,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__suit,
            calc(3px * 12) calc(3px * -11) $runner__suit,
            calc(3px * 13) calc(3px * -11) $runner__suit,
            calc(3px * 14) calc(3px * -11) $runner__suit,
            calc(3px * 15) calc(3px * -11) $runner__black,
            calc(3px * 16) calc(3px * -11) $runner__black,
            calc(3px * 17) calc(3px * -11) $runner__black,
            calc(3px * 18) calc(3px * -11) $runner__black,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__suit,
            calc(3px * 21) calc(3px * -11) $runner__suit,
            calc(3px * 10) calc(3px * -12) $runner__black,
            calc(3px * 11) calc(3px * -12) $runner__black,
            calc(3px * 12) calc(3px * -12) $runner__suit,
            calc(3px * 13) calc(3px * -12) $runner__suit,
            calc(3px * 14) calc(3px * -12) $runner__black,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__suit,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__suit,
            calc(3px * 19) calc(3px * -12) $runner__suit,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 9) calc(3px * -13) $runner__black,
            calc(3px * 10) calc(3px * -13) $runner__black,
            calc(3px * 11) calc(3px * -13) $runner__black,
            calc(3px * 12) calc(3px * -13) $runner__black,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__hair,
            calc(3px * 15) calc(3px * -13) $runner__hair,
            calc(3px * 16) calc(3px * -13) $runner__hair,
            calc(3px * 17) calc(3px * -13) $runner__hair,
            calc(3px * 18) calc(3px * -13) $runner__hair,
            calc(3px * 19) calc(3px * -13) $runner__skin,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__skin,
            calc(3px * 22) calc(3px * -13) $runner__skin,
            calc(3px * 9) calc(3px * -14) $runner__black,
            calc(3px * 10) calc(3px * -14) $runner__hair,
            calc(3px * 11) calc(3px * -14) $runner__hair,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__hair,
            calc(3px * 15) calc(3px * -14) $runner__black,
            calc(3px * 16) calc(3px * -14) $runner__black,
            calc(3px * 17) calc(3px * -14) $runner__suit,
            calc(3px * 18) calc(3px * -14) $runner__hair,
            calc(3px * 19) calc(3px * -14) $runner__hair,
            calc(3px * 20) calc(3px * -14) $runner__skin,
            calc(3px * 21) calc(3px * -14) $runner__eyes,
            calc(3px * 22) calc(3px * -14) $runner__skin,
            calc(3px * 9) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__black,
            calc(3px * 15) calc(3px * -15) $runner__black,
            calc(3px * 16) calc(3px * -15) $runner__black,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__black,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__skin,
            calc(3px * 21) calc(3px * -15) $runner__eyes,
            calc(3px * 22) calc(3px * -15) $runner__skin,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__suit,
            calc(3px * 15) calc(3px * -16) $runner__suit,
            calc(3px * 16) calc(3px * -16) $runner__black,
            calc(3px * 17) calc(3px * -16) $runner__black,
            calc(3px * 18) calc(3px * -16) $runner__hair,
            calc(3px * 19) calc(3px * -16) $runner__black,
            calc(3px * 20) calc(3px * -16) $runner__black,
            calc(3px * 21) calc(3px * -16) $runner__hair,
            calc(3px * 22) calc(3px * -16) $runner__hair,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__suit,
            calc(3px * 15) calc(3px * -17) $runner__white,
            calc(3px * 16) calc(3px * -17) $runner__suit,
            calc(3px * 17) calc(3px * -17) $runner__suit,
            calc(3px * 18) calc(3px * -17) $runner__hair,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__black,
            calc(3px * 21) calc(3px * -17) $runner__black,
            calc(3px * 22) calc(3px * -17) $runner__black,
            calc(3px * 23) calc(3px * -17) $runner__hair,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__hair,
            calc(3px * 15) calc(3px * -18) $runner__suit,
            calc(3px * 16) calc(3px * -18) $runner__suit,
            calc(3px * 17) calc(3px * -18) $runner__hair,
            calc(3px * 18) calc(3px * -18) $runner__hair,
            calc(3px * 19) calc(3px * -18) $runner__hair,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__black,
            calc(3px * 24) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__hair,
            calc(3px * 16) calc(3px * -19) $runner__suit,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__hair,
            calc(3px * 19) calc(3px * -19) $runner__hair,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__hair,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 24) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__hair,
            calc(3px * 15) calc(3px * -20) $runner__white,
            calc(3px * 16) calc(3px * -20) $runner__suit,
            calc(3px * 17) calc(3px * -20) $runner__white,
            calc(3px * 18) calc(3px * -20) $runner__white,
            calc(3px * 19) calc(3px * -20) $runner__white,
            calc(3px * 20) calc(3px * -20) $runner__white,
            calc(3px * 21) calc(3px * -20) $runner__white,
            calc(3px * 22) calc(3px * -20) $runner__white,
            calc(3px * 23) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -21) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__white,
            calc(3px * 15) calc(3px * -21) $runner__white,
            calc(3px * 16) calc(3px * -21) $runner__suit,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 23) calc(3px * -21) $runner__white,
            calc(3px * 13) calc(3px * -22) $runner__white,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__suit,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__hair,
            calc(3px * 19) calc(3px * -22) $runner__hair,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 21) calc(3px * -22) $runner__hair,
            calc(3px * 22) calc(3px * -22) $runner__hair,
            calc(3px * 14) calc(3px * -23) $runner__hair,
            calc(3px * 15) calc(3px * -23) $runner__hair,
            calc(3px * 16) calc(3px * -23) $runner__suit,
            calc(3px * 17) calc(3px * -23) $runner__hair,
            calc(3px * 18) calc(3px * -23) $runner__hair,
            calc(3px * 19) calc(3px * -23) $runner__hair,
            calc(3px * 20) calc(3px * -23) $runner__hair,
            calc(3px * 16) calc(3px * -24) $runner__suit,
            calc(3px * 17) calc(3px * -24) $runner__suit,
            calc(3px * 18) calc(3px * -24) $runner__black;
    }
    .runner__frame6--shadow {
        -webkit-box-shadow: calc(3px * 8) calc(3px * 2) $runner__shadow,
            calc(3px * 7) calc(3px * 3) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 7) calc(3px * 4) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 13) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 14) calc(3px * 5) $runner__shadow,
            calc(3px * 1) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 15) calc(3px * 6) $runner__shadow,
            calc(3px * 16) calc(3px * 6) $runner__shadow,
            calc(3px * 17) calc(3px * 6) $runner__shadow,
            calc(3px * 1) calc(3px * 7) $runner__shadow,
            calc(3px * 0) calc(3px * 7) $runner__shadow,
            calc(3px * 1) calc(3px * 7) $runner__shadow,
            calc(3px * 2) calc(3px * 7) $runner__shadow,
            calc(3px * 3) calc(3px * 7) $runner__shadow,
            calc(3px * 4) calc(3px * 7) $runner__shadow,
            calc(3px * 5) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 16) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 23) calc(3px * 7) $runner__shadow,
            calc(3px * 24) calc(3px * 7) $runner__shadow,
            calc(3px * 0) calc(3px * 8) $runner__shadow,
            calc(3px * 1) calc(3px * 8) $runner__shadow,
            calc(3px * 2) calc(3px * 8) $runner__shadow,
            calc(3px * 3) calc(3px * 8) $runner__shadow,
            calc(3px * 4) calc(3px * 8) $runner__shadow,
            calc(3px * 5) calc(3px * 8) $runner__shadow,
            calc(3px * 6) calc(3px * 8) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 23) calc(3px * 8) $runner__shadow,
            calc(3px * 24) calc(3px * 8) $runner__shadow,
            calc(3px * 3) calc(3px * 9) $runner__shadow,
            calc(3px * 4) calc(3px * 9) $runner__shadow,
            calc(3px * 5) calc(3px * 9) $runner__shadow,
            calc(3px * 6) calc(3px * 9) $runner__shadow,
            calc(3px * 7) calc(3px * 9) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 21) calc(3px * 9) $runner__shadow,
            calc(3px * 22) calc(3px * 9) $runner__shadow,
            calc(3px * 23) calc(3px * 9) $runner__shadow,
            calc(3px * 24) calc(3px * 9) $runner__shadow,
            calc(3px * 5) calc(3px * 10) $runner__shadow,
            calc(3px * 6) calc(3px * 10) $runner__shadow,
            calc(3px * 7) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 10) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 21) calc(3px * 10) $runner__shadow,
            calc(3px * 22) calc(3px * 10) $runner__shadow,
            calc(3px * 23) calc(3px * 10) $runner__shadow,
            calc(3px * 24) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 9) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 24) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 23) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 21) calc(3px * 22) $runner__shadow,
            calc(3px * 22) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 23) $runner__shadow,
            calc(3px * 15) calc(3px * 23) $runner__shadow,
            calc(3px * 16) calc(3px * 23) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow,
            calc(3px * 19) calc(3px * 23) $runner__shadow,
            calc(3px * 20) calc(3px * 23) $runner__shadow,
            calc(3px * 16) calc(3px * 24) $runner__shadow,
            calc(3px * 17) calc(3px * 24) $runner__shadow,
            calc(3px * 18) calc(3px * 24) $runner__shadow;
        box-shadow: calc(3px * 8) calc(3px * 2) $runner__shadow,
            calc(3px * 7) calc(3px * 3) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 7) calc(3px * 4) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 13) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 14) calc(3px * 5) $runner__shadow,
            calc(3px * 1) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 15) calc(3px * 6) $runner__shadow,
            calc(3px * 16) calc(3px * 6) $runner__shadow,
            calc(3px * 17) calc(3px * 6) $runner__shadow,
            calc(3px * 1) calc(3px * 7) $runner__shadow,
            calc(3px * 0) calc(3px * 7) $runner__shadow,
            calc(3px * 1) calc(3px * 7) $runner__shadow,
            calc(3px * 2) calc(3px * 7) $runner__shadow,
            calc(3px * 3) calc(3px * 7) $runner__shadow,
            calc(3px * 4) calc(3px * 7) $runner__shadow,
            calc(3px * 5) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 16) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 23) calc(3px * 7) $runner__shadow,
            calc(3px * 24) calc(3px * 7) $runner__shadow,
            calc(3px * 0) calc(3px * 8) $runner__shadow,
            calc(3px * 1) calc(3px * 8) $runner__shadow,
            calc(3px * 2) calc(3px * 8) $runner__shadow,
            calc(3px * 3) calc(3px * 8) $runner__shadow,
            calc(3px * 4) calc(3px * 8) $runner__shadow,
            calc(3px * 5) calc(3px * 8) $runner__shadow,
            calc(3px * 6) calc(3px * 8) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 23) calc(3px * 8) $runner__shadow,
            calc(3px * 24) calc(3px * 8) $runner__shadow,
            calc(3px * 3) calc(3px * 9) $runner__shadow,
            calc(3px * 4) calc(3px * 9) $runner__shadow,
            calc(3px * 5) calc(3px * 9) $runner__shadow,
            calc(3px * 6) calc(3px * 9) $runner__shadow,
            calc(3px * 7) calc(3px * 9) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 21) calc(3px * 9) $runner__shadow,
            calc(3px * 22) calc(3px * 9) $runner__shadow,
            calc(3px * 23) calc(3px * 9) $runner__shadow,
            calc(3px * 24) calc(3px * 9) $runner__shadow,
            calc(3px * 5) calc(3px * 10) $runner__shadow,
            calc(3px * 6) calc(3px * 10) $runner__shadow,
            calc(3px * 7) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 10) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 21) calc(3px * 10) $runner__shadow,
            calc(3px * 22) calc(3px * 10) $runner__shadow,
            calc(3px * 23) calc(3px * 10) $runner__shadow,
            calc(3px * 24) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 9) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 24) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 23) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 21) calc(3px * 22) $runner__shadow,
            calc(3px * 22) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 23) $runner__shadow,
            calc(3px * 15) calc(3px * 23) $runner__shadow,
            calc(3px * 16) calc(3px * 23) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow,
            calc(3px * 19) calc(3px * 23) $runner__shadow,
            calc(3px * 20) calc(3px * 23) $runner__shadow,
            calc(3px * 16) calc(3px * 24) $runner__shadow,
            calc(3px * 17) calc(3px * 24) $runner__shadow,
            calc(3px * 18) calc(3px * 24) $runner__shadow;
    }
    .runner__frame7 {
        -webkit-box-shadow: calc(3px * 13) calc(3px * -1) $runner__black,
            calc(3px * 14) calc(3px * -1) $runner__skin,
            calc(3px * 12) calc(3px * -2) $runner__black,
            calc(3px * 13) calc(3px * -2) $runner__suit,
            calc(3px * 14) calc(3px * -2) $runner__black,
            calc(3px * 15) calc(3px * -2) $runner__skin,
            calc(3px * 11) calc(3px * -3) $runner__black,
            calc(3px * 12) calc(3px * -3) $runner__suit,
            calc(3px * 13) calc(3px * -3) $runner__black,
            calc(3px * 9) calc(3px * -4) $runner__black,
            calc(3px * 10) calc(3px * -4) $runner__black,
            calc(3px * 11) calc(3px * -4) $runner__suit,
            calc(3px * 12) calc(3px * -4) $runner__suit,
            calc(3px * 13) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__black,
            calc(3px * 9) calc(3px * -5) $runner__black,
            calc(3px * 10) calc(3px * -5) $runner__suit,
            calc(3px * 11) calc(3px * -5) $runner__suit,
            calc(3px * 12) calc(3px * -5) $runner__suit,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__black,
            calc(3px * 8) calc(3px * -6) $runner__black,
            calc(3px * 9) calc(3px * -6) $runner__suit,
            calc(3px * 10) calc(3px * -6) $runner__suit,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__black,
            calc(3px * 5) calc(3px * -7) $runner__black,
            calc(3px * 6) calc(3px * -7) $runner__black,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__skin,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__black,
            calc(3px * 14) calc(3px * -7) $runner__black,
            calc(3px * 15) calc(3px * -7) $runner__black,
            calc(3px * 20) calc(3px * -7) $runner__black,
            calc(3px * 21) calc(3px * -7) $runner__black,
            calc(3px * 22) calc(3px * -7) $runner__black,
            calc(3px * 23) calc(3px * -7) $runner__black,
            calc(3px * 24) calc(3px * -7) $runner__black,
            calc(3px * 4) calc(3px * -8) $runner__black,
            calc(3px * 5) calc(3px * -8) $runner__black,
            calc(3px * 6) calc(3px * -8) $runner__black,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__skin,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__suit,
            calc(3px * 13) calc(3px * -8) $runner__suit,
            calc(3px * 14) calc(3px * -8) $runner__black,
            calc(3px * 15) calc(3px * -8) $runner__black,
            calc(3px * 16) calc(3px * -8) $runner__black,
            calc(3px * 18) calc(3px * -8) $runner__black,
            calc(3px * 19) calc(3px * -8) $runner__black,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__suit,
            calc(3px * 23) calc(3px * -8) $runner__suit,
            calc(3px * 24) calc(3px * -8) $runner__suit,
            calc(3px * 25) calc(3px * -8) $runner__black,
            calc(3px * 3) calc(3px * -9) $runner__black,
            calc(3px * 4) calc(3px * -9) $runner__black,
            calc(3px * 5) calc(3px * -9) $runner__black,
            calc(3px * 6) calc(3px * -9) $runner__black,
            calc(3px * 7) calc(3px * -9) $runner__skin,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__suit,
            calc(3px * 12) calc(3px * -9) $runner__suit,
            calc(3px * 13) calc(3px * -9) $runner__suit,
            calc(3px * 14) calc(3px * -9) $runner__black,
            calc(3px * 15) calc(3px * -9) $runner__skin,
            calc(3px * 16) calc(3px * -9) $runner__black,
            calc(3px * 17) calc(3px * -9) $runner__black,
            calc(3px * 18) calc(3px * -9) $runner__black,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 21) calc(3px * -9) $runner__suit,
            calc(3px * 22) calc(3px * -9) $runner__suit,
            calc(3px * 23) calc(3px * -9) $runner__black,
            calc(3px * 24) calc(3px * -9) $runner__black,
            calc(3px * 25) calc(3px * -9) $runner__suit,
            calc(3px * 1) calc(3px * -10) $runner__skin,
            calc(3px * 2) calc(3px * -10) $runner__black,
            calc(3px * 3) calc(3px * -10) $runner__black,
            calc(3px * 4) calc(3px * -10) $runner__black,
            calc(3px * 6) calc(3px * -10) $runner__skin,
            calc(3px * 7) calc(3px * -10) $runner__skin,
            calc(3px * 8) calc(3px * -10) $runner__black,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__suit,
            calc(3px * 11) calc(3px * -10) $runner__suit,
            calc(3px * 12) calc(3px * -10) $runner__suit,
            calc(3px * 13) calc(3px * -10) $runner__suit,
            calc(3px * 14) calc(3px * -10) $runner__suit,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__black,
            calc(3px * 17) calc(3px * -10) $runner__black,
            calc(3px * 18) calc(3px * -10) $runner__black,
            calc(3px * 19) calc(3px * -10) $runner__skin,
            calc(3px * 20) calc(3px * -10) $runner__suit,
            calc(3px * 21) calc(3px * -10) $runner__suit,
            calc(3px * 22) calc(3px * -10) $runner__suit,
            calc(3px * 23) calc(3px * -10) $runner__black,
            calc(3px * 24) calc(3px * -10) $runner__black,
            calc(3px * 25) calc(3px * -10) $runner__suit,
            calc(3px * 2) calc(3px * -11) $runner__skin,
            calc(3px * 3) calc(3px * -11) $runner__skin,
            calc(3px * 6) calc(3px * -11) $runner__skin,
            calc(3px * 8) calc(3px * -11) $runner__black,
            calc(3px * 9) calc(3px * -11) $runner__suit,
            calc(3px * 10) calc(3px * -11) $runner__suit,
            calc(3px * 11) calc(3px * -11) $runner__suit,
            calc(3px * 12) calc(3px * -11) $runner__suit,
            calc(3px * 13) calc(3px * -11) $runner__black,
            calc(3px * 14) calc(3px * -11) $runner__black,
            calc(3px * 15) calc(3px * -11) $runner__black,
            calc(3px * 16) calc(3px * -11) $runner__black,
            calc(3px * 17) calc(3px * -11) $runner__black,
            calc(3px * 18) calc(3px * -11) $runner__black,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__suit,
            calc(3px * 21) calc(3px * -11) $runner__suit,
            calc(3px * 22) calc(3px * -11) $runner__suit,
            calc(3px * 23) calc(3px * -11) $runner__black,
            calc(3px * 24) calc(3px * -11) $runner__skin,
            calc(3px * 25) calc(3px * -11) $runner__suit,
            calc(3px * 9) calc(3px * -12) $runner__suit,
            calc(3px * 10) calc(3px * -12) $runner__suit,
            calc(3px * 11) calc(3px * -12) $runner__suit,
            calc(3px * 12) calc(3px * -12) $runner__suit,
            calc(3px * 13) calc(3px * -12) $runner__suit,
            calc(3px * 14) calc(3px * -12) $runner__black,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__suit,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__suit,
            calc(3px * 19) calc(3px * -12) $runner__suit,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 23) calc(3px * -12) $runner__suit,
            calc(3px * 24) calc(3px * -12) $runner__suit,
            calc(3px * 10) calc(3px * -13) $runner__suit,
            calc(3px * 11) calc(3px * -13) $runner__suit,
            calc(3px * 12) calc(3px * -13) $runner__suit,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__hair,
            calc(3px * 15) calc(3px * -13) $runner__hair,
            calc(3px * 16) calc(3px * -13) $runner__hair,
            calc(3px * 17) calc(3px * -13) $runner__hair,
            calc(3px * 18) calc(3px * -13) $runner__skin,
            calc(3px * 19) calc(3px * -13) $runner__skin,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__skin,
            calc(3px * 22) calc(3px * -13) $runner__skin,
            calc(3px * 10) calc(3px * -14) $runner__black,
            calc(3px * 11) calc(3px * -14) $runner__black,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__black,
            calc(3px * 15) calc(3px * -14) $runner__black,
            calc(3px * 16) calc(3px * -14) $runner__suit,
            calc(3px * 17) calc(3px * -14) $runner__hair,
            calc(3px * 18) calc(3px * -14) $runner__hair,
            calc(3px * 19) calc(3px * -14) $runner__skin,
            calc(3px * 20) calc(3px * -14) $runner__eyes,
            calc(3px * 21) calc(3px * -14) $runner__skin,
            calc(3px * 22) calc(3px * -14) $runner__skin,
            calc(3px * 9) calc(3px * -15) $runner__black,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__suit,
            calc(3px * 14) calc(3px * -15) $runner__suit,
            calc(3px * 15) calc(3px * -15) $runner__suit,
            calc(3px * 16) calc(3px * -15) $runner__black,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__black,
            calc(3px * 19) calc(3px * -15) $runner__skin,
            calc(3px * 20) calc(3px * -15) $runner__eyes,
            calc(3px * 21) calc(3px * -15) $runner__skin,
            calc(3px * 22) calc(3px * -15) $runner__skin,
            calc(3px * 9) calc(3px * -16) $runner__hair,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__suit,
            calc(3px * 14) calc(3px * -16) $runner__suit,
            calc(3px * 15) calc(3px * -16) $runner__suit,
            calc(3px * 16) calc(3px * -16) $runner__black,
            calc(3px * 17) calc(3px * -16) $runner__hair,
            calc(3px * 18) calc(3px * -16) $runner__black,
            calc(3px * 19) calc(3px * -16) $runner__black,
            calc(3px * 20) calc(3px * -16) $runner__hair,
            calc(3px * 21) calc(3px * -16) $runner__hair,
            calc(3px * 22) calc(3px * -16) $runner__skin,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 9) calc(3px * -17) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__suit,
            calc(3px * 14) calc(3px * -17) $runner__skin,
            calc(3px * 15) calc(3px * -17) $runner__suit,
            calc(3px * 16) calc(3px * -17) $runner__black,
            calc(3px * 17) calc(3px * -17) $runner__hair,
            calc(3px * 18) calc(3px * -17) $runner__hair,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__black,
            calc(3px * 21) calc(3px * -17) $runner__black,
            calc(3px * 22) calc(3px * -17) $runner__black,
            calc(3px * 23) calc(3px * -17) $runner__hair,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 9) calc(3px * -18) $runner__hair,
            calc(3px * 10) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__suit,
            calc(3px * 15) calc(3px * -18) $runner__suit,
            calc(3px * 16) calc(3px * -18) $runner__hair,
            calc(3px * 17) calc(3px * -18) $runner__hair,
            calc(3px * 18) calc(3px * -18) $runner__hair,
            calc(3px * 19) calc(3px * -18) $runner__hair,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__black,
            calc(3px * 24) calc(3px * -18) $runner__hair,
            calc(3px * 9) calc(3px * -19) $runner__hair,
            calc(3px * 10) calc(3px * -19) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__suit,
            calc(3px * 16) calc(3px * -19) $runner__hair,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__hair,
            calc(3px * 19) calc(3px * -19) $runner__hair,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__hair,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 24) calc(3px * -19) $runner__hair,
            calc(3px * 10) calc(3px * -20) $runner__hair,
            calc(3px * 11) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__white,
            calc(3px * 15) calc(3px * -20) $runner__suit,
            calc(3px * 16) calc(3px * -20) $runner__white,
            calc(3px * 17) calc(3px * -20) $runner__white,
            calc(3px * 18) calc(3px * -20) $runner__white,
            calc(3px * 19) calc(3px * -20) $runner__white,
            calc(3px * 20) calc(3px * -20) $runner__white,
            calc(3px * 21) calc(3px * -20) $runner__white,
            calc(3px * 22) calc(3px * -20) $runner__white,
            calc(3px * 23) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -21) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__white,
            calc(3px * 15) calc(3px * -21) $runner__suit,
            calc(3px * 16) calc(3px * -21) $runner__hair,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 23) calc(3px * -21) $runner__white,
            calc(3px * 13) calc(3px * -22) $runner__hair,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__suit,
            calc(3px * 16) calc(3px * -22) $runner__hair,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__hair,
            calc(3px * 19) calc(3px * -22) $runner__hair,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 21) calc(3px * -22) $runner__hair,
            calc(3px * 22) calc(3px * -22) $runner__hair,
            calc(3px * 14) calc(3px * -23) $runner__hair,
            calc(3px * 15) calc(3px * -23) $runner__suit,
            calc(3px * 16) calc(3px * -23) $runner__hair,
            calc(3px * 17) calc(3px * -23) $runner__hair,
            calc(3px * 18) calc(3px * -23) $runner__hair,
            calc(3px * 19) calc(3px * -23) $runner__hair,
            calc(3px * 20) calc(3px * -23) $runner__hair,
            calc(3px * 15) calc(3px * -24) $runner__suit,
            calc(3px * 16) calc(3px * -24) $runner__black,
            calc(3px * 17) calc(3px * -24) $runner__black,
            calc(3px * 19) calc(3px * -24) $runner__black,
            calc(3px * 17) calc(3px * -25) $runner__black,
            calc(3px * 18) calc(3px * -25) $runner__black,
            calc(3px * 19) calc(3px * -25) $runner__black;
        box-shadow: calc(3px * 13) calc(3px * -1) $runner__black,
            calc(3px * 14) calc(3px * -1) $runner__skin,
            calc(3px * 12) calc(3px * -2) $runner__black,
            calc(3px * 13) calc(3px * -2) $runner__suit,
            calc(3px * 14) calc(3px * -2) $runner__black,
            calc(3px * 15) calc(3px * -2) $runner__skin,
            calc(3px * 11) calc(3px * -3) $runner__black,
            calc(3px * 12) calc(3px * -3) $runner__suit,
            calc(3px * 13) calc(3px * -3) $runner__black,
            calc(3px * 9) calc(3px * -4) $runner__black,
            calc(3px * 10) calc(3px * -4) $runner__black,
            calc(3px * 11) calc(3px * -4) $runner__suit,
            calc(3px * 12) calc(3px * -4) $runner__suit,
            calc(3px * 13) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__black,
            calc(3px * 9) calc(3px * -5) $runner__black,
            calc(3px * 10) calc(3px * -5) $runner__suit,
            calc(3px * 11) calc(3px * -5) $runner__suit,
            calc(3px * 12) calc(3px * -5) $runner__suit,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__black,
            calc(3px * 8) calc(3px * -6) $runner__black,
            calc(3px * 9) calc(3px * -6) $runner__suit,
            calc(3px * 10) calc(3px * -6) $runner__suit,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__black,
            calc(3px * 14) calc(3px * -6) $runner__black,
            calc(3px * 5) calc(3px * -7) $runner__black,
            calc(3px * 6) calc(3px * -7) $runner__black,
            calc(3px * 7) calc(3px * -7) $runner__black,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__skin,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__black,
            calc(3px * 12) calc(3px * -7) $runner__black,
            calc(3px * 13) calc(3px * -7) $runner__black,
            calc(3px * 14) calc(3px * -7) $runner__black,
            calc(3px * 15) calc(3px * -7) $runner__black,
            calc(3px * 20) calc(3px * -7) $runner__black,
            calc(3px * 21) calc(3px * -7) $runner__black,
            calc(3px * 22) calc(3px * -7) $runner__black,
            calc(3px * 23) calc(3px * -7) $runner__black,
            calc(3px * 24) calc(3px * -7) $runner__black,
            calc(3px * 4) calc(3px * -8) $runner__black,
            calc(3px * 5) calc(3px * -8) $runner__black,
            calc(3px * 6) calc(3px * -8) $runner__black,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__skin,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__black,
            calc(3px * 12) calc(3px * -8) $runner__suit,
            calc(3px * 13) calc(3px * -8) $runner__suit,
            calc(3px * 14) calc(3px * -8) $runner__black,
            calc(3px * 15) calc(3px * -8) $runner__black,
            calc(3px * 16) calc(3px * -8) $runner__black,
            calc(3px * 18) calc(3px * -8) $runner__black,
            calc(3px * 19) calc(3px * -8) $runner__black,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__suit,
            calc(3px * 23) calc(3px * -8) $runner__suit,
            calc(3px * 24) calc(3px * -8) $runner__suit,
            calc(3px * 25) calc(3px * -8) $runner__black,
            calc(3px * 3) calc(3px * -9) $runner__black,
            calc(3px * 4) calc(3px * -9) $runner__black,
            calc(3px * 5) calc(3px * -9) $runner__black,
            calc(3px * 6) calc(3px * -9) $runner__black,
            calc(3px * 7) calc(3px * -9) $runner__skin,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__suit,
            calc(3px * 12) calc(3px * -9) $runner__suit,
            calc(3px * 13) calc(3px * -9) $runner__suit,
            calc(3px * 14) calc(3px * -9) $runner__black,
            calc(3px * 15) calc(3px * -9) $runner__skin,
            calc(3px * 16) calc(3px * -9) $runner__black,
            calc(3px * 17) calc(3px * -9) $runner__black,
            calc(3px * 18) calc(3px * -9) $runner__black,
            calc(3px * 19) calc(3px * -9) $runner__suit,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 21) calc(3px * -9) $runner__suit,
            calc(3px * 22) calc(3px * -9) $runner__suit,
            calc(3px * 23) calc(3px * -9) $runner__black,
            calc(3px * 24) calc(3px * -9) $runner__black,
            calc(3px * 25) calc(3px * -9) $runner__suit,
            calc(3px * 1) calc(3px * -10) $runner__skin,
            calc(3px * 2) calc(3px * -10) $runner__black,
            calc(3px * 3) calc(3px * -10) $runner__black,
            calc(3px * 4) calc(3px * -10) $runner__black,
            calc(3px * 6) calc(3px * -10) $runner__skin,
            calc(3px * 7) calc(3px * -10) $runner__skin,
            calc(3px * 8) calc(3px * -10) $runner__black,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__suit,
            calc(3px * 11) calc(3px * -10) $runner__suit,
            calc(3px * 12) calc(3px * -10) $runner__suit,
            calc(3px * 13) calc(3px * -10) $runner__suit,
            calc(3px * 14) calc(3px * -10) $runner__suit,
            calc(3px * 15) calc(3px * -10) $runner__suit,
            calc(3px * 16) calc(3px * -10) $runner__black,
            calc(3px * 17) calc(3px * -10) $runner__black,
            calc(3px * 18) calc(3px * -10) $runner__black,
            calc(3px * 19) calc(3px * -10) $runner__skin,
            calc(3px * 20) calc(3px * -10) $runner__suit,
            calc(3px * 21) calc(3px * -10) $runner__suit,
            calc(3px * 22) calc(3px * -10) $runner__suit,
            calc(3px * 23) calc(3px * -10) $runner__black,
            calc(3px * 24) calc(3px * -10) $runner__black,
            calc(3px * 25) calc(3px * -10) $runner__suit,
            calc(3px * 2) calc(3px * -11) $runner__skin,
            calc(3px * 3) calc(3px * -11) $runner__skin,
            calc(3px * 6) calc(3px * -11) $runner__skin,
            calc(3px * 8) calc(3px * -11) $runner__black,
            calc(3px * 9) calc(3px * -11) $runner__suit,
            calc(3px * 10) calc(3px * -11) $runner__suit,
            calc(3px * 11) calc(3px * -11) $runner__suit,
            calc(3px * 12) calc(3px * -11) $runner__suit,
            calc(3px * 13) calc(3px * -11) $runner__black,
            calc(3px * 14) calc(3px * -11) $runner__black,
            calc(3px * 15) calc(3px * -11) $runner__black,
            calc(3px * 16) calc(3px * -11) $runner__black,
            calc(3px * 17) calc(3px * -11) $runner__black,
            calc(3px * 18) calc(3px * -11) $runner__black,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__suit,
            calc(3px * 21) calc(3px * -11) $runner__suit,
            calc(3px * 22) calc(3px * -11) $runner__suit,
            calc(3px * 23) calc(3px * -11) $runner__black,
            calc(3px * 24) calc(3px * -11) $runner__skin,
            calc(3px * 25) calc(3px * -11) $runner__suit,
            calc(3px * 9) calc(3px * -12) $runner__suit,
            calc(3px * 10) calc(3px * -12) $runner__suit,
            calc(3px * 11) calc(3px * -12) $runner__suit,
            calc(3px * 12) calc(3px * -12) $runner__suit,
            calc(3px * 13) calc(3px * -12) $runner__suit,
            calc(3px * 14) calc(3px * -12) $runner__black,
            calc(3px * 15) calc(3px * -12) $runner__black,
            calc(3px * 16) calc(3px * -12) $runner__suit,
            calc(3px * 17) calc(3px * -12) $runner__suit,
            calc(3px * 18) calc(3px * -12) $runner__suit,
            calc(3px * 19) calc(3px * -12) $runner__suit,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 23) calc(3px * -12) $runner__suit,
            calc(3px * 24) calc(3px * -12) $runner__suit,
            calc(3px * 10) calc(3px * -13) $runner__suit,
            calc(3px * 11) calc(3px * -13) $runner__suit,
            calc(3px * 12) calc(3px * -13) $runner__suit,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__hair,
            calc(3px * 15) calc(3px * -13) $runner__hair,
            calc(3px * 16) calc(3px * -13) $runner__hair,
            calc(3px * 17) calc(3px * -13) $runner__hair,
            calc(3px * 18) calc(3px * -13) $runner__skin,
            calc(3px * 19) calc(3px * -13) $runner__skin,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__skin,
            calc(3px * 22) calc(3px * -13) $runner__skin,
            calc(3px * 10) calc(3px * -14) $runner__black,
            calc(3px * 11) calc(3px * -14) $runner__black,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__black,
            calc(3px * 15) calc(3px * -14) $runner__black,
            calc(3px * 16) calc(3px * -14) $runner__suit,
            calc(3px * 17) calc(3px * -14) $runner__hair,
            calc(3px * 18) calc(3px * -14) $runner__hair,
            calc(3px * 19) calc(3px * -14) $runner__skin,
            calc(3px * 20) calc(3px * -14) $runner__eyes,
            calc(3px * 21) calc(3px * -14) $runner__skin,
            calc(3px * 22) calc(3px * -14) $runner__skin,
            calc(3px * 9) calc(3px * -15) $runner__black,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__suit,
            calc(3px * 14) calc(3px * -15) $runner__suit,
            calc(3px * 15) calc(3px * -15) $runner__suit,
            calc(3px * 16) calc(3px * -15) $runner__black,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__black,
            calc(3px * 19) calc(3px * -15) $runner__skin,
            calc(3px * 20) calc(3px * -15) $runner__eyes,
            calc(3px * 21) calc(3px * -15) $runner__skin,
            calc(3px * 22) calc(3px * -15) $runner__skin,
            calc(3px * 9) calc(3px * -16) $runner__hair,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__suit,
            calc(3px * 14) calc(3px * -16) $runner__suit,
            calc(3px * 15) calc(3px * -16) $runner__suit,
            calc(3px * 16) calc(3px * -16) $runner__black,
            calc(3px * 17) calc(3px * -16) $runner__hair,
            calc(3px * 18) calc(3px * -16) $runner__black,
            calc(3px * 19) calc(3px * -16) $runner__black,
            calc(3px * 20) calc(3px * -16) $runner__hair,
            calc(3px * 21) calc(3px * -16) $runner__hair,
            calc(3px * 22) calc(3px * -16) $runner__skin,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 9) calc(3px * -17) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__suit,
            calc(3px * 14) calc(3px * -17) $runner__skin,
            calc(3px * 15) calc(3px * -17) $runner__suit,
            calc(3px * 16) calc(3px * -17) $runner__black,
            calc(3px * 17) calc(3px * -17) $runner__hair,
            calc(3px * 18) calc(3px * -17) $runner__hair,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__black,
            calc(3px * 21) calc(3px * -17) $runner__black,
            calc(3px * 22) calc(3px * -17) $runner__black,
            calc(3px * 23) calc(3px * -17) $runner__hair,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 9) calc(3px * -18) $runner__hair,
            calc(3px * 10) calc(3px * -18) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__suit,
            calc(3px * 15) calc(3px * -18) $runner__suit,
            calc(3px * 16) calc(3px * -18) $runner__hair,
            calc(3px * 17) calc(3px * -18) $runner__hair,
            calc(3px * 18) calc(3px * -18) $runner__hair,
            calc(3px * 19) calc(3px * -18) $runner__hair,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__black,
            calc(3px * 24) calc(3px * -18) $runner__hair,
            calc(3px * 9) calc(3px * -19) $runner__hair,
            calc(3px * 10) calc(3px * -19) $runner__hair,
            calc(3px * 11) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__suit,
            calc(3px * 16) calc(3px * -19) $runner__hair,
            calc(3px * 17) calc(3px * -19) $runner__hair,
            calc(3px * 18) calc(3px * -19) $runner__hair,
            calc(3px * 19) calc(3px * -19) $runner__hair,
            calc(3px * 20) calc(3px * -19) $runner__hair,
            calc(3px * 21) calc(3px * -19) $runner__hair,
            calc(3px * 22) calc(3px * -19) $runner__hair,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 24) calc(3px * -19) $runner__hair,
            calc(3px * 10) calc(3px * -20) $runner__hair,
            calc(3px * 11) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__hair,
            calc(3px * 14) calc(3px * -20) $runner__white,
            calc(3px * 15) calc(3px * -20) $runner__suit,
            calc(3px * 16) calc(3px * -20) $runner__white,
            calc(3px * 17) calc(3px * -20) $runner__white,
            calc(3px * 18) calc(3px * -20) $runner__white,
            calc(3px * 19) calc(3px * -20) $runner__white,
            calc(3px * 20) calc(3px * -20) $runner__white,
            calc(3px * 21) calc(3px * -20) $runner__white,
            calc(3px * 22) calc(3px * -20) $runner__white,
            calc(3px * 23) calc(3px * -20) $runner__hair,
            calc(3px * 12) calc(3px * -21) $runner__hair,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__white,
            calc(3px * 15) calc(3px * -21) $runner__suit,
            calc(3px * 16) calc(3px * -21) $runner__hair,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 23) calc(3px * -21) $runner__white,
            calc(3px * 13) calc(3px * -22) $runner__hair,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__suit,
            calc(3px * 16) calc(3px * -22) $runner__hair,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__hair,
            calc(3px * 19) calc(3px * -22) $runner__hair,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 21) calc(3px * -22) $runner__hair,
            calc(3px * 22) calc(3px * -22) $runner__hair,
            calc(3px * 14) calc(3px * -23) $runner__hair,
            calc(3px * 15) calc(3px * -23) $runner__suit,
            calc(3px * 16) calc(3px * -23) $runner__hair,
            calc(3px * 17) calc(3px * -23) $runner__hair,
            calc(3px * 18) calc(3px * -23) $runner__hair,
            calc(3px * 19) calc(3px * -23) $runner__hair,
            calc(3px * 20) calc(3px * -23) $runner__hair,
            calc(3px * 15) calc(3px * -24) $runner__suit,
            calc(3px * 16) calc(3px * -24) $runner__black,
            calc(3px * 17) calc(3px * -24) $runner__black,
            calc(3px * 19) calc(3px * -24) $runner__black,
            calc(3px * 17) calc(3px * -25) $runner__black,
            calc(3px * 18) calc(3px * -25) $runner__black,
            calc(3px * 19) calc(3px * -25) $runner__black;
    }
    .runner__frame7--shadow {
        -webkit-box-shadow: calc(3px * 13) calc(3px * 1) $runner__shadow,
            calc(3px * 14) calc(3px * 1) $runner__shadow,
            calc(3px * 12) calc(3px * 2) $runner__shadow,
            calc(3px * 13) calc(3px * 2) $runner__shadow,
            calc(3px * 14) calc(3px * 2) $runner__shadow,
            calc(3px * 15) calc(3px * 2) $runner__shadow,
            calc(3px * 11) calc(3px * 3) $runner__shadow,
            calc(3px * 12) calc(3px * 3) $runner__shadow,
            calc(3px * 13) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 13) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 5) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 23) calc(3px * 7) $runner__shadow,
            calc(3px * 24) calc(3px * 7) $runner__shadow,
            calc(3px * 4) calc(3px * 8) $runner__shadow,
            calc(3px * 5) calc(3px * 8) $runner__shadow,
            calc(3px * 6) calc(3px * 8) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 23) calc(3px * 8) $runner__shadow,
            calc(3px * 24) calc(3px * 8) $runner__shadow,
            calc(3px * 25) calc(3px * 8) $runner__shadow,
            calc(3px * 3) calc(3px * 9) $runner__shadow,
            calc(3px * 4) calc(3px * 9) $runner__shadow,
            calc(3px * 5) calc(3px * 9) $runner__shadow,
            calc(3px * 6) calc(3px * 9) $runner__shadow,
            calc(3px * 7) calc(3px * 9) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 21) calc(3px * 9) $runner__shadow,
            calc(3px * 22) calc(3px * 9) $runner__shadow,
            calc(3px * 23) calc(3px * 9) $runner__shadow,
            calc(3px * 24) calc(3px * 9) $runner__shadow,
            calc(3px * 25) calc(3px * 9) $runner__shadow,
            calc(3px * 1) calc(3px * 10) $runner__shadow,
            calc(3px * 2) calc(3px * 10) $runner__shadow,
            calc(3px * 3) calc(3px * 10) $runner__shadow,
            calc(3px * 4) calc(3px * 10) $runner__shadow,
            calc(3px * 6) calc(3px * 10) $runner__shadow,
            calc(3px * 7) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 10) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 21) calc(3px * 10) $runner__shadow,
            calc(3px * 22) calc(3px * 10) $runner__shadow,
            calc(3px * 23) calc(3px * 10) $runner__shadow,
            calc(3px * 24) calc(3px * 10) $runner__shadow,
            calc(3px * 25) calc(3px * 10) $runner__shadow,
            calc(3px * 2) calc(3px * 11) $runner__shadow,
            calc(3px * 3) calc(3px * 11) $runner__shadow,
            calc(3px * 6) calc(3px * 11) $runner__shadow,
            calc(3px * 8) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 22) calc(3px * 11) $runner__shadow,
            calc(3px * 23) calc(3px * 11) $runner__shadow,
            calc(3px * 24) calc(3px * 11) $runner__shadow,
            calc(3px * 25) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 23) calc(3px * 12) $runner__shadow,
            calc(3px * 24) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 9) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 9) calc(3px * 17) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 9) calc(3px * 18) $runner__shadow,
            calc(3px * 10) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 9) calc(3px * 19) $runner__shadow,
            calc(3px * 10) calc(3px * 19) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 24) calc(3px * 19) $runner__shadow,
            calc(3px * 10) calc(3px * 20) $runner__shadow,
            calc(3px * 11) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 23) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 21) calc(3px * 22) $runner__shadow,
            calc(3px * 22) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 23) $runner__shadow,
            calc(3px * 15) calc(3px * 23) $runner__shadow,
            calc(3px * 16) calc(3px * 23) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow,
            calc(3px * 19) calc(3px * 23) $runner__shadow,
            calc(3px * 20) calc(3px * 23) $runner__shadow,
            calc(3px * 15) calc(3px * 24) $runner__shadow,
            calc(3px * 16) calc(3px * 24) $runner__shadow,
            calc(3px * 17) calc(3px * 24) $runner__shadow,
            calc(3px * 19) calc(3px * 24) $runner__shadow,
            calc(3px * 17) calc(3px * 25) $runner__shadow,
            calc(3px * 18) calc(3px * 25) $runner__shadow,
            calc(3px * 19) calc(3px * 25) $runner__shadow;
        box-shadow: calc(3px * 13) calc(3px * 1) $runner__shadow,
            calc(3px * 14) calc(3px * 1) $runner__shadow,
            calc(3px * 12) calc(3px * 2) $runner__shadow,
            calc(3px * 13) calc(3px * 2) $runner__shadow,
            calc(3px * 14) calc(3px * 2) $runner__shadow,
            calc(3px * 15) calc(3px * 2) $runner__shadow,
            calc(3px * 11) calc(3px * 3) $runner__shadow,
            calc(3px * 12) calc(3px * 3) $runner__shadow,
            calc(3px * 13) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 13) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 5) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 23) calc(3px * 7) $runner__shadow,
            calc(3px * 24) calc(3px * 7) $runner__shadow,
            calc(3px * 4) calc(3px * 8) $runner__shadow,
            calc(3px * 5) calc(3px * 8) $runner__shadow,
            calc(3px * 6) calc(3px * 8) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 23) calc(3px * 8) $runner__shadow,
            calc(3px * 24) calc(3px * 8) $runner__shadow,
            calc(3px * 25) calc(3px * 8) $runner__shadow,
            calc(3px * 3) calc(3px * 9) $runner__shadow,
            calc(3px * 4) calc(3px * 9) $runner__shadow,
            calc(3px * 5) calc(3px * 9) $runner__shadow,
            calc(3px * 6) calc(3px * 9) $runner__shadow,
            calc(3px * 7) calc(3px * 9) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 21) calc(3px * 9) $runner__shadow,
            calc(3px * 22) calc(3px * 9) $runner__shadow,
            calc(3px * 23) calc(3px * 9) $runner__shadow,
            calc(3px * 24) calc(3px * 9) $runner__shadow,
            calc(3px * 25) calc(3px * 9) $runner__shadow,
            calc(3px * 1) calc(3px * 10) $runner__shadow,
            calc(3px * 2) calc(3px * 10) $runner__shadow,
            calc(3px * 3) calc(3px * 10) $runner__shadow,
            calc(3px * 4) calc(3px * 10) $runner__shadow,
            calc(3px * 6) calc(3px * 10) $runner__shadow,
            calc(3px * 7) calc(3px * 10) $runner__shadow,
            calc(3px * 8) calc(3px * 10) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 21) calc(3px * 10) $runner__shadow,
            calc(3px * 22) calc(3px * 10) $runner__shadow,
            calc(3px * 23) calc(3px * 10) $runner__shadow,
            calc(3px * 24) calc(3px * 10) $runner__shadow,
            calc(3px * 25) calc(3px * 10) $runner__shadow,
            calc(3px * 2) calc(3px * 11) $runner__shadow,
            calc(3px * 3) calc(3px * 11) $runner__shadow,
            calc(3px * 6) calc(3px * 11) $runner__shadow,
            calc(3px * 8) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 11) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 22) calc(3px * 11) $runner__shadow,
            calc(3px * 23) calc(3px * 11) $runner__shadow,
            calc(3px * 24) calc(3px * 11) $runner__shadow,
            calc(3px * 25) calc(3px * 11) $runner__shadow,
            calc(3px * 9) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 12) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 23) calc(3px * 12) $runner__shadow,
            calc(3px * 24) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 9) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 9) calc(3px * 17) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 9) calc(3px * 18) $runner__shadow,
            calc(3px * 10) calc(3px * 18) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 9) calc(3px * 19) $runner__shadow,
            calc(3px * 10) calc(3px * 19) $runner__shadow,
            calc(3px * 11) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 24) calc(3px * 19) $runner__shadow,
            calc(3px * 10) calc(3px * 20) $runner__shadow,
            calc(3px * 11) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 12) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 23) calc(3px * 21) $runner__shadow,
            calc(3px * 13) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 21) calc(3px * 22) $runner__shadow,
            calc(3px * 22) calc(3px * 22) $runner__shadow,
            calc(3px * 14) calc(3px * 23) $runner__shadow,
            calc(3px * 15) calc(3px * 23) $runner__shadow,
            calc(3px * 16) calc(3px * 23) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow,
            calc(3px * 19) calc(3px * 23) $runner__shadow,
            calc(3px * 20) calc(3px * 23) $runner__shadow,
            calc(3px * 15) calc(3px * 24) $runner__shadow,
            calc(3px * 16) calc(3px * 24) $runner__shadow,
            calc(3px * 17) calc(3px * 24) $runner__shadow,
            calc(3px * 19) calc(3px * 24) $runner__shadow,
            calc(3px * 17) calc(3px * 25) $runner__shadow,
            calc(3px * 18) calc(3px * 25) $runner__shadow,
            calc(3px * 19) calc(3px * 25) $runner__shadow;
    }
    .runner__frame8 {
        -webkit-box-shadow: calc(3px * 9) calc(3px * 0) $runner__black,
            calc(3px * 10) calc(3px * 0) $runner__black,
            calc(3px * 11) calc(3px * 0) $runner__skin,
            calc(3px * 12) calc(3px * 0) $runner__skin,
            calc(3px * 9) calc(3px * -1) $runner__black,
            calc(3px * 10) calc(3px * -1) $runner__suit,
            calc(3px * 11) calc(3px * -1) $runner__black,
            calc(3px * 8) calc(3px * -2) $runner__black,
            calc(3px * 9) calc(3px * -2) $runner__suit,
            calc(3px * 10) calc(3px * -2) $runner__suit,
            calc(3px * 11) calc(3px * -2) $runner__black,
            calc(3px * 8) calc(3px * -3) $runner__black,
            calc(3px * 9) calc(3px * -3) $runner__black,
            calc(3px * 10) calc(3px * -3) $runner__black,
            calc(3px * 11) calc(3px * -3) $runner__black,
            calc(3px * 6) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -4) $runner__black,
            calc(3px * 8) calc(3px * -4) $runner__black,
            calc(3px * 9) calc(3px * -4) $runner__black,
            calc(3px * 10) calc(3px * -4) $runner__black,
            calc(3px * 11) calc(3px * -4) $runner__black,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 5) calc(3px * -5) $runner__skin,
            calc(3px * 6) calc(3px * -5) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__black,
            calc(3px * 9) calc(3px * -5) $runner__skin,
            calc(3px * 10) calc(3px * -5) $runner__skin,
            calc(3px * 11) calc(3px * -5) $runner__black,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 14) calc(3px * -5) $runner__black,
            calc(3px * 15) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -6) $runner__skin,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__skin,
            calc(3px * 8) calc(3px * -6) $runner__skin,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__black,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__suit,
            calc(3px * 14) calc(3px * -6) $runner__suit,
            calc(3px * 15) calc(3px * -6) $runner__black,
            calc(3px * 16) calc(3px * -6) $runner__black,
            calc(3px * 19) calc(3px * -6) $runner__black,
            calc(3px * 20) calc(3px * -6) $runner__black,
            calc(3px * 21) calc(3px * -6) $runner__black,
            calc(3px * 22) calc(3px * -6) $runner__black,
            calc(3px * 23) calc(3px * -6) $runner__black,
            calc(3px * 6) calc(3px * -7) $runner__skin,
            calc(3px * 7) calc(3px * -7) $runner__suit,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__black,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__suit,
            calc(3px * 12) calc(3px * -7) $runner__suit,
            calc(3px * 13) calc(3px * -7) $runner__suit,
            calc(3px * 14) calc(3px * -7) $runner__suit,
            calc(3px * 15) calc(3px * -7) $runner__black,
            calc(3px * 16) calc(3px * -7) $runner__skin,
            calc(3px * 17) calc(3px * -7) $runner__black,
            calc(3px * 18) calc(3px * -7) $runner__black,
            calc(3px * 19) calc(3px * -7) $runner__black,
            calc(3px * 20) calc(3px * -7) $runner__suit,
            calc(3px * 21) calc(3px * -7) $runner__suit,
            calc(3px * 22) calc(3px * -7) $runner__black,
            calc(3px * 23) calc(3px * -7) $runner__black,
            calc(3px * 24) calc(3px * -7) $runner__suit,
            calc(3px * 6) calc(3px * -8) $runner__skin,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__black,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__suit,
            calc(3px * 12) calc(3px * -8) $runner__suit,
            calc(3px * 13) calc(3px * -8) $runner__suit,
            calc(3px * 14) calc(3px * -8) $runner__suit,
            calc(3px * 15) calc(3px * -8) $runner__black,
            calc(3px * 16) calc(3px * -8) $runner__black,
            calc(3px * 17) calc(3px * -8) $runner__black,
            calc(3px * 18) calc(3px * -8) $runner__black,
            calc(3px * 19) calc(3px * -8) $runner__suit,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__black,
            calc(3px * 23) calc(3px * -8) $runner__skin,
            calc(3px * 24) calc(3px * -8) $runner__suit,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__suit,
            calc(3px * 12) calc(3px * -9) $runner__suit,
            calc(3px * 13) calc(3px * -9) $runner__suit,
            calc(3px * 14) calc(3px * -9) $runner__suit,
            calc(3px * 15) calc(3px * -9) $runner__suit,
            calc(3px * 16) calc(3px * -9) $runner__black,
            calc(3px * 17) calc(3px * -9) $runner__black,
            calc(3px * 18) calc(3px * -9) $runner__black,
            calc(3px * 19) calc(3px * -9) $runner__skin,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 21) calc(3px * -9) $runner__suit,
            calc(3px * 22) calc(3px * -9) $runner__suit,
            calc(3px * 23) calc(3px * -9) $runner__suit,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__suit,
            calc(3px * 13) calc(3px * -10) $runner__suit,
            calc(3px * 14) calc(3px * -10) $runner__black,
            calc(3px * 15) calc(3px * -10) $runner__black,
            calc(3px * 16) calc(3px * -10) $runner__black,
            calc(3px * 17) calc(3px * -10) $runner__black,
            calc(3px * 18) calc(3px * -10) $runner__black,
            calc(3px * 19) calc(3px * -10) $runner__suit,
            calc(3px * 20) calc(3px * -10) $runner__suit,
            calc(3px * 21) calc(3px * -10) $runner__suit,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__black,
            calc(3px * 13) calc(3px * -11) $runner__suit,
            calc(3px * 14) calc(3px * -11) $runner__suit,
            calc(3px * 15) calc(3px * -11) $runner__black,
            calc(3px * 16) calc(3px * -11) $runner__suit,
            calc(3px * 17) calc(3px * -11) $runner__suit,
            calc(3px * 18) calc(3px * -11) $runner__suit,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__skin,
            calc(3px * 21) calc(3px * -11) $runner__skin,
            calc(3px * 11) calc(3px * -12) $runner__black,
            calc(3px * 12) calc(3px * -12) $runner__black,
            calc(3px * 13) calc(3px * -12) $runner__hair,
            calc(3px * 14) calc(3px * -12) $runner__hair,
            calc(3px * 15) calc(3px * -12) $runner__hair,
            calc(3px * 16) calc(3px * -12) $runner__hair,
            calc(3px * 17) calc(3px * -12) $runner__hair,
            calc(3px * 18) calc(3px * -12) $runner__hair,
            calc(3px * 19) calc(3px * -12) $runner__skin,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 22) calc(3px * -12) $runner__skin,
            calc(3px * 10) calc(3px * -13) $runner__black,
            calc(3px * 11) calc(3px * -13) $runner__hair,
            calc(3px * 12) calc(3px * -13) $runner__hair,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__hair,
            calc(3px * 15) calc(3px * -13) $runner__black,
            calc(3px * 16) calc(3px * -13) $runner__black,
            calc(3px * 17) calc(3px * -13) $runner__suit,
            calc(3px * 18) calc(3px * -13) $runner__hair,
            calc(3px * 19) calc(3px * -13) $runner__hair,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__eyes,
            calc(3px * 22) calc(3px * -13) $runner__skin,
            calc(3px * 9) calc(3px * -14) $runner__black,
            calc(3px * 10) calc(3px * -14) $runner__hair,
            calc(3px * 11) calc(3px * -14) $runner__hair,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__black,
            calc(3px * 15) calc(3px * -14) $runner__black,
            calc(3px * 16) calc(3px * -14) $runner__black,
            calc(3px * 17) calc(3px * -14) $runner__black,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__skin,
            calc(3px * 21) calc(3px * -14) $runner__eyes,
            calc(3px * 22) calc(3px * -14) $runner__skin,
            calc(3px * 9) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__black,
            calc(3px * 15) calc(3px * -15) $runner__suit,
            calc(3px * 16) calc(3px * -15) $runner__suit,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__hair,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__hair,
            calc(3px * 22) calc(3px * -15) $runner__hair,
            calc(3px * 23) calc(3px * -15) $runner__hair,
            calc(3px * 9) calc(3px * -16) $runner__hair,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__suit,
            calc(3px * 15) calc(3px * -16) $runner__white,
            calc(3px * 16) calc(3px * -16) $runner__suit,
            calc(3px * 17) calc(3px * -16) $runner__black,
            calc(3px * 18) calc(3px * -16) $runner__hair,
            calc(3px * 19) calc(3px * -16) $runner__hair,
            calc(3px * 20) calc(3px * -16) $runner__black,
            calc(3px * 21) calc(3px * -16) $runner__black,
            calc(3px * 22) calc(3px * -16) $runner__black,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 24) calc(3px * -16) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__suit,
            calc(3px * 16) calc(3px * -17) $runner__suit,
            calc(3px * 17) calc(3px * -17) $runner__hair,
            calc(3px * 18) calc(3px * -17) $runner__hair,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__hair,
            calc(3px * 21) calc(3px * -17) $runner__hair,
            calc(3px * 22) calc(3px * -17) $runner__hair,
            calc(3px * 23) calc(3px * -17) $runner__black,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__hair,
            calc(3px * 15) calc(3px * -18) $runner__hair,
            calc(3px * 16) calc(3px * -18) $runner__suit,
            calc(3px * 17) calc(3px * -18) $runner__hair,
            calc(3px * 18) calc(3px * -18) $runner__hair,
            calc(3px * 19) calc(3px * -18) $runner__hair,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__hair,
            calc(3px * 24) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__white,
            calc(3px * 16) calc(3px * -19) $runner__suit,
            calc(3px * 17) calc(3px * -19) $runner__white,
            calc(3px * 18) calc(3px * -19) $runner__white,
            calc(3px * 19) calc(3px * -19) $runner__white,
            calc(3px * 20) calc(3px * -19) $runner__white,
            calc(3px * 21) calc(3px * -19) $runner__white,
            calc(3px * 22) calc(3px * -19) $runner__white,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__white,
            calc(3px * 14) calc(3px * -20) $runner__white,
            calc(3px * 15) calc(3px * -20) $runner__white,
            calc(3px * 16) calc(3px * -20) $runner__suit,
            calc(3px * 17) calc(3px * -20) $runner__hair,
            calc(3px * 18) calc(3px * -20) $runner__hair,
            calc(3px * 19) calc(3px * -20) $runner__hair,
            calc(3px * 20) calc(3px * -20) $runner__hair,
            calc(3px * 21) calc(3px * -20) $runner__hair,
            calc(3px * 22) calc(3px * -20) $runner__hair,
            calc(3px * 23) calc(3px * -20) $runner__white,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__hair,
            calc(3px * 15) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -21) $runner__suit,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__suit,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__hair,
            calc(3px * 19) calc(3px * -22) $runner__hair,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -23) $runner__suit,
            calc(3px * 17) calc(3px * -23) $runner__black,
            calc(3px * 18) calc(3px * -23) $runner__black;
        box-shadow: calc(3px * 9) calc(3px * 0) $runner__black,
            calc(3px * 10) calc(3px * 0) $runner__black,
            calc(3px * 11) calc(3px * 0) $runner__skin,
            calc(3px * 12) calc(3px * 0) $runner__skin,
            calc(3px * 9) calc(3px * -1) $runner__black,
            calc(3px * 10) calc(3px * -1) $runner__suit,
            calc(3px * 11) calc(3px * -1) $runner__black,
            calc(3px * 8) calc(3px * -2) $runner__black,
            calc(3px * 9) calc(3px * -2) $runner__suit,
            calc(3px * 10) calc(3px * -2) $runner__suit,
            calc(3px * 11) calc(3px * -2) $runner__black,
            calc(3px * 8) calc(3px * -3) $runner__black,
            calc(3px * 9) calc(3px * -3) $runner__black,
            calc(3px * 10) calc(3px * -3) $runner__black,
            calc(3px * 11) calc(3px * -3) $runner__black,
            calc(3px * 6) calc(3px * -4) $runner__black,
            calc(3px * 7) calc(3px * -4) $runner__black,
            calc(3px * 8) calc(3px * -4) $runner__black,
            calc(3px * 9) calc(3px * -4) $runner__black,
            calc(3px * 10) calc(3px * -4) $runner__black,
            calc(3px * 11) calc(3px * -4) $runner__black,
            calc(3px * 12) calc(3px * -4) $runner__black,
            calc(3px * 5) calc(3px * -5) $runner__skin,
            calc(3px * 6) calc(3px * -5) $runner__black,
            calc(3px * 7) calc(3px * -5) $runner__black,
            calc(3px * 8) calc(3px * -5) $runner__black,
            calc(3px * 9) calc(3px * -5) $runner__skin,
            calc(3px * 10) calc(3px * -5) $runner__skin,
            calc(3px * 11) calc(3px * -5) $runner__black,
            calc(3px * 12) calc(3px * -5) $runner__black,
            calc(3px * 13) calc(3px * -5) $runner__black,
            calc(3px * 14) calc(3px * -5) $runner__black,
            calc(3px * 15) calc(3px * -5) $runner__black,
            calc(3px * 5) calc(3px * -6) $runner__skin,
            calc(3px * 6) calc(3px * -6) $runner__black,
            calc(3px * 7) calc(3px * -6) $runner__skin,
            calc(3px * 8) calc(3px * -6) $runner__skin,
            calc(3px * 9) calc(3px * -6) $runner__black,
            calc(3px * 10) calc(3px * -6) $runner__black,
            calc(3px * 11) calc(3px * -6) $runner__black,
            calc(3px * 12) calc(3px * -6) $runner__black,
            calc(3px * 13) calc(3px * -6) $runner__suit,
            calc(3px * 14) calc(3px * -6) $runner__suit,
            calc(3px * 15) calc(3px * -6) $runner__black,
            calc(3px * 16) calc(3px * -6) $runner__black,
            calc(3px * 19) calc(3px * -6) $runner__black,
            calc(3px * 20) calc(3px * -6) $runner__black,
            calc(3px * 21) calc(3px * -6) $runner__black,
            calc(3px * 22) calc(3px * -6) $runner__black,
            calc(3px * 23) calc(3px * -6) $runner__black,
            calc(3px * 6) calc(3px * -7) $runner__skin,
            calc(3px * 7) calc(3px * -7) $runner__suit,
            calc(3px * 8) calc(3px * -7) $runner__black,
            calc(3px * 9) calc(3px * -7) $runner__black,
            calc(3px * 10) calc(3px * -7) $runner__black,
            calc(3px * 11) calc(3px * -7) $runner__suit,
            calc(3px * 12) calc(3px * -7) $runner__suit,
            calc(3px * 13) calc(3px * -7) $runner__suit,
            calc(3px * 14) calc(3px * -7) $runner__suit,
            calc(3px * 15) calc(3px * -7) $runner__black,
            calc(3px * 16) calc(3px * -7) $runner__skin,
            calc(3px * 17) calc(3px * -7) $runner__black,
            calc(3px * 18) calc(3px * -7) $runner__black,
            calc(3px * 19) calc(3px * -7) $runner__black,
            calc(3px * 20) calc(3px * -7) $runner__suit,
            calc(3px * 21) calc(3px * -7) $runner__suit,
            calc(3px * 22) calc(3px * -7) $runner__black,
            calc(3px * 23) calc(3px * -7) $runner__black,
            calc(3px * 24) calc(3px * -7) $runner__suit,
            calc(3px * 6) calc(3px * -8) $runner__skin,
            calc(3px * 7) calc(3px * -8) $runner__black,
            calc(3px * 8) calc(3px * -8) $runner__black,
            calc(3px * 9) calc(3px * -8) $runner__black,
            calc(3px * 10) calc(3px * -8) $runner__black,
            calc(3px * 11) calc(3px * -8) $runner__suit,
            calc(3px * 12) calc(3px * -8) $runner__suit,
            calc(3px * 13) calc(3px * -8) $runner__suit,
            calc(3px * 14) calc(3px * -8) $runner__suit,
            calc(3px * 15) calc(3px * -8) $runner__black,
            calc(3px * 16) calc(3px * -8) $runner__black,
            calc(3px * 17) calc(3px * -8) $runner__black,
            calc(3px * 18) calc(3px * -8) $runner__black,
            calc(3px * 19) calc(3px * -8) $runner__suit,
            calc(3px * 20) calc(3px * -8) $runner__suit,
            calc(3px * 21) calc(3px * -8) $runner__suit,
            calc(3px * 22) calc(3px * -8) $runner__black,
            calc(3px * 23) calc(3px * -8) $runner__skin,
            calc(3px * 24) calc(3px * -8) $runner__suit,
            calc(3px * 8) calc(3px * -9) $runner__black,
            calc(3px * 9) calc(3px * -9) $runner__black,
            calc(3px * 10) calc(3px * -9) $runner__black,
            calc(3px * 11) calc(3px * -9) $runner__suit,
            calc(3px * 12) calc(3px * -9) $runner__suit,
            calc(3px * 13) calc(3px * -9) $runner__suit,
            calc(3px * 14) calc(3px * -9) $runner__suit,
            calc(3px * 15) calc(3px * -9) $runner__suit,
            calc(3px * 16) calc(3px * -9) $runner__black,
            calc(3px * 17) calc(3px * -9) $runner__black,
            calc(3px * 18) calc(3px * -9) $runner__black,
            calc(3px * 19) calc(3px * -9) $runner__skin,
            calc(3px * 20) calc(3px * -9) $runner__suit,
            calc(3px * 21) calc(3px * -9) $runner__suit,
            calc(3px * 22) calc(3px * -9) $runner__suit,
            calc(3px * 23) calc(3px * -9) $runner__suit,
            calc(3px * 9) calc(3px * -10) $runner__black,
            calc(3px * 10) calc(3px * -10) $runner__black,
            calc(3px * 11) calc(3px * -10) $runner__black,
            calc(3px * 12) calc(3px * -10) $runner__suit,
            calc(3px * 13) calc(3px * -10) $runner__suit,
            calc(3px * 14) calc(3px * -10) $runner__black,
            calc(3px * 15) calc(3px * -10) $runner__black,
            calc(3px * 16) calc(3px * -10) $runner__black,
            calc(3px * 17) calc(3px * -10) $runner__black,
            calc(3px * 18) calc(3px * -10) $runner__black,
            calc(3px * 19) calc(3px * -10) $runner__suit,
            calc(3px * 20) calc(3px * -10) $runner__suit,
            calc(3px * 21) calc(3px * -10) $runner__suit,
            calc(3px * 10) calc(3px * -11) $runner__black,
            calc(3px * 11) calc(3px * -11) $runner__black,
            calc(3px * 12) calc(3px * -11) $runner__black,
            calc(3px * 13) calc(3px * -11) $runner__suit,
            calc(3px * 14) calc(3px * -11) $runner__suit,
            calc(3px * 15) calc(3px * -11) $runner__black,
            calc(3px * 16) calc(3px * -11) $runner__suit,
            calc(3px * 17) calc(3px * -11) $runner__suit,
            calc(3px * 18) calc(3px * -11) $runner__suit,
            calc(3px * 19) calc(3px * -11) $runner__suit,
            calc(3px * 20) calc(3px * -11) $runner__skin,
            calc(3px * 21) calc(3px * -11) $runner__skin,
            calc(3px * 11) calc(3px * -12) $runner__black,
            calc(3px * 12) calc(3px * -12) $runner__black,
            calc(3px * 13) calc(3px * -12) $runner__hair,
            calc(3px * 14) calc(3px * -12) $runner__hair,
            calc(3px * 15) calc(3px * -12) $runner__hair,
            calc(3px * 16) calc(3px * -12) $runner__hair,
            calc(3px * 17) calc(3px * -12) $runner__hair,
            calc(3px * 18) calc(3px * -12) $runner__hair,
            calc(3px * 19) calc(3px * -12) $runner__skin,
            calc(3px * 20) calc(3px * -12) $runner__skin,
            calc(3px * 21) calc(3px * -12) $runner__skin,
            calc(3px * 22) calc(3px * -12) $runner__skin,
            calc(3px * 10) calc(3px * -13) $runner__black,
            calc(3px * 11) calc(3px * -13) $runner__hair,
            calc(3px * 12) calc(3px * -13) $runner__hair,
            calc(3px * 13) calc(3px * -13) $runner__hair,
            calc(3px * 14) calc(3px * -13) $runner__hair,
            calc(3px * 15) calc(3px * -13) $runner__black,
            calc(3px * 16) calc(3px * -13) $runner__black,
            calc(3px * 17) calc(3px * -13) $runner__suit,
            calc(3px * 18) calc(3px * -13) $runner__hair,
            calc(3px * 19) calc(3px * -13) $runner__hair,
            calc(3px * 20) calc(3px * -13) $runner__skin,
            calc(3px * 21) calc(3px * -13) $runner__eyes,
            calc(3px * 22) calc(3px * -13) $runner__skin,
            calc(3px * 9) calc(3px * -14) $runner__black,
            calc(3px * 10) calc(3px * -14) $runner__hair,
            calc(3px * 11) calc(3px * -14) $runner__hair,
            calc(3px * 12) calc(3px * -14) $runner__hair,
            calc(3px * 13) calc(3px * -14) $runner__hair,
            calc(3px * 14) calc(3px * -14) $runner__black,
            calc(3px * 15) calc(3px * -14) $runner__black,
            calc(3px * 16) calc(3px * -14) $runner__black,
            calc(3px * 17) calc(3px * -14) $runner__black,
            calc(3px * 18) calc(3px * -14) $runner__black,
            calc(3px * 19) calc(3px * -14) $runner__black,
            calc(3px * 20) calc(3px * -14) $runner__skin,
            calc(3px * 21) calc(3px * -14) $runner__eyes,
            calc(3px * 22) calc(3px * -14) $runner__skin,
            calc(3px * 9) calc(3px * -15) $runner__hair,
            calc(3px * 10) calc(3px * -15) $runner__hair,
            calc(3px * 11) calc(3px * -15) $runner__hair,
            calc(3px * 12) calc(3px * -15) $runner__hair,
            calc(3px * 13) calc(3px * -15) $runner__hair,
            calc(3px * 14) calc(3px * -15) $runner__black,
            calc(3px * 15) calc(3px * -15) $runner__suit,
            calc(3px * 16) calc(3px * -15) $runner__suit,
            calc(3px * 17) calc(3px * -15) $runner__black,
            calc(3px * 18) calc(3px * -15) $runner__hair,
            calc(3px * 19) calc(3px * -15) $runner__black,
            calc(3px * 20) calc(3px * -15) $runner__black,
            calc(3px * 21) calc(3px * -15) $runner__hair,
            calc(3px * 22) calc(3px * -15) $runner__hair,
            calc(3px * 23) calc(3px * -15) $runner__hair,
            calc(3px * 9) calc(3px * -16) $runner__hair,
            calc(3px * 10) calc(3px * -16) $runner__hair,
            calc(3px * 11) calc(3px * -16) $runner__hair,
            calc(3px * 12) calc(3px * -16) $runner__hair,
            calc(3px * 13) calc(3px * -16) $runner__hair,
            calc(3px * 14) calc(3px * -16) $runner__suit,
            calc(3px * 15) calc(3px * -16) $runner__white,
            calc(3px * 16) calc(3px * -16) $runner__suit,
            calc(3px * 17) calc(3px * -16) $runner__black,
            calc(3px * 18) calc(3px * -16) $runner__hair,
            calc(3px * 19) calc(3px * -16) $runner__hair,
            calc(3px * 20) calc(3px * -16) $runner__black,
            calc(3px * 21) calc(3px * -16) $runner__black,
            calc(3px * 22) calc(3px * -16) $runner__black,
            calc(3px * 23) calc(3px * -16) $runner__hair,
            calc(3px * 24) calc(3px * -16) $runner__hair,
            calc(3px * 10) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -17) $runner__hair,
            calc(3px * 12) calc(3px * -17) $runner__hair,
            calc(3px * 13) calc(3px * -17) $runner__hair,
            calc(3px * 14) calc(3px * -17) $runner__hair,
            calc(3px * 15) calc(3px * -17) $runner__suit,
            calc(3px * 16) calc(3px * -17) $runner__suit,
            calc(3px * 17) calc(3px * -17) $runner__hair,
            calc(3px * 18) calc(3px * -17) $runner__hair,
            calc(3px * 19) calc(3px * -17) $runner__hair,
            calc(3px * 20) calc(3px * -17) $runner__hair,
            calc(3px * 21) calc(3px * -17) $runner__hair,
            calc(3px * 22) calc(3px * -17) $runner__hair,
            calc(3px * 23) calc(3px * -17) $runner__black,
            calc(3px * 24) calc(3px * -17) $runner__hair,
            calc(3px * 11) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -18) $runner__hair,
            calc(3px * 13) calc(3px * -18) $runner__hair,
            calc(3px * 14) calc(3px * -18) $runner__hair,
            calc(3px * 15) calc(3px * -18) $runner__hair,
            calc(3px * 16) calc(3px * -18) $runner__suit,
            calc(3px * 17) calc(3px * -18) $runner__hair,
            calc(3px * 18) calc(3px * -18) $runner__hair,
            calc(3px * 19) calc(3px * -18) $runner__hair,
            calc(3px * 20) calc(3px * -18) $runner__hair,
            calc(3px * 21) calc(3px * -18) $runner__hair,
            calc(3px * 22) calc(3px * -18) $runner__hair,
            calc(3px * 23) calc(3px * -18) $runner__hair,
            calc(3px * 24) calc(3px * -18) $runner__hair,
            calc(3px * 12) calc(3px * -19) $runner__hair,
            calc(3px * 13) calc(3px * -19) $runner__hair,
            calc(3px * 14) calc(3px * -19) $runner__hair,
            calc(3px * 15) calc(3px * -19) $runner__white,
            calc(3px * 16) calc(3px * -19) $runner__suit,
            calc(3px * 17) calc(3px * -19) $runner__white,
            calc(3px * 18) calc(3px * -19) $runner__white,
            calc(3px * 19) calc(3px * -19) $runner__white,
            calc(3px * 20) calc(3px * -19) $runner__white,
            calc(3px * 21) calc(3px * -19) $runner__white,
            calc(3px * 22) calc(3px * -19) $runner__white,
            calc(3px * 23) calc(3px * -19) $runner__hair,
            calc(3px * 12) calc(3px * -20) $runner__hair,
            calc(3px * 13) calc(3px * -20) $runner__white,
            calc(3px * 14) calc(3px * -20) $runner__white,
            calc(3px * 15) calc(3px * -20) $runner__white,
            calc(3px * 16) calc(3px * -20) $runner__suit,
            calc(3px * 17) calc(3px * -20) $runner__hair,
            calc(3px * 18) calc(3px * -20) $runner__hair,
            calc(3px * 19) calc(3px * -20) $runner__hair,
            calc(3px * 20) calc(3px * -20) $runner__hair,
            calc(3px * 21) calc(3px * -20) $runner__hair,
            calc(3px * 22) calc(3px * -20) $runner__hair,
            calc(3px * 23) calc(3px * -20) $runner__white,
            calc(3px * 13) calc(3px * -21) $runner__white,
            calc(3px * 14) calc(3px * -21) $runner__hair,
            calc(3px * 15) calc(3px * -21) $runner__hair,
            calc(3px * 16) calc(3px * -21) $runner__suit,
            calc(3px * 17) calc(3px * -21) $runner__hair,
            calc(3px * 18) calc(3px * -21) $runner__hair,
            calc(3px * 19) calc(3px * -21) $runner__hair,
            calc(3px * 20) calc(3px * -21) $runner__hair,
            calc(3px * 21) calc(3px * -21) $runner__hair,
            calc(3px * 22) calc(3px * -21) $runner__hair,
            calc(3px * 14) calc(3px * -22) $runner__hair,
            calc(3px * 15) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -22) $runner__suit,
            calc(3px * 17) calc(3px * -22) $runner__hair,
            calc(3px * 18) calc(3px * -22) $runner__hair,
            calc(3px * 19) calc(3px * -22) $runner__hair,
            calc(3px * 20) calc(3px * -22) $runner__hair,
            calc(3px * 16) calc(3px * -23) $runner__suit,
            calc(3px * 17) calc(3px * -23) $runner__black,
            calc(3px * 18) calc(3px * -23) $runner__black;
    }
    .runner__frame8--shadow {
        -webkit-box-shadow: calc(3px * 9) calc(3px * 0) $runner__shadow,
            calc(3px * 10) calc(3px * 0) $runner__shadow,
            calc(3px * 11) calc(3px * 0) $runner__shadow,
            calc(3px * 12) calc(3px * 0) $runner__shadow,
            calc(3px * 9) calc(3px * 1) $runner__shadow,
            calc(3px * 10) calc(3px * 1) $runner__shadow,
            calc(3px * 11) calc(3px * 1) $runner__shadow,
            calc(3px * 8) calc(3px * 2) $runner__shadow,
            calc(3px * 9) calc(3px * 2) $runner__shadow,
            calc(3px * 10) calc(3px * 2) $runner__shadow,
            calc(3px * 11) calc(3px * 2) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 11) calc(3px * 3) $runner__shadow,
            calc(3px * 6) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 4) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 5) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 5) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 14) calc(3px * 5) $runner__shadow,
            calc(3px * 15) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 15) calc(3px * 6) $runner__shadow,
            calc(3px * 16) calc(3px * 6) $runner__shadow,
            calc(3px * 19) calc(3px * 6) $runner__shadow,
            calc(3px * 20) calc(3px * 6) $runner__shadow,
            calc(3px * 21) calc(3px * 6) $runner__shadow,
            calc(3px * 22) calc(3px * 6) $runner__shadow,
            calc(3px * 23) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 16) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 19) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 23) calc(3px * 7) $runner__shadow,
            calc(3px * 24) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 8) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 23) calc(3px * 8) $runner__shadow,
            calc(3px * 24) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 21) calc(3px * 9) $runner__shadow,
            calc(3px * 22) calc(3px * 9) $runner__shadow,
            calc(3px * 23) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 21) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 22) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 23) calc(3px * 15) $runner__shadow,
            calc(3px * 9) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 24) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 23) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow;
        box-shadow: calc(3px * 9) calc(3px * 0) $runner__shadow,
            calc(3px * 10) calc(3px * 0) $runner__shadow,
            calc(3px * 11) calc(3px * 0) $runner__shadow,
            calc(3px * 12) calc(3px * 0) $runner__shadow,
            calc(3px * 9) calc(3px * 1) $runner__shadow,
            calc(3px * 10) calc(3px * 1) $runner__shadow,
            calc(3px * 11) calc(3px * 1) $runner__shadow,
            calc(3px * 8) calc(3px * 2) $runner__shadow,
            calc(3px * 9) calc(3px * 2) $runner__shadow,
            calc(3px * 10) calc(3px * 2) $runner__shadow,
            calc(3px * 11) calc(3px * 2) $runner__shadow,
            calc(3px * 8) calc(3px * 3) $runner__shadow,
            calc(3px * 9) calc(3px * 3) $runner__shadow,
            calc(3px * 10) calc(3px * 3) $runner__shadow,
            calc(3px * 11) calc(3px * 3) $runner__shadow,
            calc(3px * 6) calc(3px * 4) $runner__shadow,
            calc(3px * 7) calc(3px * 4) $runner__shadow,
            calc(3px * 8) calc(3px * 4) $runner__shadow,
            calc(3px * 9) calc(3px * 4) $runner__shadow,
            calc(3px * 10) calc(3px * 4) $runner__shadow,
            calc(3px * 11) calc(3px * 4) $runner__shadow,
            calc(3px * 12) calc(3px * 4) $runner__shadow,
            calc(3px * 5) calc(3px * 5) $runner__shadow,
            calc(3px * 6) calc(3px * 5) $runner__shadow,
            calc(3px * 7) calc(3px * 5) $runner__shadow,
            calc(3px * 8) calc(3px * 5) $runner__shadow,
            calc(3px * 9) calc(3px * 5) $runner__shadow,
            calc(3px * 10) calc(3px * 5) $runner__shadow,
            calc(3px * 11) calc(3px * 5) $runner__shadow,
            calc(3px * 12) calc(3px * 5) $runner__shadow,
            calc(3px * 13) calc(3px * 5) $runner__shadow,
            calc(3px * 14) calc(3px * 5) $runner__shadow,
            calc(3px * 15) calc(3px * 5) $runner__shadow,
            calc(3px * 5) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 6) $runner__shadow,
            calc(3px * 7) calc(3px * 6) $runner__shadow,
            calc(3px * 8) calc(3px * 6) $runner__shadow,
            calc(3px * 9) calc(3px * 6) $runner__shadow,
            calc(3px * 10) calc(3px * 6) $runner__shadow,
            calc(3px * 11) calc(3px * 6) $runner__shadow,
            calc(3px * 12) calc(3px * 6) $runner__shadow,
            calc(3px * 13) calc(3px * 6) $runner__shadow,
            calc(3px * 14) calc(3px * 6) $runner__shadow,
            calc(3px * 15) calc(3px * 6) $runner__shadow,
            calc(3px * 16) calc(3px * 6) $runner__shadow,
            calc(3px * 19) calc(3px * 6) $runner__shadow,
            calc(3px * 20) calc(3px * 6) $runner__shadow,
            calc(3px * 21) calc(3px * 6) $runner__shadow,
            calc(3px * 22) calc(3px * 6) $runner__shadow,
            calc(3px * 23) calc(3px * 6) $runner__shadow,
            calc(3px * 6) calc(3px * 7) $runner__shadow,
            calc(3px * 7) calc(3px * 7) $runner__shadow,
            calc(3px * 8) calc(3px * 7) $runner__shadow,
            calc(3px * 9) calc(3px * 7) $runner__shadow,
            calc(3px * 10) calc(3px * 7) $runner__shadow,
            calc(3px * 11) calc(3px * 7) $runner__shadow,
            calc(3px * 12) calc(3px * 7) $runner__shadow,
            calc(3px * 13) calc(3px * 7) $runner__shadow,
            calc(3px * 14) calc(3px * 7) $runner__shadow,
            calc(3px * 15) calc(3px * 7) $runner__shadow,
            calc(3px * 16) calc(3px * 7) $runner__shadow,
            calc(3px * 17) calc(3px * 7) $runner__shadow,
            calc(3px * 18) calc(3px * 7) $runner__shadow,
            calc(3px * 19) calc(3px * 7) $runner__shadow,
            calc(3px * 20) calc(3px * 7) $runner__shadow,
            calc(3px * 21) calc(3px * 7) $runner__shadow,
            calc(3px * 22) calc(3px * 7) $runner__shadow,
            calc(3px * 23) calc(3px * 7) $runner__shadow,
            calc(3px * 24) calc(3px * 7) $runner__shadow,
            calc(3px * 6) calc(3px * 8) $runner__shadow,
            calc(3px * 7) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 8) $runner__shadow,
            calc(3px * 9) calc(3px * 8) $runner__shadow,
            calc(3px * 10) calc(3px * 8) $runner__shadow,
            calc(3px * 11) calc(3px * 8) $runner__shadow,
            calc(3px * 12) calc(3px * 8) $runner__shadow,
            calc(3px * 13) calc(3px * 8) $runner__shadow,
            calc(3px * 14) calc(3px * 8) $runner__shadow,
            calc(3px * 15) calc(3px * 8) $runner__shadow,
            calc(3px * 16) calc(3px * 8) $runner__shadow,
            calc(3px * 17) calc(3px * 8) $runner__shadow,
            calc(3px * 18) calc(3px * 8) $runner__shadow,
            calc(3px * 19) calc(3px * 8) $runner__shadow,
            calc(3px * 20) calc(3px * 8) $runner__shadow,
            calc(3px * 21) calc(3px * 8) $runner__shadow,
            calc(3px * 22) calc(3px * 8) $runner__shadow,
            calc(3px * 23) calc(3px * 8) $runner__shadow,
            calc(3px * 24) calc(3px * 8) $runner__shadow,
            calc(3px * 8) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 9) $runner__shadow,
            calc(3px * 10) calc(3px * 9) $runner__shadow,
            calc(3px * 11) calc(3px * 9) $runner__shadow,
            calc(3px * 12) calc(3px * 9) $runner__shadow,
            calc(3px * 13) calc(3px * 9) $runner__shadow,
            calc(3px * 14) calc(3px * 9) $runner__shadow,
            calc(3px * 15) calc(3px * 9) $runner__shadow,
            calc(3px * 16) calc(3px * 9) $runner__shadow,
            calc(3px * 17) calc(3px * 9) $runner__shadow,
            calc(3px * 18) calc(3px * 9) $runner__shadow,
            calc(3px * 19) calc(3px * 9) $runner__shadow,
            calc(3px * 20) calc(3px * 9) $runner__shadow,
            calc(3px * 21) calc(3px * 9) $runner__shadow,
            calc(3px * 22) calc(3px * 9) $runner__shadow,
            calc(3px * 23) calc(3px * 9) $runner__shadow,
            calc(3px * 9) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 10) $runner__shadow,
            calc(3px * 11) calc(3px * 10) $runner__shadow,
            calc(3px * 12) calc(3px * 10) $runner__shadow,
            calc(3px * 13) calc(3px * 10) $runner__shadow,
            calc(3px * 14) calc(3px * 10) $runner__shadow,
            calc(3px * 15) calc(3px * 10) $runner__shadow,
            calc(3px * 16) calc(3px * 10) $runner__shadow,
            calc(3px * 17) calc(3px * 10) $runner__shadow,
            calc(3px * 18) calc(3px * 10) $runner__shadow,
            calc(3px * 19) calc(3px * 10) $runner__shadow,
            calc(3px * 20) calc(3px * 10) $runner__shadow,
            calc(3px * 21) calc(3px * 10) $runner__shadow,
            calc(3px * 10) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 11) $runner__shadow,
            calc(3px * 12) calc(3px * 11) $runner__shadow,
            calc(3px * 13) calc(3px * 11) $runner__shadow,
            calc(3px * 14) calc(3px * 11) $runner__shadow,
            calc(3px * 15) calc(3px * 11) $runner__shadow,
            calc(3px * 16) calc(3px * 11) $runner__shadow,
            calc(3px * 17) calc(3px * 11) $runner__shadow,
            calc(3px * 18) calc(3px * 11) $runner__shadow,
            calc(3px * 19) calc(3px * 11) $runner__shadow,
            calc(3px * 20) calc(3px * 11) $runner__shadow,
            calc(3px * 21) calc(3px * 11) $runner__shadow,
            calc(3px * 11) calc(3px * 12) $runner__shadow,
            calc(3px * 12) calc(3px * 12) $runner__shadow,
            calc(3px * 13) calc(3px * 12) $runner__shadow,
            calc(3px * 14) calc(3px * 12) $runner__shadow,
            calc(3px * 15) calc(3px * 12) $runner__shadow,
            calc(3px * 16) calc(3px * 12) $runner__shadow,
            calc(3px * 17) calc(3px * 12) $runner__shadow,
            calc(3px * 18) calc(3px * 12) $runner__shadow,
            calc(3px * 19) calc(3px * 12) $runner__shadow,
            calc(3px * 20) calc(3px * 12) $runner__shadow,
            calc(3px * 21) calc(3px * 12) $runner__shadow,
            calc(3px * 22) calc(3px * 12) $runner__shadow,
            calc(3px * 10) calc(3px * 13) $runner__shadow,
            calc(3px * 11) calc(3px * 13) $runner__shadow,
            calc(3px * 12) calc(3px * 13) $runner__shadow,
            calc(3px * 13) calc(3px * 13) $runner__shadow,
            calc(3px * 14) calc(3px * 13) $runner__shadow,
            calc(3px * 15) calc(3px * 13) $runner__shadow,
            calc(3px * 16) calc(3px * 13) $runner__shadow,
            calc(3px * 17) calc(3px * 13) $runner__shadow,
            calc(3px * 18) calc(3px * 13) $runner__shadow,
            calc(3px * 19) calc(3px * 13) $runner__shadow,
            calc(3px * 20) calc(3px * 13) $runner__shadow,
            calc(3px * 21) calc(3px * 13) $runner__shadow,
            calc(3px * 22) calc(3px * 13) $runner__shadow,
            calc(3px * 9) calc(3px * 14) $runner__shadow,
            calc(3px * 10) calc(3px * 14) $runner__shadow,
            calc(3px * 11) calc(3px * 14) $runner__shadow,
            calc(3px * 12) calc(3px * 14) $runner__shadow,
            calc(3px * 13) calc(3px * 14) $runner__shadow,
            calc(3px * 14) calc(3px * 14) $runner__shadow,
            calc(3px * 15) calc(3px * 14) $runner__shadow,
            calc(3px * 16) calc(3px * 14) $runner__shadow,
            calc(3px * 17) calc(3px * 14) $runner__shadow,
            calc(3px * 18) calc(3px * 14) $runner__shadow,
            calc(3px * 19) calc(3px * 14) $runner__shadow,
            calc(3px * 20) calc(3px * 14) $runner__shadow,
            calc(3px * 21) calc(3px * 14) $runner__shadow,
            calc(3px * 22) calc(3px * 14) $runner__shadow,
            calc(3px * 9) calc(3px * 15) $runner__shadow,
            calc(3px * 10) calc(3px * 15) $runner__shadow,
            calc(3px * 11) calc(3px * 15) $runner__shadow,
            calc(3px * 12) calc(3px * 15) $runner__shadow,
            calc(3px * 13) calc(3px * 15) $runner__shadow,
            calc(3px * 14) calc(3px * 15) $runner__shadow,
            calc(3px * 15) calc(3px * 15) $runner__shadow,
            calc(3px * 16) calc(3px * 15) $runner__shadow,
            calc(3px * 17) calc(3px * 15) $runner__shadow,
            calc(3px * 18) calc(3px * 15) $runner__shadow,
            calc(3px * 19) calc(3px * 15) $runner__shadow,
            calc(3px * 20) calc(3px * 15) $runner__shadow,
            calc(3px * 21) calc(3px * 15) $runner__shadow,
            calc(3px * 22) calc(3px * 15) $runner__shadow,
            calc(3px * 23) calc(3px * 15) $runner__shadow,
            calc(3px * 9) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 16) $runner__shadow,
            calc(3px * 11) calc(3px * 16) $runner__shadow,
            calc(3px * 12) calc(3px * 16) $runner__shadow,
            calc(3px * 13) calc(3px * 16) $runner__shadow,
            calc(3px * 14) calc(3px * 16) $runner__shadow,
            calc(3px * 15) calc(3px * 16) $runner__shadow,
            calc(3px * 16) calc(3px * 16) $runner__shadow,
            calc(3px * 17) calc(3px * 16) $runner__shadow,
            calc(3px * 18) calc(3px * 16) $runner__shadow,
            calc(3px * 19) calc(3px * 16) $runner__shadow,
            calc(3px * 20) calc(3px * 16) $runner__shadow,
            calc(3px * 21) calc(3px * 16) $runner__shadow,
            calc(3px * 22) calc(3px * 16) $runner__shadow,
            calc(3px * 23) calc(3px * 16) $runner__shadow,
            calc(3px * 24) calc(3px * 16) $runner__shadow,
            calc(3px * 10) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 17) $runner__shadow,
            calc(3px * 12) calc(3px * 17) $runner__shadow,
            calc(3px * 13) calc(3px * 17) $runner__shadow,
            calc(3px * 14) calc(3px * 17) $runner__shadow,
            calc(3px * 15) calc(3px * 17) $runner__shadow,
            calc(3px * 16) calc(3px * 17) $runner__shadow,
            calc(3px * 17) calc(3px * 17) $runner__shadow,
            calc(3px * 18) calc(3px * 17) $runner__shadow,
            calc(3px * 19) calc(3px * 17) $runner__shadow,
            calc(3px * 20) calc(3px * 17) $runner__shadow,
            calc(3px * 21) calc(3px * 17) $runner__shadow,
            calc(3px * 22) calc(3px * 17) $runner__shadow,
            calc(3px * 23) calc(3px * 17) $runner__shadow,
            calc(3px * 24) calc(3px * 17) $runner__shadow,
            calc(3px * 11) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 18) $runner__shadow,
            calc(3px * 13) calc(3px * 18) $runner__shadow,
            calc(3px * 14) calc(3px * 18) $runner__shadow,
            calc(3px * 15) calc(3px * 18) $runner__shadow,
            calc(3px * 16) calc(3px * 18) $runner__shadow,
            calc(3px * 17) calc(3px * 18) $runner__shadow,
            calc(3px * 18) calc(3px * 18) $runner__shadow,
            calc(3px * 19) calc(3px * 18) $runner__shadow,
            calc(3px * 20) calc(3px * 18) $runner__shadow,
            calc(3px * 21) calc(3px * 18) $runner__shadow,
            calc(3px * 22) calc(3px * 18) $runner__shadow,
            calc(3px * 23) calc(3px * 18) $runner__shadow,
            calc(3px * 24) calc(3px * 18) $runner__shadow,
            calc(3px * 12) calc(3px * 19) $runner__shadow,
            calc(3px * 13) calc(3px * 19) $runner__shadow,
            calc(3px * 14) calc(3px * 19) $runner__shadow,
            calc(3px * 15) calc(3px * 19) $runner__shadow,
            calc(3px * 16) calc(3px * 19) $runner__shadow,
            calc(3px * 17) calc(3px * 19) $runner__shadow,
            calc(3px * 18) calc(3px * 19) $runner__shadow,
            calc(3px * 19) calc(3px * 19) $runner__shadow,
            calc(3px * 20) calc(3px * 19) $runner__shadow,
            calc(3px * 21) calc(3px * 19) $runner__shadow,
            calc(3px * 22) calc(3px * 19) $runner__shadow,
            calc(3px * 23) calc(3px * 19) $runner__shadow,
            calc(3px * 12) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 20) $runner__shadow,
            calc(3px * 14) calc(3px * 20) $runner__shadow,
            calc(3px * 15) calc(3px * 20) $runner__shadow,
            calc(3px * 16) calc(3px * 20) $runner__shadow,
            calc(3px * 17) calc(3px * 20) $runner__shadow,
            calc(3px * 18) calc(3px * 20) $runner__shadow,
            calc(3px * 19) calc(3px * 20) $runner__shadow,
            calc(3px * 20) calc(3px * 20) $runner__shadow,
            calc(3px * 21) calc(3px * 20) $runner__shadow,
            calc(3px * 22) calc(3px * 20) $runner__shadow,
            calc(3px * 23) calc(3px * 20) $runner__shadow,
            calc(3px * 13) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 21) $runner__shadow,
            calc(3px * 15) calc(3px * 21) $runner__shadow,
            calc(3px * 16) calc(3px * 21) $runner__shadow,
            calc(3px * 17) calc(3px * 21) $runner__shadow,
            calc(3px * 18) calc(3px * 21) $runner__shadow,
            calc(3px * 19) calc(3px * 21) $runner__shadow,
            calc(3px * 20) calc(3px * 21) $runner__shadow,
            calc(3px * 21) calc(3px * 21) $runner__shadow,
            calc(3px * 22) calc(3px * 21) $runner__shadow,
            calc(3px * 14) calc(3px * 22) $runner__shadow,
            calc(3px * 15) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 22) $runner__shadow,
            calc(3px * 17) calc(3px * 22) $runner__shadow,
            calc(3px * 18) calc(3px * 22) $runner__shadow,
            calc(3px * 19) calc(3px * 22) $runner__shadow,
            calc(3px * 20) calc(3px * 22) $runner__shadow,
            calc(3px * 16) calc(3px * 23) $runner__shadow,
            calc(3px * 17) calc(3px * 23) $runner__shadow,
            calc(3px * 18) calc(3px * 23) $runner__shadow;
    }
}
